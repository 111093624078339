<template>
  <div class="sourceStore-container">
    <van-nav-bar title="社区金点子" left-arrow @click-left="onClickLeft" />
    <div class="golden-idea-body-container">
      <div class="class-source-con">
        <div class="class-source-list">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="已经到底啦~"
            @load="onLoad"
            class="recommed-list"
          >
            <ul>
              <li v-for="(item, index) in dataList" :key="index">
                <div class="title">
                  {{ `对于${item.proposalType}的建议`}}
                  <van-tag>{{item.adoptStatus}}</van-tag>
                </div>
                <div class="desc">
                  <div
                    :class="['info', expande ? 'expande' : 'close']"
                    ref="content"
                  >
                    {{ item.ideaIntro }}
                  </div>
                  <div class="date">
                    <i></i><span>{{ item.proposalTime }}</span>
                  </div>
                </div>
              </li>
            </ul></van-list
          >
        </div>
      </div>
      <!-- <div class="footer-button">
        <van-button
          block
          type="info"
          style="font-size: 16px"
          native-type="submit"
          >管理</van-button
        >
      </div> -->
    </div>
    <div class="tab-flex-bottom" @click="gotoLink('publishGoldIdea')">有金点子</div>
  </div>
</template>

<script>
import api from "api";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      listFinished: false,
      expande: true,
      needShowExpande: false,
      dataList: [],
      current: 0,
      pageSize: 10,
    };
  },
  computed: {},
  components: {},
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onLoad() {
      this.current = this.current + 1;
      this.getGoldenIdea();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getGoldenIdea({ page = this.current, size = this.pageSize } = {}) {
      this.loading = false;
      api.getProposalList({
        page,
        size,
      }).then(res => {
        const { list = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.dataList = list;
        } else {
          this.dataList = Array.from(new Set(this.dataList.concat(list)))
        }
        this.total = total;
        if (this.dataList.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }
      })
    }
  },
};
</script>

<style lang="scss">
.sourceStore-container {
  height: 100vh;
  width: 100vw;

  .golden-idea-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 50px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #ededed;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #e3effd;
            color: #4479ed;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
    .recommed-list {
      li {
        margin-bottom: 0.8rem;
        .title {
          background-image: linear-gradient(270deg, #79b1f7 0%, #4479ed 100%);
          border-radius: 4px 4px 0 0;
          height: 2.7rem;
          padding: 0 0.8rem;
          color: #fff;
          line-height: 2.7rem;
          font-size: 1rem;
          position: relative;
          .van-tag {
            background: #d5f4e2;
            color: #13bb49;
            font-size: 0.4rem;
            padding: 0 0.4rem;
            height: 20px;
            border-radius: 2px 6px 2px 6px;
            position: absolute;
            right: 0.8rem;
            top: 0.7rem;
          }
        }
        .desc {
          background: #fff;
          border-radius: 0 0 4px 4px;
          padding: 0.5rem 0.7rem;
          .name {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.85);
            line-height: 1.6rem;
            span {
              color: rgba(0, 0, 0, 0.45);
              font-size: 0.9rem;
              margin-left: 0.3rem;
            }
          }
          .info {
            font-size: 0.9rem;
            line-height: 1.4rem;
            color: rgba(0, 0, 0, 0.85);
            overflow: hidden;
            label {
              color: rgba(0, 0, 0, 0.45);
              width: 4.5rem;
              display: inline-block;
              vertical-align: top;
            }
            span {
              width: 17.4rem;
              display: inline-block;
            }
          }
          .close {
            height: 4.2rem;
            overflow: hidden;
          }
          .date {
            border-top: 1px solid rgba(17, 31, 44, 0.1);
            padding: 0.7rem 0 0.2rem;
            color: rgba(0, 0, 0, 0.45);
            font-size: 0.9rem;
            margin-top: 0.4rem;
            line-height: 1.4rem;
            i {
              display: inline-block;
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/date.png") no-repeat;
              background-size: 100% 100%;
              height: 1.2rem;
              width: 1.2rem;
              margin-right: 0.4rem;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      .van-button--info {
        border-radius: 0.5rem;
        background: #4479ed;
      }
    }
    .van-list__finished-text {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .tab-flex-bottom {
    position: fixed;
    bottom: 4rem;
    right: 10px;
    background: #4479ed;
    box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    color: #fff;
    padding: 0.8rem;
    font-size: 0.85rem;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.2rem;
  }
}
</style>
