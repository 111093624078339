<template>
  <div class="container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>体检列表</h1>
    </div>
    <div class="main">
      <div class="form">
        <div class="sub-main">
          <label>体检详情</label>
          <div class="sub-content">
            <div class="cont">
              <div class="info">
                <span class="label">体检日期</span
                ><span class="val"> {{ examDetail.tjrq }}</span>
              </div>
              <div class="info">
                <span class="label">健康评价</span
                ><span class="val"> {{ examDetail.jkpj }}</span>
              </div>
              <div class="info">
                <span class="label">健康指导</span
                ><span class="val"> {{ examDetail.jkzd }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-main">
          <label>体检明细</label>
          <!-- <div class="sub-content" style="height:auto;"> -->
          <div class="sub-content">
            <div class="cont block">
              <template v-for="item in examDetail.tjjg">
                <div class="sub-cont" v-if="item.xmjg && item.xmjg != ''">
                  <div class="info">
                    <span class="label">项目名称</span
                    ><span class="val"> {{ item.xmmc }}</span>
                  </div>
                  <div class="info">
                    <span class="label">项目结果</span
                    ><span class="val"> {{ item.xmjg }}</span>
                  </div>
                  <div class="info" v-if="item.xmdw">
                    <span class="label">项目单位</span
                    ><span class="val"> {{ item.xmdw }}</span>
                  </div>
                  <div class="info" v-if="item.bz">
                    <span class="label">备注</span
                    ><span class="val"> {{ item.bz }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      examDetail: {}
    };
  },
  components: {},
  mounted() {
    this.getExamDetail();
  },
  methods: {
    getExamDetail: function() {
      let _this = this;
      let token = this.getSessionData("healthtToken");
      let id = this.$route.params.id;
      this.axios
        .get("https://yyh.ikeqiao.net/app/health/examDtl/" + id, {
          headers: { token: token },
        })
        .then(function(res) {
          _this.examDetail = res.data.examDetail;
        })
        .catch(function(err) {});
    },
    gotoLink(path) {
      this.$router.push(path);
    },
      goBack(){
          this.$router.go(-1);
      }

  },
};
</script>

<style lang="scss">
@import "~assets/scss/health.scss";
</style>
