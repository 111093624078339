<template>
  <div class="yijiagou-container">
    <van-nav-bar
      title="伊·架构"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yijiagou-body-container">
      <!-- 妇联执委 -->
      <div class="top-bar">
        <h1>妇联执委</h1>
        <ul class="flzw-list">
          <li class="flzw-item" v-for="item in flzwList" :key="item.identity">
            <img :src="item.image" alt="">
            <div class="flzw-right">
              <div class="flzw-title-row">
                <h1>{{item.username || ''}}</h1>
                <span>{{item.political || ''}}</span>
              </div>
              <div class="flzw-row">
                {{`文化程度：${item.education || ''}`}}
              </div>
              <div class="flzw-row">
                {{`执委分工：${item.divideWork || ''}`}}
              </div>
              <div class="flzw-tag" :style="{color: colors[item.identity], background: backgrounds[item.identity]}">
                {{item.identity}}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 荣誉榜 -->
      <div class="rongyubang-bar top-bar">
        <h1>荣誉榜</h1>
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/rongyubang.png" class="rongyubang-bg" alt="">
        <div class="rongyubang-list">
          <div class="rongyubang-item" v-for="item in rongyubangList" :key="item.title">
            <div class="rongyubang-item-title">
              <i class="dot"></i>
              <span>{{item.title}}</span>
            </div>
            <div class="rongyubang-item-con">
              <div v-for="text in item.list" :key="text">
                <i class="rongyu"></i>
                <span>{{text}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      flzwList: [
        // {
        //   name: '吴亚红',
        //   title: '主席',
        //   educationLevel: '大专',
        //   workContent: '主持社区妇联全面工作。',
        //   identity: '党员',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/wyh.jpg'
        // },
        // {
        //   name: '孙小平',
        //   title: '副主席',
        //   educationLevel: '本科',
        //   workContent: '家庭建设、妇女维权、女性创业、巾帼建功',
        //   identity: '党员',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/sxp.jpg'
        // },
        // {
        //   name: '杨璎',
        //   title: '副主席',
        //   educationLevel: '大专',
        //   workContent: '妇女发展、宣传文体、组织建设、建档立制',
        //   identity: '党员',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/yy.jpg'
        // },
        // {
        //   name: '方圆',
        //   title: '执委',
        //   educationLevel: '本科',
        //   workContent: '宣传工作，文体活动',
        //   identity: '预备党员',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fy.jpg'
        // },
        // {
        //   name: '李银曼',
        //   title: '执委',
        //   educationLevel: '本科',
        //   workContent: '女性创业，巾帼建功，妇女维权',
        //   identity: '党员',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/lym.jpg'
        // },
        // {
        //   name: '周玲华',
        //   title: '执委',
        //   educationLevel: '高中',
        //   workContent: '组织网格，建档立志，公益活动，志愿服务',
        //   identity: '群众',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/zlh.png'
        // },
        // {
        //   name: '吴增芬',
        //   title: '执委',
        //   educationLevel: '大专',
        //   workContent: '家庭建设、计划生育、反家暴宣传',
        //   identity: '党员',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/wzf.png'
        // },
      ],
      rongyubangList: [
        {
          title: '2021年',
          list: [
            '第一季度浙江省“最美家庭”','绍兴市“最美家庭”'
          ]
        },
        {
          title: '2019年',
          list: [
            '诸暨市巾帼文明岗','浙江省儿童之家规范化建设试点单位','浙江省家长示范学校',
          ]
        },
        {
          title: '2017年',
          list: [
            '绍兴市优秀基层妇联',
          ]
        },
        {
          title: '2015年',
          list: [
            '诸暨市三八红旗集体',
          ]
        },
      ],
      colors: {
        '党员': '#E13F30',
        '预备党员': '#E5B20A',
        '群众': '#4479ED'
      },
      backgrounds: {
        '党员': ' #F7EAEB',
        '预备党员': '#FFF6D9',
        '群众': '#E4EDFE'
      }
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.getDataList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getDataList() {
      Api.getFLZWData().then(res => {
        this.flzwList = res.data || [];
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.yijiagou-container {
  height: 100vh;
  width: 100vw;
  
  .yijiagou-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100vw;
      padding: 0 1rem 0 1rem;
      background-color: #FFF;
      margin-bottom: 10px;
      >h1 {
        line-height: 48px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
      }
      .flzw-list {
        .flzw-item {
          width: 100%;
          display: flex;
          padding-top: 14px;
          >img {
            width: 60px;
            height: 60px;
            border-radius: 30px;
          }
          .flzw-right {
            flex: 1;
            margin-left: 16px;
            position: relative;
            padding-bottom: 14px;
            border-bottom: 0.5px solid rgba(0,0,0,0.1);
            .flzw-title-row {
              line-height: 36px;
              display: flex;
              >h1 {
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                font-weight: 500;
                margin-right: 10px;
              }
              >span {
                font-size: 14px;
                color: rgba(0,0,0,0.45);
              }
            }
            .flzw-row {
              line-height: 24px;
              font-size: 14px;
              color: rgba(0,0,0,0.65);
            }
            .flzw-tag {
              border-radius: 2px 8px;
              padding: 3px 10px;
              font-size: 12px;
              position: absolute;
              top: 10px;
              right: 10px;
            }
          }
        }
      }
    }
    .rongyubang-bar {
      position: relative;
      margin-bottom: 0;
      >h1 {
        position: relative;
        z-index: 1;
      }
      .rongyubang-bg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
      }
      .rongyubang-list {
        position: relative;
        z-index: 1;
        .rongyubang-item {
          
          .rongyubang-item-title {
            .dot {
              width: 10px;
              height: 10px;
              border: 2px solid #E9E9E9;
              border-radius: 5px;
              display: inline-block;
              margin-left: -3px;
            }
            >span {
              line-height: 24px;
              margin-left: 10px;
              font-size: 14px;
              color: rgba(0,0,0,0.85);
            }
          }
          .rongyubang-item-con {
            padding: 1rem;
            border-left: 2px solid #E9E9E9;
            >div {
              display: flex;
              align-items: center;
              .rongyu {
                width: 32px;
                height: 32px;
                display: inline-block;
                background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/rongyu.png') no-repeat;
                background-size: 100% 100%;
                margin-right: 7px;
              }
              >span {
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                font-weight: 500;
              }
            }
            
          }
        }
      }
    }
  }
}
</style>
