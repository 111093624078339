<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>{{title}}</h1>
      </div>
      <ul>
        <li class="fl-item" v-for="(item, index) in list" :key="index"  @click="openArticleUrl(item)">
          <div class="fl-title">
            <div>
              <h1>{{item.title}}</h1>
              <div class="fl-desc">
                <i class="time"></i>
                <span style="margin-right: 10px;">{{item.updateTime}}</span>
                <i class="view"></i>
                <span>{{item.visitsUp}}</span>
              </div>
            </div>
            <img v-if="item.cover" :src="item.cover" alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Api from 'api';
export default {
  data() {
    return {
      showList: [],
      list: [
        // {
        //   id: 20,
        //   title: "伊家清风小讲坛丨“红色家书 清白家风”第二期——修身篇",
        //   update: "2021-08-06",
        //   view: 22,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj1.png",
        //   url: 'https://mp.weixin.qq.com/s/fpNDfYQJsxenXVBjd7O7pQ',
        // },
        // {
        //   id: 21,
        //   title: "伊家清风小讲坛丨“红色家书 清白家风”第一期——明志篇",
        //   update: "2021-07-30",
        //   view: 11,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj2.webp",
        //   url: 'https://mp.weixin.qq.com/s/qJmWWU9Ed8OUVh4h07lb9g',
        // },
        // {
        //   id: 22,
        //   title: "【肖姐姐有话说】新《未成年人保护法》解读",
        //   update: "2021-06-01",
        //   view: 34,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj3.webp",
        //   url: 'https://mp.weixin.qq.com/s/BALSaShHMDxgblwLvsXpDQ',
        // },
        // {
        //   id: 23,
        //   title: "【肖姐姐有话说】离婚冷静期",
        //   update: "2021-04-02",
        //   view: 29,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj4.webp",
        //   url: 'https://mp.weixin.qq.com/s/unKRsAR557cL6Es5j565oQ',
        // },
        // {
        //   id: 24,
        //   title: "【肖姐姐有话说】预防未成年人被性侵",
        //   update: "2021-08-17",
        //   view: 18,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj5.webp",
        //   url: 'https://mp.weixin.qq.com/s/hwUMsujsamy7XeYIhpAQ0g',
        // },
        // {
        //   id: 25,
        //   title: "【肖姐姐有话说】《浙江省家庭教育促进条例》解读",
        //   update: "2021-06-05",
        //   view: 21,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj6.webp",
        //   url: 'https://mp.weixin.qq.com/s/PCU-_EYLnFatG-y7JCoxJg',
        // },
        // {
        //   id: 26,
        //   title: "【肖姐姐有话说】后疫情期间的夫妻关系如何调适？",
        //   update: "2021-03-27",
        //   view: 31,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj7.webp",
        //   url: 'https://mp.weixin.qq.com/s/IvJb6aCaLKRGSpKqRhIw8Q',
        // },
      ],
      list2: [
        // {
        //   id: 10,
        //   title: "伊家引领丨区妇联召开第三场村社退职妇联主席“伊家畅聊会”",
        //   update: "2021-04-07",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj1.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652580107&idx=1&sn=5beca6aae900b1bc5d1a9c9b0769bc58&scene=19#wechat_redirect',
        // },
        // {
        //   id: 11,
        //   title: "伊家助力丨“都是伊家”慈爱基金首笔资金发放",
        //   update: "2021-04-03",
        //   view: 13,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj2.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652580001&idx=1&sn=b8727bcbfefae8172cd4bcdb533e3557&scene=19#wechat_redirect',

        // },
        // {
        //   id: 12,
        //   title: "伊家引领丨区妇联召开第二场村社退职妇联主席“伊家畅聊会”",
        //   update: "2021-04-03",
        //   view: 13,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj3.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652579677&idx=1&sn=facebf25594fa9426601ccdf8db4ee0e&scene=19#wechat_redirect',
        // },
        // {
        //   id: 13,
        //   title: "伊家引领丨区妇联召开村社退职妇联主席“伊家畅聊会”",
        //   update: "2021-03-06",
        //   view: 13,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj4.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652579512&idx=1&sn=84cbcbbf49dfcb3bda670dd8e7bfe5bd&scene=19#wechat_redirect',
        // },
        // {
        //   id: 14,
        //   title: "伊家引领丨诸暨市三八红旗手、三八红旗集体名单公示",
        //   update: "2021-03-05",
        //   view: 13,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj5.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652578551&idx=2&sn=9d445eb50d9f633010e1225c96bdf44e&scene=19#wechat_redirect',
        // },
      ],
      title: '',
    };
  },
  components: {},
  mounted() {
    const { key } = this.$route.query;
    this.title = key == 3 ? '云上伊家' : '风采伊家';
    this.getDataList(key)
    // this.showList = key == 1 ? this.list : this.list2;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path.indexOf('http') === -1) {
        this.$router.push(path);
      } else {
        window.open(path);
      }
    },
    openArticleUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    getDataList(type) {
      Api.getHongArticleList({
        page: 1,
        pageSize: 20,
        type,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        this.list = records;
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .fl-item {
    margin: 10px 10px 0 10px;
    padding: 10px 0;
    .fl-title {
      display: flex;
      align-items: flex-start;
      >img {
        width: 7rem;
        margin-left: 10px;
      }
      >div {
        flex: 1;
        >h1 {
          font-size: 16px;
          line-height: 32px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
        }
        .fl-desc {
          display: flex;
          height: 24px;
          align-items: center;
          font-size: 12px;
          color: rgba(0,0,0,0.45);
          .time, .view {
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
            background-size: 100% 100%;
          }
          .view {
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      
    }
    
  }
}
</style>
