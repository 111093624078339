<template>
  <div class="video-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="video-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="video-con">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/building/huangshelou.png" alt="">
          <!-- <span class="tag">{{data.tag}}</span> -->
        </div>
        <div class="info-con">
          <h1>{{data.title}}</h1>
          <div class="info-foot">
            <span>{{data.location}}</span>
          </div>
        </div>
      </div>
      <!-- 更多信息 -->
      <van-list class="more-info-con">
        <van-cell center :title="data.time" >
          <template #icon>
            <img class="icon-img" src="~assets/images/building/icon_activity_date.png" alt="">
          </template>
        </van-cell>
        <van-cell center :title="data.location" is-link >
          <template #icon>
            <img class="icon-img" src="~assets/images/building/icon_activity_addr.png" alt="">
          </template>
        </van-cell>
        <van-cell center :title="`${data.fzr}(点击呼叫)`" >
          <template #icon>
            <img class="icon-img" src="~assets/images/building/icon_activity_user.png" alt="">
          </template>
        </van-cell>
      </van-list>
      <!-- 活动详情 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">活动详情</span>
          </div>
          <!-- <span class="text-title">东盛共建岗</span> -->
        </div>
        <div class="card-bar-content">
          <p class="comment-desc">{{data.desc}}</p>
        </div>
      </div>
      <!-- 活动参与人 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">活动参与人</span>
          </div>
          <!-- <span class="text-title">东盛共建岗</span> -->
        </div>
        <div class="card-bar-content">
          <van-cell center title="李建东" label="华社街道" >
            <template #icon>
              <img class="avatar-img" src="~assets/images/building/icon_activity_addr.png" alt="">
            </template>
          </van-cell>
          <van-cell center title="李建东" label="华社街道" >
            <template #icon>
              <img class="avatar-img" src="~assets/images/building/icon_activity_addr.png" alt="">
            </template>
          </van-cell>
          <van-cell center title="李建东" label="华社街道" >
            <template #icon>
              <img class="avatar-img" src="~assets/images/building/icon_activity_addr.png" alt="">
            </template>
          </van-cell>
        </div>
      </div>
      <!-- 详情介绍/评论 -->
      <!-- <div class="middle-bar">
        <van-tabs v-model="selectedTab" @click="handleTab" color="#4479ED">
          <van-tab title="详情介绍" name="introduce">
            <p class="comment-desc">{{data.desc}}</p>
          </van-tab>
          <van-tab :title="`评论（${data.commentList.length}）`" name="comment">
            <commentList :dataList="data.commentList"></commentList>
          </van-tab>
        </van-tabs>
      </div> -->
      <!-- 评论 -->
      <!-- <div class="footer" v-if="selectedTab === 'comment'">
        <van-field
          v-model="inputItem"
          placeholder="发布你的感想"
          shape="round"
          class="send-input"
          clearable
        >
          <template #left-icon>
            <img class="send-image" src="http://dssq.ikeqiao.net/data/image/h5/images/coordination/send.png" alt="">
          </template>
        </van-field>
        <van-button class="send-btn" size="small">发送</van-button>
      </div> -->
      <!-- 按钮 -->
      <div class="footer-button">
        <van-button
          v-if="data.status === '未报名'"
          block
          type="info"
          style="font-size: 16px"
          @click="handleApply"
        >
          我要报名
        </van-button>
        <van-button
          v-if="data.status === '已报名'"
          block
          style="font-size: 16px;margin-right: 5px;"
          @click="handleCancelApply"
        >
          取消报名
        </van-button>
        <van-button
          v-if="data.status === '已签到'"
          block
          type="info"
          plain
          style="font-size: 16px;margin-right: 5px;"
        >
          反馈评价
        </van-button>
        <van-button
          v-if="data.status === '已报名' || data.status === '已签到'"
          block
          type="info"
          style="font-size: 16px"
          @click="showSign = true"
        >
          {{`签到${selectedMembers.length}/${digitalMembers.length}`}}
        </van-button>
      </div>
    </div>
    
    <!-- 报名窗口 -->
    <van-overlay show v-if="show || showSign" @click.stop>
      <div class="wrapper">
        <div class="register-window">
          <div class="register-window-top">
            <b>{{`请选择${showSign ? '签到' : '报名'}`}}</b>
            <div class="close" v-if="show" @click="gotoLink('myFamily')">
              <van-icon size="16" color="#FFF"  name="apps-o" />
              管理
            </div>
          </div>
          <div class="register-window-content">
            <van-cell v-for="(item, index) in digitalMembers" :key="`${item.name}_${index}`" :title="item.name" icon="user-circle-o">
              <template #default>
                  <input @click="handleClick(item)" :checked="isSelected(item)" type="checkbox" />
                </template>
            </van-cell>
          </div>
          <div class="register-window-footer">
            <button class="cancle-btn" v-if="show" @click="show = false;">取消</button>
            <button class="cancle-btn" v-if="showSign" @click="showSign = false;">取消</button>
            <button v-if="show" class="confirm-btn" @click="handleWindow">确认</button>
            <button v-if="showSign" class="confirm-btn" @click="handleSign">提交</button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import commentList from 'components/commentList.vue';
export default {
  data() {
    return {
      data: {
        title: '黄社溇社区第三季度在职党员活动',
        time: '09-27 19:00至21:00',
        location: '黄社溇社区党群服务中心',
        status: '未报名',
        fzr: '缪超男',
        phone: '',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/videoDefault.png',
        desc: '详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍',
        commentList: [
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '公共资源需要大家共同承担。',
            hasThumbsUp: '未点赞',
            thumbsUp: 23,
          },
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '公共资源需要大家共同承担。',
            hasThumbsUp: '已点赞',
            thumbsUp: 23,
          }
        ]
      },
      inputItem: '', // 感想
      selectedTab: 'introduce',
    };
  },
  computed: {
  },
  components: { commentList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.video-detail-container {
  height: 100vh;
  width: 100vw;
  
  .video-detail-body-container {
    height: calc(100vh - 136px);
    width: 100vw;
    background-color: #F4F5F8;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .top-bar {
      width: 100%;
      background: #FFF;
      margin-bottom: 10px;
      .video-con {
        width: 100%;
        height: 240px;
        // background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/videoDefault.png) no-repeat;
        // background-size: 100% 100%;
        position: relative;
        >img {
          height: 100%;
          width: 100%;
        }
        .tag {
          position: absolute;
          top: 12px;
          right: 12px;
          width: 70px;
          height: 24px;
          background: rgba(252,235,234,0.9);
          font-size: 14px;
          color: #E13F30;
          line-height: 24px;
          text-align: center;
          border-radius: 8px 2px 8px 2px;
        }
      }
      .info-con {
        width: 100%;
        padding: 12px;
        >h1 {
          font-size: 16px;
          color: #000000;
          line-height: 26px;
          font-weight: 500;
        }
        .info-foot {
          font-size: 14px;
          color: rgba(0,0,0,0.45);
          text-align: left;
          line-height: 24px;
          font-weight: 400;
          >span {
            margin-right: 16px;
          }
        }
      }
    }

    .more-info-con {
      .icon-img {
        width: 20px;
        height: 20px;
        margin-right: 14px;
      }
    }

    .card-bar {
      width: 100%;
      margin-top: 10px;
      background-color: #FFF;
      .card-bar-title {
        width: 100%;
        // height: 2rem;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,0.05);
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            line-height: 24px;
          }
        }
      }
      .card-bar-content {
        padding: 10px;
        width: 100%;
        font-size: 16px;
        color: rgba($color: #000000, $alpha: 0.85);
        
        line-height: 24px;
        .avatar-img {
          height: 60px;
          width: 60px;
          border-radius: 30px;
          margin-right: 16px;
        }
      }
    }

    .middle-bar {
      width: 100%;
      flex: 1;
      background: #FFF;
      .comment-desc {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: left;
        line-height: 24px;
        font-weight: 400;
        padding: 10px 15px;
        width: 100%;
        height: 100%;
      }
    }

    .footer {
      position: fixed;
      bottom: 10px;
      left: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      .send-input {
        background: #F4F5F8;
        font-size: 15px;
        color: rgba(0,0,0,0.35);
        display: flex;
        align-items: center;
        border-radius: 20px;
        width: 82%;
        height: 36px;
        .send-image {
          height: 18px;
          width: 18px;
        }
      }
      .send-btn {
        flex: 1;
        // margin-left: 10px;
        border: none;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: center;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 0.8rem;
      display: flex;
      background-color: #FFF;
      border-top: 1px solid rgba(0,0,0,0.01);
      align-items:  center;
    }

  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .register-window {
      background-color: #FFF;
      border-radius: 8px;
      
      font-size: 14px;
      width: 85vw;
      .register-window-top {
        width: 100%;
        height: 48px;
        background-color:  #4479ED;
        font-size: 16px;
        color: #FFF;
        text-align: center;
        line-height: 48px;
        border-radius: 8px 8px 0 0;
        position: relative;
        .close {
          position: absolute;
          top: 16px;
          right: 10px;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 16px;
        }
      }
      .register-window-content {
        width: 100%;
        // text-align: center;
        line-height: 20px;
        padding: 6px 0;
        
      }
      .register-window-footer {
        width: 100%;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 48px;
        border-top: 0.5px solid rgba(0,0,0,0.1);
        .confirm-btn {
          flex: 1;
          color: #4479ed;
          border: none;
          background: none;
        }
        .cancle-btn {
          flex: 1;
          color: rgba(0, 0, 0, 0.45);
          border-right: 0.5px solid rgba(0,0,0,0.1);
          background: none;
          border-top: none;
          border-left: none;
          border-bottom: none;
        }
      }
    }
  }
}
</style>
