<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="borrow-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index">
        <div class="borrow-list-top">
          <span>{{item.toolName}}</span>
          <span :style="{color: colors[item.checked]}">{{statusList[item.checked]}}</span>
        </div>
        <div class="borrow-list-row">
          <span class="label">借用人：</span>
          <span>{{item.rentUser}}</span>
        </div>
        <div class="borrow-list-row">
          <span class="label">联系电话：</span>
          <span>{{item.rentPhone}}</span>
        </div>
        <div class="borrow-list-row">
          <span class="label">借用时间：</span>
          <span>{{item.rentTime}}</span>
        </div>
        <div class="borrow-list-row">
          <span class="label">归还时间：</span>
          <span>{{item.returnTime}}</span>
        </div>
        <div class="borrow-list-row">
          <span class="label">借用用途：</span>
          <span>{{item.rentReason}}</span>
        </div>
        <div class="borrow-list-footer" v-if="item.checked === '1'">
          <button class="refuse-btn" @click="handleCheck(item, '3')">不通过</button>
          <button class="agree-btn" @click="handleCheck(item, '2')">通过借用</button>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import api from "api";
import { mapState } from 'vuex';
export default {
  name: 'borrowList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
      colors: {
        '1': '#4479ED',
        '2': '#E5B20A',
        '3': '#ce1a21',
        '4': 'rgba(0,0,0,0.45)',
      },
      statusList: {
        '1': '待处理',
        '2': '已通过',
        '3': '不通过',
        '4': '已过期'
      }
    }
  },
  props: {
    type: {
      type: String,
      default: function() {
        return '1';
      }
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    handleCheck(data, status) {
      api.handleBorrow({
        id: data.id,
        checked: status,
      }).then(res => {
        if(res.flag) {
          this.$toast('处理成功');
          this.current = 1;
          this.getDataList({page: 1});
        }
      })
    },
    getDataList({ page = this.page, size = this.pageSize } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      api
        .getBorrowList({
          page,
          size,
          object: {
            checked: this.type,
          },
        })
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
          const { list = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = list;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(list)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
      // this.dataList = new Array(4).fill({
      //     toolName: '梯子',
      //     borrowUser: '张三',
      //     contactPhone: '13200009393',
      //     borrowTime: '2021-08-30 12:00',
      //     returnTime: '2021-08-30 12:00',
      //     borrowReason: '要装灯泡',
      //     status: this.type,
      //   });
      // this.total = 4;
      // this.listFinished = true;
    },
    handleGoto(data) {
      this.$router.push(`${this.type}Detail`);
    }
  }
}
</script>
<style lang="scss" scoped>
.borrow-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  border: none;
  li {
    padding: 0.3rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    background-color: #FFF;
    margin: 10px;
    border-radius: 4px;
    .borrow-list-top {
      height: 36px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid rgba(0,0,0,0.1);
      padding: 0 10px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .borrow-list-row {
      line-height: 24px;
      color: rgab(0,0,0,0.85);
      font-size: 14px;
      padding: 0 10px;
      .label {
        color: rgba(0,0,0,0.45)
      }
    }
    .borrow-list-footer {
      display: flex;
      padding: 10px;
      border-top: 0.5px solid rgba(0,0,0,0.1);
      margin-top: 10px;
      justify-content: space-around;
      .refuse-btn, .agree-btn {
        width: 45%;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #ce1a21;
        color: #ce1a21;
        background: none;
      }
      .agree-btn {
        border: 1px solid #4479ed;
        color: #4479ed;
      }
    }
    
  }
}
</style>