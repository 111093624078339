<template>
  <div id="index">
    <div class="hgxt-container">
      <van-nav-bar
        title="惠工学堂"
        left-arrow
        @click-left="onClickLeft"
      />
      <div class="hgxt-body-container">
        <img style="width: 100vw;" src="http://dssq.ikeqiao.net/data/image/h5/images/hong/hgxt-bg.png" />
        <!-- 惠工学堂 -->
        <div class="huigong-bar">
          <div :class="['huigong-bar-title', { opened: show }]">
            <div class="decoration-title">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-l.png" alt="">
              <h1>惠工学堂</h1>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-r.png" alt="">
            </div>
            <p>{{ desc }}</p>
            <div class="organization-button" @click="show = !show">
              <span>{{ show ? "收起" : "展开" }}</span>
              <img
                src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
                :class="['organization-arrow', { rollover: show }]"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 风采展示 -->
        <div class="hgxt-card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">风采展示</span>
             <!-- <span class="text-more" @click="gotoLink('kghList')">
              更多
              <van-icon name="arrow" color="#bfbfbf" size="14px" />
            </span> -->
          </div>
          <div class="card-bar-content">
            <ul>
              <li class="fl-item" v-for="(item, index) in list" :key="index" @click="openArticleUrl(item)">
                <div class="fl-title">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="fl-desc">
                      <i class="time"></i>
                      <span style="margin-right: 10px;">{{item.updateTime}}</span>
                      <!-- <i class="view"></i>
                      <span>{{item.view}}</span> -->
                    </div>
                  </div>
                  <img v-if="item.cover" :src="item.cover" alt="">
                </div>
                
              </li>
            </ul>
          </div>
        </div>
        <!-- 活动信息 -->
        <!-- <div class="hgxt-card-bar">
          <div class="card-bar-title">
            <span class="text-title">活动信息</span>
            <span class="text-more">
              更多
              <van-icon name="arrow" color="rgba(0,0,0,0.45)" size="14px" />
            </span>
          </div>
          <div class="hgxt-activity-list">
            <div :style="{width: activityList ? `${activityList.length * 262}px` : '100%',display: 'flex'}">
              <div v-for="(item, index) in activityList" :key="`${item.id}_${index}`" class="hgxt-activity-card">
                <img :src="item.imgUrl" class="hgxt-activity-image"/>
                <div class="hgxt-activity-title-1">
                  <h1>{{item.title}}</h1>
                  <span>已报名<b>{{item.applyNum}}</b></span>
                </div>
                <div class="hgxt-activity-text">
                  <span class="text-label">活动时间：</span>
                  <span>{{item.date}}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
export default {
  data() {
    return { 
      show: false,
      desc: '“惠工学堂”是浣东街道总工会的职工培训品牌，我们的目标是使职工培训进一步“扩面提质”。一方面是扩大“面”的覆盖，让职工培训走进东盛社区的每一个小区，使每一位社区居民都能享受到培训服务；另一方面是实现“质”的提升，通过全方面、多维度的培训项目，进一步丰富职工文化生活，有效提升职工文化素养，营造和谐温馨的社区氛围。“惠工学堂”将不定时推出“菜单式”培训课程，职工可根据喜好需求自行选择报名，在传统文化传承、文体活动培训、学历技能提升等方面，满足不同职工群体需求，提高职工队伍素质，更好地发挥工会组织作用。',
      list: [
        // {
        //   id: 0,
        //   title: "【党史学习教育】东盛社区——学习百年党史 汲取奋进力量",
        //   update: "2021-06-23",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/kgh/hgxt1.jpg",
        //   url: 'https://mp.weixin.qq.com/s/QePo4uYA7CKKLk8P4ZU4bg'
        // },
      ],
      activityList: [
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "0",
          time: "剩5日20小时开始",
          applyNum: "10",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "1",
          time: "剩20分钟结束",
          applyNum: "200",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "2",
          time: "",
          applyNum: "30",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
      ]
    };
  },
  components: {},
  mounted() {
    this.getDataList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    openUrl(url) {
      if(url) {
        window.location.href = url;
      }
    },
    openArticleUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    getDataList() {
      Api.getHongArticleList({
        page: 1,
        pageSize: 4,
        type: 7,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        this.list = records;
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.hgxt-container {
  height: 100vh;
  width: 100vw;
  .hgxt-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .huigong-bar-title {
      width: calc(100% - 30px);
      // background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/organization-bg.png) no-repeat;
      // background-size: 100% 100%;
      background: #FFF;
      border-radius: 4px;
      padding: calc(2rem - 15px);
      color: rgba(0,0,0,0.85);
      margin: -50px 15px 15px 15px;
      position: relative;
      z-index: 2;
      .decoration-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 10px;
        > h1 {
          font-size: 18px;
          color: #4479ED;
          font-weight: 600;
          margin: 0 10px;
        }
        >img {
          height: 14px;
        }
      }
      
      > p {
        width: calc(100vw - 4rem);
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
        max-height: 60px;
        min-height: 60px;
        margin-bottom: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .organization-button {
        margin: 0px auto;
        width: 60px;
        height: 24px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 12px;
        color: #4479ed;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        > img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .opened {
      > p {
        max-height: 100vh;
      }
      .organization-button {
        > img {
          transform: rotate(180deg);
        }
      }
    }
    .hgxt-card-bar {
      width: 100%;
      margin: 10px 0;
      padding: 6px 0;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        background: #fff;
        .text-title {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          width: 50%;
        }
        .text-more {
          color: #bfbfbf;
          font-size: 14px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        .fl-item {
          margin: 10px 10px 0 10px;
          padding: 10px 0;
          .fl-title {
            display: flex;
            align-items: flex-start;
            >img {
              width: 7rem;
              margin-left: 10px;
            }
            >div {
              flex: 1;
              >h1 {
                font-size: 16px;
                line-height: 32px;
                color: rgba(0,0,0,0.85);
                font-weight: 500;
              }
              .fl-desc {
                display: flex;
                height: 24px;
                align-items: center;
                font-size: 12px;
                color: rgba(0,0,0,0.45);
                .time, .view {
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                  background-size: 100% 100%;
                }
                .view {
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
            
          }
          
        }
      }
      .hgxt-activity-list {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 10px;
        background-color: #FFF;
        .hgxt-activity-card {
          width: 252px !important;
          border-radius: 4px;
          margin-right: 10px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
          .hgxt-activity-image {
            width: 100%;
            height: 87px;
          }
          .hgxt-activity-title-1 {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px;
            >h1 {
              font-size: 14px;
              color: rgba(0,0,0,0.85);
              letter-spacing: 0;
              font-weight: 500;
            }
            >span {
              font-size: 12px;
              color: rgba(0,0,0,0.65);
              >b {
                color: #4479ed;
              }
            }
          }
          .hgxt-activity-text {
            font-size: 14px;
            line-height: 24px;
            color: rgba(0,0,0,0.85);
            padding: 0 10px;
            .text-label {
              color: rgba(0,0,0,0.45);
            }
          }
        }
      }
    }
  }
}
</style>
