<template>
  <div class="help-preview-container">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="help-preview-body-container">
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="username"
            name="username"
            label="姓名"
            placeholder="请填写"
            required
            :rules="[{ required: true, message: '请填写姓名' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="idCard"
            name="idCard"
            label="身份证号"
            placeholder="请输入"
            required
            :rules="[{ required: true, message: '请填写身份证号' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            required
            :rules="[{ required: true, message: '请填写联系电话' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="marriage"
            name="marriage"
            label="婚姻状况"
            placeholder="请选择"
            is-link
            readonly
            required
            @click="show = true"
            :rules="[{ required: true, message: '请选择婚姻状况' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="address"
            name="address"
            label="家庭地址"
            placeholder="请输入"
            required
            :rules="[{ required: true, message: '请填写家庭地址' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            v-model="content"
            size="large"
            name="content"
            rows="5"
            autosize
            required
            label="调解问题"
            type="textarea"
            maxlength="200"
            placeholder="请填写您的调解具体问题"
            show-word-limit
            class="input-bar"
            input-align="right"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              提交预约
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-picker
        title="婚姻状况"
        show-toolbar
        :columns="marrySituation"
        @confirm="onConfirm"
        @cancel="onCancle('show')"
      />
    </van-popup>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      username: "",
      phone: "",
      idCard: "",
      address: '',
      content: '',
      marriage: '',
      show: false,
      title: '',
      id: '',
      type: '',
      marrySituation: ['已婚', '未婚'],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { title = '', id = '' } = this.$route.query;
    this.title = title;
    this.id = id;
  },
  watch: {
   
  },
  methods: {
    testTouch(e) {
      e.stopPropagation();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onSubmit(values) {
      console.log('values', values);
      Api.addReserve({ ...values, cid: this.id, marriage: values.marriage === '未婚' ? 0 : 1, adjustType: this.title }).then(res => {
        if(res.flag) {
          this.$toast({
            message: '申请提交成功！',
            position: 'top',
          });
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer);
          }, 1000);
        }
      })
      
    },
    onOk() {
      this.show = false;
    },
    onConfirm(e){
        this.marriage = e;
        this.show = false;
    },
    onCancle(type) {
      this[type] = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.help-preview-container {
  height: 100vh;
  width: 100vw;
  
  .help-preview-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 50px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/help-preview-top-bar.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #FFF;
      }
      .report-arrow {
        color: #FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }
    .form-bar {
      .cell {
        color: rgba(0, 0, 0, 0.65);
      }
      .radio {
        height: 2rem;
        border-radius: 1rem;
        // background: #4479ED;
        color: rgba(0, 0, 0, 0.35);
        border: 1px solid rgba(0, 0, 0, 0.35);
        padding: 0.1rem 1.2rem;
        margin-left: 8px;
      }
      .selected {
        background: #4479ed;
        color: #ffffff;
        border: 1px solid #4479ed;
      }
      .divide {
        height: 0.8rem;
        background: #f6f7fa;
      }
      .footer-button {
        position: fixed;
        bottom: 0.8rem;
        right: 0.8rem;
        left: 0.8rem;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10vh;
      
      .pay-window {
        background-color: #FFF;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75vw;
        .pay-window-top {
          width: 100%;
          height: 48px;
          background-color:  #4479ED;
          font-size: 16px;
          color: #FFF;
          line-height: 48px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .close {
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }
        .pay-window-content {
          width: 100%;
          text-align: center;
          line-height: 20px;
          padding: 16px;
          // .image-con {
          //   width: 60vw;
          //   height: 60vw;
          //   padding: 16px;
            >img {
              width: 100%;
              height: 100%;
            }
          // }
          .text {
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            text-align: left;
          }
          .button-help-preview {
            width: 100%;
            background-color: #4479ED;
            color: #FFF;
            font-size: 18px;
            line-height: 44px;
            border: none;
            border-radius: 4px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
