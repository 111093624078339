<template>
  <div class="yeweihui-container">
    <van-nav-bar
      title="业委会介绍"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yeweihui-body-container">
      <div class="yeweihui-container">
        <div class="page-con">
          <i class="right_circle"></i>
          <i class="left-circle"></i>
          <div class="red-row" style="margin-bottom: 16px;">蝶庄业委会党支部</div>
          <p style="margin-bottom: 10px;">蝶庄业委会党支部成立于2021年9月，内设党支部书记1名，党支部委员3名。在业委会党支部带领下，蝶庄小区以打造“美丽楼道”为切入口，积极发动居民共建共治，在美丽楼道建设过程中增进了邻里感情、消弭了小区杂音，打造了党建引领居民自治共享的“蝶庄故事”。</p>
          
          <div class="person-list-con">
            <div class="person-list-item" v-for="item in personList" :key="item.name">
              <img class="person-list-image" :src="item.picture" alt="">
              <span v-if="item.tag" class="person-list-tag"></span>
              <div class="person-list-card">
                <div class="person-list-card-title"><h1>{{item.name}}</h1><span>{{item.title1}}</span></div>
                <div class="person-list-row">{{item.title}}</div>
                <div class="person-list-row" @click="callPhone(item.phone)">
                  <i class="phone"></i>{{item.phone}}
                </div>
                <div class="person-list-row">{{item.specialty}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      data: {},
      personList: [
        {
          name: "丁成吉",
          title: '1幢党小组组长',
          title1: '党支部书记',
          phone: '13735334864',
          specialty: '抗疫先锋',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/dingchengji.jpg',
        },
        {
          name: "张剑勇",
          title1: '党支部委员',
          title: '9幢党小组组长',
          phone: '13758554664',
          specialty: '建设美丽楼道',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/zhangjianyong.jpg',
        },
        {
          name: "李丽华",
          title1: '党支部委员',
          title: '11幢党小组组长',
          phone: '13957589150',
          specialty: '热心公益',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/lilihua.jpg',
        },
        {
          name: "占琼宇",
          title1: '党支部委员',
          title: '3幢党小组组长',
          phone: '18258288504',
          specialty: '擅长邻里矛盾调解',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/zhanqiongyu.jpg',
        },
        
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.getStatisticData();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    getStatisticData() {
      api.getHWWStaticData({}).then(res => {
        if(res.flag) {
          this.data = res.data;
        }
      })
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    callPhone(num) {
      window.location.href = `tel://${num}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.yeweihui-container {
  height: 100vh;
  width: 100vw;
  
  .yeweihui-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .yeweihui-container {
      height: calc(100% - 32px);
      margin-top: 16px;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      margin-bottom: 16px;
      z-index: 3;
      .list-con {
        margin: 10px;
        padding: 10px 15px;
        background: #FFEFEE;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .yezhu-con, .wuye-con, .yeweihui-con {
          width: 100%;
          min-height: 110px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/yezhu.png') no-repeat;
          background-size: 100% 100%;
          padding: 14px 10px 14px 66px;
          font-size: 12px;
          color: rgba(0,0,0,0.85);
          line-height: 20px;
        }
        .wuye-con {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/wuye.png') no-repeat;
          background-size: 100% 100%;
        }
        .yeweihui-con {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/yeweihui.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .page-con {
        padding: 10px 15px;
        background: #FFEFEE;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        margin: 0 10px;
        
        .right_circle {
          width: 56px;
          height: 88px;
          position: absolute;
          right: 0;
          top: 250px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/circle-right.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
        }
        .left-circle {
          width: 82px;
          height: 364px;
          position: absolute;
          left: 0;
          top: 380px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/circle-left.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
        }
        >img {
          width: 70%;
          margin: 0 15%;
        }
        >p {
          color: rgba(0,0,0,0.85);
          font-size: 14px;
          line-height: 18px;
          text-indent: 28px;
        }
        .push-arrow {
          height: 36px;
          width: 18px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/push_arrow.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          margin: 5px;
        }
        .red-row {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/red_row_bg.png') no-repeat;
          background-size: 100% 100%;
          text-align: center;
          font-size: 15px;
          color: #FFF;
          font-weight: 500;
          padding: 10px 10px 15px 10px;
          min-width: 174px;
        }
        .red-linear-row {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/red_linear_bg.png') no-repeat;
          background-size: 100% 100%;
          min-width: 170px;
          text-align: center;
          font-size: 12px;
          color: rgba(0,0,0,0.85);
          padding: 6px 0;
          margin: 10px 0;
        }
        .red-linear-row2 {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/red_linear_bg_2.png') no-repeat;
          background-size: 100% 100%;
          min-width: 300px;
          text-align: center;
          color: rgba(0,0,0,0.85);
          padding: 8px 0;
          margin: 10px 0;
        }
        .person-list-con {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .person-list-item {
            width: 48%;
            margin-top: 10px;
            // padding: 10px;
            position: relative;
            .person-list-image {
              width: 80%;
              margin-left: 10%;
              border-radius: 4px;
              border: 4px solid #FE8776;
              background-image: linear-gradient(0deg, #CA1832 0%, #FE8776 100%);
            }
            .person-list-tag {
              width: 36px;
              height: 36px;
              position: absolute;
              top: 0;
              right: 0;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/yeweihui-tag.png') no-repeat;
              background-size: 100% 100%;
            }
            .person-list-card {
              background-color:#FFD2CF;
              border-radius: 4px;
              padding: 10px 8px;
              margin-top: -20px;
              position: relative;
              z-index: 2;
              .person-list-card-title {
                display: flex;
                align-items: center;
                >h1 {
                  font-size: 16px;
                  color: rgba(0,0,0,0.85);
                  font-weight: 500;
                  margin-right: 3px;
                }
                >span {
                  font-size: 14px;
                  color: rgba(0,0,0,0.65);
                }
              }
              .person-list-row {
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 6px;
                font-size: 12px;
                color: #FFFFFF;
                font-weight: 500;
                background-image: linear-gradient(90deg, #FF8A77 0%, #F8686D 100%);
                border-radius: 20px;
                .phone {
                  width: 14px;
                  height: 14px;
                  background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/yeweihui-phone.png') no-repeat;
                  background-size: 100% 100%;
                  display: inline-block;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
