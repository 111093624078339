<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发起志愿活动</h1>
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="title"
            name="title"
            label="活动标题"
            placeholder="请输入"
            class="input-bar"
            maxlength="20"
            input-align="right"
            :rules="[{ required: true, message: '请输入活动标题' }]"
          />
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="负责人"
            placeholder="请输入"
            class="input-bar"
            input-align="right"
            :rules="[{ required: true, message: '请输入负责人' }]"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            type="tel"
            label="联系电话"
            placeholder="请输入"
            input-align="right"
            :rules="[{ pattern, message: '请输入正确的联系电话' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="address"
            name="address"
            label="具体活动地址"
            placeholder="请输入"
            class="input-bar"
            input-align="right"
            :rules="[{ required: true, message: '请填写' }]"
          />
          <van-field
            size="large"
            v-model="beginTime"
            name="beginTime"
            is-link
            readonly
            label="开始时间"
            placeholder="请选择开始时间"
            @click="showStart = true"
            input-align="right"
            :rules="[{ required: true, message: '请选择开始时间' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="endTime"
            name="endTime"
            is-link
            readonly
            label="结束时间"
            placeholder="请选择结束时间"
            @click="handleEnd"
            input-align="right"
            :rules="[{ required: true, message: '请选择结束时间' }]"
            class="input-bar"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">具体内容</div>
            <van-field
              size="large"
              v-model="description"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入活动具体内容"
              show-word-limit
              :rules="[{ required: true, message: '请输入活动具体内容' }]"
            />
          </div>
          <div class="section" style="padding: 0.8rem">
            <div class="title">活动图片</div>
            <van-uploader
              v-model="fileList"
              name="imageList"
              multiple
              :max-count="3"
              :rules="[{ required: true, message: '请选择活动照片' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >立即发起</van-button
            >
          </div>
        </van-form>
      </div>
      <van-calendar
        v-model="showStart"
        type="single"
        @confirm="onStartConfirm"
      />
      <van-calendar v-model="showEnd" type="single" @confirm="onEndConfirm" />
    </div>
  </div>
</template>

<script>
import commonApi from "api/common";
import loveActivityApi from "api/ddya/loveActivity";
import { DatetimePicker } from "mint-ui";
import { Uploader, Toast } from "vant";
import { Field } from "mint-ui";
import { mapState } from "vuex";
import context from '@/main';
export default {
  data() {
    return {
      name: "",
      title: "",
      address: "",
      phone: "",
      beginTime: "",
      endTime: "",
      showStart: false,
      showEnd: false,
      description: "",
      fileList: [],
      pattern: /^[1]([3-9])[0-9]{9}$/,
    };
  },
  components: {},
  mounted() {
    this.name = this.userInfo.nickname;
  },
  computed: {
    ...mapState({
      userInfo: (state) =>
        state.login.userInfo ||
        (context.getSessionData("userInfo")),
    }),
  },
  methods: {
    uploadImg() {
      this.pickerVisible = true;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleEnd() {
      if (!this.beginTime) {
        Toast("请先选择开始时间");
      } else {
        this.showEnd = true;
      }
    },
    onStartConfirm(date) {
      this.beginTime = this.formatDateTime(date);
      this.showStart = false;
    },
    onEndConfirm(date) {
      this.endTime = this.formatDateTime(date);
      if (this.endTime >= this.beginTime) {
        this.showEnd = false;
      } else {
        Toast("结束时间不能早于开始时间！");
      }
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    onSubmit(values) {
      if (this.fileList && this.fileList.length < 1) {
        Toast("请选择活动图片后提交");
        return;
      }
      const formData = new FormData();
      this.fileList.map((item) => formData.append("files", item.file));
      commonApi.uploadMultiFile(formData).then((res) => {
        const params = {
          ...values,
          image: res.data,
        };
        loveActivityApi.addActivity(params).then((res) => {
          const { message,data, flag } = res;
          if (flag) {
            Toast(data);
            this.$router.go(-1);
          } else {
            Toast(message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0.6rem 0;
  height: calc(100vh - 6.7rem);
  width: 100vw;
  margin-top: 2.7rem;
  overflow-y: auto;
  overflow-x: hidden;
  .form-bar {
    height: 100%;

    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }

}
</style>
