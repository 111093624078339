<template>
  <div class="yiweiquan-container">
    <van-nav-bar
      title="伊·维权"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yiweiquan-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <p>小红热线</p>
        <h1>81166576</h1>
      </div>
      <!-- image-bar -->
      <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/yiweiquan-bg.png" class="image-bar" alt="">
      <div class="yiweiquan-list-con">
        
        <!-- 小红热线 -->
        <div class="yiweiquan-bar">
          <div :class="['yiweiquan-bar-title', { opened: show }]">
            <div class="decoration-title">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-l.png" alt="">
              <h1>小红热线</h1>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-r.png" alt="">
            </div>
            <p>{{ desc }}</p>
            <div class="organization-button" @click="show = !show">
              <span>{{ show ? "收起" : "展开" }}</span>
              <img
                src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
                :class="['organization-arrow', { rollover: show }]"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 卡片列表 -->
        <div class="desc-card" v-for="item in cardList" :key="item.title">
          <h1>{{item.title}}</h1>
          <p>{{item.desc}}</p>
        </div>
      </div>
      
      <!-- 按钮列表 -->
      <div class="icon-list">
        <div class="icon-item" v-for="icon in iconList" :key="icon.title" @click="gotoLink(icon.path)">
          <img :src="icon.icon" alt="">
          <p>{{icon.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      desc: '“伊柯”有约妇儿维权品牌主要依托区妇联“都市伊家”服务平台，由街道妇联牵头打造。工作室设在社区妇女之家。“小红热线”作为“伊柯有约”子品牌，以原社区妇联主席徐小红命名，联合诸暨市广播电视总台“亚红热线”栏目主持人顾亚红（社区居民），共同关注和解答居民朋友生活健康、社会生活方面的热线难点疑点问题。在生活上，关注和引导广大居民朋友建立积极向上的生活态度和健康的生活方式；在民生上，倾听和排解居民朋友的生活难题。',
      cardList: [
        {
          title: '维权热线',
          desc: '在妇女之家设立24小时咨询专线（电话：81166576），解答法律、婚姻、家庭、心理等方面的咨询，提供及时、有效的维权服务',
        },
        {
          title: '服务内容',
          desc: '以服务辖区内妇女儿童发展为目标，通过维权热线、面对面咨询、线上课堂、上门调解等形式，提供妇儿维权、婚姻家事、家庭教育、心理健康等咨询服务，逐步打通维护妇女儿童合法权益的最后一公里。',
        },
      ],
      iconList: [
        {
          title: '我要咨询',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/woyaozixun.png',
          path: 'needZx',
        },
        {
          title: '我要求助',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/woyaoqiuzhu.png',
          path: 'needHelp'
        },
        {
          title: '找专家',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhaozhuanjia.png',
          path: 'needZj',
        },
        {
          title: '我要了解',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/woyaoliaojie.png',
          path: 'needLj',
        }
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.yiweiquan-container {
  height: 100vh;
  width: 100vw;
  
  .yiweiquan-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .top-bar {
      text-align: center;
      padding: 1rem;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/xiaohongrexian.png') no-repeat;
      background-size: 100% 100%;
      >h1 {
        font-size: 20px;
        color: #E72C34;
        font-weight: 500;
        line-height: 32px;
      }
      >p {
        font-size: 16px;
        color: #E72C34;
        line-height: 24px;
      }
    }
    .image-bar {
      width: 100vw;
    }
    .yiweiquan-list-con {
      height: calc(100% - 280px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: -80px;
      position: relative;
      z-index: 2;
      .yiweiquan-bar-title {
        width: calc(100% - 30px);
        // background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/organization-bg.png) no-repeat;
        // background-size: 100% 100%;
        background: #FFF;
        border-radius: 4px;
        padding: calc(2rem - 15px);
        color: rgba(0,0,0,0.85);
        margin: 0 15px 15px 15px;
        .decoration-title {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          margin-top: 10px;
          > h1 {
            font-size: 18px;
            color: #4479ED;
            font-weight: 600;
            margin: 0 10px;
          }
          >img {
            height: 14px;
          }
        }
        
        > p {
          width: calc(100vw - 4rem);
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0;
          max-height: 60px;
          min-height: 60px;
          margin-bottom: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .organization-button {
          margin: 0px auto;
          width: 60px;
          height: 24px;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 12px;
          color: #4479ed;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          > img {
            width: 12px;
            height: 12px;
          }
        }
      }
      .opened {
        > p {
          max-height: 100vh;
        }
        .organization-button {
          > img {
            transform: rotate(180deg);
          }
        }
      }
      .desc-card {
        background-color: #FFF;
        width: calc(100vw - 30px);
        margin: 15px;
        border-radius: 4px;
        padding: 1rem;
        >h1 {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          font-weight: 600;
          line-height: 32px;
        }
        >p {
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          line-height: 20px;
        }
      }
    }
    
    .icon-list {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 4;
      display: flex;
      width: calc(100vw - 30px);
      margin: 15px;
      justify-content: space-between;
      .icon-item {
        width: 21%;
        background: #FFF;
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        >img {
          width: 1.5rem;
          height: 1.5rem;
          margin-top: 0.7rem;
        }
        >p {
          font-size: 10px;
          color: rgba(0,0,0,0.85);
          line-height: 24px;
        }
      }
    }
  }
}
</style>
