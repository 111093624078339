<template>
  <div class="own-index" style="background-color:#f5f5f5">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>手机认证</h1>
      </div>
      <div class="park-main">
        <div class="main-des">
            为保障社区内的住户享受高品质的服务，请提供您的真实信息以进行验证
        </div>
        <div class='own-form'>
            <div class="row">
                <div class="lable">手机号</div>
                <div class='value'>
                    <input type="tel" v-model="phone" placeholder="请输入手机号">
                </div>
            </div>
             <div class="row">
                <div class="lable">验证码</div>
                <div class='value'>
                    <input type="tel" v-model="code" placeholder="请输入验证码">
                    <button v-if="showCode" @click="sendMessage">获取验证码</button>
                    <button v-if="!showCode" style="color: #FFFFFF;display: flex;justify-content: center;align-items: center;">
                      <van-count-down  style="color: #FFFFFF;" @finish="handleFinish" :time="time" format="ss" />秒后重发
                    </button>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="btns-flex" style="justify-content:center">
        <div class="save-btn"><button class="save" @click="handleSubmit">下一步</button></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Toast } from 'vant';
import context from '@/main';
import Api from 'api';
export default {
  data() {
    return {
      phone: '',
      code: '',
      showCode: true,
      time: 1000 * 60,
      inviterId: '',
    };
  },
  components: {},
  mounted() {
    const { key = '' } = this.$route.query;
    console.log(key, this.userInfo, this.userInfo.checkStatus);
    this.inviterId = key === 'null' ? '' : key;
    if (key) {
      if (this.userInfo.checkStatus === "已实名") {
        this.$router.push('/');
      }
    }
  },
  methods: {
    ...mapActions({
      // sendMessage: 'sendMessage',
    }),
    sendMessage() {
      if(!this.phone || !/^1[0-9]{10}$/.test(this.phone)) {
        Toast.fail('请输入正确的手机号码。')
      } else {
        Api.checkPhone({ param: this.phone }).then(res => {
          if(res.flag) {
            this.$store.dispatch('login/sendMessage', {
              phone: this.phone, 
              callback: () => {
                Toast('验证码已发送，请注意查收');
                this.showCode = false;
              }
            });
          }
        })
        
      }
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleSubmit() {
      // console.log('store', this.$store.state.login.userInfo);
      if(!this.phone || !/^1[0-9]{10}$/.test(this.phone)) {
        Toast.fail('请输入正确的手机号码。')
        return;
      }
      if(!this.code) {
        Toast.fail('请输入验证码。')
        return;
      }
      this.$store.dispatch('login/checkCode', {
        data: {
          phone: this.phone,
          code: this.code,
          userId: this.userInfo.userId || '',
        },
        callback: () => {
          this.$router.push(`loginHouse?inviterId=${this.inviterId}`);
        }
      })
    },
    handleFinish() {
      console.log('aaa');
      this.showCode = true;
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => (context.getSessionData('userInfo')) ||  state.login.userInfo
    })
  }
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.main-des{
    background-color: #f5f5f5;
    line-height: 1.8rem;
    color: rgba(187, 187, 187, 100);
    font-size: 0.9rem;
    padding:0.8rem 1.6rem;
    margin-left: -0.8rem;
    width:110%;
}
</style>
