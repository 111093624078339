<template>
  <div>
    <van-nav-bar
      title="我要加入"
      left-arrow
      @click-left="goBack"
    />
    <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="owner"
            name="owner"
            label="姓名"
            placeholder="请输入"
            class="input-bar"
            maxlength="20"
            :rules="[{ required: true, message: '请输入姓名' }]"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            class="input-bar"
            :rules="[{ required: true, message: '请输入联系电话' }]"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">需求内容</div>
            <van-field
              size="large"
              v-model="content"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入需求内容"
              show-word-limit
              :rules="[{ required: true, message: '请输入需求内容' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >申请加入</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
</template>
<script>
import llyjrApi from "api/llyjr/index";
import { Toast } from 'vant';
export default {
  data() {
    return {
      owner: "",
      phone: "",
      content: '',
      groupId: '',
    };
  },
  components: {},
  mounted() {
    const { key } = this.$route.query;
    this.groupId = key; 
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      const { id } = this.$route.params;
      if(this.fileList && this.fileList.length < 1) {
        Toast('请选择活动图片后提交');
        return ;
      }
      llyjrApi
      .addGroup({ ...values, groupId: this.groupId })
      .then(res => {
        console.log(res);
        const { flag } = res || {};
        if (flag) {
          this.$router.replace('znt');
          Toast('申请成功！等待管理员审核');
        }
      });
    }
  },
};
</script>

<style lang="scss">

  .form-bar {
    height: 100%;
    
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
</style>
