<template>
  <div class="hui-study-container">
    <van-nav-bar
      class="hui-study-nav-bar"
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hui-study-body-container">
      <div class="title">{{mapObj[id].title}}</div>
      <div v-html="mapObj[id].desc" class="html-class" v-if="mapObj[id].desc"></div>
      <img :src="mapObj[id].img" alt="" v-if="mapObj[id].img"/>
      <div class="footer">{{mapObj[id].name}}</div>
      <div class="footer">{{mapObj[id].time}}</div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import mapObj from './config';
import { mapMutations, mapState } from "vuex";
import WyslList from "./wyslList";
export default {
  data() {
    return {
      id: '',
      mapObj: {},
    };
  },
  computed: {
    ...mapState({
      studyItemSelect: (state) => state.study.studyItemSelect,
    }),
  },
  // components: { WyslList },
  mounted() {
    const { query = {} } = this.$router.history.current;
    this.id = query.id;
    this.mapObj = mapObj;
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleTabSelect(name, title) {
      this.$store.commit('study/setStudyItemSelect', name);
    },
    showToast(message) {
      this.$toast(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.hui-study-container {
  height: 100vh;
  background: white;
  width: 100vw;
  img {
    width: 100%;
    margin-top: 10px;
  }
  .title {
    display: flex;
    font-size: 18px;
    font-weight: bolder;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    line-height: 20px;
  }
  .html-class {
    font-size: 16px;
    line-height: 28px;
    margin-top: 10px;
    background: white;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 22px;
    font-size: 16px;
    margin-top: 10px;
  }
  .arcContent{
    .itemText{
      padding: 0 10px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      font-weight: 400;height: 56px;
      background: #FFFFFF;
      border-bottom: 1px solid rgba(17,31,44,0.10);
      line-height: 56px;
      display: flex;
    }
  }
}
</style>
