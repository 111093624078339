<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>书屋有声</h1>
      </div>
      <div class="kgh-main">
        <div class="banner3">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/swys-banner.jpg" />
        </div>
        <div class="content-box">
          <div class="wxparse-box">
            职工书屋是中华全国总工会为保障广大职工特别是一线职工、农民工的基本文化权益，丰富基层职工精神文化生活，在全国工会开展的一项重要文化工程，是一件公益工程。
          </div>
          <div class="wxparse-box">
            东盛社区“慧渡书苑”是诸暨市首批图书分馆，面积约100平方米，内置图书约5000本，设置有“有声读吧”、“漂流书吧”、“红色书柜”、“健康知识阅读角”等功能区。“慧渡书苑”作为社区联合工会的职工书屋，积极为一线职工和社区居民打造时尚、典雅、温馨、舒适的阅读环境，提升职工阅读体验，进一步丰富职工精神文化生活。
          </div>
          <h3 style="line-height: 28px;">有声书吧</h3>
          <div class="wxparse-box">
            如果觉得眼睛阅读疲劳的时候，在这里可以享受“耳朵”阅读。打开手机，扫一扫墙上的二维码，就可以听有声图书。
          </div>
          <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/swys2.jpg" alt="">
          <h3 style="line-height: 28px;">漂流书吧</h3>
          <div class="wxparse-box">
            如果有闲置的好书推荐，可以放在漂流书吧柜里，分享给其他职工们阅读。
          </div>
          <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/swys3.jpg" alt="">
          <h3 style="line-height: 28px;">红色书柜</h3>
          <div class="wxparse-box">
            今年是建党100周年，“慧渡书苑”增设了一个红色书柜，职工们可以在这个书柜里学习党史党章等系列书籍。
          </div>
          <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/swys4.jpg" alt="">
          <h3 style="line-height: 28px;">健康知识阅读角</h3>
          <div class="wxparse-box">
            劳逸结合，工作生活才更有动力。 “健康知识阅读角”设有健康养生系列的书本，职工们可以在这里阅读学习一些养生知识。
          </div>
          <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/swys5.jpg" alt="">
          <div class="wxparse-box">
            “一盏灯温暖一座城市”，“慧渡书苑”点亮职工们温暖的阅读之灯，提供全时段阅读、静心学习的好去处和阅读推广交流平台。
          </div>
          <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/swys6.png" alt="">
        </div>
        <!-- <div class="commnewlist" style="margin-top:20.4rem">
          <div class="tab">风采展示<span>更多 &gt;</span></div>
          <ul>
            <li
              @click="gotoLink('')"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <div class="desc">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="time">时间：{{ item.update }}</div>
              </div>
              <div class="img">
                <img :src="item.imgurl" />
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        {
          id: 0,
          title:
            "【瓜渚专栏】忆辉煌百年 诵中华经典 | 浣东街道举行“七一”表彰大会暨庆祝建党百年经典诵读活动",
          update: "2021-07-13 14:46:56",
          imgurl:
            "https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/maYeibgMV4R8.png",
        },
        {
          id: 1,
          title:
            "【 瓜渚专栏】书香浸润生活 | 浣东街道举办“陆游与浙东唐诗之路”读书日分享会",
          update: "2021-07-13 14:41:51",
          imgurl:
            "https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/zaS5ytPi32dH.png",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
