<template>
  <div id="index">
    <div class="page-container" style="height: auto !important;position: relative;">
      <div class="header-nav clearfix">
        <i @click="goBack"></i>
        <h1>社区学堂</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/sdbxt_banner.png" />
        </div>
        <!-- <div class="all-nav clearfix">
          <ul>
            <li @click="gotoLink('sdbxtDetail')">
              <i class="sdbxtDetail"></i><span>社区学堂简介</span>
            </li>
            <li @click="gotoLink('sdbxt/volsign')">
              <i class="zmzyz"></i><span>招募志愿者</span>
            </li>
            <li @click="gotoLink('huodongzhongxin')">
              <i class="hdzx"></i><span>活动中心</span>
            </li>
          </ul>
        </div> -->

      </div>
    </div>
    <!-- <div class="volunteer">
      <div class="main-table">
        今日志愿者
      </div>
      <ul class="tabel-content">
        <li v-for="item in volunteers" :key="item.id">
          <div><img :src="item.image || '/img/defaultUser.png'" alt=""></div>
          <div><span>{{item.nickname}}</span></div>
        </li>
      </ul>
    </div> -->
    <!-- <div class="reservation">
      <div class="main-table">
        预约动态
      </div>
      <ul class="reservation-content">
        <li v-for="item in previewList"
            :key="item.dsc">{{ item.dsc}}</li>
      </ul>
    </div> -->
    <div class="news-con">
      <div class="news-title">
        <div>
          <span class="title-bar"> </span>
          <span class="title-text">最新活动</span>
        </div>
        <div @click="gotoLink('huodongzhongxin')">
          <span class="more">更多</span>
          <img
            src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png"
            alt=""
          />
        </div>
      </div>
      <div class="news-body">
        <ul class="detail-img-box-two" style="width: 100%">
          <li v-for="item in list" :key="item.activityId">
            <div class="img" @click="onQuestionClick(item)">
              <div class="img-top">
                <img :src="item.image" />
                <div class="img-status" :style="{background: colors[item.status].background,color: colors[item.status].text}"><p class="img-text">{{ item.activityStatus }}</p></div>
              </div>
              <div class="img-title">{{ item.title }}</div>
              <div class="img-des">
                <span>
                  已有
                  <span class="blue">{{ item.number }}</span>人报名
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="test"></div>
  </div>
</template>

<script>
import sdbxtApi from 'api/sdbxt/sdbxt';
import wyslApi from "api/llyjr/wysl";
import { Toast } from 'vant';
import { mapMutations } from 'vuex';
var moment = require("moment")
export default {
  data () {
    return {
      volunteers: [],
      previewList: [],
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      list: [],
    };
  },
  components: {},
  mounted () {
    sdbxtApi.previewDynamic({
      "page": 1,
      "size": 10,
    }).then((res) => {
      this.previewList = res.data.list
    });
    sdbxtApi.getVolunteerList({}).then(res => {
      if(res.flag) {
        this.volunteers = res.data;
      }
    })
    // this.showDialog();
    this.getList();
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    showDialog() {
      this.$dialog.alert({
        title: '提示',
        message: '社区学堂开课时间会提前通知各位居民哦～'
      }).then(() => {});
    },
    handleXtsk() {
      sdbxtApi.queryPreviewList({
        "page": 1,
        "size": 10,
        "status": "1"
      }).then((res1) => {
        if(res1.data.list && res1.data.list.length > 0) {
          this.gotoLink('sdbxt/xtsk')
        } else {
          Toast('您没有进行中的托管。')
        }
      })
      
    },
    getList({ page = 1, size = 6 } = {}) {
      wyslApi
        .getGamePage({
          page,
          size,
          object: {
            activityType: '假日课堂',
            status: '',
            sortField: '',
            sort: 'desc'
          }
        })
        .then(res => {
          const { list = [] } = res.data || {};
          this.list = list;
        });
    },
    onQuestionClick(item) {
      // this["setQuestionInfo"](item);
      this.gotoLink("huodongzhongxinDetail?key="+item.activityId);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.park-main {
  .banner {
    img {
      border-radius: 10px;
    }
  }
  .all-nav {
    padding-top: 0;
    padding-left: 0;
  }
}
.main-table {
  height: 1.4667rem;
  margin-top: 1.133rem;
  margin-left: 0.8rem;
  margin-bottom: 1rem;
  padding-left: 0.4667rem;
  border-left: 0.2667rem solid #4479ed;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
  font: 1.0667rem/1.4667rem "PingFangSC-Medium";
  font-weight: 600;
}
.main-table {
  color: rgba(0, 0, 0, 0.85);
}
.volunteer {
  // width: 25rem;
  // height: 9.2rem;
  background-color: #fff;
  margin-bottom: 0.8rem;
  overflow: hidden;

  .tabel-content {
    margin: 0 0.8rem;
    margin-bottom: 1.2rem;
    // background-color: #bfa;
    // width: 25rem;
    height: 4.4rem;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    li {
      margin-right: 0.533rem;
      text-align: center;
      color: #000000;
      img {
        width: 2.9rem;
        height: 2.9rem;
        border-radius: 2.9rem;
      }
      span {
        // display: block;
        font-family: "PingFangSC-Regular";
        font-size: 0.8rem;
        line-height: 1.2rem;
      }
    }
  }
}
.reservation {
  overflow: hidden;
  width: 25rem;
  height: 14.2rem;
  background-color: #fff;
  .reservation-content {
    color: rgba(0, 0, 0, 0.85);
    margin-left: 1.4667rem;
    li {
      margin-bottom: 0.8rem;
      font-family: "PingFangSC-Regular";
      font-size: 1.0667rem;
      line-height: 0.8rem;
      margin-bottom: 1.6rem;
    }
  }
}
.news-con {
  width: 100%;
  background-color: #FFF;
  padding: 10px;
  .news-title {
    width: 100%;
    // height: 2rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      .title-bar {
        height: 18px;
        width: 4px;
        background: #4479ed;
        margin-right: 10px;
      }
      .title-text {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
      .more {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      > img {
        height: 12px;
        width: 12px;
      }
      i {
        color: #4479ed;
      }
    }
  }
  .news-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li {
      display: inline-flex;
      align-items: center;
      width: 50% !important;
      height: auto !important;
      // position: relative;
      // overflow: hidden;
      // .tag {
      //     position: absolute;
      //     top: 0;
      //     right: 0.5rem;
      //     padding: 0.2rem 0.5rem;
      //     border-radius: 4px;
      //     font-size: 12px;
      // }
      .img {
        height: auto !important;
        width: 100% !important;
        text-align: center;
        img {
          width: auto !important;
          height: auto !important;
          max-height: 10rem;
          max-width: 100%;
        }
        .img-top {
          margin: 0 0.6rem;
          position: relative;
          overflow: hidden;
        }
        .img-status {
          width: 20rem;
          height: 6rem;
          max-width: 50%;
          background-color: #fff;
          transform: rotate(45deg);
          position: absolute;
          right: -3rem;
          top: -3.1rem;
        }
        .img-text {
          width: 20rem;
          height: 5.5rem;
          padding: 0;
          margin: 0;
          display:table-cell;      /*按照单元格的样式显示元素*/
          vertical-align:bottom;   /*底对齐*/
        }
        .img-title {
          text-align: left;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 1rem;
          font-weight: bold;
          padding: 0.5rem 0.6rem 0 0.6rem;
        }
        .img-des {
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 0.8rem;
          padding: 0.6rem;
          display: flex;
          align-items: center;

          .blue {
            color: #4479ed;
            margin: 0 2px;
          }
          img {
            width: 1rem !important;
            height: 1rem !important;
            margin-right: 0.2rem;
            padding: 0;
          }
          img:nth-child(3) {
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
}
</style>
