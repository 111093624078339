<template>
  <div
    class="home-index"
    style="background: #fff;height: auto !important; margin-top: 0px !important;"
  >
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>文e家</h1>
      </div>
      <div
        class="hot-main"
        style="width: 100%; padding-top: 2.7rem !important;"
      >
        <mt-navbar v-model="selected">
          <mt-tab-item id="wed">文e队</mt-tab-item>
          <mt-tab-item id="sed">随e点</mt-tab-item>
          <mt-tab-item id="ekt">e课堂</mt-tab-item>
          <!-- <mt-tab-item id="esf">e书房</mt-tab-item> -->
        </mt-navbar>
        <mt-tab-container v-model="selected">
          <mt-tab-container-item id="wed">
            <div class="wyj_content">
              <div class="top_banner">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/wed_top.png" />
              </div>
              <div
                class="sub_banner"
                @click="
                  openHref(
                    'https://www.zjwhtb.cn/content/index/startUp#!/Organization/Index/indexSearchTab/city_code/110600'
                  )
                "
              >
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/wed_sub.png" />
                <div class="text">
                  <div class="text_title">文化秀场</div>
                  <div>群众文艺社团组织</div>
                </div>
              </div>
              <div class="titleBg">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/wed_title.png" />
              </div>
            </div>
            <van-tabs
              @change="handleTabChange"
              style="margin-top: -10px;"
              color="#4479ED"
            >
              <van-tab title="东盛社区">
                <wenList :selectStreet="selectStreet"></wenList>
              </van-tab>
              <!-- <van-tab title="浣东街道">
                <wenList :selectStreet="selectStreet"></wenList>
              </van-tab>
              <van-tab title="诸暨市">
                <wenList :selectStreet="selectStreet"></wenList>
              </van-tab> -->
            </van-tabs>
            <div
              class="tab-flex-bottom"
              @click="openHref('https://kljc.zjwhhly.com/cultureE/#/mine')"
            >
              我的足迹
            </div>
          </mt-tab-container-item>
          <mt-tab-container-item id="sed">
            <div class="wyj_content">
              <div class="top_banner">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/sed_top.png" />
              </div>
              <div
                class="sub_banner"
                @click="
                  openHref(
                    'https://www.zjwhtb.cn/content/index/startUp#!/ServiceMenu/Index/indexSearchTab'
                  )
                "
              >
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/sed_sub.png" />
                <div class="text">
                  <div class="text_title">服务菜单</div>
                  <div>文化服务共享点单</div>
                </div>
              </div>
              <div class="titleBg">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/sed_title.png" />
              </div>
            </div>
            <ul class="sed_content">
              <li
                v-for="item in sedlist || []"
                :key="item.key"
                class="item"
                :style="`background-image: url(${item.image})`"
                @click="
                  item.name !== '今日活动'
                    ? gotoLink('wenEjiaLink?link=' + item.link)
                    : openHref(item.link)
                "
              >
                {{ item.name }}
              </li>
            </ul>
          </mt-tab-container-item>
          <mt-tab-container-item id="ekt">
            <div class="wyj_content">
              <div class="top_banner">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/ekt_top.png" />
              </div>
              <div
                class="sub_banner"
                @click="
                  openHref(
                    'https://www.zjwhtb.cn/content/index/startUp#!/WebLesson/Index/indexSearchTab/city_code/110600'
                  )
                "
              >
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/ekt_sub.png" />
                <div class="text">
                  <div class="text_title">共享课堂</div>
                  <div>百姓公益艺术培训</div>
                </div>
              </div>
              <div class="titleBg">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/ekt_title.png" />
              </div>
            </div>
            <ul class="ekt_content">
              <li
                v-for="item in ektlist || []"
                :key="item.key"
                class="item"
                :style="`background-image: url(${item.image})`"
                @click="
                  item.name !== '今日活动'
                    ? gotoLink('wenEjiaLink?link=' + item.link)
                    : openHref(item.link)
                "
              >
                {{ item.name }}
              </li>
            </ul>
          </mt-tab-container-item>
          <mt-tab-container-item id="esf">
            <div class="wyj_content" style="height: 100%">
              <iframe
                id="myiframe"
                scrolling="auto"
                frameborder="0"
                width="100%"
                height="100%"
                src="https://www.zjwhtb.cn/content/index/startUp#!/WebStudy/Index/index/city_code/110600"
              ></iframe>
            </div>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
    </div>
  </div>
</template>

<script>
import wenList from "./wenEJiaList";
import service from "utils/service";
export default {
  data() {
    return {
      selected: "wed",
      selectStreet: "东盛社区",
      wzlist: [],
      current: 1,
      sedlist: [
        {
          name: "地方戏曲",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/dfxq_icon.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=68",
        },
        {
          name: "品牌才艺秀",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/ppcyx_icon.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=61",
        },
        {
          name: "民俗礼仪",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/msly_icon.png",
          link: "http://palmhall.carbonkitty.com:5000/ruleList.html",
        },
        {
          name: "今日活动",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/jrhd_icon.png",
          link: "https://kljc.zjwhhly.com/cultureE/#/recommend?id=15",
        },
      ],
      ektlist: [
        // {
        //   name: "明珠课堂",
        //   image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/mzkt.png",
        //   link:
        //     "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=66",
        // },
        {
          name: "成校课堂",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/cxkt.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=142",
        },
        // {
        //   name: "小柯开讲",
        //   image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/xkkj.png",
        //   link:
        //     "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=246",
        // },
        {
          name: "健康讲座",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/jkjz.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=141",
        },
        {
          name: "安全宣讲",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/aqxj.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=255",
        },
        {
          name: "科普知识",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/kpzs.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=244",
        },
        {
          name: "法律援助",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/flyz.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=143",
        },
        {
          name: "茶文化",
          image: "http://dssq.ikeqiao.net/data/image/h5/images/wej/nyjs.png",
          link:
            "http://palmhall.carbonkitty.com:5000/videoList.html?cate_id=248",
        },
      ],
    };
  },
  components: {
    wenList,
  },
  mounted() {
    const value = this.getLocalData("wejSelect") || "";
    if (value) {
      this.selected = value;
    }
    const { key } = this.$route.query;
    if (key) {
      this.selected = key;
    }
  },
  watch: {
    selected(val, oldVal) {
      console.log(val);
      this.setLocalData("wejSelect", val);
    },
  },
  methods: {
    handleTabChange(name, title) {
      this.selectStreet = title;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    openHref(href) {
      window.location.href = href;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.wyj_content {
  width: 100%;
  .mint-navbar {
    border-bottom: 0rem solid #f6f7fa !important;
  }
  .top_banner {
    width: 100%;
    > img {
      width: 100%;
      height: 160px;
    }
  }
  .sub_banner {
    padding: 12px;
    position: relative;
    > img {
      width: 100%;
      height: 95px;
    }
    .text {
      position: absolute;
      top: 30px;
      left: 40px;
      font-size: 12px;
      line-height: 24px;
      color: #ffffff;
      .text_title {
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .titleBg {
    padding: 12px;
    margin: 0;
    > img {
      width: 100%;
      height: 56px;
    }
  }
}
.tab-flex-bottom {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #4479ed;
  box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.2rem;
}

.wz_content {
  padding: 12px;
  background: #fff;
  .item {
    width: 100%;
    height: 100px;
    display: flex;
    overflow: hidden;
    .image {
      width: 80px;
      padding: 0px 10px;
      height: 100%;
      > img {
        width: 100%;
        height: 60px;
        border-radius: 50%;
      }
    }
    .text {
      width: calc(100% - 80px);
      height: 100%;
      .title {
        font-weight: bold;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 22px;
        padding: 6px 0px;
      }
      .desc {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.sed_content {
  background: #fff;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 49%;
    height: 70px;
    line-height: 70px;
    margin-top: 12px;
    text-align: center;
    background-image: url("http://dssq.ikeqiao.net/data/image/h5/images/wej/dfxq.png");
    background-position: 0px 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 18px;
    color: #ffffff;
  }
}

.ekt_content {
  background: #fff;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 48%;
    text-align: center;
    height: 70px;
    line-height: 70px;
    margin-top: 12px;
    background-image: url("http://dssq.ikeqiao.net/data/image/h5/images/wej/dfxq.png");
    background-position: 0px 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 18px;
    color: #ffffff;
  }
}
.hot-main {
  .mint-tab-container {
    height: 100% !important;
    overflow: scroll;
  }
  .mint-tab-container-wrap {
    height: 100% !important;
  }
  .mint-tab-container-item {
    height: 100% !important;
  }
}
</style>
