<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="共享场地"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="shared-sites-body-container">
      <!-- 搜索框 -->
      <div class="top-bar">
        <van-search
          v-model="condition"
          show-action="true"
          placeholder="搜索场地名"
          @search="onSearch"
          @cancel="onCancel"
        />
      </div>
      <!-- 列表 -->
      <div class="list-con">
        <van-list
          v-model="loading"
          :finished="listFinished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <ul>
            <li v-for="(item, index) in dataList" :key="index" @click="gotoLink(`sharedSiteDetail?id=${item.id}`)">
              <div class="left">
                <img :src="item.cover" alt="">
              </div>
              <div class="right">
                <div class="tit">{{item.name}}</div>
                <div class="introduce">
                  {{item.purpose}}
                </div>
                <div class="icon-text">
                  <i class="address"></i>
                  {{item.place}}
                </div>
                <div class="icon-text">
                  <i class="date"></i>
                  {{item.bespokenTime}}
                </div>
              </div>
              
            </li>
          </ul>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/sharedSites.js';
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
      condition: '',
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    getImage(picture) {
      return require(picture);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    getDataList({ page = this.current, pageSize = this.pageSize, condition = this.condition } = {}) {
      this.loading = false;
      api
        .getSharedSites({
          name: condition,
          page,
          pageSize,
        })
        .then((res) => {
          // console.log('res', res);
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = records;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(records)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
      // this.loading = false;
      // this.dataList = this.condition ? data.data.filter(item => item.name.indexOf(condition) > -1) : data.data;
      // this.total = this.dataList.length;
      // this.listFinished = true;
    },
    onSearch(value) {
      this.condition = value;
      this.current = 1;
      this.getDataList();

    },
    onCancel() {
      this.condition = '';
      this.current = 1;
      this.getDataList();
    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .shared-sites-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100%;
      height: 48px;
    }
    .list-con {
      width: 100%;
      height: calc(100% - 48px);
      overflow-y: auto;
      overflow-x: hidden;
      li {
        padding: 1rem;
        margin: 10px;
        // border-bottom: 1px solid #ddd;
        font: 500 0.9rem/1.4rem "microsoft yahei";
        display: flex;
        background: #FFF;
        border-radius: 4px;
        .left {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          flex: 1;
          .tit {
            font-weight: 500;
            color: #000000;
            font-size: 16px;
            line-height: 24px;
          }
          .introduce {
            color: rgba(0,0,0,0.45);
            font-size: 14px;
            line-height: 24px;
          }
          .icon-text {
            font-size: 14px;
            display: flex;
            align-items: center;
            color: rgba(0,0,0,0.65);
            line-height: 24px;
            .address {
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/location.png') no-repeat;
              background-size: 100% 100%;
              height: 16px;
              width: 16px;
              margin-right: 5px;
            }
            .date {
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/date.png') no-repeat;
              background-size: 100% 100%;
              height: 16px;
              width: 16px;
              margin-right: 5px;
            }
          }
        }
        
      }
    }

  }
}
</style>
