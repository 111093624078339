import { render, staticRenderFns } from "./bothLocationReport.vue?vue&type=template&id=79847037&scoped=true&"
import script from "./bothLocationReport.vue?vue&type=script&lang=js&"
export * from "./bothLocationReport.vue?vue&type=script&lang=js&"
import style0 from "./bothLocationReport.vue?vue&type=style&index=0&id=79847037&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79847037",
  null
  
)

export default component.exports