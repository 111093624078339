<template>
  <div class="yspf-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yspf-detail-body-container">
      <!-- top bar -->
      <div class="top-bar" v-html="data.content">
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import list from 'assets/json/yunshangpufa';
export default {
  data() {
    return {
      data: {}
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.data = list.find(item => item.id === id);
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss">
.yspf-detail-container {
  height: 100vh;
  width: 100vw;
  .yspf-detail-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100%;
      p {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

  }
}
</style>
