<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="lr-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index" @click="handleGoto(item)">
        <div class="left">
          <div class="tit">{{item.title}}</div>
          <div class="foot-info">
            <p>{{`时间：${item.time}`}}</p>
            <p>{{`地点：${item.location}`}}</p>
          </div>
        </div>
        <div class="right">
          <img :src="item.picture" alt="">
          <i :class="classes[item.status]"></i>
        </div>
        
      </li>
    </ul>
  </van-list>
</template>
<script>
import nrbApi from "api/llyjr/nrb";
import { mapState } from 'vuex';
export default {
  name: 'locationReportList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
      classes: {
        1: 'in-progress',
      }
    }
  },
  props: {
    type: {
      type: String,
      default: function() {
        return 'video';
      }
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      // nrbApi
      //   .getNrbList({
      //     page,
      //     size,
      //     object: {
      //       className: category,
      //       issueStatus: status,
      //       belongOwn: this.belongOwn,
      //     },
      //   })
      //   .then((res) => {
      //     console.log('res', res);
          
      //     this.loading = false;
      //     const { list = [], total = 0 } = res.data || {};
      //     if(page === 1) {
      //       this.dataList = list;
      //     } else {
      //       this.dataList = Array.from(new Set(this.dataList.concat(list)))
      //     }
      //     this.total = total;
      //     if (this.dataList.length >= this.total) {
      //       // 数据全部加载完成
      //       this.listFinished = true;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('error====>', err);
      //     this.loading = false;
      //   });
      this.dataList = new Array(4).fill({
          title: '黄社溇社区第三季度在职党员活动',
          time: '09-27 19:00至21:00',
          location: '黄社溇社区党群服务中心',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/huangshelou.png',
          status: 1
        });
      this.total = 4;
      this.listFinished = true;
    },
    handleGoto(data) {
      this.$router.push('locationReportDetail');
    }
  }
}
</script>
<style lang="scss" scoped>
.lr-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  border: none;
  li {
    padding: 1rem;
    background-color: #FFF;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    display: flex;
    margin-bottom: 10px;
    .left {
      position: relative;
      .tit {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        margin-bottom: 10px;
      }
      .foot-info {
        color: rgba($color: #000000, $alpha: 0.45);
        font-size: 14px;
      }
      
    }
    .right {
      height: 86px;
      width: 140px;
      position: relative;
      img {
        width: 140px;
        // height: 86px;
      }
      .in-progress {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 0;
        right: 0;
        background: url('~assets/images/building/icon_activity_tag1.png') no-repeat;
        background-size: 100% 100%;
        display: inline-block;
      }
    }
    
  }
}
</style>