<template>
  <div class="myNrb-con">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>我的</h1>
    </div>
    <van-tabs @click="handleTab" color="#4479ED">
      <van-tab title="我的发布">
        <nrbList></nrbList>
      </van-tab>
      <van-tab title="我的认领">
        <nrbList></nrbList>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import NrbList from './myNrbList';
export default {
  data() {
    return {
    }
  },
  components: {
    NrbList
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    })
  },
  methods: {
    handleTab(value, title) {
      this.$store.commit('setSelectedTab', title)
      console.log('title', title);
      
    },
    goBack() {
      this.$store.commit('setSelectedTab', '我的发布')
      this.$router.go(-1);
    }
  }
}
</script>
<style lang="scss" scoped>
.myNrb-con {
  height: 100vh;
  background: #FFFFFF;
  padding-top: 2.7rem;
}
</style>