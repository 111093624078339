<template>
  <div class="publish-need-container">
    <van-nav-bar
      title="发布需求"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="publish-wise-man-need-body-container">
      <div class="image-bar">
        发布需求
        <p>您可以发布下周想要的服务需求，社区会统一安排</p>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="serviceType"
          name="needType"
          label-class="input-label"
          is-link
          readonly
          label="服务类型"
          placeholder="请选择服务类型"
          required
          :rules="[{ required: true, message: '' }]"
          @click="show = true"
        />
        <van-field
          size="large"
          v-model="address"
          label-class="input-label"
          name="needAddress"
          label="期望地点"
          placeholder="请输入期望地点"
          required
          :rules="[{ required: true, message: '' }]"
          right-icon="location-o"
        />
        <div class="section" style="padding: 0.8rem;">
          <div class="title">期望时间（可多选）</div>
          <div class="date-btn-con">
            <div
              v-for="(item, i) in dateList"
              :key="`${item.day}_${i}`"
              :class="['date-btn', {'selected': isSelected(item.day)}]"
              @click="handleSelect(item.day)"
            >
              {{`${item.week}（${item.day}）`}}
            </div>
          </div>
          <div class="divider"></div>
        </div>
        <van-field
          size="large"
          v-model="name"
          label-class="input-label"
          name="name"
          label="发布人"
          placeholder="请输入"
          required
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          size="large"
          v-model="phone"
          label-class="input-label"
          name="phone"
          label="联系电话"
          placeholder="请输入"
          required
          :rules="[{ required: true, message: '' }]"
        />
        <div class="section" style="padding: 0.8rem;margin-top: 0.8rem;">
          <div class="title">需求备注</div>
          <van-field
            size="large"
            v-model="remark"
            rows="3"
            autosize
            name="needIntroduce"
            type="textarea"
            maxlength="200"
            placeholder="请填写需求备注"
            show-word-limit 
          />
        </div>
        <div class="footer-button">
          <van-button
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
          >
            发送需求
          </van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-picker
        title
        show-toolbar
        :columns="options"
        value-key="dictLabel"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import context from '@/main';
import moment from 'moment';
moment.locale('zh-cn', {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY年MM月DD日',
        LLL: 'YYYY年MM月DD日Ah点mm分',
        LLLL: 'YYYY年MM月DD日ddddAh点mm分',
        l: 'YYYY-M-D',
        ll: 'YYYY年M月D日',
        lll: 'YYYY年M月D日 HH:mm',
        llll: 'YYYY年M月D日dddd HH:mm'
    },
    meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
    meridiemHour: function (hour, meridiem) {
        if (hour === 12) {
            hour = 0;
        }
        if (meridiem === '凌晨' || meridiem === '早上' ||
            meridiem === '上午') {
            return hour;
        } else if (meridiem === '下午' || meridiem === '晚上') {
            return hour + 12;
        } else {
            // '中午'
            return hour >= 11 ? hour : hour + 12;
        }
    },
    meridiem: function (hour, minute, isLower) {
        const hm = hour * 100 + minute;
        if (hm < 600) {
            return '凌晨';
        } else if (hm < 900) {
            return '早上';
        } else if (hm < 1130) {
            return '上午';
        } else if (hm < 1230) {
            return '中午';
        } else if (hm < 1800) {
            return '下午';
        } else {
            return '晚上';
        }
    },
    calendar: {
        sameDay: '[今天]LT',
        nextDay: '[明天]LT',
        nextWeek: '[下]ddddLT',
        lastDay: '[昨天]LT',
        lastWeek: '[上]ddddLT',
        sameElse: 'L'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return number + '日';
            case 'M':
                return number + '月';
            case 'w':
            case 'W':
                return number + '周';
            default:
                return number;
        }
    },
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年'
    },
    week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
})
export default {
  data() {
    return {
      remark: '',
      phone: '',
      name: '',
      address: '',
      show: false, // 服务类型选项
      options: ['类型一', '类型二', '类型三'],
      selectedDates: [],
      serviceType: '',
    };
  },
  computed: {
    dateList() {
      const result = [];
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week() + 1).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('MM-DD');
        const week = current.format('ddd');
        result.push({ day, week });
      }
      return result;
    },
    ...mapState({
      userInfo: state =>
        (state.login.userInfo.nickname ? state.login.userInfo : null) ||
        (context.getSessionData("userInfo"))
    })
  },
  components: {},
  mounted() {
    this.name = this.userInfo.userName;
    this.phone = this.userInfo.phone;
    this.getServiceOptions();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      // console.log('values', values);
      if(!this.selectedDates || this.selectedDates.length < 1) {
        this.$toast('请选择期望日期');
      } else {
        api.applyWiseManNeed({
          ...values,
          expectTime: this.selectedDates.join(','),
        }).then(res => {
          if (res.flag) {
            this.$toast({
              message: '需求发布成功！',
              position: 'top',
            });
            this.$router.go(-1);
          }
        })
      }
    },
    getServiceOptions() {
      api.getWiseManServiceType().then(res => {
        this.options = res.data;
      })
    },
    onConfirm(value) {
      this.show = false;  
      this.serviceType = value.dictLabel;
    },
    isSelected(item) {
      return this.selectedDates.find(day => day === item);
    },
    handleSelect(day) {
      if(this.isSelected(day)) {
        this.selectedDates = this.selectedDates.filter(item => item !== day);
      } else {
        this.selectedDates.push(day);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.publish-need-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFF;

  .publish-wise-man-need-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    

    .image-bar {
      width: 100%;
      height: 92px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/form-top-bg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      text-align: center;
      padding: 20px;
      line-height: 28px;
      >p {
        font-size: 14px;
      }
    }
    ::v-deep .input-label {
      >span {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
    }
    .section {
      width: 100%;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
      .date-btn-con {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .date-btn {
          width: calc(50% - 5px);
          background: #F4F5F8;
          border-radius: 4px;
          line-height: 32px;
          text-align: center;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          margin-bottom: 10px;
        }
        .selected {
          color:  #4479ED;
          background:  rgba(68,121,237,0.15);
        }
      }
      .divider {
        height: 1px;
        width: 100%;
        background-color:#ebedf0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      font-size: 18px;
    }
  }
}
</style>
