<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>云上普法</h1>
      </div>
      <div class="kgh-main blue-background">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/yspf-bg.png" />
        </div>
        <!-- 工会法律法规 -->
        <div class="card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">工会法律法规</span>
          </div>
          <div class="card-bar-content">
            <ul>
              <li class="fl-item" v-for="(item, index) in list" :key="index" @click="gotoLink(item)">
                <div class="fl-title">
                  <img :src="item.icon" alt="">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="fl-desc">
                      <i class="time"></i>
                      <span style="margin-right: 10px;">{{item.created_at}}</span>
                      <!-- <i class="view"></i>
                      <span>{{item.view}}</span> -->
                  </div>
                  </div>
                </div>
                
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="kgh-list">
          <ul>
            <li v-for="(item, index) in list" :key="index">
              <i></i>
              <div class="desc">
                <div class="title">{{ item.title }}</div>
                <p>{{ item.update }}</p>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import list from 'assets/json/yunshangpufa';
export default {
  data() {
    return {
      list: list,
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(data) {
      this.$router.push(`yspfDetail?id=${data.id}`);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.blue-background {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 0;
  height: 100vh;
  .card-bar {
    width: 100%;
    margin: 10px 0;
    padding: 6px 0;
    .card-bar-title {
      width: 100%;
      height: 33px;
      padding: 10px 10px 0 10px;
      background: #fff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .text-title {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        width: 50%;
      }
      .text-more {
        color: #bfbfbf;
        font-size: 11px;
        float: right;
        display: flex;
        align-items: center;
      }
    }
    .card-bar-content {
      width: 100%;
      .fl-item {
        border-bottom: 0.5px solid rgba(0,0,0,0.1);
        margin: 10px 10px 0 10px;
        padding: 10px 0;
        .fl-title {
          display: flex;
          align-items: flex-start;
          >img {
            width: 2rem;
            height: 2rem;
            margin-right: 10px;
          }
          >div {
            >h1 {
              font-size: 16px;
              line-height: 32px;
              color: rgba(0,0,0,0.85);
              font-weight: 500;
            }
            .fl-desc {
              display: flex;
              height: 24px;
              align-items: center;
              font-size: 12px;
              color: rgba(0,0,0,0.45);
              .time, .view {
                width: 16px;
                height: 16px;
                display: inline-block;
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                background-size: 100% 100%;
              }
              .view {
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          
        }
        
      }
    }
  }
}
</style>
