<template>
  <div class="huanwuwei-container">
    <van-nav-bar
      title="业委会介绍"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="huanwuwei-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="top-data-item" v-for="(item) in labelList" :key="item.title">
          <h1>{{data[item.value]}}</h1>
          <p>{{item.title}}</p>
        </div>
      </div>
      <div class="huanwuwei-container">
        <div class="page-con-huanwuwei">
          <van-tabs @click="changeTab">
            <van-tab v-for="(item) in tabList" :title="item" :key="item">
            </van-tab>
          </van-tabs>
          <div class="content" v-html="getContent" v-if="!(this.mapObj[this.checkTab] || {}).isImg">
            <!-- <img :src="getContent" alt=""/> -->
          </div>
          <div class="content" v-if="(this.mapObj[this.checkTab] || {}).isImg">
            <img :src="getContent" alt="" @click="showDetailPage"/>
          </div>
          <div class="table-con">
            <div>物业人员</div>
            <img :src="getImg" alt="" @click="showImg(getImg)"/>
          </div>
          <div class="table-con" v-if="(this.mapObj[this.checkTab] || {}).tbImg1">
            <div>业主委员会</div>
            <img :src="getImg1" alt="" @click="showImg(getImg1)"/>
          </div>
          <div class="content" v-html="(this.mapObj[this.checkTab] || {}).desc">
            <!-- <img :src="getContent" alt=""/> -->
          </div>
          <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/huanwuwei_title.png" alt=""> -->
          <!-- <p style="margin-bottom: 10px;">2018年8月9日，在街道领导的指导带领下，社区成立了环境与物业管理委员会，这是“业委会”在诸暨市乃至绍兴市的首批试点之一。</p>
          <i class="right_circle"></i>
          <i class="left-circle"></i>
          <div class="red-row">街道党工委—办事处</div>
          <i class="push-arrow"></i>
          <div class="red-row">浣东派出所  社区党总支部</div>
          <div class="red-linear-row">监督 指导 管理</div>
          <i class="push-arrow"></i>
          <div class="red-row">业委会</div>
          <div class="red-linear-row2" >监督  指导  宣传</div>
          <div class="red-linear-row2" >做好物业公司和业委会之间的协调工作</div>
          <div class="red-linear-row2" >宣传物业管理法律法规，引导全体业主遵守法律法规</div>
          <div class="red-linear-row2" >监督业委会执行业主大会赋予的各项事项</div>
          <div class="red-linear-row2" >指导业委会监督物业公司全面履行物业服务合同</div>
          <div class="red-linear-row2"><p style="transform: scale(0.9);white-space: nowrap;">实行业委会委员的履职信用记分制度、物业公司违纪记分制度</p></div>
          <div class="person-list-con">
            <div class="person-list-item" v-for="item in personList" :key="item.name">
              <img class="person-list-image" :src="item.picture" alt="">
              <span v-if="item.tag" class="person-list-tag"></span>
              <div class="person-list-card">
                <div class="person-list-card-title"><h1>{{item.name}}</h1><span>{{item.title}}</span></div>
                <div class="person-list-row" @click="callPhone(item.phone)">
                  <i class="phone"></i>{{item.phone}}
                </div>
                <div class="person-list-row">{{item.specialty}}</div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="list-con">
          <div class="yezhu-con">
            <p>遵守业主公约、业主大会议事规则；执行业主大会的决定和业主大会授权业主委员会作出决定；按时交纳物业服务费用；法律、法规规定的其他义务；监督业委会工作。</p>
          </div>
          <div class="yeweihui-con">
            <p>召集业主大会会议；提出物业管理建议；督促不交纳物业费的业主限期交纳；监督管理规约实施；组织筹集专项维修资金；组织业委会换届选举工作；完成业主大会其他事</p>
          </div>
          <div class="wuye-con">
            <p>物业管理服务；房屋及设备设施维修养护；安全管理、秩序维护；环境管理包含环境保洁、绿化；小区档案管理；其他服务。</p>
          </div>
        </div> -->
      </div>
      <div class="comm-btn-con">
        <div
          class="comm-btn"
          v-for="(item, index) in btnList"
          :key="index"
          @click="gotoLink(item.path)"
        >
          <img :src="item.picture" :alt="item.title">
          <p>{{item.title}}</p>
        </div>
        <!-- <el-button @click="gotoLink('eventList')"
          >典型事件</el-button
        >
        <el-button type="primary" @click="gotoLink('eventAdd')"
          >我要上报</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import {ImagePreview} from 'vant';
import mapObj from './ywhConfig';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      // tabList: ['步升花园', '丁香公寓', '翡翠园', '江南华庭', '朗臻雅苑', '祥生君城', '祥生君庭', '阳光花园', '朗臻丰泽苑'],
      tabList: ['江南华庭', '朗臻雅苑', '祥生君城', '朗臻丰泽苑'],
      checkTab: '江南华庭',
      mapObj,
      labelList: [
        {
          title: '上报数',
          value: 'reportNum'
        },
        {
          title: '已处理',
          value: 'dealNum'
        },
        {
          title: '处理中',
          value: 'ingNum'
        },
        {
          title: '参与人数',
          value: 'joinNum'
        },
      ],
      btnList: [
        {
          title: '社区推荐',
          path: 'recommendList',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/shequtuijian-icon.png'
        },
        {
          title: '社区金点子',
          path: 'goldenIdea',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/jindianzi-icon.png'
        },
        // {
        //   title: '典型事件',
        //   path: 'eventList',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/dianxingshijian-icon.png'
        // },
        // {
        //   title: '我要上报',
        //   path: 'eventAdd',
        //   picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/woyaoshangbao-icon.png'
        // },
      ],
      data: {},
      personList: [
        {
          name: "张晓鸿",
          title: '社区工作人员',
          phone: '13906758777',
          specialty: '擅长矛盾协调',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/zhangxiaohong.png',
        },
        {
          name: "钟伟",
          title: '纪检干部',
          phone: '13567530828',
          specialty: '擅长纪检监察',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/zhongwei.png',
        },
        {
          name: "周恩锋",
          title: '民警',
          phone: '13484328235',
          specialty: '擅长治安管理、纠纷调处',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/zhouenfeng.png',
        },
        {
          name: "冯斌",
          title: '民警',
          phone: '13754331150',
          specialty: '擅长治安管理、纠纷调处',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/fengbin.png',
        },
        {
          name: "陈云",
          title: '民警',
          phone: '13516751383',
          specialty: '擅长治安管理、纠纷调处',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/chenyun.png',
        },
        {
          name: "来思远",
          title: '民警',
          phone: '19557500233',
          specialty: '擅长治安管理、纠纷调处',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/laisiyuan.png',
        },
        {
          name: "胡杰丰",
          title: '律师',
          phone: '13757505616',
          specialty: '擅长法律咨询',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/hujiefeng.png',
        },
        {
          name: "丁良初",
          title: '机关干部',
          phone: '13575545593',
          specialty: '擅长工程管理',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/dingliangchu.png',
        },
        {
          name: "薛忠兴",
          title: '社区工作人员',
          phone: '13626875118',
          specialty: '擅长财务管理',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/xuezhongxing.png',
        },
        {
          name: "周民锋",
          title: '公司负责人',
          phone: '13867548980',
          specialty: '擅长工程建设',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/zhouminfeng.png',
        },
        {
          name: "车土根",
          title: '退休干部',
          phone: '13505758982',
          specialty: '擅长财务管理',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/chetugen.png',
        },
        {
          name: "孙丽萍",
          title: '公司负责人',
          phone: '13706756846',
          specialty: '擅长工程建设',
          tag: true,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/butler/sunliping.png',
        },
      ]
    };
  },
  computed: {
    getContent() {
      // const sign = (this.mapObj[this.checkTab] || {}).content;
      // if (sign) {
      //   return <img src="" alt=""/>
      // }
      return (this.mapObj[this.checkTab] || {}).content;
    },
    getImg() {
      return (this.mapObj[this.checkTab] || {}).tbImg;
    },
    getImg1() {
      return (this.mapObj[this.checkTab] || {}).tbImg1;
    },
  },
  components: {},
  mounted() {
    this.getStatisticData();
    console.log(this.mapObj[this.checkTab]);
  },
  watch: {
   
  },
  methods: {
    ...mapMutations(['setDataInfo']),
    showImg(url) {
      ImagePreview([url]);
    },
    showDetailPage(name) {
      const {intro} = this.mapObj[this.checkTab];
      this.setDataInfo({
        content: intro,
        title: this.checkTab,
      });
      this.$router.push(`/zxzxDetail`);
    },
    changeTab(name, title) {
      this.checkTab = title;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    getStatisticData() {
      api.getHWWStaticData({}).then(res => {
        if(res.flag) {
          this.data = res.data;
        }
      })
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    callPhone(num) {
      window.location.href = `tel://${num}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.huanwuwei-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  ::v-deep .van-tabs__wrap--scrollable .van-tabs__nav {
    background: transparent;
    width: 94%;
  }
  .content {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-indent: 4px;
    }
    img {
      width: 100%;
      margin-top: 10px;
    }
  }
  .table-con {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      margin-top: 10px;
    }
  }
  .huanwuwei-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100%;
      height: 97px;
      display: flex;
      justify-content: space-around;
      text-align: center;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/statistic_bg.png') no-repeat;
      background-size: 100% 100%;
      color: #FFF;
      padding: 16px 1rem 0 1rem;
      position: relative;
      z-index: 1;
      .top-data-item {
        >h1 {
          line-height: 32px;
          font-size: 24px;
        }
      }
      
    }
    .huanwuwei-container {
      height: calc(100% - 10.6rem);
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      margin-top: -20px;
      margin-bottom: 8px;
      z-index: 3;
      .list-con {
        margin: 10px;
        padding: 10px 15px;
        background: #FFEFEE;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .yezhu-con, .wuye-con, .yeweihui-con {
          width: 100%;
          min-height: 110px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/yezhu.png') no-repeat;
          background-size: 100% 100%;
          padding: 14px 10px 14px 66px;
          font-size: 12px;
          color: rgba(0,0,0,0.85);
          line-height: 20px;
        }
        .wuye-con {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/wuye.png') no-repeat;
          background-size: 100% 100%;
        }
        .yeweihui-con {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/yeweihui.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .page-con-huanwuwei {
        padding: 10px 15px;
        background: #FFEFEE;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        margin: 0 10px;
        
        .right_circle {
          width: 56px;
          height: 88px;
          position: absolute;
          right: 0;
          top: 250px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/circle-right.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
        }
        .left-circle {
          width: 82px;
          height: 364px;
          position: absolute;
          left: 0;
          top: 380px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/circle-left.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
        }
        >img {
          width: 70%;
          margin: 0 15%;
        }
        >p {
          color: rgba(0,0,0,0.85);
          font-size: 14px;
          line-height: 18px;
          text-indent: 28px;
        }
        .push-arrow {
          height: 36px;
          width: 18px;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/push_arrow.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          margin: 5px;
        }
        .red-row {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/red_row_bg.png') no-repeat;
          background-size: 100% 100%;
          text-align: center;
          font-size: 15px;
          color: #FFF;
          font-weight: 500;
          padding: 10px 10px 15px 10px;
          min-width: 174px;
        }
        .red-linear-row {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/red_linear_bg.png') no-repeat;
          background-size: 100% 100%;
          min-width: 170px;
          text-align: center;
          font-size: 12px;
          color: rgba(0,0,0,0.85);
          padding: 6px 0;
          margin: 10px 0;
        }
        .red-linear-row2 {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/red_linear_bg_2.png') no-repeat;
          background-size: 100% 100%;
          min-width: 300px;
          text-align: center;
          color: rgba(0,0,0,0.85);
          padding: 8px 0;
          margin: 10px 0;
        }
        .person-list-con {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .person-list-item {
            width: 48%;
            margin-top: 10px;
            // padding: 10px;
            position: relative;
            .person-list-image {
              width: 80%;
              margin-left: 10%;
              border-radius: 50%;
              border: 4px solid #FE8776;
              background-image: linear-gradient(0deg, #CA1832 0%, #FE8776 100%);
            }
            .person-list-tag {
              width: 36px;
              height: 36px;
              position: absolute;
              top: 0;
              right: 0;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/huanwuwei-tag.png') no-repeat;
              background-size: 100% 100%;
            }
            .person-list-card {
              background-color:#FFD2CF;
              border-radius: 4px;
              padding: 10px 8px;
              margin-top: -10px;
              position: relative;
              z-index: 2;
              .person-list-card-title {
                display: flex;
                align-items: center;
                >h1 {
                  font-size: 14px;
                  color: rgba(0,0,0,0.85);
                  font-weight: 500;
                  margin-right: 3px;
                }
                >span {
                  font-size: 12px;
                  color: rgba(0,0,0,0.65);
                }
              }
              .person-list-row {
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 6px;
                font-size: 12px;
                color: #FFFFFF;
                font-weight: 500;
                background-image: linear-gradient(90deg, #FF8A77 0%, #F8686D 100%);
                border-radius: 20px;
                .phone {
                  width: 14px;
                  height: 14px;
                  background: url('http://dssq.ikeqiao.net/data/image/h5/images/butler/huanwuwei-phone.png') no-repeat;
                  background-size: 100% 100%;
                  display: inline-block;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
    .comm-btn-con {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .comm-btn {
        width: 72px;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        >img {
          width: 20px;
          height: 20px;
        }
        >p {
          font-size: 10px;
          color: rgba(0,0,0,0.85);
          line-height: 32px;
        }
      }
      
    }
  }
}
</style>
