import service from "utils/service";

const zjyApi = {
  getMarkList: (params) => {
    return service.get("/dataProvide/getMap", {params});
  },
  getParty: (params) => {
    return service.get("/screen/h5/getParty", {params});
  },
};
export default zjyApi;
