<template>
  <div class="educationDepart-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="education-depart-body-container">
      <!-- 机构详情 -->
      <div class="detail-info">
        <div class="detail-info-image">
          <img :src="data.positionImage" />
        </div>
        <div class="detail-info-text">
          <div class="detail-info-title">
            <h1>{{data.positionName}}</h1>
            <span>{{data.studyTypeValue}}</span>
          </div>
          <div class="icon-text">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/time-icon.png" alt="">
            <span>{{data.time}}</span>
          </div>
          <div class="icon-text">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/phone-icon.png" alt="">
            <span>{{data.phone}}</span>
          </div>
          <div class="location-text">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/location-icon.png" alt="">
            <span>{{data.location}}</span>
          </div>
        </div>
      </div>
      <!-- 详情 -->
      <van-tabs color="#4479ED" style="margin-top: 12px;">
        <van-tab title="活动信息" name="activity">
          <div class="" style="background: #FFF;">
            <van-dropdown-menu>
              <van-dropdown-item
                v-model="status"
                @change="handleChange($event, 'status')"
                :options="option2"
              />
              <van-dropdown-item
                v-model="sortField"
                @change="handleChange($event, 'sortField')"
                :options="option3"
              />
            </van-dropdown-menu>
            <div class="map-activity-list">
              <div v-for="(item, index) in list" :key="`${item.id}_${index}`" class="map-activity-card">
                <img :src="item.activityImage" class="map-activity-image"/>
                <div class="map-activity-title-1">
                  <h1>{{item.activityName}}</h1>
                  <span>已报名<b>{{item.signNum}}</b></span>
                </div>
                <div class="map-activity-text">
                  <span class="text-label">活动时间：</span>
                  <span>{{`${item.startTime}至${item.endTime}`}}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="详情介绍" name="detail">
          <div class="tab-container">
            {{data.positionDes}}
          </div>
        </van-tab>
        <van-tab title="资质信息" name="zizhi">
          <div class="tab-container">
            <div class="sub-title">机构全称</div>
            <div class="sub-value">{{data.positionName}}</div>
            <div class="sub-title">法人</div>
            <div class="sub-value">{{data.positionName}}</div>
            <div class="sub-title">证件资质</div>
            <div class="qualification-list">
              <div class="qualification-item"> 
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png" alt="">
                <div>民非登记证书</div>
              </div>
              <div class="qualification-item"> 
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png" alt="">
                <div>营业执照</div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import educationMapApi from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      data: {},
      option2: [
        { text: "全部状态", value: undefined },
        { text: "未开始", value: "未开始" },
        { text: "进行中", value: "进行中" },
        { text: "已结束", value: "已结束" },
      ],
      option3: [
        { text: "默认排序", value: undefined },
        { text: "最新发布", value: "最新发布" },
      ],
      status: undefined,
      sortField: undefined,
      id: '',
      list: [],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id = '' } = this.$route.query;
    this.id = id;
    educationMapApi.getDepartDetail({ id }).then(res => {
      if(res.flag) {
        this.data = res.data;
      }
    });
    this.getActivityList({ id });
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleChange(e, type) {
      this[type] = e;
    },
    getActivityList(params = {}) {
      educationMapApi.getDepartActivity({
        page: 1,
        size: 10,
        state: this.status,
        sort: this.sortField,
        id: this.id,
        ...params,
      }).then(res => {
        if(res.flag) {
          this.list = res.data.records;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.educationDepart-container {
  height: 100vh;
  width: 100vw;
  
  .education-depart-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .detail-info {
      background: #FFF;
      display: flex;
      padding: 10px;
      .detail-info-image {
        margin-right: 10px;
        >img {
          width: 6rem;
          height: 6rem;
        }
      }
      .detail-info-text {
        flex: 1;
        .detail-info-title {
          display: flex;
          align-items: center;
          >h1 {
            width: 190px;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          >span {
            background: #E3EFFD;
            border-radius: 2px;
            font-size: 12px;
            color: #4479ED;
            padding: 2px;
          }
        }
        .icon-text {
          display: flex;
          height: 24px;
          color: rgba(0,0,0,0.85);
          font-size: 14px;
          align-items: center;
          >img {
            height: 16px;
            width: 16px;
          }
        }
        .location-text {
            display: flex;
          height: 24px;
          color: rgba(0,0,0,0.45);
          font-size: 12px;
          align-items: center;
          >img {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    .tab-container {
      background-color: #FFF;
      padding: 16px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      line-height: 24px;
      .sub-title {
        background: #FFF;
        font-size: 14px;
        color: rgba(0,0,0,0.45);
        line-height: 24px;
      }
      .sub-value {
        margin-bottom: 10px;
      }
      .qualification-list {
        width: 100%;
        display: flex;
        .qualification-item {
          flex: 1;
          >img {
            width: 100%;
          }
          >div {
            text-align: center;
            font-size: 14px;
          }
        }
      }
      .map-activity-list {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 10px;
        .map-activity-card {
          width: 100%;
          border-radius: 4px;
          margin-right: 10px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
          .map-activity-image {
            width: 100%;
            height: 87px;
          }
          .map-activity-title-1 {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px;
            >h1 {
              font-size: 14px;
              color: rgba(0,0,0,0.85);
              letter-spacing: 0;
              font-weight: 500;
            }
            >span {
              font-size: 12px;
              color: rgba(0,0,0,0.65);
              >b {
                color: #4479ed;
              }
            }
          }
          .map-activity-text {
            font-size: 14px;
            line-height: 24px;
            color: rgba(0,0,0,0.85);
            padding: 0 10px;
            .text-label {
              color: rgba(0,0,0,0.45);
            }
          }
        }
      }
    }
  }
}
</style>
