<template>
  <div class="payMoneyPage" style="height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>订单详情</h1>
    </div>
    <div class="payList">
      <div v-for="(item, i) in (payList ? payList.filter((v, i) => showCount || i <4) : []).filter(v => v.count)" class="item" :key="i">
        <div class="image">
          <img :src="item.image" alt="">
        </div>
        <div class="text">
          <div class="name">{{item.name}}</div>
          <div class="btnGroup">
            <div class="count">✖️{{item.count}}</div>
            <!-- <div class="momeny">¥{{item.momeny || 0}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="payList.length > 4" class="showMore" @click="showCount = !showCount">{{ showCount ? '收起' : `展开(共${payList.length || 0}条)`}}</div>
    <div class="payList">
      <div class="item">
        <div class="text">
          <div class="name">数量</div>
          <div class="btnGroup">
            <div class="momeny">{{count || 0}}</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="text">
          <div class="name">金额</div>
          <div class="btnGroup">
            <div class="momeny">¥{{countMoney || 0}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnList">
      <div @click="ydOk">预定</div>
      <div @click="zfOk">支付</div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import lodash from 'lodash';
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      payList: [],
      count: 0,
      countMoney: 0,
      hunCount: 0,
      suCount: 0,
      zhuCount: 0,
      showCount: false,
      costArr: [
        { cost: 10, su: 3, hun: 0 },
        { cost: 13, su: 2, hun: 1 },
        { cost: 15, su: 3, hun: 1 },
        { cost: 20, su: 2, hun: 2 }
      ]
    };
  },
  components: {},
  mounted(opt) {
    this.payList = this.$store.state.allOther.buyList;
      this.count = 0;
      this.countMoney = 0;
      // this.payList.forEach(element => {
      //   this.count += element.count;
      //   if (element.count) {
      //     this.countMoney += element.momeny * element.count;
      //   }
      // });
      this.hunCount = 0;
      this.suCount = 0;
      this.zhuCount = 0;
      this.payList.forEach(element => {
        this.count += element.count;
        this.hunCount += element.type === '荤菜' ? element.count : 0;
        this.suCount += element.type === '素菜' ? element.count : 0;
        this.zhuCount += element.name === '白米饭' ? element.count : 0;
        // if (element.count) {
        //   this.countMoney += element.momeny * element.count;
        // }
      });
      const ele = this.costArr.find(item => item.hun === this.hunCount && item.su === this.suCount)
      if(ele) {
        this.countMoney = ele.cost + this.zhuCount * 2;
      }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    ydOk(){
      this.$router.push(`/orderList`)
      // Toast.success("预定成功！");
    },
    zfOk(){
      Dialog.confirm({
        title: '提示',
        message: '目前未开通支付，敬请期待',
        'confirm-button-text': '取消',
        'confirm-button-color': '#2e70ed',
        'cancel-button-color': '#eeeeee'
      })
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .payMoneyPage{
    height: 100% !important;
    position: relative;
    .payList{
      margin-top: 10px;
      padding: 10px;
      background: #fff;
      .item{
        margin-bottom: 10px;
        display: flex;
        height: 50px;
        line-height: 50px;
        .image{
          width: 40px;
          height: 40px;
          margin: 5px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          display: flex;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // border-bottom: 1px solid rgba(0,0,0,0.1);
          .name{
            font-size: 16px;
            color: #000000;
            font-weight: 500;
          }
          .btnGroup{
            display: flex;
            margin-left: auto;
            margin-right: 10px;
            .count{
              font-size: 16px;
              margin-right: 10px;
            }
            .momeny{
              font-size: 16px;
              width: 50px;
              text-align: right;
            }
            >img{
              width: 16px;
              height: 16px;
              margin: 17px 5px;
            }
          }
        }
      }
    }
    .showMore{
      background: #fff;
      border-top: 1px solid rgba(0,0,0,0.1);
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
    .btnList{
      margin-top: 10px;
      display: flex;
      >div{
        width: 50%;
        height: 50px;
        margin-bottom: 10px;
        line-height: 50px;
        text-align: center;
        background: #fff;
        color: #3E71F9;
        font-size: 16px;
        &:last-child{
          background: #ecf1ff;
        }
      }
    }
  }
</style>
