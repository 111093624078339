<template>
  <div class="coordination-container">
    <van-nav-bar
      class="coordination-nav-bar"
      title="享·协同"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="coordination-body-container">
      <div class="image-bar"></div>
      <!-- 按钮栏 -->
      <div class="icon-bar">
        <div
          v-for="(icon, index) in iconList"
          @click="gotoLink(icon.path)"
          :key="`${icon.name}_${index}`"
        >
          <img class="icon" :src="icon.icon" :alt="icon.name" />
          <p>{{ icon.name }}</p>
        </div>
      </div>
      <!-- 组织生活协同共享 -->
      <div class="decoration">
        <i class="decoration-left"></i>
        <span>组织生活协同共享</span>
        <i class="decoration-right"></i>
      </div>
      <div class="card-bar" style="background: #FFF;">
        <div class="card-bar-title">
          <span class="text-title">党建超市</span>
        </div>
        <div class="card-bar-content">
          <div class="picture-bar">
            <div
              v-for="(icon, index) in supermarketList"
              @click="gotoLink(icon.path)"
              :key="`${icon.name}_${index}`"
              class="picture"
            >
              <img :src="icon.icon" :alt="icon.name" />
            </div>
          </div>
        </div>
      </div>
      <!-- 党建活动 -->
      <!-- <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">党建活动</span>
          <span class="text-more">
            更多
            <van-icon name="arrow" color="#bfbfbf" size="11px" />
          </span>
        </div>
        <div class="card-bar-content">
          <div class="card-item">
            <h1 class="text-banner">完善制度</h1>
            <h1 class="text-banner">规范管理</h1>
          </div>
        </div>
      </div> -->
      <!-- 协同培训 -->
      <div class="card-bar" style="background: #FFF;">
        <div class="card-bar-title">
          <span class="text-title">协同培训</span>
        </div>
        <div class="card-bar-content">
          <div class="coin-bar-con" >
            <div
              v-for="(icon, index) in exerciseList"
              :class="['coin-bar', icon.icon]"
              @click="gotoLink(icon.path)"
              :key="`${icon.name}_${index}`"
            >
              <h1>{{icon.name}}</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- 组织生活大脑 -->
      <div class="card-bar" style="background: #FFF;">
        <div class="card-bar-title">
          <span class="text-title">组织生活大脑</span>
        </div>
        <div class="card-bar-content">
          <div class="icon-bar">
            <div
              v-for="(icon, index) in iconList1"
              @click="gotoLink(icon.path)"
              :key="`${icon.name}_${index}`"
            >
              <img class="icon" :src="icon.icon" :alt="icon.name" />
              <p>{{ icon.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations, mapState } from "vuex";
import SourceList from "./sourceList.vue";
export default {
  data() {
    return {
      iconList: [
        {
          name: "入党申请",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/heart.png",
          path: 'wxxd'
        },
        {
          name: "党员管理",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/manage.png",
          path: "memberManage",
        },
        {
          name: "东盛先锋",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/community1.png",
          path: "ddvanguard",
        },
      ],
      iconList1: [
        {
          name: "活跃指数",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/hyzs.png",
          // path: 'wxxd'
        },
        {
          name: "范围指数",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/fwzs.png",
          // path: "memberManage",
        },
        {
          name: "黑红榜",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/hhb.png",
          // path: "ddvanguard",
        },
        {
          name: "热力圈",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/rlq.png",
          // path: "ddvanguard",
        },
      ],
      exerciseList: [
        {
          name: "区级统一培训",
          icon: "training",
        },
        {
          name: "基层党委协同培训",
          icon: "member",
        },
        {
          name: "在线课堂",
          icon: "worker",
        },
        {
          name: "精品课",
          icon: "introduce",
        },
      ],
      supermarketList: [
        {
          name: "资源超市",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/source-store-bg.png",
          path: "sourceStore",
        },
        {
          name: "活动大厅",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity-pool-bg.png",
          path: "activityPool",
        },
        // {
        //   name: "智囊团",
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/knowledgeGroup.png",
        //   path: "knowledgeGroup",
        // },
      ],
    };
  },
  computed: {},
  components: { SourceList },
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.coordination-container {
  height: 100vh;
  width: 100vw;
  .coordination-nav-bar {
    background-image: linear-gradient(
      to left,
      rgb(52, 208, 247),
      rgb(127, 13, 255),
      rgb(98, 88, 240)
    );
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .coordination-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 176px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bar.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .decoration {
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      .decoration-left {
        width: 20%;
        height: 1px;
        margin-right: 10px;
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/decoration-left1.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .decoration-right {
        width: 20%;
        height: 1px;
        margin-left: 10px;
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/decoration-right1.png")
          no-repeat;
        background-size: 100% 100%;
      }
      > span {
        text-align: center;
      }
    }

    .icon-bar {
      margin: 10px;
      width: calc(100% - 20px);
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #fff;
      border-radius: 4px;
      > div {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        .icon {
          width: 47px;
          height: 47px;
          margin-bottom: 5px;
        }
        > p {
          text-align: center;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .coin-bar-con {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .coin-bar {
        width: 45%;
        margin: 10px 0;
        height: 112px;
        background-size: 100% 100%;
        padding: 10px;
        color: #FFF;
        position: relative;
        >h1 {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(0,0,0,0.40);
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .training {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/training-bg.png') no-repeat;
        background-size: 100% 100%;
      }
      .worker {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/worker-bg.png') no-repeat;
        background-size: 100% 100%;
      }
      .introduce {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/introduce-bg.png') no-repeat;
        background-size: 100% 100%;
      }
      .member {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/member-bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .picture-bar {
      margin: 10px;
      width: calc(100% - 20px);
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #fff;
      border-radius: 4px;
      
      .picture {
        flex: 1;
        height: 100%;
        margin: 10px;
        >img {
          width: 100%;
        }

      }
      
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      padding: 16px 0;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        background: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          width: 50%;
        }
        .text-more {
          color: #bfbfbf;
          font-size: 11px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        .card-item {
          width: 100%;
          height: 174px;
          background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/rule.png") no-repeat;
          background-size: 100% 100%;
          padding: 20px;
          .text-banner {
            font-size: 24px;
            color: #fff;
            line-height: 32px;
          }
        }
        .exercise-item {
          height: 214px;
          width: 100%;
          display: flex;
          .item-left {
            height: 214px;
            width: 245px;
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/positive.png")
              no-repeat;
            background-size: 100% 100%;
            padding: 20px;
          }
          .item-right {
            width: calc(100% - 255px);
            height: 214px;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .image-text {
              width: 100%;
              height: 67px;
              > p {
                margin: 40px 5px 0 0;
                line-height: 27px;
                text-align: right;
              }
            }
            .member {
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/member.png")
                no-repeat;
              background-size: 100% 100%;
            }
            .worker {
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/worker.png")
                no-repeat;
              background-size: 100% 100%;
            }
            .introduce {
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/introduce.png")
                no-repeat;
              background-size: 100% 100%;
            }
          }
          
        }
      }
    }
  }
}
</style>
