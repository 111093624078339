<template>
  <div class="video-detail-container">
    <van-nav-bar title="详情" left-arrow @click-left="onClickLeft" />
    <div class="video-info-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="video-con">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/coordination/play.png" alt="" />
        </div>
        <div class="info-con">
          <h1>{{ data.title }}</h1>
          <div class="info-foot">
            <span>{{ data.time }}</span>
            <span>{{ `${data.num}次播放` }}</span>
          </div>
        </div>
      </div>
      <!-- 详情介绍/评论 -->
      <div class="middle-bar">
        <van-tabs v-model="selectedTab" @click="handleTab" color="#4479ED">
          <van-tab title="详情介绍" name="introduce">
            <p class="comment-desc">{{ data.desc }}</p>
          </van-tab>
          <van-tab :title="`评论（${data.commentList.length}）`" name="comment">
            <commentList :dataList="data.commentList"></commentList>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 评论 -->
      <div class="footer" v-if="selectedTab === 'comment'">
        <van-field
          v-model="inputItem"
          placeholder="发布你的感想"
          shape="round"
          class="send-input"
          clearable
        >
          <template #left-icon>
            <img
              class="send-image"
              src="http://dssq.ikeqiao.net/data/image/h5/images/coordination/send.png"
              alt=""
            />
          </template>
        </van-field>
        <van-button class="send-btn" size="small">发送</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations, mapState } from "vuex";
import commentList from "components/commentList.vue";
export default {
  data() {
    return {
      data: {
        title: "奋勇前进 不负人民-习近平总书记“七一”重要讲话精神解读",
        tag: "党章党规",
        num: 342,
        picture: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/videoDefault.png",
        time: "2021-05-17 12:00",
        desc:
          "详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍",
        commentList: [
          {
            image: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png",
            userName: "张三",
            createTime: "2021-05-21 12:00",
            commentContent: "公共资源需要大家共同承担。",
            hasThumbsUp: "未点赞",
            thumbsUp: 23,
          },
          {
            image: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png",
            userName: "张三",
            createTime: "2021-05-21 12:00",
            commentContent: "公共资源需要大家共同承担。",
            hasThumbsUp: "已点赞",
            thumbsUp: 23,
          },
        ],
      },
      inputItem: "", // 感想
      selectedTab: "introduce",
    };
  },
  computed: {},
  components: { commentList },
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-detail-container {
  height: 100vh;
  width: 100vw;

  .video-info-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .top-bar {
      width: 100%;
      background: #fff;
      margin-bottom: 10px;
      .video-con {
        width: 100%;
        height: 240px;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/videoDefault.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        > img {
          height: 34px;
          width: 34px;
          position: absolute;
          top: calc(50% - 17px);
          left: calc(50% - 17px);
        }
        .tag {
          position: absolute;
          top: 12px;
          right: 12px;
          width: 70px;
          height: 24px;
          background: rgba(252, 235, 234, 0.9);
          font-size: 14px;
          color: #e13f30;
          line-height: 24px;
          text-align: center;
          border-radius: 8px 2px 8px 2px;
        }
      }
      .info-con {
        width: 100%;
        padding: 12px;
        > h1 {
          font-size: 16px;
          color: #000000;
          line-height: 26px;
          font-weight: 500;
        }
        .info-foot {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 24px;
          font-weight: 400;
          > span {
            margin-right: 16px;
          }
        }
      }
    }

    .middle-bar {
      width: 100%;
      flex: 1;
      background: #fff;
      .comment-desc {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 24px;
        font-weight: 400;
        padding: 10px 15px;
        width: 100%;
        height: 100%;
      }
    }

    .footer {
      position: fixed;
      bottom: 10px;
      left: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      .send-input {
        background: #f4f5f8;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.35);
        display: flex;
        align-items: center;
        border-radius: 20px;
        width: 82%;
        height: 36px;
        .send-image {
          height: 18px;
          width: 18px;
        }
      }
      .send-btn {
        flex: 1;
        // margin-left: 10px;
        border: none;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
      }
    }
  }
}
</style>
