<template>
  <div class="study-source-store-container">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="study-source-store-body-container">
      <van-tabs v-model="selected" color="#4479ed">
        <van-tab title="智能技术">
          <div class="tab-container class-source-con">
            <div class="class-source-top">
              <div class="top-left">
                <div @click="category = 'studyVideo'" :class="['top-left-btn', { 'selected': category === 'studyVideo'}]">视频</div>
                <div @click="category = 'studyArticle'" :class="['top-left-btn', { 'selected': category === 'studyArticle'}]">文章</div>
              </div>
              <!-- <div class="top-right">
                <van-dropdown-menu>
                  <van-dropdown-item v-model="type" :options="typeOption" />
                </van-dropdown-menu>
              </div> -->
            </div>
            <div class="class-source-list">
              <classList :type="category"></classList>
            </div>
          </div>
        </van-tab>
        <van-tab title="知识技能">
          <div class="tab-container class-source-con">
            <NoData></NoData>
          </div>
        </van-tab>
        <van-tab title="文化休闲">
          <div class="tab-container"><NoData></NoData></div>
        </van-tab>
         <van-tab title="国学礼仪">
          <div class="tab-container"><NoData></NoData></div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import NoData from 'components/noData.vue';
import classList from './classList';
export default {
  data() {
    return {
      typeOption: [
        { text: '全部', value: 0 },
        { text: '党章党规', value: 1 },
        { text: '党性教育', value: 2 },
      ],
      type: 0, // 党课资源标签
      selected: '全部', // 活动超市类型
      category: 'studyVideo', //党课资源类型
    };
  },
  computed: {
  },
  components: { NoData, classList },
  mounted() {
    const { title = '' } = this.$route.query;
    this.title = title;
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    }
  }
};
</script>

<style lang="scss" scoped>
.study-source-store-container {
  height: 100vh;
  width: 100vw;
  
  .study-source-store-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .tab-container {
      height: calc(100vh - 2.7rem - 78px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 10px;
    }

    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      border-radius: 4px;
      background-color: #FFF;
      padding: 10px;
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #EDEDED;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #E3EFFD;
            color: #4479ED;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
