<template>
    <!-- 2021年第二季度数据动态情况 -->
    <Echart
      :options="options"
      id="basicLine1"
      height="100%"
      width="100%"
    ></Echart>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => ([])
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
              text: '2021年第二季度数据动态情况',
              subtext: '数据来源：东盛社区',
              left: 'center',
              textStyle: {
                fontSize: 16,
                color: 'rgba(0,0,0,0.85)',
              },
              subtextStyle: {
                fontSize: 12,
                color: 'rgba(0,0,0,0.45)',
                fontWeight: 400,
              }
          },
          tooltip: {
              trigger: 'axis',
          },
          legend: {
              data: newData.map(item => item.title),
              bottom: 5,
              icon: 'rect',
              itemHeight: 1,
              itemWidth: 16,
              textStyle: {
                color: 'rgba(0,0,0,0.65)',
                fontSize: 12
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: 30,
              top: 60,
              containLabel: true
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['4月', '5月', '6月'],
              axisLabel: {
                show: true,
                textStyle: {
                  color: 'rgba(0,0,0,0.65)',
                  fontSize: 12
                }
              }
          },
          yAxis: {
              type: 'value',
              name: '数量',
              nameTextStyle: {
                color: 'rgba(0,0,0,0.45)',
                fontSize: 12,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dotted'
                }
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: 'rgba(0,0,0,0.65)',
                  fontSize: 12
                }
              }
          },
          series: [
            {
              name: "普法活动次数",
              type: "line",
              itemStyle: {
                normal: {
                  color: "#F06C5F"
                }
              },
              data: Array(3).fill(newData.find(item => item.title === '普法活动次数').num),
              smooth: false
            },
            {
              name: "调解数量",
              type: "line",
              itemStyle: {
                normal: {
                  color: "#FD9709",
                }
              },
              data: Array(3).fill(newData.find(item => item.title === '调解数量').num),
              smooth: false
            },
            {
              name: "志愿者人数",
              type: "line",
              itemStyle: {
                normal: {
                  color: "#4479ED"
                }
              },
              data: Array(3).fill(newData.find(item => item.title === '志愿者人数').num),
              smooth: false
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>