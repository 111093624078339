<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>服务详情</h1>
      </div>
      <div class="nrb-box">
        <div class="reply-detail">
          <div class="tit">{{ canTakeDetail.title }}</div>
          <div class="reply-info">
            <img :src="canTakeDetail.image" />
            <div>{{ canTakeDetail.owner }}<br /><span>发布于 {{ canTakeDetail.createTime }}</span></div>
            <!-- <span class="state">已接送<i>233</i>次</span> -->
          </div>

          <div class="data">
            <div><label>接送学校：</label><span class="blue">{{ canTakeDetail.age }}</span></div>

            <div>
              <label>接送方式：</label><span class="blue">{{ canTakeDetail.meetMethod }}</span>
            </div>
            <div><label>接送时间：</label><span class="blue">{{ canTakeDetail.meetTime }}</span></div>
          </div>
          <p>{{ canTakeDetail.content }}</p>
        </div>
      </div>
      <van-button
        v-if="canTakeDetail.organizer==='no'"
        type="info"
        class="button"
        color="#4479ED"
        @click="handleContent"
      >
        <van-icon name="phone-o" /> 联系Ta
      </van-button>
      <van-button
        v-if="canTakeDetail.organizer==='yes'"
        type="info"
        class="button"
        color="red"
        @click="handleDeleteService"
      >
        删除服务
      </van-button>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import yqpddApi from "api/yqpdd";
import { mapState } from "vuex";
export default {
  data() {
    return {
      status: false,
    };
  },
  components: {},
  computed: {
    ...mapState({
      canTakeDetail: state => {
        return state.llyjr.canTakeDetail;
      }
    })
  },
  mounted() {
    // this.getlist({ page: 1, size: 10 });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleContent() {
      // console.log('电话联系')
      if(this.canTakeDetail.phone) {
        window.location.href = `tel://${this.canTakeDetail.phone}`;
      }
    },
    handleDeleteService() {
      const { canTakeDetail } = this;
      console.log('cantakeDetail', canTakeDetail)
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '确认删除',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '请确认是否删除',
      })
      .then(() => {
        yqpddApi.deleteService({ id: canTakeDetail.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            // Toast('删除成功');
            this.$messagebox("提示", "删除成功");
            this.$router.go(-1);
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  padding: 0;
  .nrb-box {
    margin-top: 2.8rem;
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .main-table {
    padding: 0 0 0 0.5rem;
    border-left: 0.2rem solid #3291f8;
    text-align: left;
    color: #101010;
    width: 23.4rem;
    margin: 0.3rem auto;
    font: 600 1rem/1.2rem "microsoft yahei";
  }
  .pj-info {
    color: #bbb;
    width: 23.4rem;
    margin: 0 auto 1.5rem;
    font: 500 0.9rem/1.4rem "microsoft yahei";
  }
  .reply-detail {
    width: 23.4rem;
    margin: 0 auto;
    padding: 1.5rem 0;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    .tit {
      font-weight: 600;
      color: #333;
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    .data {
      font-size: 0.9rem;
      margin-bottom: 0.7rem;
      label {
        color: #bbb;
      }
      .blue {
        color: #1890ff;
      }
    }
    p {
      color: #7a7a7a;
    }
    .img {
      margin-top: 0.4rem;
      img {
        border-radius: 0.3rem;
        height: 5.4rem;
        width: 5.4rem;
        margin-right: 0.8rem;
      }
    }
    .reply-info {
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
      position: relative;
      height: 2rem;
      img {
        width: 2rem;
        height: 2rem;
        float: left;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
      div {
        float: left;
        font: 500 0.8rem/1rem "microsoft yahei";
        color: #333;
        span {
          color: #bbb;
        }
      }
      .state {
        color: #bbb;
        float: right;
        i {
          color: #1890ff;
          font-style: inherit;
        }
      }
    }
  }
  .button {
    position: fixed;
    left: 5vw;
    bottom: 1rem;
    width: 90vw;
    height: 3rem;
    font-size: 1rem;
    border-radius: 1.5rem;
  }
}
</style>
