<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发布服务</h1>
      </div>
      <div class="form-bar">
        <van-form label-width="130" @submit="onSubmit">
          <van-field
            size="large"
            v-model="title"
            name="title"
            label="标题"
            placeholder="请输入"
            class="input-bar"
            maxlength="20"
            :rules="[{ required: true, message: '请输入标题' }]"
          />
          <van-field
            size="large"
            v-model="owner"
            name="owner"
            label="发起人"
            placeholder="发起人"
            class="input-bar"
            :rules="[{ required: true, message: '请输入发起人' }]"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            :rules="[{ pattern, message: '请输入正确的联系电话' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="meetMethod"
            name="meetMethod"
            is-link
            readonly
            label="接送方式"
            placeholder="请选择"
            @click="showMeetMethodType = true"
            :rules="[{ required: true, message: '请选择接送方式' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="age"
            name="age"
            is-link
            readonly
            label="接送学校(可多选)"
            placeholder="请选择"
            @click="showType = true"
            :rules="[{ required: true, message: '请输入孩子接送学校' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="meetTime"
            name="meetTime"
            is-link
            readonly
            label="接送时间"
            placeholder="请选择"
            @click="showMeetTime = true"
            :rules="[{ required: true, message: '请选择接送时间' }]"
            class="input-bar"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">接送服务详情</div>
            <van-field
              size="large"
              v-model="content"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入需求内容"
              show-word-limit
              :rules="[{ required: true, message: '请输入具体内容' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >立即发布</van-button
            >
          </div>
        </van-form>
      </div>
      <van-popup v-model="showMeetMethodType" position="bottom">
        <van-picker
          title="接送方式"
          show-toolbar
          :columns="meetMethodTypeList"
          @confirm="meetMethodTypeConfirm"
          @cancel="onCancel('showMeetMethodType')"
        />
      </van-popup>
      <van-popup v-model="showType" class="multiSelect-school" position="bottom">
        <div class="multiSelect-school-top">
          <span class="multiSelect-school-cancel" @click="onCancel('showType')">取消</span>
          <h4 class="multiSelect-school-title">接送学校</h4>
          <span class="multiSelect-school-confirm" @click="confirmSchoolClick">确认</span>
        </div>
        <div class="multiSelect-school-select">
          <van-checkbox-group v-model="result">
            <van-checkbox class="school-checkbox" v-for="item in typeList" :key="item" :name="item">{{ item }}</van-checkbox>
          </van-checkbox-group>
        </div>
        <!-- <van-picker
          title="接送学校"
          show-toolbar
          :columns="typeList"
          @confirm="onTypeConfirm"
          @cancel="onCancel('showType')"
        /> -->
      </van-popup>
      <van-popup v-model="showMeetTime" position="bottom">
        <van-datetime-picker 
          v-model="showMeetTime"
          type="datetime"
          title="选择接送时间"
          :filter="filter"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onDateConfirm"
          @cancel="onDateCancel"
          :formatter="formatter" 
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Search, Toast } from "vant";
import yqpddApi from "api/yqpdd";
export default {
  data() {
    return {
      title: "",
      owner: "",
      phone: "",
      meetMethod: "",
      age: '',
      result: [],
      meetTime: "",
      content: "",
      pattern: /^[1]([3-9])[0-9]{9}$/,
      showType: false,
      showMeetMethodType: false,
      meetMethodTypeList: ['不限', '汽车', '电瓶车', '自行车', '步行', '其他'],
      typeList: ['朝阳幼儿园', '弘德幼儿园'],
      showMeetTime: false,
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
    };
  },
  components: {},
  mounted() {
    this.getlist({ page: 1, size: 10 });
  },
  methods: {
    onSubmit (values) {
      // this.$messagebox("提示", "预约成功");
      yqpddApi.publishService(values).then(res => {
        const { message, flag } = res;
        if (flag) {
          this.$messagebox("提示", "发布成功");
          // Toast('发布成功');
          this.$router.go(-1);
        } else {
          Toast(message);
        }
      })
    },
    confirmSchoolClick() {
      this.age = `${this.result}`;
      this.showType = false;
    },
    onCancel(type) {
      this[type] = false;
    },
    onTypeConfirm(value, index) {
      this.age = value;
      this.showType = false;
    },
    meetMethodTypeConfirm(value, index) {
      this.meetMethod = value;
      this.showMeetMethodType = false;
    },
    onDateConfirm (value, index) {
      this.meetTime = this.formatDateTime(value);
      this.showMeetTime = false;
    },
    onDateCancel () {
      this.showMeetTime = false;
    },
    filter (type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 30 === 0);
      }
      return options;
    },
    formatDateTime (date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d + " " + h + ":" + minute;
    },
    formatter (type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getlist(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          type: "拼接",
        },
        ...params,
      };
      yqpddApi.getOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => (item.img = item.image.split("#")[0]));
        this.pageDataList = records.filter((item) => item.type === "拼接");
      });
    },
    onSearch(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          title: value,
          type: "拼接",
        },
      };
      yqpddApi.getOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => (item.img = item.image.split("#")[0]));
        this.pageDataList = records;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";
.page-container {
  padding-top: 2.7rem;
  // height: calc('100vh - 3rem');
  // background: #f6f7fa;
  .form-bar {
    height: 100%;

    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
  .multiSelect-school {
    height: 40%;
    .multiSelect-school-top {
      widows: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      line-height: 44px;
      span {
        height: 100%;
        padding: 0 16px;
        font-size: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      .multiSelect-school-cancel {
        color: #969799;
      }
      .multiSelect-school-confirm {
        color: #576b95;
      }
      .multiSelect-school-title {
        max-width: 50%;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }
    .multiSelect-school-select {
      width: 100%;
      padding: 0 16px;
      .school-checkbox {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }
  }
}
</style>
