<template>
  <div class="wise-man-detail-container">
    <van-nav-bar
      title="服务详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <!-- top bar -->
      <div class="top-bar">
        <van-image
          width="100%"
          height="200px"
          :src="data.serviceImage"
          fit="cover"
        />
        <div class="service-title">{{data.serviceTitle || '-'}}</div>
        <div class="service-row">
          <span class="service-row-label">联系人：</span>
          <span>{{data.servicePerson || '-'}}</span>
        </div>
        <div class="service-row">
          <span class="service-row-label">服务时间：</span>
          <span>{{data.serviceTime || '-'}}</span>
        </div>
        <div class="service-row">
          <span class="service-row-label">服务地点：</span>
          <span>{{data.serviceAddress || '-'}}</span>
        </div>
      </div>
      <div class="body-bar">
        <div class="info-title">服务介绍</div>
        <div class="info-body" v-html="data.serviceIntroduce"></div>
      </div>
    </div>
    <div class="wise-man-foot-button">
      <van-button class="foot-button-item" @click="callPhone" type="info">拨打电话</van-button>
      <van-button class="foot-button-item" @click="gotoLink(`previewWiseMan?id=${data.id}`)" type="info">预约服务</van-button>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      data: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id = '' } = this.$route.query;
    this.getDetail(id);
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getDetail(id) {
      api.getWiseManList({}).then(res => {
        this.data = res.data.serviceList ? res.data.serviceList.find(item => item.id == id) : {};
        console.log(res.data.serviceList.find(item =>  item.id == id));
        
      })
    },
    callPhone() {
      if(this.data.servicePhone) {
        window.location.href = `tel://${this.data.servicePhone}`;
      } else {
        this.$toast('暂无电话信息')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wise-man-detail-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;
  
  .body-container {
    height: calc(100vh - 46px - 2.8rem);
    width: 100vw;
    // padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      background: #FFF;
      margin-bottom: 12px;
      .service-title {
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.85);
        padding: 12px;
        font-weight: 500;
      }
      .service-row {
        padding: 0 12px 8px;
        .service-row-label {
          color: rgba(0, 0, 0, 0.45)
        }
      }
    }
    .body-bar {
      background: #FFF;
      padding: 12px 0;
      .info-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2rem;
        border-left: 3px solid #4379ed;
        padding: 0 12px;
      }
      .info-body {
        padding: 12px;
      }
    }
  }
  .wise-man-foot-button {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .foot-button-item {
      flex: 1;
      margin: 0 12px;
      height: 2rem;
    }
  }
}
</style>
