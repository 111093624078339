<template>
  <div class="preview-wise-man-container">
    <van-nav-bar
      title="预约服务"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="name"
          name="name"
          label="姓名"
          placeholder="请填写"
          required
          :rules="[{ required: true, message: '请填写姓名' }]"
          class="input-bar"
          input-align="right"
        />
        <van-field
          size="large"
          v-model="phone"
          name="phone"
          label="联系电话"
          placeholder="请输入"
          required
          :rules="[{ required: true, message: '请填写联系电话' }]"
          class="input-bar"
          input-align="right"
        />
        <div style="line-height: 24px;padding: 12px;font-size: 1.067rem;color: rgba(0,0,0,0.65);">{{`当前可预约${data.serviceTime || ''}`}}</div>
        <van-field
          size="large"
          v-model="startTime"
          name="startTime"
          label="预约开始时间"
          placeholder="请选择"
          is-link
          readonly
          required
          @click="showStartTime = true"
          :rules="[{ required: true, message: '' }]"
          class="input-bar"
          input-align="right"
        />
        <van-field
          size="large"
          v-model="endTime"
          name="endTime"
          label="预约结束时间"
          placeholder="请选择"
          is-link
          readonly
          required
          @click="handleEndTime"
          :rules="[{ required: true, message: '请选择' }]"
          class="input-bar"
          input-align="right"
        />
        <van-field
          size="large"
          v-model="type"
          name="appointType"
          label="预约类型"
          placeholder="请选择"
          is-link
          readonly
          required
          @click="showType = true"
          :rules="[{ required: true, message: '请选择预约类型' }]"
          class="input-bar"
          input-align="right"
        />
        <van-field
          v-if="type"
          size="large"
          v-model="address"
          name="location"
          label="服务地点"
          placeholder="请填写"
          :readonly="type === '到店服务'"
          required
          :rules="[{ required: true, message: '' }]"
          class="input-bar"
          input-align="right"
        />
        <van-field
          v-model="remark"
          size="large"
          name="remarks"
          rows="5"
          autosize
          label="预约备注"
          type="textarea"
          maxlength="200"
          placeholder="请填写预约备注"
          show-word-limit
          class="input-bar"
          input-align="right"
        />
        <div class="footer-button">
          <van-button
            round
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
          >
            提交预约
          </van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="showType" round position="bottom">
      <van-picker
        title="预约类型"
        show-toolbar
        :columns="typeList"
        value-key="dictLabel"
        @confirm="onConfirm($event.dictLabel, 'type')"
        @cancel="onCancle('showType')"
      />
    </van-popup>
    <van-popup v-model="showStartTime" round position="bottom">
      <van-datetime-picker
        type="time"
        title="选择开始时间"
        :min-hour="minTime"
        :max-hour="maxTime"
        @confirm="onConfirm($event, 'startTime')"
        @cancel="onCancle('showStartTime')"
      />
    </van-popup>
    <van-popup v-model="showEndTime" round position="bottom">
      <van-datetime-picker
        type="time"
        title="选择结束时间"
        :min-hour="endMinHour"
        :max-hour="maxTime"
        @confirm="onConfirm($event, 'endTime')"
        @cancel="onCancle('showEndTime')"
      />
    </van-popup>
  </div>
</template>

<script>
import butlerApi from 'api/partyBuilding/butler';
import api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      data: {},
      startTime: '',
      endTime: '',
      minTime: '',
      maxTime: '',
      type: '',
      address: '',
      typeList: ['到店服务', '上门服务'],
      name: '',
      phone: '',
      remark: '',
      showType: false,
      showStartTime: false,
      showEndTime: false,
      endMinHour: '',
      endMinMinute: '',
      id: '',
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const obj = JSON.parse(sessionStorage.getItem('userInfo'));
    // console.log(obj)
    const { userName = '', phone = '' } = obj || {};
    this.name = userName;
    this.phone = phone;
    const { id = '' } = this.$route.query;
    this.id = id;
    this.getDetail(id);
    this.getTypeList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getTypeList() {
      api.getAllDictByType({ type: "appoint_type" }).then((res) => {
        const { data, flag } = res;
        if (flag) {
          this.typeList = data;
        } else {
          this.typeList = [];
        }
      });
    },
    getDetail(id) {
      api.getWiseManList({}).then(res => {
        this.data = res.data.serviceList ? res.data.serviceList.find(item => item.id == id) : {};
        if(this.data && this.data.serviceTime) {
          this.minTime = this.data.serviceTime.slice(0,2);
          this.maxTime = this.data.serviceTime.slice(6,8);
        }
      })
    },
    handleEndTime() {
      if(this.startTime) {
        this.showEndTime = true;
      } else {
        this.$toast('请先选择开始时间')
      }
    },
    onConfirm(data, name) {
      this[name] = data;
      switch(name) {
        case 'startTime': {
          this.endMinHour = this.startTime.slice(0,2);
          // this.endMinMinute = this.startTime.slice(3,5);
          this.showStartTime = false;
          break;
        }
        case 'endTime': {
          this.showEndTime = false;
          break;
        }
        case 'type': {
          this.showType = false;
          if(data === '到店服务')  {
            this.address = this.data.serviceAddress;
          } else {
            this.address = '';
          }
          break;
        }
      }
    },
    onCancle(name) {
      this[name] = false;
    },
    onSubmit(values) {
      butlerApi.previewWiseMan({
        ...values,
        serviceId: this.id,
      }).then(res => {
        if(res.flag) {
          this.$toast('提交成功！');
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer);
          }, 1000);
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.preview-wise-man-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    // padding-bottom: 10px;
    background: #f6f7fa;
    overflow-y: auto;
    overflow-x: hidden;
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      font-size: 18px;
      display: flex;
    }

  }
}
</style>
