<template>
  <div class="handle-borrow-container">
    <van-nav-bar
      title="借用处理"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="handle-borrow-body-container">
      <van-tabs v-model="active" color="#4479ED">
        <van-tab title="待处理" name="1">
          <borrowList type="1" />
        </van-tab>
        <van-tab title="已通过" name="2">
          <borrowList type="2" />
        </van-tab>
        <van-tab title="不通过" name="3">
          <borrowList type="3" />
        </van-tab>
        <van-tab title="已过期" name="4">
          <borrowList type="4" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import BorrowList from './borrowList';
export default {
  data() {
    return {
      pageDataList: [],
      active: 0,
      colors: {
        '待使用': { text: '#4479ED', background: '#E3EFFD' },
        '已使用': { text: '#E5B20A', background: '#FFF6D9' },
        '已过期': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
    };
  },
  computed: {
  },
  components: { BorrowList },
  mounted() {
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.handle-borrow-container {
  height: 100vh;
  width: 100vw;
  
  .handle-borrow-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
