<template>
  <div id="index">
    <div class="luck-wdjp-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我的奖品</h1>
      </div>
      <div class="wdjp-title">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/lucky/tz.svg" alt="">
        <span>请在开奖结束后一周内到东盛社区便民服务中心领取</span>
      </div>
      <div class="wdjp-list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="top">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/lucky/sj.svg" alt="">
            <div class="time">{{item.winningTime}}</div>
            <div class="tag" :style="item.receiveStatus === '未领取' ? 'background: rgba(227,239,253, 0.9);color: #4479ED;' : 'background: rgba(237,237,237, 0.9);color: rgba(0,0,0,0.45);'">{{item.receiveStatus}}</div>
          </div>
          <div class="bottom">
            <div class="image"><img :src="item.prizeImage" alt=""></div>
            <div class="text">
              <div class="title">{{item.prizeName}}</div>
              <div class="count">数量：{{item.prizeNumber}}个</div>
            </div>
          </div>
        </div>
        <div v-if="list.length === 0" style="text-align: center; margin-top: 30px; font-size: 18px;">暂无奖品</div>
      </div>
    </div>
  </div>
</template>

<script>
import luckyApi from 'api/lucky';
export default {
  data() {
    return {
      list: [],
    };
  },
  components: {
  },
  mounted() {
    const { key } = this.$route.query;
    luckyApi.getPrizeByInfoId({ lId: key }).then((res = {}) => {
      this.list =  res.data || [];
    });
  },
  methods: {
    goBack(){
          this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.luck-wdjp-container{
  padding-top: 2.7rem;
  padding-bottom: 10px;
  height: 100vh;
  overflow: scroll;
  .wdjp-title{
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    display: flex;
    font-size: 13px;
    color: rgba(0,0,0,0.65);
    font-weight: 400;
    >img{
      width: 20px;
      height: 20px;
      margin: 5px;
    }
  }
  .wdjp-list{
    .item{
      background: #fff;
      margin-bottom: 10px;
      .top{
        line-height: 40px;
        display: flex;
        border-bottom: 1px solid rgba(17,31,44,0.10);
        >img{
          width: 20px;
          height: 20px;
          margin: 10px;
        }
        .time{
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          text-align: left;
          font-weight: 500;
        }
        .tag{
          width: 56px;
          height: 24px;
          background: rgba(227,239,253, 0.9);
          color: #4479ED;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;
          margin-left: auto;
          margin-right: 10px;
        }
      }
      .bottom{
        padding: 10px;
        display: flex;
        .image{
          margin-right: 10px;
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          border: 1px solid rgba(0,0,0,0.1);
          border-radius: 3px;
          >img{
            width: 50px;
            height: 50px;
          }
        }
        .text{
          width: calc(100% - 90px);
          .title{
            line-height: 30px;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            font-weight: 500;
          }
          .count{
            line-height: 24px;
            font-size: 14px;
            color: rgba(0,0,0,0.65);
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
