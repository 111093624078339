<template>
  <div class="educationMap-container">
    <van-nav-bar
      title="学习地图"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="education-map-body-container">
      <!-- 地图 -->
      <div class="map-con" id="mapContain" tabindex="0"></div>
      <!-- 搜索栏 -->
      <van-cell-group class="search-con">
        <van-search
          v-model="condition"
          background="#FFF"
          shape="round"
          placeholder="搜索"
          class="search-bar"
        />
        <van-dropdown-menu class="dropdown-menu-bar" active-color="#4479ed">
          <van-dropdown-item title="筛选" ref="item">
            <div class="radio">
              <div 
                v-for="(item, index) in list"
                :key="`${item}_${index}`"
                @click="handleSelect(item)"
                :class="['radio-btn', { 'selected-radio-btn': isSelected(item)}]"
              >
                {{item.streetName}}
              </div>
            </div>
            <div class="dropdown-menu-footer">
              <button @click="handleReset" class="dropdown-menu-btn">重置</button>
              <button @click="handleOk" class="dropdown-menu-btn blue">确定</button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-cell-group>
      <!-- 图标 -->
      <div class="map-icons-con">
        <div class="map-icons-item" v-for="(icon, index) in mapIcons" :key="`${icon.key}_${index}`">
          <img :src="icon.icon" alt="">
          <span>{{icon.name}}</span>
        </div>
      </div>
      <!-- 详情弹窗 -->
      <van-popup
        v-model="showPopup"
        closeable
        position="bottom"
        :style="{ height: '20%' }"
      >
        <div class="point-detail-con">
          <!-- 机构详情 -->
          <div class="detail-info">
            <div class="detail-info-image">
              <img :src="detailRecord.icon_path" />
            </div>
            <div class="detail-info-text">
              <div class="detail-info-title">
                <h1>{{detailRecord.name}}</h1>
                <!-- <span>{{detailRecord.studyTypeValue || ''}}</span> -->
              </div>
              <!-- <div class="icon-text">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/time-icon.png" alt="">
                <span>{{detailRecord.time || ''}}</span>
              </div>
              <div class="icon-text">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/phone-icon.png" alt="">
                <span>{{detailRecord.phone || ''}}</span>
              </div> -->
              <div class="location-text">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/location-icon.png" alt="">
                <span>{{detailRecord.address}}</span>
              </div>
            </div>
          </div>
          <!-- 活动信息 -->
          <!-- <div class="map-activity-con">
            <div class="map-activity-title">
              <span class="text-title">活动信息</span>
              <span class="text-more">
                更多
                <van-icon name="arrow" color="rgba(0,0,0,0.45)" size="14px" />
              </span>
            </div>
            <div class="map-activity-list">
              <div :style="{width: detailRecord.activityList ? `${detailRecord.activityList.length * 262}px` : '100%',display: 'flex'}">
                <div v-for="(item, index) in detailRecord.activityList" :key="`${item.id}_${index}`" class="map-activity-card">
                  <img :src="item.activityImage" class="map-activity-image"/>
                  <div class="map-activity-title-1">
                    <h1>{{item.activityName}}</h1>
                    <span>已报名<b>{{item.signNum}}</b></span>
                  </div>
                  <div class="map-activity-text">
                    <span class="text-label">活动时间：</span>
                    <span>{{`${item.startTime}至${item.endTime}`}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import educationMapApi from 'api';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/educationMap';
export default {
  data() {
    return {
      condition: '',
      list: ['浣东街道', '柯岩街道', '华舍街道', '齐贤街道'],
      selected: [],
      mapIcons: [
        {
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/museum.png',
          name: '公办幼儿园',
          key: 'gongban'
        },
        {
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/school.png',
          name: '民办幼儿园',
          key: 'school'
        },
        {
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/institution.png',
          name: '小学',
          key: 'institution'
        },
        {
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/art-museum.png',
          name: '初中',
          key: 'art-museum'
        },
        {
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/science-museum.png',
          name: '高中',
          key: 'science-museum'
        },
      ],
      mapObj: {},
      markerList: [],
      showPopup: false,
      detailRecord: {},
      refreshCount: 0,
      mapCenter: [120.469639,30.088573],
      locationMark: null,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const _this = this;
    _this.mapObj = new window.AMap.Map('mapContain', {
      resizeEnable: true,
      zoom: 14,
      center: [120.51539,30.080082],//中心点坐标
      scrollWheel: false,
      
    });
    // 定位
    // _this.mapObj.on("complete", () => {
    //   _this.mapObj.plugin('AMap.Geolocation', function () {
    //       const geolocation = new window.AMap.Geolocation({  
    //         enableHighAccuracy: true,//是否使用高精度定位，默认:true  
    //         timeout: 3000,          //超过5秒后停止定位，默认：无穷大  
    //         buttonOffset: new window.AMap.Pixel(10, 120),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
    //         zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
    //         // buttonDom:'<input >',
    //         buttonPosition:'RT',
    //         useNative: true,
    //         showMarker: false, 
    //         showCircle: false, 
    //         showButton: true,
    //       });
    //     if (window.AMap.UA.ios && document.location.protocol !== 'https:') {
    //       //使用远程定位，见 remogeo.js
    //       var remoGeo = new window.RemoGeoLocation();
    //       //替换方法
    //       navigator.geolocation.getCurrentPosition = function() {
    //         return remoGeo.getCurrentPosition.apply(remoGeo, arguments);
    //       };
    //       //替换方法
    //       navigator.geolocation.watchPosition = function() {
    //         return remoGeo.watchPosition.apply(remoGeo, arguments);
    //       };
    //     }
        
    //       _this.mapObj.addControl(geolocation);  
    //       geolocation.getCurrentPosition();
    //       //返回定位信息  
    //       window.AMap.event.addListener(geolocation, 'complete', function (res) {
    //         console.log('location', res, res.formattedAddress)
    //         const data = res.position ? res.position : {};
    //         _this.drawLocationMark(data.lng, data.lat);
          
    //       });  
    //       //返回定位出错信息  
    //       window.AMap.event.addListener(geolocation, 'error', function (err) {  
    //           console.log(err);
    //         _this.drawLocationMark(_this.mapCenter[0], _this.mapCenter[1]);
    //         if(err.message === 'Geolocation permission denied.') {
    //           _this.$toast({
    //             message: '定位失败，请开启GPS后点击右上角定位按钮进行定位',
    //           });
    //         }
    //         for(;_this.refreshCount < 3; _this.refreshCount += 1) {
    //           geolocation.getCurrentPosition();
    //         }
    //       });  
    //   });
    // });
    _this.mapObj.panBy(0, 1);
    this.getMarkerList();
    this.getStreetList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleReset() {
      this.selected = [];
      this.$refs.item.toggle();
    },
    handleOk() {
      // 筛选操作
      this.$refs.item.toggle();
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    isSelected(item) {
      return this.selected.find(day => day === item);
    },
    handleSelect(data) {
      if(this.isSelected(data)) {
        this.selected = this.selected.filter(item => item !== data);
      } else {
        this.selected.push(data);
      }
    },
    getStreetList() {
      educationMapApi.getStreet().then(
        res => {
          if(res.flag) {
            this.list = res.data;
          }
        }
      )
    },
    getMarkerList() {
      this.markerList = data;
      // educationMapApi.getPositionSimple({}).then(res => {
      //   if(res.flag) {
      //     this.markerList = res.data;
      //     this.drawMark(this.markerList);
      //   }
      // })
      this.drawMark(this.markerList);
    },
    drawLocationMark(lng, lat) {
				if (this.locationMark) {
					this.locationMark.setMap(null);
					this.locationMark =null;
				}
				this.locationMark = new window.AMap.Marker({
					icon: new window.AMap.Icon({
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/point-set.png',
            imageSize: new window.AMap.Size(22,26),
          }),
					position: [Number(lng),Number(lat)],
					offset: new window.AMap.Pixel(0, 0)
				});
				this.locationMark.setMap(this.mapObj);
			},
    drawMark(list) {
      if (this.markerList.length > 0) {
        this.mapObj.remove(this.markerList);
        this.markerList = [];
      }
      list.forEach(v => {
        const { lon, lat, icon_path} = v;
        if(lon && lat) {
          const marker = new window.AMap.Marker({
            icon: new window.AMap.Icon({
              // image: require(`http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/marker_${type}.png`),
              image: icon_path,
              imageSize: new window.AMap.Size(22,26),
            }),
            // icon: icons.icon || '../../../yhcz/static/mapIcon/marker_yfp.png',
            position: [Number(lon),Number(lat)],
            offset: new window.AMap.Pixel(0, 0)
          });
          marker.on('click', (e) => {
            this.showDetailInfo(v);
            this.showPopup = true;
            // 换图标
            // e.icon = new window.AMap.Icon({
            //   image: require(`http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/marker_${type}_select.png`),
            //   imageSize: new window.AMap.Size(22,26),
            // });
            // e.setMap(this.mapObj);
          })
          marker.setMap(this.mapObj);
          this.markerList.push(marker);
        }
      })
    },
    showDetailInfo(data) {
      // this.detailRecord = data;
      // educationMapApi.getPositionDetail({id: 1}).then(res => {
      //   if(res.flag) {
      //     this.detailRecord = res.data;
      //     this.showPopup = true;
      //   }
      // })
      console.log('data', data)
      this.detailRecord = data;
      
    },
  }
};
</script>

<style lang="scss" scoped>
.educationMap-container {
  height: 100vh;
  width: 100vw;
  
  .education-map-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .map-con {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .search-con {
      display: flex;
      background-color: #FFF;
      width: 100%;
      .search-bar {
        background: #FFF;
        width: 80%;
      }
      .dropdown-menu-bar {
        flex: 1;
        ::v-deep .van-dropdown-menu__bar {
          box-shadow: none;
        }
        .radio {
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          .radio-btn {
            width: 81px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #DCDEE0;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            text-align: center;
            line-height: 32px;
            margin-right: 5px;
          }
          .selected-radio-btn {
            background: #4479ed;
            color: #fff;
            border: none;
          }
        }
        .dropdown-menu-footer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 10px 0;
          .dropdown-menu-btn {
            width: 46vw;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #DCDEE0;
            border-radius: 4px;
            color: rgba(0,0,0,0.85);
            text-align: center;
            line-height: 40px;
            margin: 0 auto;
          }
          .blue {
            background: #4479ed;
            color: #FFF;
          }
        }
        
      }
    }
    .map-icons-con {
      position: fixed;
      top: 110px;
      left: 10px;
      background: #FFF;
      border-radius: 4px;
      padding: 10px;
      width :110px;
      .map-icons-item {
        display: flex;
        align-items: center;
        height: 24px;
        >img {
          width: 24px;
          height: 24px;
          // margin: auto 10px;
        }
      }
    }
    .point-detail-con {
      background-color: #F7F8FA;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      .detail-info {
        background: #FFF;
        display: flex;
        padding: 10px;
        .detail-info-image {
          margin-right: 10px;
          >img {
            width: 6rem;
            height: 6rem;
          }
        }
        .detail-info-text {
          flex: 1;
          .detail-info-title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            >h1 {
              width: 220px;
              line-height: 18px;
              font-size: 16px;
              color: rgba(0,0,0,0.85);
              white-space: wrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
            >span {
              background: #E3EFFD;
              border-radius: 2px;
              font-size: 12px;
              color: #4479ED;
              padding: 2px;
            }
          }
          .icon-text {
            display: flex;
            height: 24px;
            color: rgba(0,0,0,0.85);
            font-size: 14px;
            align-items: center;
            >img {
              height: 16px;
              width: 16px;
            }
          }
          .location-text {
             display: flex;
            height: 24px;
            color: rgba(0,0,0,0.45);
            font-size: 12px;
            align-items: center;
            >img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      .map-activity-con {
        width: 100%;
        background: #FFF;
        margin-top: 12px;
        .map-activity-title {
          width: 100%;
          height: 33px;
          padding: 10px;
          background: #fff;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          .text-title {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            font-weight: 500;
          }
          .text-more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            float: right;
            display: flex;
            align-items: center;
          }
        }
        .map-activity-list {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          padding: 10px;
          .map-activity-card {
            width: 252px !important;
            border-radius: 4px;
            margin-right: 10px;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
            .map-activity-image {
              width: 100%;
              height: 87px;
            }
            .map-activity-title-1 {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 10px;
              >h1 {
                font-size: 14px;
                color: rgba(0,0,0,0.85);
                letter-spacing: 0;
                font-weight: 500;
              }
              >span {
                font-size: 12px;
                color: rgba(0,0,0,0.65);
                >b {
                  color: #4479ed;
                }
              }
            }
            .map-activity-text {
              font-size: 14px;
              line-height: 24px;
              color: rgba(0,0,0,0.85);
              padding: 0 10px;
              .text-label {
                color: rgba(0,0,0,0.45);
              }
            }
          }
        }
      }
    }
  }
}
</style>
