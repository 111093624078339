<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>心理援助</h1>
      </div>
      <div class="card-nav">
        <img v-if="showData.avatarUrl" :src="showData.avatarUrl" class="image" alt="头像" />
        <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" class="image" alt="头像" />
        <div class="card-content">
          <div class="title">{{showData.nickName}}</div>
          <div>擅长：{{showData.ability}}</div>
          <div class="footer">
            <span>
              服务人次
              <i>{{showData.serviceUp}}</i>
            </span>
            <span>
              积分（次）
              <i>{{showData.invitationMoney}}</i>
            </span>
          </div>
        </div>
      </div>
      <div class="foot-button" @click="handleAddServiceUp(showData)">咨询TA</div>
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";

export default {
  data() {
    return {
      showData: {}
    };
  },
  components: {},
  mounted() {
    const { key } = this.$route.query;
    llyjrApi.getDetail({ invitationId: key }).then(res => {
      this.showData = res.data;
    });
  },
  methods: {
    handleAddServiceUp(item) {
      this.$store.dispatch('login/checkRealName', () => {
        llyjrApi
          .addServiceUp({ invitationId: item.invitationId })
          .then(res => {});
      })
      
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  padding-top: 3rem;
  background: #f6f7fa;
  .card-nav {
    background: #ffffff;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      height: 4rem;
      width: 4rem;
      border-radius: 2rem;
    }
    .card-content {
      // padding: 0.6rem;
      margin: 0 0.6rem;

      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      text-align: left;
      line-height: 22px;
      .title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        text-align: center;
        margin: 1rem 0;
        font-weight: 600;
      }
      .footer {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 12px;
        padding: 1rem 0;
        margin-top: 1rem;
        border-top: 1px solid rgba(17, 31, 44, 0.1);
        span {
          margin-right: 1rem;
          i {
            color: #4479ed;
            margin: 0 2px;
          }
        }
      }
    }
  }
  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
