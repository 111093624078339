<template>
  <div class="volunteer-container">
    <van-nav-bar title="时间银行" left-arrow @click-left="onClickLeft" />
    <div class="body-container">
      <!-- top bar -->
      <div class="white-bg padding-12">
        <div class="top-bar">
          <h1 class="top-bar-title">志愿者管理制度</h1>
          <p @click="gotoLink('volunteerRule')" style="display: flex; align-items: center">
            查看详情<van-icon name="arrow" size="14" />
          </p>
        </div>
        <div class="flex around" style="margin-top: 12px">
          <div
            v-for="(item, index) in countList"
            :key="item.title"
            :style="{ flex: 1, 'border-right': index < countList.length - 1 ? '1px solid #3E71F9' : '', 'text-align': 'center' }">
            <h1 class="title-text blue">{{ `${item.value}${item.unit || ''}` }}</h1>
            <p class="small-text">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="margin-12 padding-12 white-bg">
        <h1 class="title-text">志愿者团队</h1>
        <div class="flex">
          <img
            @click="gotoLink('volunteerTeam')"
            width="80px"
            height="80px"
            src="http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/card.png"
            alt="" />
          <div style="margin-left: 12px">
            <div class="flex between">
              <span style="title-text">{{ volunteerTeam.name || '-' }}</span>
              <van-button
                round
                type="info"
                style="height: 1.6rem; color: #3e71f9; padding: 0 6px; font-size: 0.9rem; border-color: #3e71f9"
                plain
                @click="handleApply"
                >{{ getApplyBtnText() }}</van-button
              >
            </div>
            <!-- <div class="flex">
              <span style="flex: 1;">人数：34</span>
              <span style="flex: 1;">活动：10</span>
            </div> -->
            <div class="van-multi-ellipsis--l2 gray small-text">
              {{ volunteerTeam.introduction || '-' }}
            </div>
          </div>
        </div>
      </div>

      <div class="card-con">
        <!-- 卡片标题 -->
        <div class="card-title-con">
          <div class="card-title-text-con">
            <i class="card-title-icon"></i>
            <h2 class="title-text">志愿者活动</h2>
          </div>
          <!-- <div class="card-title-more">
            <span>更多</span>
            <img
              class="card-title-more-icon"
              src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png"
              alt=""
            />
          </div> -->
        </div>
        <!-- 卡片内容 -->
        <div class="card-body">
          <div
            class="activity-item"
            v-for="item in activityList"
            :key="item.title"
            @click="gotoLink(`volunteerActivity?key=${item.activityId}`)">
            <div class="top-image-con">
              <van-image :src="item.image" width="100%" height="120px" fit="cover" />
              <span class="date-tag" v-if="item.permitEnroll">{{ `截止日期：${item.deadlineTime}` }}</span>
              <span class="date-tag" v-else>{{ item.time }}</span>
              <span class="status-tag" :style="{ background: colors[item.status].background, color: colors[item.status].text }">{{
                item.activityStatus
              }}</span>
            </div>
            <div class="activity-item-body">
              <div class="flex between">
                <div class="flex">
                  <span class="type-tag">{{ item.activityType }}</span>
                  <div class="title-text" style="line-height: 1.2rem">{{ item.title }}</div>
                </div>
                <div style="width: 4.5rem; word-break: break-all">
                  <span class="tag-text">已报名</span>
                  <span class="tag-text blue">{{ item.number }}</span>
                </div>
              </div>
              <div>
                <span class="label-text">发布人：</span>
                <span>{{ item.createUserName }}</span>
              </div>
              <div>
                <span class="label-text">活动时间：</span>
                <span>{{ item.startTime }}～{{ item.endTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wyslApi from 'api/llyjr/wysl';
import volunteerApi from 'api/volunteer';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      countList: [
        {
          title: '志愿者数',
          value: 266
        },
        {
          title: '活动场次',
          value: 12
        },
        {
          title: '参与人数',
          value: 218
        },
        {
          title: '服务时长',
          value: '1100h',
          unit: '时'
        }
      ],
      colors: {
        0: { text: '#4479ED', background: '#E3EFFD' },
        1: { text: '#E5B20A', background: '#FFF6D9' },
        2: { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        4: { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' }
      },
      activityList: [],
      volunteerTeam: {}
    };
  },
  computed: {},
  components: {},
  mounted() {
    wyslApi
      .getGamePage({
        page: 1,
        size: 10,
        object: {
          activityType: '志愿服务',
          status: this.status,
          sortField: this.sortField,
          sort: 'desc'
        }
      })
      .then(res => {
        const { list = [], total = 0 } = res.data || {};
        this.activityList = list;
      });
    this.getVolunteerTeam();
    this.getStaticData();
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    getVolunteerTeam() {
      volunteerApi.getVolunteerTeam().then(res => {
        if (res.flag) {
          this.volunteerTeam = res.data || {};
        } else {
          this.volunteerTeam = {};
        }
      });
    },
    getStaticData() {
      volunteerApi.getStatistic().then(res => {
        if (res.flag) {
          const { volunteerNum, activityNum, volunteerIntegral, hour } = res.data;
          // this.countList = [
          //   { title: '志愿者数', value: volunteerNum },
          //   { title: '活动场次', value: activityNum },
          //   { title: '志愿积分', value: 5105 },
          //   { title: '服务时长', value: hour, unit: 'h' }
          // ];

          this.countList = [
            { title: '志愿者数', value: 313 },
            { title: '活动场次', value: 31 },
            { title: '志愿积分', value: 5105 },
            { title: '服务时长', value: 5105, unit: 'h' }
          ];
        } else {
          this.countList = [
            { title: '志愿者数', value: 313 },
            { title: '活动场次', value: 31 },
            { title: '志愿积分', value: 5105 },
            { title: '服务时长', value: 5105, unit: 'h' }
          ];
        }
      });
    },
    getApplyBtnText() {
      switch (this.volunteerTeam.enrollStatus) {
        case '申请中': {
          return '申请中';
        }
        case '已加入': {
          return '取消加入';
        }
        case '未通过': {
          return '重新申请';
        }
        default: {
          return '申请加入';
        }
      }
    },
    handleApply() {
      switch (this.volunteerTeam.enrollStatus) {
        case '申请中': {
          this.gotoLink('volunteerApply');
          break;
        }
        case '已加入': {
          this.$dialog
            .confirm({
              title: '提示',
              message: '确定是否取消加入',
              confirmButtonText: '立即取消',
              confirmButtonColor: '#4379ed'
            })
            .then(() => {
              volunteerApi.cancelVolunteer({ type: 0 }).then(res => {
                this.getVolunteerTeam();
              });
            })
            .catch(e => {});
          break;
        }
        case '未通过': {
          this.gotoLink('volunteerApply');
          break;
        }
        default: {
          this.gotoLink('volunteerApply');
          break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.volunteer-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);

  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: #f6f7fa;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      height: 121px;
      width: 100%;
      background: url('http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/志愿服务banner.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      padding: 40px 20px 0;
      .top-bar-title {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        .van-icon {
          margin-left: 6px;
        }
      }
    }
    .blue-divider {
      width: 1px;
      height: 36px;
      background: #3e71f9;
    }
    .card-con {
      background-color: #fff;
      width: 100%;
      padding: 12px;
      margin-top: 12px;
      // 卡片标题
      .card-title-con {
        width: 100%;
        height: 1.44rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .card-title-text-con {
          height: 100%;
          display: flex;
          align-items: center;
          .card-title-icon {
            display: block;
            width: 4px;
            height: 1rem;
            background-color: #4479ed;
            margin-right: 7px;
          }
          .card-title-text {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 1rem;
          }
        }
        .card-title-more {
          height: 100%;
          color: rgba(0, 0, 0, 0.45);
          display: flex;
          align-items: center;
          .card-title-more-icon {
            height: 0.94rem;
          }
        }
      }
      // 卡片内容
      .card-body {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        .activity-item {
          width: 100%;
          margin: 12px 0;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
          background: #fff;
          .top-image-con {
            width: 100%;
            height: 120px;
            overflow: hidden;
            position: relative;
            background: #fff;
            font-size: 0.8rem;
            line-height: 1rem;
            .date-tag {
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.2);
              color: #fff;
              padding: 2px 6px;
              border-radius: 4px;
            }
            .status-tag {
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(213, 244, 226, 0.9);
              color: #13bb49;
              padding: 2px 10px;
              border-radius: 2px 2px 8px 2px;
            }
          }
          .activity-item-body {
            width: 100%;
            padding: 12px;
            .type-tag {
              min-width: 70px;
              height: 24px;
              line-height: 24px;
              color: #fff;
              padding: 0 10px 0 6px;
              margin-right: 5px;
              font-size: 0.8rem;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/educationSource/status-bg.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
</style>
