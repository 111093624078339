<template>
  <div class="memberManage-container">
    <van-nav-bar title="组织架构" left-arrow @click-left="onClickLeft" />
    <div class="my-organization-body-container">
      <!-- 头部详情 -->
      <!-- <div :class="['image-bar', { opened: show }]">
        <h1>{{ organization.organization || organization.defaultOrganization }}</h1>
        <p>{{ organization.description || organization.defaultDescription }}</p>
        <div class="organization-button" @click="show = !show">
          <span>{{ show ? "收起" : "展开" }}</span>
          <img
            src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
            :class="['organization-arrow', { rollover: show }]"
            alt=""
          />
        </div>
      </div> -->
      <!-- 详情 -->
      <div class="organization-body">
        <div
          class="card-con quyudangjianlianmeng"
          @click="gotoLink('areaPartyBuilding')"
        >
          <h1>东盛社区党总支</h1>
          <!-- <div class="play-introduce"><i></i>播放介绍</div> -->
        </div>
        <div style="width: 100%;text-align: center;">
          <!-- <img style="height: 30px;" src="http://dssq.ikeqiao.net/data/image/h5/images/hong/line_arrow.png" alt=""> -->
        </div>
        <div
          class="card-con dadushequdangzongzhi"
          @click="gotoLink('partyModified')"
        >
          <h1>东盛社区党总支</h1>
          <span>我的党组织</span>
        </div>
        <div style="width: 100%;text-align: center;">
          <!-- <img style="height: 30px;" src="http://dssq.ikeqiao.net/data/image/h5/images/hong/three_arrow.png" alt=""> -->
        </div>
        <div class="organization-list-con">
          <!-- <div class="organization-list-title">{{ organization.subTitle }}</div> -->
          <div class="organization-list-body">
            <div
              class="organization-list-item"
              v-for="(item, index) in organizationList"
              :key="`${item.key}_${index}`"
            >
              <div class="item-body" @click="gotoPulseDtl(item.value)">
                <div class="item-tag">{{ item.key }}</div>
                <div class="item-btn">点击进入<i></i></div>
              </div>
              <div
                v-if="item.key === organization.organization"
                class="item-footer"
              >
                <img
                  src="http://dssq.ikeqiao.net/data/image/h5/images/hong/flag.png"
                  alt=""
                />
                <span>我所在党支部</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api'
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      show: false, // 收起状态
      organization: {},
      organizationList: [],
    }
  },
  computed: {},
  components: {},
  mounted() {
    this.getMyCommunity()
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path)
      }
    },
    gotoPulseDtl(id) {
      this.$router.push(`pulseList?id=${id}`)
    },
    getMyCommunity() {
      api.getMyCommunity({}).then((res) => {
        if (res.flag) {
          this.organization = res.data
          this.organizationList = res.data.organizations
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;

  .my-organization-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png')
      no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .image-bar {
      width: 100%;
      background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/organization-bg.png)
        no-repeat;
      background-size: 100% 100%;
      padding: 1.5rem 2rem;
      color: #fff;
      > h1 {
        font-size: 20px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }
      > p {
        width: calc(100vw - 4rem);
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
        max-height: 80px;
        min-height: 80px;
        margin-bottom: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .organization-button {
        margin: 0px auto;
        width: 60px;
        height: 24px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 12px;
        color: #4479ed;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        > img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .opened {
      > p {
        max-height: 100vh;
      }
      .organization-button {
        > img {
          transform: rotate(180deg);
        }
      }
    }
    .organization-body {
      margin: 12px;
      background-color: #fff;
      border-radius: 10px;
      padding: 15px;
      .card-con {
        height: 94px;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/my-organization-bg.png)
          no-repeat;
        background-size: 100% 100%;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
        text-align: center;
        margin-bottom: 10px;
        > h1 {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          line-height: 24px;
        }
        > span {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
        }
        .play-introduce {
          background: rgba(255, 255, 255, 0.6);
          border-radius: 12px;
          padding: 5px;
          font-size: 14px;
          color: #4479ed;
          display: flex;
          align-items: center;
          width: fit-content;
          margin: 5px auto;
          > i {
            width: 15px;
            height: 15px;
            background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/play_icon.png')
              no-repeat;
            background-size: 100% 100%;
            display: inline-block;
          }
        }
      }
      .dadushequdangzongzhi {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        h1 {
          margin-top: 10px;
          font-weight: bolder;
        }
      }
      .quyudangjianlianmeng {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/quyudangjianlianmeng.png)
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        h1 {
          margin-top: 10px;
          font-weight: bolder;
        }
      }
    }
    .organization-list-con {
      .organization-list-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
      }
      .organization-list-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 10px;
        .organization-list-item {
          width: 6.5rem;
          .item-body {
            width: 100%;
            height: 4.5rem;
            background: url(../../../assets/images/zhibu.png) no-repeat;
            background-size: 100% 100%;
            border-radius: 4px;
            // padding-top: calc(3.25rem - 12px);
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            .item-tag {
              width: 100%;
              // background: rgba(255, 0, 0, 0.6);
              font-size: 14px;
              // color: #ffffff;
              letter-spacing: 1px;
              font-weight: bolder;
              // text-align: center;
              padding: 5px 0;
            }
            // .item-btn {
            // 	background: rgba(255, 0, 0, 0.6);
            // 	font-size: 12px;
            // 	border-radius: 200px;
            // }
            .item-btn {
              display: flex;
              align-items: center;
              i {
                width: 16px;
                height: 16px;
                // background: rgba(255, 0, 0, 0.6);
                background: url('../../../assets/images/enter.png') no-repeat;
                background-size: 100% 100%;
                display: block;
                // margin: 10px auto;
              }
            }
          }
          .item-footer {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.85);
            margin-top: 5px;
            text-align: center;
            > img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
