<template>
  <div>
    <div class="reply-list">
      <ul v-if="hasData">
        <li v-for="item in list" :key="item.id" @click="onChildrenToMeetClick(item)">
          <div class="reply-info">
            <img :src="item.image" />
            <div>{{ item.owner }}<br /><span>发布于{{ item.createTime }}</span></div>
            <i :class="item.tag">{{ item.status }}</i>
          </div>
          <div class="data">
            <div><label>孩子姓名：</label><span>{{ item.childName }}</span></div>
            <div><label>所在学校/班级：</label><span>{{ item.meetAddress}} {{item.meetClass}}</span></div>
            <div><label>接送时间：</label><span>{{ item.meetTime }}</span></div>
            <div>
              <label>送往位置：</label><span>{{ item.sendAddress }}</span>
            </div>
            <div><label>接单时间：</label><span>{{ item.createTime }}</span></div>
          </div>
        </li>
      </ul>
      <div class="noData" v-if="!hasData">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { Search } from "vant";
import yqpddApi from "api/yqpdd";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      page: 1,
      size: 10,
      value: "",
      status: "",
      remark: "", // 接送时间
      list: [],
      finished: false,
      sortField: "",
      mode: "",
      hasData: false,
    };
  },
  components: {},
  mounted() {
    this.getlist({ page: 1, size: 10 });
  },
  methods: {
    ...mapMutations(["setChildrenToMeetDetail"]),
    onChildrenToMeetClick(item) {
      this["setChildrenToMeetDetail"](item);
      this.gotoLink('yqpddpjDetail')
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleChange(e, type) {
      this[type] = e;
      this.onSearch();
    },
    getlist({ page = this.page, size = this.size} = {}) {
      const params = {
        page,
        size,
        object: {
          remark: "receive",
        },
        ...params,
      };
      yqpddApi.getOwnOrders(params).then((res) => {
        const { records = [], total = 0 } = res.data || {};
        records.map(item => (item.tag = item.status === '未接单' ? 'tag1' : item.status === '已接单' ? 'tag2' : 'tag3'))
        this.list = this.list.concat(records);
        this.total = total;
        this.hasData = total === 0 ? false : true;
        if (this.list.length >= this.total) {
          // 数据全部加载完成
          this.finished = true;
        }
      });
    },
    onSearch(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
         status: this.status,
          remark: this.remark,
        },
      };
      yqpddApi.getOwnOrders(params).then((res) => {
        const { records = [], total = 0 } = res.data || {};
        records.map(item => (item.tag = item.status === '未接单' ? 'tag1' : item.status === '已接单' ? 'tag2' : 'tag3'))
        this.list = records;
        this.total = total;
        if (this.list.length >= this.total) {
          // 数据全部加载完成
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  padding: 0;
  .nrb-box {
    margin-top: 2.8rem;
    .van-tab--active {
      color: #1890ff;
    }
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    -webkit-box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    font-size: 13px;
    color: #fff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
  .reply-list {
    width: 23.4rem;
    margin: 0 auto;
    padding: 0.6rem 0;
    .noData {
      height: 4rem;
      line-height: 4rem;
      text-align: center;
    }
    li {
      padding: 0.8rem 0;
      //border-bottom: 1px solid #ddd;
      font: 500 0.9rem/1.4rem "microsoft yahei";
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      .data {
        font-size: 0.9rem;
        label {
          color: #bbb;
        }
        .blue {
          color: #1890ff;
        }
      }
      .reply-info {
        margin-bottom: 0.4rem;
        position: relative;
        height: 2rem;
        img {
          width: 2rem;
          height: 2rem;
          float: left;
          border-radius: 50%;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        div {
          float: left;
          font: 500 0.8rem/1rem "microsoft yahei";
          color: #333;
          span {
            color: #bbb;
          }
        }
        i {
          position: absolute;
          display: inline-block;
          height: 1.8rem;
          padding: 0 0.7rem 0 0.9rem;
          line-height: 1.8rem;
          border-radius: 0.9rem 0 0 0.9rem;
          font-style: inherit;
          font-size: 0.7rem;
          color: #fff;
          right: -0.8rem;
          top: 0.1rem;
        }
        .tag1 {
          background: #3291f8;
        }
        .tag2 {
          background: #f8b432;
        }
        .tag3 {
          background: #c5cbd2;
        }
      }
      .state {
        padding-top: 0.4rem;
        span {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.8rem;
          background: #e8e8e8;
          color: #101010;
        }
      }
    }
  }
  .card-nav {
    width: 100%;
    // border-top: 0.8rem solid #f6f7fa;
    // background: #FFFFFF;
    // padding: 0.8rem;
    .question {
      margin-bottom: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      .question-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 12px;
        line-height: 2rem;
        font-weight: 600;
      }
      .foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .person {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          text-align: left;
          line-height: 22px;
          height: 2.5rem;
          .avatar {
            height: 2rem;
            width: 2rem;
            border-radius: 1rem;
            margin-right: 5px;
          }
        }
        .question-foot {
          height: 1.5rem;
          margin: 0.4rem 0;
          color: rgba(0, 0, 0, 0.45);
          display: flex;
          align-items: center;

          div {
            margin-right: 0.4rem;
          }
          img {
            height: 1rem;
            margin-right: 0.2rem;
          }
        }
      }
      .paragraph {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        .long {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);

    font-size: 13px;
    color: #ffffff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
}
</style>
