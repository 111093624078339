<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>E课堂</h1>
      </div>
      <div class="iframe-nav" style="margin-top: 2.7rem;">
        <!-- <iframe id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" src="https://web.chinamcloud.com/kqt/kzkt/index.shtml?1622185891608" ></iframe> -->
        <iframe id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" src="http://palmhall.carbonkitty.com:5000/kqgd_mobile_index.html" ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "book",
      commList: [
        {
          id: "7dbe5b30-2746-11eb-b2dd-5ba57894141e",
          name: "感恩节活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/CLBfhayuHVAO.jpg",
          starttime: "2020-11-21 09:00:00",
          endtime: "2020-11-21 14:00:00",
          join_starttime: "2020-11-13 09:00:00",
          join_endtime: "2020-11-20 14:00:00",
          address: "瓜渚湖",
          now_people: 28,
          plan_people: 30,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "0c0cd740-2746-11eb-8f06-bf8b9b33b309",
          name: "社区金点子之活动篇",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/tIDjpy0a5kH8.jpg",
          starttime: "2020-12-22 09:30:00",
          endtime: "2020-12-26 20:00:00",
          join_starttime: "2020-12-19 08:00:00",
          join_endtime: "2020-12-19 20:00:00",
          address: "东盛社区党建室",
          now_people: 15,
          plan_people: 30,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "ce7d9ab0-2746-11eb-b29e-fbdc8a0631dd",
          name: "社区微景观营造之画石墩",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/TAuf8co1JwCR.jpg",
          starttime: "2020-12-26 09:00:00",
          endtime: "2020-12-26 17:00:00",
          join_starttime: "2020-12-22 10:00:00",
          join_endtime: "2020-12-24 16:00:00",
          address: "东盛社区润泽大院",
          now_people: 10,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "a7f2fbc0-2745-11eb-8359-b9416f123c52",
          name: "社区微景观之小小泥瓦匠",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/DHr6SQAEfMOe.jpg",
          starttime: "2020-11-07 09:00:00",
          endtime: "2020-11-07 11:00:00",
          join_starttime: "2020-11-02 10:00:00",
          join_endtime: "2020-11-06 17:00:00",
          address: "东盛社区创享空间",
          now_people: 10,
          plan_people: 15,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "3c7b8b80-2745-11eb-b773-1f49d229d6fe",
          name: "社区青少年编程教育",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/26Hs7pQJbYw9.jpg",
          starttime: "2020-12-19 18:30:00",
          endtime: "2020-12-19 20:30:00",
          join_starttime: "2020-12-18 08:00:00",
          join_endtime: "2020-12-18 17:00:00",
          address: "东盛社区市民讲堂",
          now_people: 2,
          plan_people: 20,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "f2f92bd0-a5a1-11eb-9247-29a8d5b55be0",
          name: "清明节活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/vfO1SMQHTaNi.jpg",
          starttime: "2021-04-02 09:00:00",
          endtime: "2021-04-02 11:00:00",
          join_starttime: "2021-03-31 00:00:00",
          join_endtime: "2021-04-01 00:00:00",
          address: "社区活动室",
          now_people: 0,
          plan_people: 30,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "f6a94980-9cea-11eb-8622-53d4ca906b0d",
          name: "人防知识宣传",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/skqTXi7xgQ0Z.jpg",
          starttime: "2020-12-03 09:00:00",
          endtime: "2020-12-03 11:00:00",
          join_starttime: "2020-12-02 08:00:00",
          join_endtime: "2020-12-02 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "adb05f40-9cea-11eb-8e38-814d90cdcd94",
          name: "法律知识宣传活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/lZVCOu5U79cF.jpg",
          starttime: "2020-12-03 09:00:00",
          endtime: "2020-12-03 11:00:00",
          join_starttime: "2020-12-02 08:00:00",
          join_endtime: "2020-12-02 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "69fa5650-9cea-11eb-b114-3d260e99e771",
          name: "人防知识培训",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/NFIKlxHjXSG2.jpg",
          starttime: "2020-11-25 09:00:00",
          endtime: "2020-11-25 11:00:00",
          join_starttime: "2020-11-24 08:00:00",
          join_endtime: "2020-11-24 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "04dd9190-9ce8-11eb-a131-e986411e3e96",
          name: "民防知识培训",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/QLdwGznOYAmb.jpg",
          starttime: "2020-11-25 09:00:00",
          endtime: "2020-11-25 11:00:00",
          join_starttime: "2020-11-24 08:00:00",
          join_endtime: "2020-11-24 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "bd139840-9ce7-11eb-9a8d-bd43653c4b0d",
          name: "社区美化家",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/9QkZX74d1Msz.jpg",
          starttime: "2020-11-10 09:00:00",
          endtime: "2020-11-10 11:00:00",
          join_starttime: "2020-11-09 08:00:00",
          join_endtime: "2020-11-09 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 60,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "75f39010-9ce7-11eb-9fc2-395a69b82118",
          name: "香甜重阳糕，温暖老人心活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/TIDKY2ipLOs7.jpg",
          starttime: "2020-10-25 09:00:00",
          endtime: "2020-10-25 11:00:00",
          join_starttime: "2020-05-24 08:00:00",
          join_endtime: "2020-05-24 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 200,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "2c1a1be0-9ce7-11eb-b8cc-3bef91466dfa",
          name: "青少年编程教学",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/zUJQKX2RtHY6.jpg",
          starttime: "2020-10-24 14:00:00",
          endtime: "2020-10-24 16:00:00",
          join_starttime: "2020-10-23 08:00:00",
          join_endtime: "2020-10-23 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 40,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "dc958370-9ce6-11eb-9194-9d240f9fe93b",
          name: "敬老爱老助夕阳  浓浓情意度重阳",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/DVgcBEGQjFL8.jpg",
          starttime: "2020-10-22 09:00:00",
          endtime: "2020-10-22 11:00:00",
          join_starttime: "2020-10-21 08:00:00",
          join_endtime: "2020-10-21 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "95d525c0-9ce6-11eb-b44d-9d7d595a6fb6",
          name: "消防演练",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/LAaUDkjWNQi1.jpg",
          starttime: "2020-09-25 09:00:00",
          endtime: "2020-09-25 11:00:00",
          join_starttime: "2020-09-24 08:00:00",
          join_endtime: "2020-09-24 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 80,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "146583c0-9ce6-11eb-895a-4b3c9b081dfe",
          name: "中秋、国庆双节同庆",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/eQJ6VzMjNCRP.jpg",
          starttime: "2020-09-27 09:00:00",
          endtime: "2020-09-27 11:00:00",
          join_starttime: "2020-09-26 08:00:00",
          join_endtime: "2020-09-26 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 300,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "152fcb70-9ce2-11eb-8f5d-596a5e135c77",
          name: "全国爱牙日",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/s2LFJivyqlpu.jpg",
          starttime: "2020-09-18 09:00:00",
          endtime: "2020-09-18 11:00:00",
          join_starttime: "2020-09-17 08:00:00",
          join_endtime: "2020-09-17 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "0fd19b00-9cc7-11eb-9aaa-fd5964e08b34",
          name: "我要健康肌肤",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/fJrMVKvw9SWe.jpg",
          starttime: "2020-09-16 09:00:00",
          endtime: "2020-09-16 11:00:00",
          join_starttime: "2020-09-15 08:00:00",
          join_endtime: "2020-09-15 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "b800dfb0-9cc2-11eb-a046-8930877d5b67",
          name: "凉茶小铺",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/87wSxLNsnC1A.jpg",
          starttime: "2020-08-12 09:00:00",
          endtime: "2020-08-12 11:00:00",
          join_starttime: "2020-08-11 08:00:00",
          join_endtime: "2020-08-11 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "43acb870-9cc1-11eb-ac3b-c5e68fba2367",
          name: "社区微景观营造",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/OCPUfn9xyEYh.jpg",
          starttime: "2020-07-31 09:00:00",
          endtime: "2020-07-31 11:00:00",
          join_starttime: "2020-07-30 08:00:00",
          join_endtime: "2020-07-30 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "003cf290-9cc1-11eb-8271-afc1eb49b3d2",
          name: "萌宝营",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/FPaVOIuXJ2Gz.jpg",
          starttime: "2020-07-29 09:00:00",
          endtime: "2020-07-29 11:00:00",
          join_starttime: "2020-07-28 08:00:00",
          join_endtime: "2020-07-28 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 40,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "a4c3cde0-9cc0-11eb-b246-f9fc5bd8a38d",
          name: "游戏空间",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/MA4OV1coTU3j.jpg",
          starttime: "2020-07-18 09:00:00",
          endtime: "2020-07-18 11:00:00",
          join_starttime: "2020-07-17 08:00:00",
          join_endtime: "2020-07-17 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 60,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "30b4c810-9cbe-11eb-a973-8f05327c4e53",
          name: "美语巴士",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/ki5NTGpa7OEs.jpg",
          starttime: "2020-07-18 09:00:00",
          endtime: "2020-07-18 11:00:00",
          join_starttime: "2020-07-17 08:00:00",
          join_endtime: "2020-07-17 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 60,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "df001fb0-9cbd-11eb-81cb-33afb5dc624e",
          name: "萌宝营",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/nFOEp2zxhUva.jpg",
          starttime: "2020-07-15 09:00:00",
          endtime: "2020-07-15 11:00:00",
          join_starttime: "2020-07-14 08:00:00",
          join_endtime: "2020-07-14 11:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 40,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "12509220-9c30-11eb-92c9-674079510897",
          name: "防诈骗宣传",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/Rb4S2pefyBGY.jpg",
          starttime: "2020-07-14 09:00:00",
          endtime: "2020-07-14 11:00:00",
          join_starttime: "2020-07-13 08:00:00",
          join_endtime: "2020-07-13 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 200,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "a7fa86e0-9c2f-11eb-8180-45f73ecc9d93",
          name: "萌宝营",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/48J1ly9kaW3L.jpg",
          starttime: "2020-07-10 09:00:00",
          endtime: "2020-07-10 11:00:00",
          join_starttime: "2020-07-09 08:00:00",
          join_endtime: "2020-07-09 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 40,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "ebd0e0b0-9c2c-11eb-b2a1-81b8ce13fef1",
          name: "自制冰箱贴",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/4j16DvhIeZVd.jpg",
          starttime: "2020-07-09 09:00:00",
          endtime: "2020-07-09 11:00:00",
          join_starttime: "2020-07-08 08:00:00",
          join_endtime: "2020-07-08 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 70,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "868afde0-9c29-11eb-8a07-4187874b738e",
          name: "粽香端午",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/ofjgeJq2T6S1.jpg",
          starttime: "2020-06-24 09:00:00",
          endtime: "2020-06-24 11:00:00",
          join_starttime: "2020-06-23 08:00:00",
          join_endtime: "2020-06-23 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 200,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "1a9cbd80-9c29-11eb-b605-47601e35dd85",
          name: "越读越有味",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/vxk0IPGfNXir.jpg",
          starttime: "2020-06-20 09:00:00",
          endtime: "2020-06-20 11:00:00",
          join_starttime: "2020-06-19 08:00:00",
          join_endtime: "2020-06-19 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "b32b7930-9c28-11eb-a2f0-cbd22386d18f",
          name: "“粽”享端午邻里行",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/WsCFSlibeBgE.jpg",
          starttime: "2020-06-19 18:30:00",
          endtime: "2020-06-19 20:30:00",
          join_starttime: "2020-06-18 08:00:00",
          join_endtime: "2020-06-18 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "1f76fd50-9c27-11eb-bb75-abe5a212216a",
          name: "消防知识安全培训",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/VP6xZJjehkbL.jpg",
          starttime: "2020-06-05 18:30:00",
          endtime: "2020-06-05 20:30:00",
          join_starttime: "2020-06-04 08:00:00",
          join_endtime: "2020-06-04 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 60,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "d330bbd0-9c23-11eb-a4c1-97534438e0ce",
          name: "共享童趣六一活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/ejU7uFc2yiNm.jpg",
          starttime: "2020-05-31 09:00:00",
          endtime: "2020-05-31 11:00:00",
          join_starttime: "2020-05-30 09:00:00",
          join_endtime: "2020-05-30 11:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 300,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "741bf290-9c23-11eb-a861-39e40e6ce489",
          name: "小足迹·大梦想”迎六一画展",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/VQTBwU1Pf320.jpg",
          starttime: "2020-05-29 09:00:00",
          endtime: "2020-05-29 11:00:00",
          join_starttime: "2020-05-28 08:00:00",
          join_endtime: "2020-05-28 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "04b3f230-9c23-11eb-bba0-01e0bc200704",
          name: "智能垃圾分类",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/oi3raCz4IElx.jpg",
          starttime: "2020-05-29 09:00:00",
          endtime: "2020-05-29 11:00:00",
          join_starttime: "2020-05-28 08:00:00",
          join_endtime: "2020-05-28 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 150,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "5cc5c960-9c22-11eb-a5fe-830cf9deb88b",
          name: "文明进万家  健康你我他",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/Dhwl5PT9W0cu.jpg",
          starttime: "2020-05-22 09:00:00",
          endtime: "2020-05-22 11:00:00",
          join_starttime: "2020-05-21 08:00:00",
          join_endtime: "2020-05-21 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 200,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "4718fee0-9c21-11eb-ac81-391ee3effdf8",
          name: "小小甜品家（直播活动）",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/KJbePzWD54Hp.jpg",
          starttime: "2020-05-16 09:00:00",
          endtime: "2020-05-16 11:00:00",
          join_starttime: "2020-05-15 09:00:00",
          join_endtime: "2020-05-15 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "d8cc2700-9c20-11eb-b44c-9fc36e073c74",
          name: "防空防灾宣传",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/V7aGBFPYQ5Ng.jpg",
          starttime: "2020-05-12 09:00:00",
          endtime: "2020-05-12 11:00:00",
          join_starttime: "2020-03-11 08:00:00",
          join_endtime: "2020-03-11 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 80,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "1405f660-9c1f-11eb-be58-435eb003fe1b",
          name: "胜日寻春系列活动之赏味春天（直播活动）",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/ruKa0Ng2UyOZ.jpg",
          starttime: "2020-04-16 18:30:00",
          endtime: "2020-04-16 20:30:00",
          join_starttime: "2020-04-15 08:00:00",
          join_endtime: "2020-04-15 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "99738ca0-9c1e-11eb-8e6b-c360567d9bcc",
          name: "乐高玩“家”亲子线上活动（直播活动）",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/oaxyEYzJ6PGO.jpg",
          starttime: "2021-04-11 09:00:00",
          endtime: "2021-04-11 11:00:00",
          join_starttime: "2020-04-10 08:00:00",
          join_endtime: "2020-04-10 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "2cfca2c0-9c1e-11eb-9717-b17b6cbd319a",
          name: "胜日寻春系列活动之多肉联萌（直播活动）",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/k3vpqlBoce8u.jpg",
          starttime: "2020-04-03 09:00:00",
          endtime: "2020-04-03 11:00:00",
          join_starttime: "2020-04-02 08:00:00",
          join_endtime: "2020-04-02 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "94e2e770-9c1d-11eb-ace4-2743ddffb405",
          name: "社区营造家（直播活动）",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/N39kVe4nfsKX.jpg",
          starttime: "2020-03-27 18:30:00",
          endtime: "2020-03-27 20:30:00",
          join_starttime: "2020-03-26 08:00:00",
          join_endtime: "2020-03-26 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "2f4393e0-9c1d-11eb-b970-b1fb1b2c9279",
          name: "消防宣传",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/uC3J6XIjTDBa.jpg",
          starttime: "2020-01-22 09:00:00",
          endtime: "2020-01-22 11:00:00",
          join_starttime: "2020-01-21 08:00:00",
          join_endtime: "2020-01-21 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "eef7bd10-9907-11eb-8da6-edebbbc12075",
          name: "智能垃圾分类",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/lZYyuczetQWk.png",
          starttime: "2020-01-21 14:00:00",
          endtime: "2020-01-21 16:00:00",
          join_starttime: "2020-01-20 08:00:00",
          join_endtime: "2020-01-20 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "7f886990-9907-11eb-bc99-296d56360960",
          name: "“送福·送春联·送温暖”",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/xtnzTK4ylX2B.png",
          starttime: "2020-01-14 09:00:00",
          endtime: "2020-01-14 11:00:00",
          join_starttime: "2020-01-13 08:00:00",
          join_endtime: "2020-01-13 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 300,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "0d13bf50-9907-11eb-a031-a13baac5f6c3",
          name: "平安宣传",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/8dt5XWRPw326.png",
          starttime: "2020-01-07 09:00:00",
          endtime: "2020-01-07 11:00:00",
          join_starttime: "2020-01-06 08:00:00",
          join_endtime: "2020-01-06 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "bc457120-9906-11eb-9b68-c91afc33c2c0",
          name: "智能垃圾分类",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/NrxIi06QVRuf.png",
          starttime: "2020-01-02 14:00:00",
          endtime: "2020-01-02 16:00:00",
          join_starttime: "2020-01-01 08:00:00",
          join_endtime: "2020-01-01 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 60,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "843aedb0-98fb-11eb-bf47-83e212e9c79e",
          name: "香水湾幼儿园送福",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/h5AzkJrKRd7Y.png",
          starttime: "2020-01-02 09:00:00",
          endtime: "2020-01-02 11:00:00",
          join_starttime: "2020-01-01 08:00:00",
          join_endtime: "2020-01-01 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 100,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "b53590d0-98f8-11eb-bdf3-fbe9ca78e5f2",
          name: "暖心腊八粥",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/zXedRw6obju8.png",
          starttime: "2020-01-02 05:00:00",
          endtime: "2020-01-02 08:00:00",
          join_starttime: "2020-01-01 08:00:00",
          join_endtime: "2020-01-01 17:00:00",
          address: "东盛邻里中心",
          now_people: 0,
          plan_people: 200,
          progress: 1,
          apply_status: 0,
        },
      ],
      streetList: [
        {
          id: "ab593020-a1a6-11eb-8b29-bddc538d2b1b",
          name: "疫苗接种志愿者",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/v4KANfgCnPIS.jpg",
          starttime: "2021-04-15 00:00:00",
          endtime: "2021-05-16 00:00:00",
          join_starttime: "2021-04-15 00:00:00",
          join_endtime: "2021-05-15 00:00:00",
          address: "接种门诊",
          now_people: 12,
          plan_people: 20,
          progress: 4,
          apply_status: 0,
        },
        {
          id: "1cf68110-5f86-11eb-9dec-b53d588ff03d",
          name: "职工培训",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/EhKbMpxFte3A.jpg",
          starttime: "2021-05-01 00:00:00",
          endtime: "2021-05-31 00:00:00",
          join_starttime: "2021-03-05 08:00:00",
          join_endtime: "2021-04-30 16:00:00",
          address: "总工会",
          now_people: 6,
          plan_people: 10,
          progress: 4,
          apply_status: 0,
        },
        {
          id: "84c8ab90-e137-11ea-b32b-e9ce402b4a53",
          name: "社区义卖",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/uAX97GnPakE2.jpg",
          starttime: "2020-11-25 10:00:00",
          endtime: "2020-11-30 17:00:00",
          join_starttime: "2020-11-01 08:00:00",
          join_endtime: "2020-11-08 16:00:00",
          address: "瓜渚湖社区活动中心",
          now_people: 53,
          plan_people: 60,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "0a257830-4c0a-11eb-825b-23a282816fd3",
          name: "庆元旦迎新年活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/AgG3JDU1F7rW.jpg",
          starttime: "2021-01-02 09:00:00",
          endtime: "2021-01-02 11:00:00",
          join_starttime: "2020-12-26 10:00:00",
          join_endtime: "2020-12-31 20:00:00",
          address: "玉兰党群服务中心",
          now_people: 20,
          plan_people: 20,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "bc630030-e849-11ea-990c-c5a5e5ed37e8",
          name: "乒乓球赛",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/xbQf9pCt1OB4.jpg",
          starttime: "2020-12-26 09:00:00",
          endtime: "2020-12-26 13:00:00",
          join_starttime: "2020-12-20 09:00:00",
          join_endtime: "2020-12-20 16:00:00",
          address: "玉兰活动中心",
          now_people: 20,
          plan_people: 20,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "8466f800-2bc2-11eb-9a83-2764bf6b41b2",
          name: "社区小小手工能手活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/peEhDTZ9RB8V.jpg",
          starttime: "2020-12-27 09:00:00",
          endtime: "2020-12-27 11:00:00",
          join_starttime: "2020-12-26 10:00:00",
          join_endtime: "2020-12-27 17:00:00",
          address: "社区小小手工能手活动",
          now_people: 15,
          plan_people: 20,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "0a0915e0-2bc2-11eb-8dd0-cbd1ca21e6c6",
          name: "温暖社区之心理咨询",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/t8cK3hjgYMZq.jpg",
          starttime: "2021-01-01 10:00:00",
          endtime: "2021-01-02 14:00:00",
          join_starttime: "2020-12-28 10:00:00",
          join_endtime: "2020-12-31 11:00:00",
          address: "花之木兰-女性之家",
          now_people: 12,
          plan_people: 15,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "76c97e80-a1a3-11eb-837b-2158aa9bba6a",
          name: "老年人陪聊志愿服务",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/Fw8DiJRh2LSb.jpg",
          starttime: "2021-04-01 00:00:00",
          endtime: "2021-05-01 00:00:00",
          join_starttime: "2021-04-01 00:00:00",
          join_endtime: "2021-04-30 00:00:00",
          address: "上门服务",
          now_people: 10,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "71fac640-e1ec-11ea-a9b5-4bbcd5d68fcc",
          name: "羽毛球活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/VurQKImpPMUG.jpg",
          starttime: "2020-10-10 09:00:00",
          endtime: "2020-10-11 14:00:00",
          join_starttime: "2020-10-01 09:00:00",
          join_endtime: "2020-10-03 11:00:00",
          address: "鉴湖园社区活动中心",
          now_people: 10,
          plan_people: 13,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "9a26a400-dd55-11ea-a783-c14631e81ed6",
          name: "篮球活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/LAaiVyOEfeow.jpg",
          starttime: "2020-12-20 08:00:00",
          endtime: "2020-12-20 11:00:00",
          join_starttime: "2020-12-19 08:00:00",
          join_endtime: "2020-12-19 13:00:00",
          address: "玉兰社区活动中心",
          now_people: 10,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "fed14260-e128-11ea-8e74-3dc39db02f02",
          name: "关爱老人活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/hTz2HgubAlj4.jpg",
          starttime: "2020-12-05 09:00:00",
          endtime: "2020-12-06 14:00:00",
          join_starttime: "2020-11-01 08:00:00",
          join_endtime: "2020-11-30 11:00:00",
          address: "瓜渚湖活动中心",
          now_people: 9,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "db7a7f20-2bbf-11eb-8777-2f4ef489ebea",
          name: "戏曲交流会",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/NwRpvYSqcr61.jpg",
          starttime: "2020-12-12 09:00:00",
          endtime: "2020-12-13 16:00:00",
          join_starttime: "2020-12-01 09:00:00",
          join_endtime: "2020-12-06 17:00:00",
          address: "鉴湖园社区礼堂",
          now_people: 5,
          plan_people: 15,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "475b8d80-2bbf-11eb-a3da-094fa4d519d9",
          name: "社区气排球活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/l1vJLNP6ARpH.jpg",
          starttime: "2020-11-27 09:00:00",
          endtime: "2020-11-28 17:00:00",
          join_starttime: "2020-11-21 10:00:00",
          join_endtime: "2020-11-21 15:00:00",
          address: "鉴湖园社区活动室",
          now_people: 5,
          plan_people: 18,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "41f66c10-2bc0-11eb-8f76-cd4b0c7ee494",
          name: "读书分享会",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/NKOCrQipnt7l.jpg",
          starttime: "2020-12-05 10:00:00",
          endtime: "2020-12-05 14:00:00",
          join_starttime: "2020-11-21 09:00:00",
          join_endtime: "2020-11-22 17:00:00",
          address: "鉴湖园社区图书室",
          now_people: 4,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "9d034c90-a663-11eb-9883-bda6a7d1423c",
          name: "周四公益瑜伽课",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/QCKyZItaubcj.jpg",
          starttime: "2021-04-01 14:00:00",
          endtime: "2021-04-29 15:00:00",
          join_starttime: "2021-04-01 00:00:00",
          join_endtime: "2021-04-29 00:00:00",
          address: "玉兰社区文化家园二楼瑜伽室",
          now_people: 3,
          plan_people: 8,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "da159e30-9748-11eb-972c-a3408920db7a",
          name: "反诈宣传活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/OnvJrYl9IDPB.jpg",
          starttime: "2021-04-12 09:00:00",
          endtime: "2021-04-12 10:00:00",
          join_starttime: "2021-04-07 08:00:00",
          join_endtime: "2021-04-10 16:00:00",
          address: "浣东街道楼宇企业服务中心",
          now_people: 3,
          plan_people: 15,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "12842ac0-2bcd-11eb-83e4-79084f72aec0",
          name: "社区摄影活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/UpnSKPDGoEg7.jpg",
          starttime: "2021-01-09 10:00:00",
          endtime: "2021-01-09 20:00:00",
          join_starttime: "2020-12-31 10:00:00",
          join_endtime: "2020-12-31 21:00:00",
          address: "瓜渚湖社区",
          now_people: 1,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "f01bc780-2bca-11eb-9abf-4725542d098a",
          name: "社区青少年英语",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/gnk2EM8lZXxU.jpg",
          starttime: "2020-12-12 08:00:00",
          endtime: "2020-12-13 11:00:00",
          join_starttime: "2020-12-01 09:00:00",
          join_endtime: "2020-12-06 17:00:00",
          address: "瓜渚湖社区文化服务中心",
          now_people: 1,
          plan_people: 15,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "b4bc4480-b206-11eb-9a21-bd536411b853",
          name: "民族团结一家亲，同心共筑中国梦",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/RCFaovix3pru.JPG",
          starttime: "2021-05-08 14:00:00",
          endtime: "2021-05-08 16:00:00",
          join_starttime: "2021-04-30 14:00:00",
          join_endtime: "2021-05-05 16:00:00",
          address: "鉴湖园社区文化礼堂",
          now_people: 0,
          plan_people: 20,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "cce21150-a664-11eb-a75f-13de38af299c",
          name: "玉兰社区“彩绘春天”帆布袋DIY活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/EALOeRPua9w7.jpg",
          starttime: "2021-04-17 14:00:00",
          endtime: "2021-04-17 16:00:00",
          join_starttime: "2021-04-12 00:00:00",
          join_endtime: "2021-04-15 00:00:00",
          address: "玉兰花园二期草坪",
          now_people: 0,
          plan_people: 50,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "6162a720-a664-11eb-a941-b99f893780bc",
          name: "大爱玉兰“致敬最美丽的妳”妇女节活动",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/dXwuicFoVzpb.png",
          starttime: "2021-03-05 14:00:00",
          endtime: "2021-03-05 16:00:00",
          join_starttime: "2021-03-01 00:00:00",
          join_endtime: "2021-03-03 00:00:00",
          address: "玉兰国际1903无咖咖啡室",
          now_people: 0,
          plan_people: 20,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "fd261ce0-a663-11eb-9b19-370a6389d9c6",
          name: "（周一）大爱玉兰“颐乐学院”音乐课",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/u71LmGAXZaQF.jpg",
          starttime: "2021-03-01 00:00:00",
          endtime: "2021-03-31 00:00:00",
          join_starttime: "2021-03-01 00:00:00",
          join_endtime: "2021-03-31 00:00:00",
          address: "玉兰花园二期会所",
          now_people: 0,
          plan_people: 15,
          progress: 1,
          apply_status: 0,
        },
        {
          id: "4447bcd0-a618-11eb-b32c-29288b513053",
          name: "玉兰社区“学雷锋”志愿行",
          image:
            "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/tdKf3jgC1h4G.jpg",
          starttime: "2021-03-05 00:00:00",
          endtime: "2021-03-05 00:00:00",
          join_starttime: "2021-03-03 00:00:00",
          join_endtime: "2021-03-05 00:00:00",
          address: "玉兰社区",
          now_people: 0,
          plan_people: 10,
          progress: 1,
          apply_status: 0,
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.iframe-nav {
  height: calc(100vh - 2.7rem);
}
</style>
