<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <ul class="wz_content">
      <li
        v-for="item in dataList || []"
        :key="item.key"
        class="item"
        @click="gotoLink('wenEjiaDetail?key=' + item.id)"
      >
        <div class="image">
          <img
            :src="
              item.picture
                ? item.picture
                : 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'
            "
          />
        </div>
        <div class="text">
          <div class="title">
            {{ item.name }}<van-tag>互动招募<van-icon name="arrow"/></van-tag>
          </div>
          <div class="desc">{{ item.introduce }}</div>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import service from "utils/service";
export default {
  name: "wenList",
  data() {
    return {
      current: 0,
      pageSize: 10,
      dataList: [],
      loading: false,
      listFinished: false,
    };
  },
  props: {
    selectStreet: {
      type: String,
      default: "东盛社区",
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    onLoad() {
      this.current += 1;
      this.getDataList({page: this.current});
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList(params) {
      service
        .post("/homePageCulture/selectByPage", {
          page: this.current,
          size: this.pageSize,
          object: {
            type: this.selectStreet,
          },
          ...params,
        })
        .then((res) => {
          this.loading = false;
          if (res.flag) {
            console.log(this.current, res.data.pages);
            
            if (this.current === res.data.pages) {
              this.dataList = Array.from(
                new Set(this.dataList.concat(res.data.records))
              );
              this.listFinished = true;
            } else if (this.current < res.data.pages) {
              this.dataList = Array.from(
                new Set(this.dataList.concat(res.data.records))
              );
            } else {
              this.dataList = res.data.records;
            }
          }
        });
    },
  },
};
</script>
<style lang="scss">
.van-tag--default {
  background: #fff;
  border: 1px solid #d3b17a;
  color: #d3b17a;
}
.van-tag {
  float: right;
}
</style>
