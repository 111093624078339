<template>
  <div class="wrap">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>报修服务</h1>
    </div>
    <div class="content">
      <div class="header">
        <div class="header-title">{{ repairDetail.description }}</div>
        <div class="header-time">{{ repairDetail.createDate }}</div>
      </div>
      <div class="bottom">
        <div class="bottom-row">
          <div>报修地址</div>
          <div>{{ repairDetail.address }}</div>
        </div>
        <div class="bottom-row">
          <div>处置状态</div>
          <div>{{ statusCn }}</div>
        </div>
        <div class="bottom-img">
          <div v-for="(item, index) in repairDetail.imageUrl" :key="index">
            <el-image
              class="bottom-img-item"
              fit="contain"
              :src="item"
              :preview-src-list="repairDetail.imageUrl"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      repairDetail: (state) => state.repair.repairDetail,
    }),
    statusCn() {
      switch (this.repairDetail.status) {
        case 0:
          return "未整治";
        case 1:
          return "整治中";
        case 2:
          return "已整治";
        case 4:
          return "已作废";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions({
      getRepairById: "repair/getRepairById",
    }),
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    const { id } = this.$route.query;
    this.getRepairById({ id });
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0.75rem;
  .content {
    flex: auto;
    margin-top: 2.7rem;

    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem 0.5rem 0.25rem 0.25rem;
    .header {
      padding-bottom: 0.5rem;
      line-height: 0.875rem;
      border-bottom: 1px solid #e5e5e5;
      &-title {
        font-size: 1.125rem;
        line-height: 1.4rem;
        color: rgb(34, 34, 34);
      }
      &-time {
        line-height: 1.5rem;
        color: rgba(rgb(34, 34, 34), 0.45);
      }
    }
    .bottom {
      margin-top: 1.125rem;
      &-row {
        display: flex;
        justify-content: space-between;
        line-height: 1.875rem;
        :first-child {
          flex-shrink: 0;
          margin-right: 1rem;
          color: rgba(rgba(0, 0, 0, 0.65), 0.45);
        }
      }
      &-img {
        flex: auto;
        flex-wrap: wrap;
        min-height: 4.75rem;
        margin-top: 1rem;
        &-item {
          width: 4.5625rem;
          height: 4.5625rem;
          margin-top: 0.5rem;
          margin-right: 0.25rem;
          background: #fff0c3;
        }
      }
    }
  }
}
</style>
