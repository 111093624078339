<template>
  <div class="shared-site-detail-container">
    <van-nav-bar
      title="场地详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="shared-site-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="image-con">
          <img class="image" :src="dataInfo.cover" alt="">
        </div>
        <div class="desc">
          <div class="title">
            <b>{{ dataInfo.name }}</b>
          </div>
          <div class="info">
            <label>位置：</label><span class="location-con">{{ dataInfo.place }}<i class="location"></i></span>
          </div>
        </div>
      </div>
      <div class="top-bar">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">场地介绍</span>
          </div>
        </div>
        <div class="desc">
          <div class="info">
            <label>场地用途：</label><span>{{ dataInfo.purpose }}</span>
          </div>
          <div class="info">
            <label>预约时段：</label><span>{{ dataInfo.bespokenTime }}</span>
          </div>
          <div class="info">
            <label>配套设施：</label><span>{{ dataInfo.supportMeasures }}</span>
          </div>
          <div class="info">
            <label>容纳人数：</label><span>{{ dataInfo.galleryful }}</span>
          </div>
          <div class="info">
            <label>联系人：</label><span>{{ dataInfo.contactsUser }}</span>
          </div>
        </div>
      </div>
      <button class="shared-site-button" @click="callPhone(dataInfo.phone)">电话预约</button>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/sharedSites.js';
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id } = this.$route.query
    this.getDetail(id);
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    callPhone(phone) {
      window.location.href = `tel://${phone}`;
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getDetail(id) {
      api.getSharedSiteDetail({ id }).then(res => {
        if (res.data) {
          this.dataInfo = res.data;
        }
      });
      // this.dataInfo = data.data.find(item => item.name === name);
    }
  }
};
</script>

<style lang="scss" scoped>
.shared-site-detail-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFF;
  
  .shared-site-detail-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;

    .top-bar {
      width: 100%;
      background: #FFF;
      margin-bottom: 10px;
      .image-con {
        width: 100%;
        height: 240px;
        position: relative;
        .image {
          height: 100%;
          width: 100%;
        }
      }
      .card-title {
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .desc {
        background: #fff;
        border-radius: 0 0 0.3rem 0.3rem;
        padding: 0.5rem 0.7rem;
        .title {
          height: 2rem;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          overflow: hidden;
          line-height: 26px;
          b {
            float: left;
            color: rgba(0, 0, 0, 0.85);
            font-size: 1rem;
            width: 17.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .info {
          font-size: 14px;
          min-height: 1.6rem;
          line-height: 1.6rem;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          label {
            color: rgba(0, 0, 0, 0.45);
            width: 80px;
          }
          span {
            flex: 1;
          }
          .location-con {
            display: flex;
            align-items: center;
            .location {
              display: inline-block;
              width: 1rem;
              height: 1rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/location-blue.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .shared-site-button {
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      width: calc(100% - 20px);
      background-color: #4479ED;
      color: #FFF;
      font-size: 18px;
      line-height: 48px;
      border: none;
      border-radius: 4px;
    }
  }
}
</style>
