<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>分享工具</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="invitationTitle"
          name="invitationTitle"
          required
          label="工具名称"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写工具名称' }]"
        />
        <van-field
          v-model="toolNumber"
          name="toolNumber"
          type="number"
          required
          label="工具数量"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写工具数量' }]"
        />
        <van-field
          v-model="createUserName"
          name="createUserName"
          label="分享人"
          required
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写分享人' }]"
        />
        <van-field
          v-model="createUserPhone"
          name="createUserPhone"
          type="tel"
          label="联系电话"
          required
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <van-field
          v-model="toolPlace"
          name="toolPlace"
          label="具体地址"
          required
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写具体地址' }]"
        />
        <div class="section-textArea">
          <div class="section-label">工具介绍</div>
          <van-field
            v-model="toolDescribe"
            name="toolDescribe"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="50"
            placeholder="请输入"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem">
          <div class="image-title">相关照片</div>
          <van-uploader v-model="invitationContent" name="invitationContent" :max-count="1" />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">立即分享</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import commonApi from "api/common";
import gjxApi from "api/gjx";
import { mapState } from "vuex";
import context from '@/main';

export default {
  data() {
    return {
      invitationTitle: "",
      toolDescribe: "",
      invitationContent: [],
      toolNumber: "",
      createUserName: "",
      createUserPhone: "",
      toolPlace: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state =>
        (state.login.userInfo.nickname ? state.login.userInfo : null) ||
        (context.getSessionData("userInfo"))
    })
  },
  components: {},
  mounted() {
    this.createUserName = this.userInfo ? this.userInfo.userName : "";
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    pushToolCase(values) {
      gjxApi.pushToolCase(values).then(res => {
        if (res.data == 1) {
          Toast.success("分享成功");
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer)
          }, 1000);
        }
      });
    },
    onSubmit(values) {
      if (this.invitationContent.length) {
        const formData = new FormData();
        this.invitationContent.forEach(file => {
          formData.append("files", file.file);
        });
        commonApi.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.invitationContent = res.data;
            this.pushToolCase(values);
          }
        });
      } else {
        this.pushToolCase(values);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";
.page-container {
  background: #ffffff;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .section-textArea {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }
}
</style>
