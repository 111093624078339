<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要报名</h1>
      </div>
      <div class="form-bar">
        <div class="vol-sub">
          选择志愿时间<span>(周一至周五 15:30-17:30)</span>
        </div>
        <div class="date-picker">
          <div class="picker-top">
            <span class="picker-arrow" @click="preYear">&lsaquo; &lsaquo;</span>
            <span class="picker-arrow" @click="preMonth">&lsaquo;</span>
            <span class="date-text"
              >{{ year }}-{{ month > 9 ? month : "0" + month }}</span
            >
            <span class="picker-arrow" @click="nextMonth">&rsaquo;</span>
            <span class="picker-arrow" @click="nextYear">&rsaquo;&rsaquo;</span>
          </div>
          <!--生成对应的月份星期表格 start-->
          <div class="picker-content">
            <table>
              <thead>
                <tr>
                  <th v-for="(item, idx) in weekList" :key="'week' + idx">
                    {{ getLangText(item) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="idx in createArray(weekNum)" :key="'weekNum' + idx">
                  <td
                    v-for="m in createArray(7)"
                    :key="'monthDay' + idx + '_' + m"
                    :class="[
                      new Date(monthList[idx][m]).getMonth() + 1 ==
                      month
                        ? ''
                        : 'gray',
                      new Date(new Date() - 30) ? '' : 'gray',
                      getSelectDate(monthList[idx][m]) ? 'active' : '',
                    ]"
                    @click="onSelectDate(monthList[idx][m])"
                  >
                    <div>
                      <p>{{ new Date(monthList[idx][m]).getDate() }}</p>
                      <span class="num">{{
                        getItemByDate(new Date(monthList[idx][m]).getMonth() + 1,new Date(monthList[idx][m]).getDate()) 
                        ? getItemByDate(new Date(monthList[idx][m]).getMonth() + 1, new Date(monthList[idx][m]).getDate()).status : ''}}</span>
                      <!--<span class="full">已满</span>-->
                    </div>
                  </td>
                  <!--日期为该月为深色否则为浅色-->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="vol-sub" v-if="!isSign">
          报名信息
        </div>
        <van-form @submit="onSubmit" v-if="!isSign">
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="报名人姓名"
            placeholder="请输入"
            class="input-bar"
            :rules="[{ required: true, message: '请输入报名人姓名' }]"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            type="tel"
            label="联系电话"
            placeholder="请输入"
            :rules="[{ pattern, message: '请输入正确的联系电话' }]"
            class="input-bar"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
        <div class="footer-button" v-if="isSign">
          <van-button
            round
            block
            type="info"
            style="font-size: 16px"
            @click="cancelApply"
            >取消报名</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";
import sdbxtApi from "api/sdbxt/sdbxt";
import { DatetimePicker } from "mint-ui";
import { Uploader, Toast } from "vant";
import { Field } from "mint-ui";
import { mapState } from "vuex";
import monent from 'moment';
import context from '@/main';
Date.prototype.format = function(fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};
export default {
  name: "DatePicker",
  props: {
    langType: {
      type: String,
      default: "zh",
    },
    date: {
      type: String,
      default: "",
    },

    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeDateItemId: '',
      name: "",
      phone: "",
      pattern: /^[1]([3-9])[0-9]{9}$/,
      weekList: [
        { zh: "周日", en: "Sun" },
        { zh: "周一", en: "Mon" },
        { zh: "周二", en: "Tue" },
        { zh: "周三", en: "Wed" },
        { zh: "周四", en: "Thu" },
        { zh: "周五", en: "Fir" },
        { zh: "周六", en: "Sat" },
      ],
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),

      startDay: 0,
      endDay: 0,
      weekNum: 0,
      selectDate: new Date(new Date().format("yyyy-MM-dd 00:00")).getTime(),
      monthList: [],
      result: [],
      selectOrderDate: {}, // 选择的志愿活动日期

      volunteerTime: [], // 后端志愿活动日期数据
      isSign: false, // 是否已报名
    };
  },
  components: {},
  mounted() {
    // this.getOrderType();
    this.name = this.userInfo.userName || this.userInfo.nickname || "";
    this.phone = this.userInfo.phone || '';
    console.log('userInfo', this.userInfo, context.getSessionData('userInfo'));
    
    const { id } = this.$route.params;
    this.getMonthDay();
    this.parseDate();
    this.getVolunteerTime();
  },
  computed: {
    ...mapState({
      userInfo: (state) => context.getSessionData("userInfo"),
    }),
  },
  watch: {
    date() {
      this.parseDate();
    },
    year() {
      this.getMonthDay();
      this.getVolunteerTime();
    },
    month() {
      this.getMonthDay();
      this.getVolunteerTime();
    },
  },
  methods: {
    createArray(num) {
      const arr = [];
      for(let i = 0; i < num; i++) {
        arr.push(i);
      }
      return arr;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onSubmit(values) {
      if (this.result.length > 0 && this.selectOrderDate) {
        const timeS = this.result[0];
        // const { key } = this.$route.query;
        const year = new Date(timeS).getFullYear();
        const month = new Date(timeS).getMonth() + 1;
        const day = new Date(timeS).getDate();
        console.log(`${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`, values);
        sdbxtApi
          .applyVolunteer({
            name: values.name,
            phone: values.phone,
            dsc: '三点半课堂',
            orderDate: `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`,
            orderId: this.selectOrderDate.id,
            type: 1,
          })
          .then((res) => {
            if (res.flag) {
              Toast('报名成功');
              setTimeout(() => {
                this.$router.go(-1)
              }, 500);
            } else {
              Toast(res.message)
            }
          });
      } else {
        this.$messagebox("提示", "请选择日期");
      }
    },
    getOrderType() {
      yqpddApi.getOrderType().then((res) => {
        const { data } = res;
        const list = data.map((item) => {
          return item.type;
        });
        this.typeList = list;
      });
    },
    getLangText(item) {
      if (item) {
        if (this.langType == "en") {
          if (item.en && item.en.length > 1) {
            return item.en.substring(0, 1).toUpperCase() + item.en.substring(1);
          } else if (item.en && item.en.length == 1) {
            return item.en.toUpperCase();
          } else {
            return "--";
          }
        } else {
          return item.zh ? item.zh : "--";
        }
      } else {
        return "--";
      }
    },
    preYear() {
      this.year = this.year - 1;
    },
    nextYear() {
      this.year = this.year + 1;
    },
    nextMonth() {
      if (this.month == 12) {
        this.year = this.year + 1;
        this.month = 1;
      } else {
        this.month++;
      }
    },
    preMonth() {
      if (this.month == 1) {
        this.year = this.year - 1;
        this.month = 12;
      } else {
        this.month--;
      }
    },
    getSelectDate(time) {
      for (var i = 0; i < this.result.length; i++) {
        if (time == this.result[i]) {
          return true;
        }
      }
      return false;
    },
    showPicker(e) {
      this.$emit("update:is-show", true);
      var time = new Date().getTime();
      this.year = new Date(time).getFullYear();
      this.month = new Date(time).getMonth() + 1;
      this.day = new Date(time).getDate();
      this.selectDate = new Date(
        new Date(time).format("yyyy-MM-dd 00:00")
      ).getTime();
    },
    onConfirmDate(time) {
      this.onSelectDate(time);
      this.confirmPicker();
    },
    closePicker() {
      this.$emit("update:is-show", false);
    },
    setNow() {
      this.year = new Date().getFullYear();
      this.month = new Date().getMonth() + 1;
      this.day = new Date().getDate();
      this.selectDate = new Date(
        new Date().format("yyyy-MM-dd 00:00")
      ).getTime();
    },
    confirmPicker() {
      this.$emit("update:date", new Date(this.selectDate).format("yyyy-MM-dd"));
      this.$emit(
        "picker-result",
        new Date(this.selectDate + this.selectHour * 3600000).format(
          "yyyy-MM-dd hh:00"
        )
      );
      this.closePicker();
    },
    getMonthDay() {
      var monthFirst = new Date(this.year + "/" + this.month + "/01 00:00");
      var w = monthFirst.getDay();

      this.startDay = monthFirst.getTime() - w * 24 * 3600 * 1000;
      if (this.month == 12) {
        this.endDay = new Date(this.year + 1 + "/01/01 00:00").getTime() - 1000;
      } else {
        this.endDay =
          new Date(this.year + "/" + (this.month + 1) + "/01 00:00").getTime() -
          1000;
      }

      var monthDay = (this.endDay + 1000 - this.startDay) / (24 * 3600 * 1000);
      this.weekNum = Math.ceil(monthDay / 7);
      console.log('weekNum', monthDay,this.weekNum);
      
      this.monthList = [];
      for (var i = 0; i < this.weekNum; i++) {
        var item = [];
        for (var j = 0; j < 7; j++) {
          item.push(
            this.startDay + i * 24 * 3600 * 1000 * 7 + j * 24 * 3600 * 1000
          );
        }
        this.monthList.push(item);
      }
      console.log('monthList', this.monthList);
      
    },
    getVolunteerTime() {
      sdbxtApi.getVolunteerTime({
        month: `${this.month < 10 ? `0${this.month}` : this.month}`,
        year: this.year
      }).then(res => {
        console.log('time', res.data);
        
        if (res.flag) {
          this.volunteerTime = res.data;
        }
      })
    },
    getItemByDate(month, date) {
      const dateParam = `${month < 10 ? `0${month}` : month}.${date < 10 ? `0${date}` : date}`;
      if (!this.volunteerTime || !date) {
        return null;
      }
      return this.volunteerTime.find(item => item.openDate === dateParam);
    },
    onSelectDate(time) {
      if(new Date(time).getMonth() + 1 !== this.month) {
        return;
      }
      this.selectDate = time;
      this.year = new Date(time).getFullYear();
      this.month = new Date(time).getMonth() + 1;
      this.day = new Date(time).getDate();
      var tag = true;
      const dateParam = new Date(time).getDate();
      const dateItem = this.getItemByDate(new Date(time).getMonth() + 1, dateParam);
      console.log('date',dateParam, dateItem);
      
      if (dateItem && dateItem.ban) {
        this.$dialog({
          message: '该日期已过期！',
        });
        return;
      }
      if (dateItem && !dateItem.id) {
        this.$dialog({
          message: '该日期暂不招收志愿者',
        });
        return;
      }
      if (dateItem && dateItem.status === '已满') {
        this.$dialog({
          message: '该日期志愿者已招满',
        });
        return;
      }

      //已选择就取消选择
      for (var i = 0; i < this.result.length; i++) {
        if (this.result[i] == time) {
          tag = false;
          this.result.splice(i, 1);
          this.selectOrderDate = {};
          break;
        }
      }
      //未选择就添加日期
      if (tag) {

        // 获取报名状态
        dateItem && sdbxtApi.isSign({ orderId: dateItem.id }).then(res => {
          if (res.flag && res.data) {
            // 已报名
            this.isSign = true;
            this.activeDateItemId = dateItem.id;
          } else {
            this.isSign = false;
            this.activeDateItemId = dateItem.id;
          }
        })

        this.result = [time];
        this.selectOrderDate = dateItem;
        this.result = this.result.sort(function(a, b) {
          return a - b;
        });
      }
      var list = [];
      for (var i = 0; i < this.result.length; i++) {
        if (this.result[i] > 0) {
          list.push(new Date(this.result[i]).format("yyyy-MM-dd"));
        }
      }
      if (list.length > 0) {
        this.$emit("update:date", list.join(",") + "(共" + list.length + "天)");
      } else {
        this.$emit("update:date", "");
      }

      this.$emit("picker-result", this.result);
    },
    cancelApply() {
      sdbxtApi.disApply({ id: this.selectOrderDate.id }).then(res => {
        if (res.flag) {
          this.$toast('取消报名成功');
          this.getVolunteerTime();
        // 获取报名状态
          this.activeDateItemId && sdbxtApi.isSign({ orderId: this.activeDateItemId }).then(res => {
            if (res.flag && res.data) {
              // 已报名
              this.isSign = true;
            } else {
              this.isSign = false;
            }
          })
        } else {
          this.$toast('取消报名失败')
        }
      })
    },
    onFullMonth() {
      this.$emit("update:date", this.year + "年" + this.month + "月份");
      this.$emit("picker-result", 30);
    },

    parseDate() {
      if (this.date) {
        var str = this.date;
        if (this.date.indexOf("(") > 0) {
          str = this.date.substring(0, this.date.indexOf("("));
        }
        if (str) {
          var list = str.split(",");
          var result = [];
          for (var i = 0; i < list.length; i++) {
            result.push(
              new Date(
                new Date(list[i]).format("yyyy-MM-dd 00:00:00")
              ).getTime()
            );
          }
          this.result = result;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.page-container {
  background: #fff;
  padding: 0.6rem 0;
  height: calc(100vh - 6.7rem);
  width: 100vw;
  margin-top: 2.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  .form-bar {
    height: 100%;
    .vol-sub {
      font: 600 0.9rem/1.2rem "microsoft yahei";
      color: #333;
      padding: 0.5rem 0.8rem;
      span {
        color: #bbb;
        font-weight: 500;
        font-size: 0.8rem;
      }
    }
    .van-cell--large .van-cell__title {
      font-size: 0.9rem;
    }
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }

  .date-picker {
    background-color: white;
    display: block;
    padding: 0 0.8rem;
    margin-bottom: 1.5rem;
    z-index: 6;
    border: solid 0px gainsboro;
    border-radius: 2px;
    .picker-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1.4rem;
      padding: 0 0 0.3rem;
      .picker-arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        font-size: 1.2rem;
        cursor: pointer;
        .iconfont {
          color: #8a8a8a;
        }
        .iconfont:active,
        .iconfont:hover {
          color: #388dea;
        }
      }

      .date-text {
        flex: 1;
        font-weight: bold;
        display: inline-block;
        text-align: center;
        font-size: 1rem;
      }
    }

    .picker-content {
      display: block;
      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 0.8rem;
        line-height: 1.2rem !important;
        thead > tr {
          background-color: #fff;
          th {
            text-align: center;
            font-weight: normal;
          }
        }
        tbody {
          tr {
            td {
              font-weight: 600;
              cursor: pointer;
              text-align: center;
              padding: 0.3rem 0.2rem;
              div {
                p {
                  font-family: tahoma;
                }
                border-radius: 0.3rem;
                height: 3rem;
                padding: 0.3rem 0;
                background: #fff;
                border: 1px solid #bbb;
                color: #333;
                .num {
                  color: #3291f8;
                  font-weight: 500;
                }
                .full {
                  color: #bbb;
                  font-weight: 500;
                  font-size: 0.5rem;
                }
              }
            }
            td.gray {
              div {
                border: 0;
                font-weight: normal;
                color: #bbb;
                background: #f9f9f9;
                span {
                  display: none;
                }
              }
            }
            td.active {
              div {
                border: 0;
                color: white;
                background: #3291f8;
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .picker-content1 {
      @extend .picker-content;
      display: flex;
      flex-direction: row;
      table {
        width: calc(100% - 40px);
      }
      .hour-list {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
        height: 100%;
        overflow-x: hidden;
        width: 40px;
        font-size: 12px;

        overflow-y: auto;
        li {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          padding: 0 4px;
          height: 20px;
          cursor: pointer;
        }
        li:not(:last-child) {
          border-bottom: solid 1px gainsboro;
        }
        li.active {
          color: white;
          background: #388dea;
        }
      }
      .hour-list::-webkit-scrollbar {
        background: transparent;
        height: 8px;
        width: 8px;
        border: none;
      }

      .hour-list::-webkit-scrollbar-thumb {
        background: lightgray;
        border-radius: 5px;
      }
      //设置滚动条 end
    }
  }
}
</style>