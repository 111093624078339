<template>
  <div class="sourceStore-container">
    <van-nav-bar title="东盛先锋" left-arrow @click-left="onClickLeft" />
    <div class="vanguard-body-container">
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="status"
          @change="handleChange($event, 'status')"
          :options="option1"
        />
      </van-dropdown-menu>
      <div class="card-list-con">
        <van-list
          v-model="loading"
          :finished="listFinished"
          finished-text="没有更多了"
          @load="onLoad"
          class="reply-list"
        >
          <div class="activity-need-list-con">
            <div class="item" v-for="(item, i) in dataList" :key="i" @click="gotoLink(`vanguardDetail?id=${item.id}&isAll=true`)">
              <div class="itemName">
                <div class="image">
                  <img :src="item.submitUserAvatar" alt="" />
                </div>
                <div class="text">
                  <div class="title">
                    {{ item.submitUserName }}
                    <span v-if="item.submitUserPartyType !== 0" class="tag">党员</span>
                  </div>
                  <div>发布时间：{{ item.createTime }}</div>
                  <van-tag v-if="item.my == '0'" @click="gotoLink('myvanguard')">我发布的</van-tag>
                </div>
              </div>
              <div class="itemDesc">
                {{ item.issueContent }}
              </div>
              <div class="image-list-con">
                <img
                  v-for="(item, index) in item.photoVO"
                  :src="item"
                  :key="index"
                  alt=""
                />
              </div>
              <div class="data-info">
                <span>{{ item.visitsUp }}</span>人浏览 <span>{{ item.thumbsUp }}</span>个点赞
                <!-- <span>{{ item.repoNum }}</span>条评论 -->
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <footer>
        <ul>
          <li class="catalog">
            <a
              ><i class="home"></i>
              <p>首页</p>
            </a>
          </li>
          <li class="selected">
            <a @click="gotoLink('vanguardAdd')"
              ><i></i>
              <p>先锋发布</p>
            </a>
          </li>
          <li class="mine">
            <a @click="gotoRepalaceLink('myvanguard')"
              ><i></i>
              <p>我的</p>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import api from "api";
import nrbApi from "api/llyjr/nrb";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      status: "",
      option1: [
        { text: "默认排序", value: "" },
        { text: "最新发布", value: "最新发布" },
      ],
      current: 0,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    };
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === "我的发布" ? "yes" : "no";
    },
  },
  components: {},
  mounted() {
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    gotoRepalaceLink(path) {
      this.$router.replace(path);
    },
    onLoad() {
      this.current += 1;
      this.getDataList({page: this.current});
    },
    getDataList({ page = this.current, pageSize = this.pageSize } = {}) {
      this.loading = false;
      api
        .getVanguardList({
          page,
          pageSize,
          isMyAdd: true,
          isMyComment: true,
          myAdd: "",
          myComment: "",
          orderBy: "",
          orderByType: 0,
        })
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = records;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(records)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
      // this.dataList = data.filter(item => item.type === this.type) || [];
      // this.total = 4;
      // this.listFinished = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sourceStore-container {
  height: 100vh;
  width: 100vw;

  .vanguard-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png")
      no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .card-list-con {
      height: calc(100% - 48px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 2rem;
    }
    .activity-need-list-con {
      width: 100%;
      margin: 0 auto;
      border: none;
      padding: 10px;
      .item {
        position: relative;
        padding: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        border-radius: 4px;
        .itemName {
          display: flex;
          height: 50px;
          padding: 5px;
          .image {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            > img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text {
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            position: relative;
            .title {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 500;
              .tag {
                background: #e3effd;
                border-radius: 2px;
                font-size: 12px;
                color: #4479ed;
                padding: 3px 6px;
              }
            }
            .van-tag {
              background: #e3effd;
              color: #4479ed;
              font-size: 0.4rem;
              padding: 0 0.5rem;
              height: 20px;
              line-height: 20px;
              border-radius: 2px 6px 2px 6px;
              position: absolute;
              right: 0rem;
              top: 0.2rem;
            }
          }
        }
        .itemDesc {
          width: 100%;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .image-list-con {
          width: 100%;
          // padding: 10px;
          display: flex;
          flex-wrap: wrap;
          > img {
            width: 56px;
            height: 56px;
            border-radius: 4px;
            margin: 5px;
          }
        }
        .data-info {
          width: 100%;
          margin: 10px 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          span {
            color: #4479ed;
            margin-left: 1rem;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
    footer {
      height: 3.2rem;
      border-top: 2px solid #f1f2f5;
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding-top: 0.4rem;
      ul {
        display: flex;
        justify-content: space-around;
        li {
          flex: 1;
          text-align: center;
          i {
            display: inline-block;
            width: 1.3rem;
            height: 1.3rem;
          }
          p {
            font: 500 0.6rem/1rem "microsoft yahei";
            color: #a3b0d0;
          }
        }
        .home {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/home.png)
            no-repeat;
          background-size: 100% 100%;
        }
        .mine i {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/mine.png)
            no-repeat;
          background-size: 100% 100%;
        }
        .mine2 i {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/mine_select.png)
            no-repeat;
          background-size: 100% 100%;
        }
        .mine2 p {
          color: #2a70ed;
        }
        .selected i {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/vang-add.png)
            no-repeat;
          background-size: 4.5rem 4.5rem;
          margin-top: -2.5rem;
          margin-bottom: -0.7rem;
          width: 4.5rem;
          height: 4.5rem;
        }
        .selected p {
          color: #2a70ed;
        }
      }
    }
  }
}
</style>
