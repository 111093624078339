<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要停车</h1>
      </div>
      <div class="search-bar">
        <div class="input-bar" @click="showPicker">
          {{ community }}
          <van-icon name="arrow-down" size="14px" />
        </div>
        <div style="flex: 1">
          <van-search
            style="height: 1.8rem"
            v-model="selectItem.parkingCode"
            placeholder="搜索"
            shape="round"
            @input="refreshParkingData"
            @clear="handleSearchClear"
          />
        </div>
      </div>
      <div class="search-bar">
        <div class="normal-bar" @click="showType = true">
          {{ type }}
          <van-icon name="arrow-down" size="14px" />
        </div>
        <div class="normal-bar" @click="showSort = true">
          {{ sort }}
          <van-icon name="arrow-down" size="14px" />
        </div>
      </div>
      <van-list
        v-model="listLoading"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="loadBottom"
        class="list"
        :finished="finished"
        finished-text="没有更多了"
        ref="list"
      >
        <!-- <mt-loadmore
          :top-method="loadTop"
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          class="list"
          bottomPullText=""
        > -->
          <ul>
            <li
              v-for="(item, index) in parkingData"
              :key="index + item.parkingCode"
              class="card-con"
            >
              <img
                style="width: 80px; height: 80px"
                :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultPark.jpg'"
                class="image"
              />
              <div
                class="tag"
                :style="{
                  color: item.parkingStatus ? colors[item.parkingStatus || '空闲中'].text : '#13BB49',
                  background: item.parkingStatus ? colors[item.parkingStatus || '空闲中'].background : '#D5F4E2',
                }"
              >
                {{ item.parkingStatus ? item.parkingStatus : '空闲中'  }}
              </div>
              <div>
                <div class="card-title">
                  <span style="font-weight:bold;">车位号：{{ item.parkingCode }}</span>
                </div>
                <div class="row">
                  <div>时间：</div>
                  <div>
                    {{ `${item.startTime.substring(0, item.startTime.lastIndexOf(":"))}~${item.overTime.substring(0, item.overTime.lastIndexOf(":"))}` }}
                  </div>
                </div>
                <div class="row">
                  <span>{{ '\u2002\u2002\u2002\u2002\u2002\u2002' + item.week }}</span>
                </div>
                <div class="row">
                  <span>位置：{{ item.location }}</span>
                </div>
                <div class="card-button" @click="gotoLink(selectItem, item)">立即预约</div>
              </div>
            </li>
          </ul>
        </van-list> 
        <!-- </mt-loadmore> -->
    </div>

    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="选择类型"
        show-toolbar
        :columns="types"
        @confirm="typeConfirm"
        @cancel="showType = false"
      />
    </van-popup>

    <van-popup v-model="showArea" position="bottom">
      <van-picker
        title="选择小区"
        show-toolbar
        :columns="communities"
        value-key= "communityName"
        @confirm="areaConfirm"
        @cancel="showArea = false"
      />
    </van-popup>

    <van-popup v-model="showSort" position="bottom">
      <van-picker
        title="选择排序"
        show-toolbar
        :columns="sorts"
        @confirm="sortConfirm"
        @cancel="showSort = false"
      />
    </van-popup>

    <van-dialog v-model="showSelectItem" @cancel="cancelSelectItem" @confirm="selectFreePakring" show-cancel-button>
      <van-field
        readonly
        is-link
        clickable
        name="datetimePicker"
        :value="selectItem.date"
        label="选择日期"
        placeholder="点击选择日期"
        @formatter="formatter"
        @click="getDateCheck"
      />
      <van-field
        readonly
        is-link
        clickable
        name="datetimePicker"
        :value="selectItem.startTime"
        label="开始时间"
        @formatter="formatter"
        placeholder="点击选择时间"
        @click="getTimeCheck(true)"
      />
      <van-field
        readonly
        is-link
        clickable
        name="datetimePicker"
        :value="selectItem.overTime"
        label="结束时间"
        @formatter="formatter"
        placeholder="点击选择时间"
        @click="getTimeCheck(false)"
      />
    </van-dialog>
    <van-popup v-model="showStartTime" @close="closePopFunc(true)" position="bottom">
      <van-datetime-picker
        v-model="selectItem.startTime"
        type="time"
        ref="startTimePick"
        :filter="filter"
        @change="startChange"
        :min-hour="minStartHour"
        :min-minute="minStartMin"
        @confirm="startConfirm"
        @cancel="timeCancel(true)"
      />
    </van-popup>
    <van-popup v-model="showOverTime" @close="closePopFunc(false)" position="bottom">
      <van-datetime-picker
        v-model="selectItem.overTime"
        type="time"
        ref="overTimePick"
        :filter="filter"
        @change="overChange"
        :min-hour="minEndHour"
        :min-minute="minEndMin"
        @confirm="overConfirm"
        @cancel="timeCancel(false)"
      />
    </van-popup>
    <van-calendar
      v-model="showDate"
      type="single"
      @min-date="new Date()"
      @confirm="onDateConfirm"
      @close="closeDate"
    />
  </div>
</template>

<script>
import { Calendar, Notify, Toast  } from "vant";

export default {
  data() {
    return {
      error: false,
      listLoading: false,
      finished: false,
      parkingData: [],
      colors: {
        空闲中: { text: "#13BB49", background: "#D5F4E2" },
        已预订: { text: "#E5B20A", background: "#FFF6D9" },
        未开放: { text: "#000000", background: "#EDEDED" },
        泊车中: { text: "#4479ED", background: "#E3EFFD" },
      },
      sorts: ["综合排序", "正序", "倒序"],
      communities: [{communityId: "", communityName: "全部小区"}],
      sort: "综合排序",
      community: "全部小区",

      types: ['所有车位', '空闲车位'],
      type: '所有车位',
      showArea: false,

      showSort: false,
      showSelectItem: false,
      showStartTime: false,
      showOverTime: false,
      showDate: false,
      showType: false,

      minEndHour: '',
      minEndMin: '',
      minStartHour: '',
      minStartMin: '',

      value: "",
      rangeDate: [],
      current: 1,
      allLoaded: false,

      closePop: true,

      pages: 0,

      selectItem: {
        startTime: "",
        overTime: "",
        date: "",
        communityId: "",
        stayTime: '',
        order: "",
        parkingCode: ""
      },

      timePickSign: "",
    };
  },
  components: {},
  mounted() {
    this.getParkingData(false);
    this.getCommunityList();
  },
  methods: {
    closePopFunc(value){
      if ( this.closePop && value){
        this.selectItem.startTime = this.timePickSign;
        this.timePickSign = "";
      } else if( this.closePop ){
        this.selectItem.overTime = this.timePickSign;
        this.timePickSign = "";
      }
      this.closePop = true;
      this.showStartTime = false;
      this.showOverTime = false;
      this.showSelectItem = true;
    },
    startChange(picker, value, colum){
      if (picker.getColumnValue(0) != this.minStartHour){
        this.minStartMin = '00';
      }else {
        const now = new Date();
        var hour = now.getHours();
        var h = Number(hour);
        var minute = now.getMinutes();
        var min = Number(minute);
        if (picker.getColumnValue(1) < min){
            if ( parseInt(min/10) == 5 ){
              min = 0;
              if (h == 23){
                  h == 0;
              }else {
                h = h + 1;
              }
            }else {
              min = (parseInt(min/10) + 1)*10 ;
            }
            min = min < 10 ? "0" + min : min;
            h = h < 10 ? "0" + h : h;
            this.minStartHour = h;
            this.minStartMin = min;
            if (picker.getColumnValue(0) == h && picker.getColumnValue(1) <= min && this.timePickSign != '' && !this.closePop){
              console.log("修改！");
              this.selectItem.startTime = h + ":"+ min;
            }
          }
        }
    },
    overChange(picker, value, colum){
      if (picker.getColumnValue(0) != this.minEndHour){
        this.minEndMin = '00';
      }else {
        this.minEndHour = Number(this.selectItem.startTime.substring(0,2));
        this.minEndMin = Number(this.selectItem.startTime.substring(3,5));
        if (picker.getColumnValue(0) == this.minEndHour && picker.getColumnValue(1) <= this.minEndMin
             && this.timePickSign != '' && !this.closePop){
          this.selectItem.startTime = this.minEndHour + ":"+ this.minEndMin;
        }
      }
    },
     filter(type, options){
      if (type === 'minute') {
        return options.filter(option =>  option % 10 == 0 );
      }
      return options;
     },
     formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      return val;
    },
    cancelSelectItem(){
      this.type = "所有车位";
      this.clearItem();
      this.refreshParkingData();
    },
    getCommunityList(){
      this.$store.dispatch("llcw/getCommunityList", { obj: this });
    },
    selectFreePakring(){
      if (this.selectItem.overTime == ""){
          Notify({
          message: '请选择结束时间!',
          duration: 1000
        });
      }else {
        this.refreshParkingData();
      }
    },
    getSelectItem(){
      this.showSelectItem = true
    },
    typeConfirm(value){
      this.type = value;
      if("所有车位" == value){
        this.clearItem();
        this.refreshParkingData();
      }else {
         this.initSelectItem();
         this.getSelectItem();
      }
      this.showType = false;
    },

    clearItem(){
      this.minEndHour = "";
      this.minStartHour = "";
      this.minEndMin = "";
      this.minStartMin = "";
      this.selectItem = {
        startTime: "",
        overTime: "",
        date: "",
        stayTime: '',
        communityId: this.selectItem.communityId,
        order: this.selectItem.order,
        parkingCode: this.selectItem.parkingCode,
      };
    },
    initSelectItem(){
      const now = new Date();
      var hour = now.getHours();
      var h = Number(hour);
      var minute = now.getMinutes();
      var min = Number(minute);
      if ( parseInt(min/10) == 5 ){
        min = 0;
        if (h == 23){
            h == 0;
        }else {
          h = h + 1;
        }
      }else {
        min = (parseInt(min/10) + 1)*10 ;
      }
      min = min < 10 ? "0" + min : min;
      h = h < 10 ? "0" + h : h;
      this.minStartHour = h;
      this.minEndHour = h;
      this.minStartMin = min;
      this.minEndMin = min;
      // console.log(h + ":" + min);
      // this.selectItem.startTime = hour + ":" + minute; 
    },
    timeCancel(value) {
      if (value){
        this.selectItem.startTime = this.timePickSign;
        this.timePickSign = "";
      } else {
        this.selectItem.overTime = this.timePickSign;
        this.timePickSign = "";
      }
      this.showStartTime = false;
      this.showOverTime = false;
      this.showSelectItem = true;
    },
    startConfirm(value) {
      this.closePop = false;
      this.selectItem.startTime = value;
      this.minEndHour = Number(this.selectItem.startTime.substring(0,2));
      this.minEndMin = Number(this.selectItem.startTime.substring(3,5))
      this.showSelectItem = true;
      this.showStartTime = false;
    },
    overConfirm(value) {
      this.closePop = false;
      if (this.selectItem.startTime == ''){
        Toast.fail("请先选择开始时间！");
        return;
      }
      this.selectItem.overTime = value;
      this.showSelectItem = true;
      this.showOverTime = false;
    },
    getTimeCheck(flag) {
      if (flag) {
        this.timePickSign = this.selectItem.startTime;
        this.showStartTime = true;
      } else {
        this.timePickSign = this.selectItem.overTime;
        this.showOverTime = true;
      }
      this.showSelectItem = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onDateConfirm(value){
      this.selectItem.date = this.formatDateTime(value);
      var now = new Date();
      if(this.formatDateTime(value) != this.formatDateTime(now)){
        this.minStartHour = 0;
        this.minStartMin = 0;
      }else {
         this.initSelectItem();
      }
      this.showSelectItem = true;
      this.showDate = false;
    },
    closeDate() {
      this.showSelectItem = true;
      this.showDate = false;
    },
    getDateCheck() {
      this.showDate = true;
      this.showSelectItem = false;
    },
    refreshParkingData(){
      // console.log('refresh', this.selectItem);
      if(this.type === '所有车位') {
        this.clearItem();
      }
      this.current = 1;
      this.getParkingData(false);
      this.$refs.list.scrollTop = 0;
    },
    handleSearchClear() {
      this.refreshParkingData()
    },
    getParkingData(flag) {
      // console.log('current', this.selectItem)
      const params = {
          page: this.current,
          size: 10,
          object: this.selectItem
      };
      if (this.selectItem.startTime != "" &&  this.selectItem.overTime != ""){
        this.selectItem.startTime = this.selectItem.startTime + ":00";
        this.selectItem.overTime = this.selectItem.overTime + ":00";
      }
      // flag用来判断是刷新还是新增
      this.$store.dispatch("llcw/getInitParkData", { flag: flag, data: params, obj: this });
      // console.log(this.selectItem);
    },

    loadBottom() {
        this.current = this.current + 1;
        this.getParkingData(true);
    },
    loadTop() {
      this.refreshParkingData();
    },

    gotoLink(item, info) {
      this.$router.push({name:'cwyy',params: {item: item, info: info}});
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    gotoDetail(info) {
      this.$router.push("gjxxq");
    },
    areaConfirm(value, index) {
      this.community = value.communityName;
      this.selectItem.communityId = value.communityId;
      this.showArea = false;
      this.refreshParkingData();
    },

    sortConfirm(value, index) {
      if (value == '综合排序'){
        this.selectItem.order = "";
      }
      if (value == "正序"){
        this.selectItem.order = "asc";
      }
      if (value == "倒序"){
        this.selectItem.order = "desc";
      }
      this.sort = value;
      this.showSort = false;
      this.refreshParkingData();
    },

    showPicker() {
      this.showArea = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .search-bar {
    display: flex;
    align-items: center;
    .input-bar {
      min-width: 7rem;
      background: #f7f8fa;
      border-radius: 18px;
      padding: 5px 12px;
      line-height: 24px;
      height: 34px;
      font-size: 14px;
      text-align: left;
     
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        height: 1rem;
        width: 1rem;
      }
    }
    .normal-bar {
      height: 2rem;
      line-height: 2rem;
      margin: 0.2rem 0.8rem;
      font-size: 14px;
    }
  }
  .list {
    width: 100%;
    height: calc(100% - 5.5rem);
    overflow-y: auto;
    overflow-x: hidden;
    .card-con {
      display: flex;
      align-items: center;
      padding: 0.4rem 0.6rem;
      position: relative;
      margin-bottom: 0.5rem;
      .tag {
        position: absolute;
        top: 0.4rem;
        left: 0.6rem;
        padding: 0.2rem 0.6rem;
        border-radius: 4px;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 1.8rem;
        font-weight: 500;
        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 5.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
       
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;
       
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .card-button {
        position: absolute;
        top: 0.4rem;
        right: 0.8rem;
        width: 5rem;
        height: 2rem;
        background: #4479ed;
        color: #ffffff;
        text-align: center;
        line-height: 2rem;
        border-radius: 4px;
      }
    }
  }
}
</style>
