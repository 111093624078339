<template>
  <div class="dhresult-container">
    <van-nav-bar
      title="兑换详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <van-image width="60" height="60" src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/成功.png" />
      <h1 class="title-text" style="margin-bottom: 40px;margin-top: 20px;">{{`您已成功兑换“${name}”${count}个`}}</h1>
      <van-button round @click="gotoLink('dhjl')" type="info" style="width: 100%;">查看兑换记录</van-button>
      <van-button round @click="gotoLink('jfdh')" type="info" plain style="width: 100%;margin-top: 20px;">返回首页</van-button>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      name: '',
      count: 0,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { name = '', count = 0 } = this.$route.query;
    this.name = name;
    this.count = count;
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.dhresult-container {
  height: 100vh;
  width: 100vw;
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: #FFF;
    padding: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
}
</style>