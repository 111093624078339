<template>
  <div class="offline-container">
    <van-nav-bar
      title="线下送教上门"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="offline-study-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/decoration-left.png" alt="">
        <b>线下送教上门</b>
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/decoration-right.png" alt="">
      </div>
      <!-- 简介 -->
      <p class="offline-info">线下送教上门服务介绍文案线下送教上门服务介绍文案线下送教上门服务介绍文案线下送教上门服务介绍文案</p>
      <img class="offline-bg" src="http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/offline-bg.png" alt="">
      <b class="bg-title">足不出户 一键送教</b>
      <div class="offline-footer-btn">
        <van-button
          block
          type="info"
          style="font-size: 18px;"
        >
          一键送教
        </van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.offline-container {
  height: 100vh;
  width: 100vw;
  
  .offline-study-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .top-bar {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      >img {
        height: 12px;
      }
      >b {
        font-size: 18px;
        color: #4479ED;
        font-weight: 500;
        margin: 0 6px;
      }
    }
    .offline-info {
      margin: 0 16px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      line-height: 24px;
    }
    .offline-bg {
      width: 100%;
      position: absolute;
      bottom: 148px;
    }

    .bg-title {
      font-size: 20px;
      color: #4479ED;
      font-weight: 600;
      position: absolute;
      bottom: 128px;
    }

    .offline-footer-btn {
      position: absolute;
      bottom: 16px;
      right: 16px;
      left: 16px;
    }


  }
}
</style>
