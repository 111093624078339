<template>
  <div id="index">
    <div class="gjxxq-con">
      <van-nav-bar
        title="工具箱详情"
        left-arrow
        @click-left="goBack"
      />
      <div class="top-con">
        <div class="detail-img">
          <img :src="questionInfo.invitationContent || 'http://dssq.ikeqiao.net/data/image/h5/images/banner/gjx_banner.png'" />
        </div>
        <div class="card-con">
          <div>
            <div class="card-title">
              <span>{{ questionInfo.invitationTitle }}</span>
            </div>
            <div class="row">
              <div class="label">数量<i></i></div>
              <div>：{{ questionInfo.toolNumber }}</div>
            </div>
            <div class="row">
              <div class="label">分享人<i></i></div>
              <div ref="name" :title="questionInfo.createUserName">
                ：{{ addName }}
              </div>
            </div>
            <div class="row">
              <div class="label">位置<i></i></div>
              <div ref="place" :title="questionInfo.toolPlace">
                ：{{ toolPlace }}
              </div>
            </div>
            <div class="row" v-if="questionInfo.permitRent === '2'">
              <div class="label">联系电话<i></i></div>
              <div ref="phone1" @click="callPhone(questionInfo.createUserPhone)" :title="questionInfo.createUserPhone">
                ：{{ questionInfo.createUserPhone }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="tab">工具介绍</div>
        <div class="content">
          <p>{{ questionInfo.toolDescribe }}</p>
        </div>
      </div>
    </div>
    
    <div v-if="questionInfo.permitRent === '允许借用'" class="foot-button" @click="handleBorrow">
      申请借用
    </div>
    <div v-else-if="questionInfo.permitRent === '1'" class="foot-button gray">
      申请审核中
    </div>
    <div v-else-if="questionInfo.permitRent === '2'" @click="callPhone(questionInfo.createUserPhone)" class="foot-button">
      申请已通过，请电话联系借用
    </div>
    <div v-else-if="questionInfo.permitRent === '3'" class="foot-button">
      申请未通过，请查看
    </div>
  </div>
</template>

<script>
import gjxApi from "api/gjx";
import { mapState, mapMutations } from "vuex";
import { Toast, Dialog } from "vant";

export default {
  data() {
    return {
      addName: "",
      toolPlace: "",
      questionInfo: {},
    };
  },
  computed: {
    // ...mapState({
    //   questionInfo: (state) => {
    //     return state.llyjr.questionInfo;
    //   },
    // }),
  },
  mounted() {
    let showname = this.$refs.name.title;
    this.addName = showname.substr(0, 1) + "**";

    let place = this.$refs.place.title;
    this.toolPlace = place.substr(0, 4) + "****";
    const { invitationId } = this.$route.query;

    // let phone = this.$refs.phone1.title;
    // console.log('phone', phone)
    // this.phone = phone.substr(0,3) + '****' + phone.substr(7);

    gjxApi.getDetail({
      invitationId,
    }).then(res => {
      if(res.flag) {
        this.questionInfo = res.data;
      }
    })
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        gjxApi
          .rentTool({ invitationId: this.questionInfo.invitationId })
          .then((res) => {
            if (res.data == 1) {
              // Toast.success("借用成功");
              done();
            }
          });
      } else {
        done();
      }
    },
    callPhone(phone) {
      window.location.href = `tel://${phone}`;
    },
    handleBorrow() {
      // this.$store.dispatch("login/checkRealName", () => {
      //   Dialog.confirm({
      //     title: "借用提示",
      //     message: "借用工具将电话联系分享人，是否继续借用？",
      //     confirmButtonText: "联系借用",
      //     confirmButtonColor: "#2e70ed",
      //     cancelButtonText: "取消",
      //     cancelButtonColor: "#aaa",
      //   })
      //     .then(() => {
      //       this.beforeClose("confirm",() => {
      //         window.location.href = `tel://${this.questionInfo.createUserPhone}`;
      //       });
      //     })
      //     .catch(() => {
      //       Toast("取消借用");
      //     });
      // });
      if(this.questionInfo.shareTool) {
        Toast('无法借用您自己的工具')
      } else {
        this.$router.push(`borrowApply?invitationId=${this.questionInfo.invitationId}`)
      }
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";

.mint-msgbox {
  border-radius: 0.8rem;
  width: 75%;

  .mint-msgbox-content {
    padding: 10px 15%;

    .mint-msgbox-message {
      color: #333;
    }
  }
}
</style>
