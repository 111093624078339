<template>
  <div id="index">
    <div class="zyya-page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>志愿有爱</h1>
      </div>
      <div class="blue-background">
        <img style="width: 100vw;" src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/zyya-bg.jpg" />
        <!-- 志愿有爱 -->
        <div class="huigong-bar">
          <div :class="['huigong-bar-title', { opened: show }]">
            <div class="decoration-title">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-l.png" alt="">
              <h1>志愿有爱</h1>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-r.png" alt="">
            </div>
            <p>{{ desc }}</p>
            <div class="organization-button" @click="show = !show">
              <span>{{ show ? "收起" : "展开" }}</span>
              <img
                src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
                :class="['organization-arrow', { rollover: show }]"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 风采展示 -->
        <div class="hgxt-card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">风采展示</span>
             <!-- <span class="text-more" @click="gotoLink('kghList')">
              更多
              <van-icon name="arrow" color="#bfbfbf" size="14px" />
            </span> -->
          </div>
          <div class="card-bar-content">
            <ul>
              <li class="fl-item" v-for="(item, index) in list" :key="index" @click="openArticleUrl(item)">
                <div class="fl-title">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="fl-desc">
                      <i class="time"></i>
                      <span style="margin-right: 10px;">{{item.updateTime}}</span>
                      <!-- <i class="view"></i>
                      <span>{{item.view}}</span> -->
                    </div>
                  </div>
                  <img v-if="item.cover" :src="item.cover" alt="">
                </div>
                
              </li>
            </ul>
          </div>
        </div>
        <!-- 活动信息 -->
        <!-- <div class="hgxt-card-bar">
          <div class="card-bar-title">
            <span class="text-title">活动信息</span>
            <span class="text-more">
              更多
              <van-icon name="arrow" color="rgba(0,0,0,0.45)" size="14px" />
            </span>
          </div>
          <div class="hgxt-activity-list">
            <div :style="{width: activityList ? `${activityList.length * 262}px` : '100%',display: 'flex'}">
              <div v-for="(item, index) in activityList" :key="`${item.id}_${index}`" class="hgxt-activity-card">
                <img :src="item.imgUrl" class="hgxt-activity-image"/>
                <div class="hgxt-activity-title-1">
                  <h1>{{item.title}}</h1>
                  <span>已报名<b>{{item.applyNum}}</b></span>
                </div>
                <div class="hgxt-activity-text">
                  <span class="text-label">活动时间：</span>
                  <span>{{item.date}}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
export default {
  data() {
    return { 
      show: false,
      desc: '“柯工慧”志愿服务是浣东街道总工会的志愿服务品牌,作为志愿服务的一个新兴品牌，“柯工慧”以智慧云平台建设为契机，以“慧智、汇融、惠工”为服务理念，以劳模工匠志愿队为特色，进一步汇聚各方力量，融合各方志愿队伍，尤其是发动企业志愿者力量，形成社企联动。东盛站作为“柯工慧”志愿服务中心的启航地，于2021年3月成立“东盛E家”邻里志愿服务联盟。自“柯工慧”志愿服务站启动以来，东盛站已组织开展“三五学雷锋、工青志愿行”等系列活动，邀请资深志愿者分享志愿服务体会，同时结合街道中心工作，先后开展“越惠保”、反诈宣传、防疫宣传等志愿服务。',
      list: [
        // {
        //   id: 0,
        //   title: "【瓜渚专栏】领跑竞跑 志愿启航 | 最美的遇见，从“柯工慧”开始",
        //   update: "2021-03-02",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/kgh/zyya-bg.jpg",
        //   url: 'https://mp.weixin.qq.com/s/eWu6doQKrHcYMUQaMBfBRw'
        // },
        // {
        //   id: 1,
        //   title: "【瓜渚专栏】“我”将成为“你”，志愿会延续 | 三五学雷锋，工青志愿行",
        //   update: "2021-03-03",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/kgh/zyya2.jpg",
        //   url: 'https://mp.weixin.qq.com/s/MLJqs2gOhBdSW_NXoYLu5A'
        // },
      ],
      activityList: [
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "0",
          time: "剩5日20小时开始",
          applyNum: "10",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "1",
          time: "剩20分钟结束",
          applyNum: "200",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "2",
          time: "",
          applyNum: "30",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
      ]
    };
  },
  components: {},
  mounted() {
    this.getDataList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    openUrl(url) {
      if(url) {
        window.location.href = url;
      }
    },
    openArticleUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    getDataList() {
      Api.getHongArticleList({
        page: 1,
        pageSize: 20,
        type: 8,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        this.list = records;
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.blue-background {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 0;
  height: 100vh;
  .huigong-bar-title {
    width: calc(100% - 30px);
    // background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/organization-bg.png) no-repeat;
    // background-size: 100% 100%;
    background: #FFF;
    border-radius: 4px;
    padding: calc(2rem - 15px);
    color: rgba(0,0,0,0.85);
    margin: -50px 15px 15px 15px;
    position: relative;
    z-index: 2;
    .decoration-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 10px;
      > h1 {
        font-size: 18px;
        color: #4479ED;
        font-weight: 600;
        margin: 0 10px;
      }
      >img {
        height: 14px;
      }
    }
    
    > p {
      width: calc(100vw - 4rem);
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0;
      max-height: 60px;
      min-height: 60px;
      margin-bottom: 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .organization-button {
      margin: 0px auto;
      width: 60px;
      height: 24px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 12px;
      color: #4479ed;
      line-height: 24px;
      text-align: center;
      font-size: 14px;
      > img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .opened {
    > p {
      max-height: 100vh;
    }
    .organization-button {
      > img {
        transform: rotate(180deg);
      }
    }
  }
  .hgxt-card-bar {
    width: 100%;
    margin: 10px 0;
    padding: 6px 0;
    .card-bar-title {
      width: 100%;
      height: 33px;
      padding: 10px;
      background: #fff;
      .text-title {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        width: 50%;
      }
      .text-more {
        color: #bfbfbf;
        font-size: 14px;
        float: right;
        display: flex;
        align-items: center;
      }
    }
    .card-bar-content {
      width: 100%;
      .fl-item {
        margin: 10px 10px 0 10px;
        padding: 10px 0;
        .fl-title {
          display: flex;
          align-items: flex-start;
          >img {
            width: 7rem;
            margin-left: 10px;
          }
          >div {
            flex: 1;
            >h1 {
              font-size: 16px;
              line-height: 32px;
              color: rgba(0,0,0,0.85);
              font-weight: 500;
            }
            .fl-desc {
              display: flex;
              height: 24px;
              align-items: center;
              font-size: 12px;
              color: rgba(0,0,0,0.45);
              .time, .view {
                width: 16px;
                height: 16px;
                display: inline-block;
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                background-size: 100% 100%;
              }
              .view {
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          
        }
        
      }
    }
    .hgxt-activity-list {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px;
      background-color: #FFF;
      .hgxt-activity-card {
        width: 252px !important;
        border-radius: 4px;
        margin-right: 10px;
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
        .hgxt-activity-image {
          width: 100%;
          height: 87px;
        }
        .hgxt-activity-title-1 {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 10px;
          >h1 {
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            font-weight: 500;
          }
          >span {
            font-size: 12px;
            color: rgba(0,0,0,0.65);
            >b {
              color: #4479ed;
            }
          }
        }
        .hgxt-activity-text {
          font-size: 14px;
          line-height: 24px;
          color: rgba(0,0,0,0.85);
          padding: 0 10px;
          .text-label {
            color: rgba(0,0,0,0.45);
          }
        }
      }
    }
  }
  
}
</style>
