<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>微跑管家</h1>
      </div>
      <div class="manage-contnet">
        <h3>管家上门代办事项增幅达167%</h3>
        <div class="desc">
          2019年，浣东街道作为全区试点，率先探索实施“管家跑上门”服务新模式，为辖区内特殊群众(老弱病残)提供上门代办业务，实现“最多跑一次”到“管家跑上门”的转变。截止目前，共有管家团成员68人，志愿者185人，开展上门代办130余次，服务人数超530人次。
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-1.jpg" /></div>
        <div class="desc">
          近日，为进一步深化“三服务”，延伸服务半径，我们又在初步筛选137项基层民生事项的基础上，精选“代办”事项，将上门代办事项从6项，调整为16项,增幅达167%。涵盖民政、水务、法律、社保、公安等三大类别7个子项，确保服务供有所需、需有所用。
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-2.jpg" /></div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-3.jpg" /></div>
        <h3>服务基层类</h3>
        <div class="tab">民政——养老服务补贴办理</div>
        <div class="box">
          <div>
            <p><b>办理条件：</b></p>
            <p>1、具有浣东街道户籍；</p>
            <p>2、年满80周岁；</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道初审—区民政复核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、《绍兴市诸暨市养老服务补贴申请表》原件两份；</p>
            <p>2、本人居民户口簿复印件两份、身份证复印件两份、一寸彩照两张；</p>
            <p>3、《绍兴市诸暨市养老服务补贴申请对象公示》原件两份</p>
            <p>4、《绍兴市诸暨市养老服务补贴申请对象公示回执》原件两份</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab">民政——智慧养老一键通办理</div>
        <div class="box">
          <div>
            <p><b>办理条件：</b></p>
            <p>1、具有浣东街道户籍；</p>
            <p>2、年满80周岁；</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道初审—区民政复核—电信局领一键通电话</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、《绍兴市诸暨市智慧养老一键通申请表》原件两份；</p>
            <p>2、本人居民户口簿复印件两份、身份证复印件两份；</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab green">水务——水费缴纳</div>
        <div class="box green">
          <div>
            <p><b>办理条件：</b></p>
            <p>诸暨市范围内的居民</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道初审—水务窗口复核—办结</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab green">水务——水表验表申请</div>
        <div class="box green">
          <div>
            <p><b>办理条件：</b></p>
            <p>诸暨市范围内水表出现异常的居民</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道初审—水务窗口复核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>居民身份证</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab red">妇联——妇儿维权政策咨询、婚姻家庭纠纷调解</div>
        <div class="box red">
          <div>
            <p><b>办理条件：</b></p>
            <p>涉及到婚姻家庭纠纷的妇儿及家庭</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道初审—“伊柯有约”工作室复核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>身份证、结婚证、申请表等</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-4.jpg" /></div>
        <h3>服务百姓类</h3>
        <div class="tab org">社保——城乡居民基本养老保险参保登记</div>
        <div class="box org">
          <div>
            <p><b>办理条件：</b></p>
            <p>1、具有诸暨市户籍；</p>
            <p>2、年满16周岁（全日制学校在校学生除外）；</p>
            <p>3、非国家机关、事业单位、社会团体工作人员；</p>
            <p>4、未参加职工基本养老保险的城乡居民。</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、《城乡居民基本养老保险参保登记表》原件一份；</p>
            <p>2、本人居民户口簿原件、身份证原件或社会保障卡；</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab org">社保——申请补缴城乡居民基本养老保险费</div>
        <div class="box org">
          <div>
            <p><b>办理条件：</b></p>
            <p>1、具有诸暨市户籍；</p>
            <p>2、参保人员年满60周岁累计缴费不足15年的。</p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、《浙江省城乡居民基本养老保险费补缴申请表》；</p>
            <p>2、本人居民户口簿原件、身份证原件或社会保障卡；</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab org">社保——城乡居民基本养老保险待遇核准支付</div>
        <div class="box org">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              诸暨市参加城乡居民基本养老保险并符合领取养老待遇条件的城乡居民
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、本人居民户口簿原件、身份证原件或社会保障卡；</p>
            <p>2、瑞丰银行银行卡原件。</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab prup">计生——《生育登记服务卡》办理</div>
        <div class="box prup">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              生育子女数合计不超过1个的夫妻
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、夫妻双方居民户口本原件、身份证原件、结婚证原件；</p>
            <p>2、夫妻双方均为非我区户籍的，还需提供《浙江省居住证》</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab prup">计生——四项手术优惠券申领</div>
        <div class="box prup">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              育龄妇女上、取环、流产、引产
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、夫妻双方居民户口本原件、身份证原件、结婚证原件；</p>
            <p>2、夫妻双方均非我户籍的，还需提供《浙江省居住证》</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab blue">公安——《身份证上门》代办</div>
        <div class="box blue">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              残疾人、高龄、不便出门的特殊群体
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b>84125980</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab blue">公安——新生儿出生申报</div>
        <div class="box blue">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              户籍信息变更（市内迁移、婚姻、文化程度、工作单位等信息）
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab blue">公安——身份证挂失</div>
        <div class="box blue">
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—街道审核—办结</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-5.jpg" /></div>
        <h3>服务企业类</h3>
        <div class="tab pink">《团员关系》转接</div>
        <div class="box pink">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              新就业或刚毕业团员
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—楼宇企业服务中心审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>1、《城乡居民基本养老保险参保登记表》原件一份；</p>
            <p>2、本人居民户口簿原件、身份证原件或社会保障卡；</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab pink">高校毕业生各类补贴申领</div>
        <div class="box pink">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              符合申请条件的新就业大学生
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—楼宇企业服务中心审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>劳动合同、租房协议、毕业证书等</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
        <div class="tab pink">展会补贴、民营经济发展专项资金等各类补助申领</div>
        <div class="box pink">
          <div>
            <p><b>办理条件：</b></p>
            <p>
              符合申请条件的企业
            </p>
          </div>
          <div>
            <p><b>办事流程：</b></p>
            <p>微跑管家上门办理—楼宇企业服务中心审核—办结</p>
          </div>
          <div>
            <p><b>申报资料：</b></p>
            <p>营业执照、展会申请表、工资单等</p>
          </div>
        </div>
        <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/w-6.gif" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
