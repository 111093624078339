<template>
  <div id="index">
    <div class="myInvite-container">
      <div class="header-nav" style="display: flex">
        <img @click="goBack" style="width: 20px; height: 20px; margin: 10px" src="http://dssq.ikeqiao.net/data/image/h5/images/myInvite/leftWhite.png" />
        <h1 style="margin-left: 7.5%">活动抽奖</h1>
        <div class="header-share" @click="showShare(true)">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/lucky/share.svg" />
          <div>分享</div>
        </div>
      </div>
      <div class="myInvite-banner" :style="`background-image: url('${require('../../assets/turntableBannerBg.png')}');`">
        <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/lucky/firstD.svg" /> -->
      </div>
      <div class="turnTableContent">
        <div class="title" v-if="timeStatus !== '已结束'">
          <div v-if="loginDays > 0">{{showStartTime || '11-08 00:00'}}至{{showEndTime || '11-14 19:30'}}前</div>
          <div v-if="loginDays > 0">累计再登录{{loginDays > 0 ? loginDays : 0}}天，即可获得抽奖资格</div>
          <div v-if="loginDays <= 0">您已获得抽奖资格</div>
        </div>
        <div v-if="timeStatus !== '已结束'" class="timeCount" :style="`background-image: url('${require('../../assets/timerCount.png')}');`">
          {{timeStatus === '未开始' ? '距抽奖开始' : '距抽奖结束'}}
          <span class="num">{{timeShow}}</span>
        </div>
        <div style="text-align: center" v-if="timeStatus === '已结束'" class="timeCount" :style="`background-image: url('${require('../../assets/timerCount.png')}');`">
          抽奖已结束
        </div>
        <div class="turnTable">
          <turnTable
            :timeStatus="timeStatus"
            :award="award"
            :surplusNumber="surplusNumber"
            :luckyActiveItem="luckyActiveItem"
            @onTurnChange="turnChange"
          />
        </div>
      </div>
      <div class="descContent">
        <div class="titleBar" :style="activePage === 1 ? `background: url(${bannerBg1})` : `background: url(${bannerBg2})`">
          <div class="item" :style="{ color: activePage === 1 ? '#3833D7' : '#fff' }" @click="changeActiveItem(1)">抽奖规则</div>
          <div class="item" :style="{ color: activePage === 2 ? '#3833D7' : '#fff' }" @click="changeActiveItem(2)">抽奖奖品</div>
        </div>
        <div class="descript" v-if="activePage === 1">
          <div class="textContent" v-html="luckyActiveItem.activityDescription">
            <!-- <div class="title">1. 抽奖条件</div>
            <div>
              每轮活动周期内,
              <span style="color: #FFE002">累计登录天数≥3天</span>
              ,且满足条件的用户即可进行抽奖
            </div>
            <div class="title">2. 活动时间</div>
            <div>2021年11月8日 00:00至11月14日 19:30</div>
            <div class="title">3. 抽奖时间</div>
            <div>2021年11月14日 19:30-21:30</div>
            <div class="title">4. 活动说明</div>
            <div>(1)本轮抽奖活动每个用户最多只能抽一次；</div>
            <div>(2)本次活动最终解释权归东盛社区所有。</div> -->
          </div>
        </div>
        <div class="prizeList" v-if="activePage === 2">
          <div class="item" v-for="(item, index) in prizeList" :key="index">
            <div class="text">
              <div class="title">{{item.awardName}} {{item.awardNumber}}名</div>
              <div>{{item.prizeName}}</div>
            </div>
            <div class="image"><img :src="item.prizeImage" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wdjp" @click="gotoLink('/luckWdjp?key=' + luckyActiveItem.lid)">我的奖品</div>
    <div v-if="luckyActiveItem.ptype === '自定义' || luckyActiveItem.ptype === '链接'" class="xqyg" @click="gotoLink('/luckXqyg?key=' + luckyActiveItem.lid)">下期预告</div>
    <div v-if="shareVisible" class="shareModal">
      <div class="sharePoint"><img src="http://dssq.ikeqiao.net/data/image/h5/images/lucky/sharePoint.svg" /></div>
      <div class="shareContent">
        <div>请点击右上角“···”</div>
        <div>分享给好友或者朋友圈</div>
      </div>
      <div class="closeBtn"  @click="showShare(false)">关闭</div>
    </div>
    <div class="tipsShowList" v-if="tagsShowList.length > 0">
      <div class="tipsContent" :style="`top: -${count * 40}px`">
        <div class="item" v-for="(item, i) in tagsShowList" :key="i">
          <div>恭喜</div>
          <img :src="item.image" />
          <div>{{`${item.nickName} 抽中 ${item.prizeName}`}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import luckyApi from 'api/lucky';
import { mapState } from 'vuex';
import context from '../../main';
import turnTable from './turntable2';
import moment from 'moment';
import { sharePage } from 'utils';
export default {
  data() {
    return {
      bannerBg1: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/bannerChoice1.svg',
      bannerBg2: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/bannerChoice2.svg',
      activePage: 1,
      prizeList: [
        { awardName: '一等奖 1名', prizeName: '小米空气净化器', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/kqjhj.png' },
        { awardName: '二等奖 5名', prizeName: '小米电饭煲', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/dfb.png' },
        { awardName: '三等奖 20名', prizeName: '西王胚芽油', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xwpyy.png' },
      ],
      checkStatus: '',
      startTime: '',
      endTime: '',
      timeStatus: '未开始',
      timeShow: '',
      timer: null,
      shareVisible: false,
      award: [{},{},{},{},{},{},{},{}],
      drawNumber: 0,
      loginDays: 0,
      surplusNumber: 0,
      luckyActiveItem: {},
      showStartTime: '',
      showEndTime: '',
      tagsShowList: [],
      count: 0,
    };
  },
  components: {
    turnTable,
  },
  mounted() {
    const { checkStatus, userId } = this.userInfo || {};
    const { key } = this.$route.query;
    this.checkStatus = checkStatus;
    moment.locale('zh-cn')
    luckyApi.drawAwardSetListByLid({ lId: key }).then((res = {}) => {
      const list =  res.data || [];
      const arr = [];
      for (let index = 0; index < 8; index++) {
        arr.push({ awardName: '谢谢参与!' });
      }
      this.award = list && list.length > 0 ? list.sort((a, b) => a.awardLocation - b.awardLocation) : arr;
      console.log(this.award);
    });
    luckyApi.drawPrizeInformationListByLid({ lId: key }).then((res = {}) => {
      this.prizeList =  res.data || [];
    });
    luckyApi.getPrizeByLid({ lId: key }).then((res = {}) => {
      this.tagsShowList =  res.data || [];
      if (this.timmer2) {
          clearInterval(this.timmer2);
          this.timmer2 = null;
        }
        this.timmer2 = setInterval(() => {
          if (this.count === this.tagsShowList.length - 1) {
            this.count = 0;
          } else {
            this.count += 1;
          }
        }, 4000);
    });
    luckyApi.drawLotteryInformationListPage({
        sort: 1,
        lid: key,
      }).then((res) => {
        const { data = {} } = res;
        const { records = [] } = data;
        console.log(records);
        this.luckyActiveItem = records.length > 0 ? records[0] : {};
        this.showStartTime = this.luckyActiveItem.loginStartTime ? moment(this.luckyActiveItem.loginStartTime).format('MM-DD HH:mm') : '';
        this.showEndTime = this.luckyActiveItem.loginEndTime ? moment(this.luckyActiveItem.loginEndTime).format('MM-DD HH:mm') : '';
        this.startTime = this.luckyActiveItem.luckStartTime || '';
        this.endTime = this.luckyActiveItem.luckEndTime || '';
        if (this.timmer) {
          clearInterval(this.timmer);
          this.timmer = null;
        }
        this.timmer = setInterval(() => {
          this.getTime(this.startTime, this.endTime);
        }, 1000);
        this.loginDays = this.luckyActiveItem.loginDays;
        sharePage(key, `luckTurnTable`, { orderBaseInfo: { title: this.luckyActiveItem.luckTitle ? `【抽奖】${this.luckyActiveItem.luckTitle}` : '', imageIcon: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/shareIcon.png' } });
        luckyApi.getDrawUserInformation({ lId: key, infoId: userId }).then((res = {}) => {
          const { data = {} } = res;
          this.drawNumber = data.drawNumber || 0;
          this.surplusNumber = data.surplusNumber || 0;
          this.loginDays = (this.luckyActiveItem.loginDays || 0) - (data.loginDays || 0);
          if (data.loginDays < this.luckyActiveItem.loginDays) {
            this.surplusNumber = 0;
          }
        });
      });
  },
  destroyed(){
    console.log('destroyed');
    if (this.timmer) {
      clearInterval(this.timmer);
      this.timmer = null;
    }
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    getTime(startTime, endTime){
      if (moment().isBefore(startTime)) {
        this.timeStatus = '未开始';
        const data = moment(startTime).diff(moment(),'days' );
        const time = moment(moment(startTime).diff(moment()));
        const h = Math.floor(Number(time)%(1000 * 60 * 60 * 24)/(1000 * 60 * 60));
        const m = Math.floor(Number(time)%(1000 * 60 * 60)/(1000 * 60));
        const s = Math.floor(Number(time)%(1000 * 60)/1000);
        this.timeShow = `${data}天 ${h < 10 ? '0' + h : h }:${m < 10 ? '0' + m : m }:${s < 10 ? '0' + s : s }`
      } else if (moment(startTime).isBefore(moment()) && moment().isBefore(endTime)) {
        const data = moment(endTime).diff(moment(new Date()),'days' );
        const time = moment(moment(endTime).diff(moment(new Date())));
        const h = Math.floor(Number(time)%(1000 * 60 * 60 * 24)/(1000 * 60 * 60));
        const m = Math.floor(Number(time)%(1000 * 60 * 60)/(1000 * 60));
        const s = Math.floor(Number(time)%(1000 * 60)/1000);
        this.timeShow = `${data}天 ${h < 10 ? '0' + h : h }:${m < 10 ? '0' + m : m }:${s < 10 ? '0' + s : s }`
        this.timeStatus = '进行中';
      } else {
        this.timeShow = "0天 00:00:00";
        this.timeStatus = '已结束';
      }
    },
    goBack(){
          this.$router.go(-1);
    },
    changeActiveItem(type){
      this.activePage = type;
    },
    showShare(type){
      this.shareVisible = type;
    },
    turnChange(){
      const { userId } = this.userInfo || {};
      luckyApi.getDrawUserInformation({ lId: this.luckyActiveItem.lid, infoId: userId }).then((res = {}) => {
        const { data = {} } = res;
        this.drawNumber = data && data.drawNumber ? data.drawNumber : 0;
        this.surplusNumber = data.surplusNumber || 0;
        this.loginDays = (this.luckyActiveItem.loginDays || 0) - (data.loginDays || 0);
        if (data.loginDays < this.luckyActiveItem.loginDays) {
          this.surplusNumber = 0;
        }
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => (state.login.userInfo.nickname ? state.login.userInfo : null) || context.getSessionData('userInfo') || {},
      // luckyActiveItem: state => {
      //   return state.lucky.luckyActiveItem;
      // }
    })
  }
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.myInvite-container{
  padding-top: 2.7rem;
  padding-bottom: 10px;
  background-image: linear-gradient(179deg, #282DAE 1%, #0E1760 97%);
  .header-nav{
    background-image: linear-gradient(179deg, #282DAE 1%, #282DAE 97%);
    >h1{
      color: #fff;
    }
  }
  .header-share{
    position: absolute;
    right: 5px;
    top: 5px;
    img{
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
    >div{
      font-size: 12px;
      color: #fff;
    }
  }
  .myInvite-banner{
    position: relative;
    width: 100%;
    height: 270px;
    // background-image: url("../../assets/turntableBannerBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    >svg{
      height: 100%;
      width: 100%;
    }
    >img{
      width: 72px;
      height: 20px;
      position: absolute;
      left: calc(50% - 36px);
      top: 55px;
    }
  }
  .turnTableContent{
    width: 340px;
    margin: 10px auto;
    background-image: linear-gradient(180deg, #4A77FA 0%, #3832D6 100%);
    border-radius: 8px;
    padding: 14px;
    position: relative;
    .timeCount{
      position: absolute;
      width: 224px;
      height: 50px;
      line-height: 55px;
      padding-left: 55px;
      left: calc(50% - 112px);
      // background-image: url("../../assets/timerCount.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 400;
      .num{
        font-size: 12px;
        color: #fff900;
        font-weight: bold;
      }
    }
    .title{
      padding: 10px;
      width: 290px;
      line-height: 24px;
      background-image: linear-gradient(0deg, #1E27DE 0%, #4A3EFF 100%);
      border-radius: 28px;
      text-align: center;
      margin: 10px auto;
      margin-top: 0px;
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 500;
    }
    .turnTable{
      height: 362px;
    }
  }
  .descContent{
    width: 340px;
    margin: 10px auto;
    border-radius: 8px;
    .titleBar{
      border-top-right-radius: 8px;
      height: 50px;
      width: 100%;
      line-height: 50px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/lucky/bannerChoice1.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      .item{
        width: 50%;
        text-align: center;
        font-size: 18px;
        color: #3833D7;
        letter-spacing: 0.75px;
        font-weight: 500;
        &:last-child{
          color: #FFFFFF;
        }
      }
    }
    .descript{
      padding: 16px;
      background-image: linear-gradient(180deg, #4A77FA 0%, #3832D6 100%);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .textContent{
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 400;
        line-height: 24px;
        >div{
          padding-left: 16px;
        }
        .title{
          font-weight: 500;
          padding-left: 0px;
        }
      }
    }
    .prizeList{
      .item{
        margin-top: 10px;
        background-image: linear-gradient(180deg, #3833D7 0%, #3832D6 100%);
        border-radius: 8px;
        height: 100px;
        padding: 10px;
        display: flex;
        .text{
          width: calc(100% - 90px);
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
          .title{
            line-height: 30px;
            font-size: 18px;
            color: #FFE002;
            font-weight: 500;
          }
        }
        .image{
          width: 80px;
          height: 80px;
          margin-left: 10px;
          border-radius: 8px;
          background: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: space-around;
          >img{
            height: 48px;
          }
        }
      }
    }
  }
}
.wdjp{
  position: fixed;
  right: 0px;
  top: 300px;
  width: 28px;
  height: 84px;
  background-image: linear-gradient(0deg, #242AA2 0%, #4A3EFF 100%);
  box-shadow: 0px 2px 4px 0px rgba(22,30,120,0.4);
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.58px;
  text-align: center;
  line-height: 16px;
  font-weight: 500;
  padding: 10px 0px;
}
.xqyg{
  position: fixed;
  right: 0px;
  top: 390px;
  width: 28px;
  height: 84px;
  background-image: linear-gradient(0deg, #242AA2 0%, #4A3EFF 100%);
  box-shadow: 0px 2px 4px 0px rgba(22,30,120,0.4);
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.58px;
  text-align: center;
  line-height: 16px;
  font-weight: 500;
  padding: 10px 0px;
}
.shareModal{
  position: fixed;
  overflow: hidden;
  left: 0px;
  top: 0px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.70);
  padding: 40px 70px;
  .sharePoint{
    width: 42px;
    height: 42px;
    margin-left: auto;
    margin-right: 0px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  .shareContent{
    margin: 0 auto;
    color: #fff;
    font-size: 15px;
    width: 190px;
    padding: 10px;
    letter-spacing: 1px;
    line-height: 30px;
    border: 1px dashed rgba(255,255,255,0.8);
    border-radius: 8px;
    text-align: center;
  }
  .closeBtn{
    line-height: 32px;
    width: 80px;
    text-align: center;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    color: #fff;
    margin: 0 auto;
    margin-top: 10px;
  }
}
.tipsShowList{
  position: absolute;
  left: 0px;
  top: 100px;
  overflow: hidden;
  height: 40px;
  width: 100%;
  .tipsContent{
    position: absolute;
    left: 30px;
    right: 10px;
    top: 0px;
    transition: top 0.5s;
  }
  .item{
    // width: calc(100% - 30px);
    height: 40px;
    background: rgb(14,23,98,0.75);
    border-radius: 20px;
    display: flex;
    padding: 5px 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    >div{
      line-height: 30px;
      font-size: 14px;
      color: rgba(255,255,255,0.65);
      font-weight: 400;
    }
    img{
      width: 20px;
      height: 20px;
      margin: 5px;
      border-radius: 50%;
    }
  }
}
</style>
