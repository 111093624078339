<template>
  <div class="wrapper">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>报修服务</h1>
    </div>
    <div class="content">
      <div class="content-nav">
        <div
          class="content-nav-tab"
          :style="{
            background: tabKey === '1' ? '#fff' : '#FFF0C3',
            height: tabKey === '1' ? '3.625rem' : '3.5rem',
            borderRadius:
              tabKey === '1'
                ? '0.625rem 0.625rem 0.625rem 0'
                : '0.625rem 0 0.625rem 0',
            color: tabKey === '1' ? '#352300' : null,
          }"
          @click="onChangeTab('1')"
        >
          <div class="tab-image">
            <img
              style="width: 100%; height: 100%"
              fit="fill"
              :src="
                tabKey === '1' ? tabObj[0].selectedImg : tabObj[0].unselectedImg
              "
            />
          </div>
          <div class="tab-title">我的报修单</div>
        </div>
        <div
          class="content-nav-tab"
          :style="{
            background: tabKey === '2' ? '#fff' : '#FFF0C3',
            height: tabKey === '2' ? '3.625rem' : '3.5rem',
            borderRadius:
              tabKey === '2'
                ? '0.625rem 0.625rem 0.625rem 0'
                : '0 0.625rem 0 0.625rem',
            color: tabKey === '2' ? '#352300' : null,
          }"
          @click="onChangeTab('2')"
        >
          <div class="tab-image">
            <img
              style="width: 100%; height: 100%"
              fit="fill"
              :src="
                tabKey === '2' ? tabObj[1].selectedImg : tabObj[1].unselectedImg
              "
            />
          </div>
          <div class="tab-title">我要发布</div>
        </div>
      </div>
      <div
        v-if="tabKey === '1'"
        style="height:calc(100vh - 15.5rem);overflow: hidden"
      >
        <div class="content-list" v-infinite-scroll="scrolltolower">
          <div v-for="item in repairList" :key="item.id">
            <router-link
              tag="div"
              class="content-card"
              :to="{ name: 'repairDetail', query: { id: item.id } }"
            >
              <img
                class="content-card-img"
                fit="contain"
                :src="item.imageUrl ? JSON.parse(item.imageUrl)[0] : ''"
              />
              <div class="content-card-text">
                <div>{{ item.description }}</div>
                <div>{{ item.createDate }}</div>
              </div>
            </router-link>
          </div>
          <p
            class="content-label"
            v-if="triggered === 'more'"
            @click="scrolltolower"
          >
            加载更多
          </p>
          <p class="content-label" v-if="triggered === 'loading'">
            正在加载更多
          </p>
          <p
            class="content-label"
            v-if="triggered === 'noMore'"
            @click="scrolltolower"
          >
            没有更多数据了
          </p>
        </div>
      </div>
      <div v-if="tabKey === '2'" class="content-release-scroll">
        <div class="form-row">
          <el-input
            class="form-row-input"
            v-model="address"
            placeholder-style="color: #c8c8cc"
            placeholder="请填写故障地点"
          />
        </div>
        <div class="release-title">故障描述</div>
        <el-input
          type="textarea"
          style="height: 7.8125rem; padding: 0.8125rem 1.3125rem;width: calc(100% - 2.625rem);"
          resize="none"
          v-model="textValue"
          placeholder-style="color: #c8c8cc"
          placeholder="填写故障描述"
        />
        <div class="release-content-photo-wrap">
          <!-- <div v-for="(item, index) in imgUrl" :key="index">
            <div class="photo">
              <img style="width: 100%; height: 100%" fit="fill" :src="item" />
              <div class="close-icon" @click="deletePhoto(index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div> -->
          <van-uploader
            v-model="imgUrl"
            name="imageList"
            multiple
            :max-count="3"
          />
          <!-- <el-upload
            class="photo"
            action="#"
            :show-file-list="false"
            :before-upload="uploadPhoto"
          >
            <i class="el-icon-camera-solid icon-camera"></i>
            <div class="photo-desc">上传照片</div>
          </el-upload> -->
          <!-- <div class="photo" @click="uploadPhoto"></div> -->
        </div>

        <div class="form-row">
          <el-input
            v-model="contactName"
            class="form-row-input"
            placeholder-style="color: #c8c8cc"
            placeholder="请填写联系人"
          />
        </div>
        <div class="form-row">
          <el-input
            v-model="contactPhone"
            class="form-row-input"
            placeholder-style="color: #c8c8cc"
            placeholder="请填写联系人电话"
          />
        </div>
        <el-button class="form-btn" :disabled="false" @click="submit">
          发布
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Toast } from "vant";
import repairApi from "api/repair";
import unselectedImg1 from "../../assets/images/repair/tab-three-head.png";
import selectedImg1 from "../../assets/images/repair/tab-three-head.png";
import unselectedImg2 from "../../assets/images/repair/tab-four-head.png";
import selectedImg2 from "../../assets/images/repair/tab-four-head-active.png";

// import { pathToBase64 } from "../../utils/image-tools/index.js";
export default {
  data() {
    return {
      tabKey: "1",
      tabObj: [
        {
          unselectedImg: unselectedImg1,
          selectedImg: selectedImg1,
        },
        {
          unselectedImg: unselectedImg2,
          selectedImg: selectedImg2,
        },
      ],
      triggered: "more",
      pageSize: 5,
      contentText: {
        more: "加载更多",
        loading: "正在加载更多",
        noMore: "没有更多数据了",
      },
      address: "",
      textValue: "",
      imgUrl: [],
      contactName: "", // 联系人
      contactPhone: "", // 联系电话
    };
  },
  computed: {
    ...mapState({
      repairList: (state) => state.repair.repairList,
    }),
    disabled() {
      return (
        !this.address ||
        !this.textValue ||
        this.imgUrl.length === 0 ||
        !this.contactName ||
        !this.contactPhone
      );
    },
  },
  methods: {
    ...mapActions({
      getRepairList: "repair/getRepairList",
      saveRepairInfo: "repair/saveRepairInfo",
      uploadFile: "repair/uploadFile",
    }),
    onChangeTab(key) {
      this.tabKey = key;
    },
    scrolltolower() {
      if (this.triggered === "noMore" || this.triggered === "loading") return;
      this.triggered = "loading";
      this.getList({ size: this.pageSize });
    },
    // async uploadPhoto(file) {
    //   const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    //   if (!isJPG) {
    //     Toast.fail("照片只能是 JPG/PNG 格式!");
    //     return false;
    //   }
    //   this.uploadFile({
    //     files: file,
    //     callback: (data) => {
    //       this.imgUrl = [...this.imgUrl, data];
    //     },
    //   });
    // },
    deletePhoto(key) {
      this.imgUrl = this.imgUrl.filter((_, index) => index !== key);
    },
    getList(params) {
      this.getRepairList({
        pageNum: 1,
        pageSize: params.size,
        callback: (total) => {
          if (total >= this.pageSize) {
            this.triggered = "more";
            this.pageSize += 5;
          } else {
            this.triggered = "noMore";
          }
        },
      });
    },
    async submit() {
      const formData = new FormData();
      this.imgUrl.map((item) => formData.append("files", item.file));
      formData.append("split", "#");
      const { data } = await repairApi.uploadFile(formData);
      console.log(data);
      this.saveRepairInfo({
        address: this.address,
        description: this.textValue,
        imageUrl: (data || '').split("#"),
        contactName: this.contactName,
        contactPhone: this.contactPhone,
        callback: () => {
          Toast.success("发布成功");
          this.tabKey = "1";
          // 表单重置
          this.address = "";
          this.textValue = "";
          this.imgUrl = [];
          this.contactName = "";
          this.contactPhone = "";

          this.getList({ size: this.pageSize });
        },
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.getList({ size: this.pageSize });
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex: auto;
  height: 16.75rem;
  margin-top: 2.7rem;
  padding-top: 6.625rem;
  background-image: url("../../assets/images/repair/repair-bg.jpg");
  background-size: 100% 100%;
  .content {
    width: calc(100vw - 1.25rem);
    min-height: 18.75rem;
    margin: 0 auto;
    background: #fff;
    border-radius: 0.625rem 0.625rem 0 0;
    &-nav {
      display: flex;
      align-items: flex-end;
      height: 3.5rem;
      &-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        .tab-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.25rem;
          height: 2.25rem;
          border-radius: 50%;
          background: #fff;
        }
        .tab-title {
          margin-left: 0.5rem;
          font-size: 16px;
        }
      }
    }
    &-list {
      // max-height: calc(100vh - 15.5rem);
      height: 100%;
      overflow: auto;
    }
    &-label {
      text-align: center;
      margin-top: 0.75rem;
    }
    &-card {
      display: flex;
      height: 7.125rem;
      padding: 0.875rem 0.625rem;
      border-bottom: 1px solid #e5e5e5;
      &-img {
        flex-shrink: 0;
        width: 5.375rem;
        height: 5.375rem;
        margin-right: 0.9375rem;
        background: #fff0c3;
      }
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.25rem;
        :first-child {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        :last-child {
          color: rgba(rgba(0, 0, 0, 0.65), 0.35);
        }
      }
    }

    .form-row {
      display: flex;
      align-items: center;
      height: 3.125rem;
      margin-top: 1px;
      padding: 0 0.625rem 0 1.25rem;
      background: #fff;
      &-input {
        width: calc(100% - 1.125rem);
        font-size: 1rem;
      }
    }
    &-release-scroll {
      max-height: calc(100vh - 11.875rem - 7.5rem);
      overflow: auto;
      ::v-deep {
        .el-input__inner,
        .el-textarea__inner {
          border: none !important;
        }
      }
    }
  }
}

.release-title {
  display: flex;
  align-items: flex-end;
  height: 3.125rem;
  padding: 0.3125rem 1.3125rem;
  font-size: 16px;
  border-top: 1px solid #e5e5e5;
}
.release-content {
  &-photo-wrap {
    display: flex;
    height: 6.875rem;
    margin: 0 1.3125rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid #e5e5e5;
    overflow: auto hidden;
    .photo {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;
      width: 5.375rem;
      height: 5.375rem;
      margin-right: 0.5rem;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      background: #f6f6f6;
      .el-upload {
        width: 100%;
        height: 100%;
      }
      &-desc {
        font-size: 0.875rem;
        color: #686c72;
      }
      .close-icon {
        position: absolute;
        right: -0.5rem;
        top: -0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.65);
      }
      .icon-camera {
        width: 30px;
        height: 30px;
        font-size: 30px;
        color: #686c72;
      }
    }
  }
}
.form-btn {
  position: fixed;
  bottom: 1.125rem;
  width: calc(100vw - 1.25rem);
  color: #fff;
  border: 1px solid transparent;
  background: #ffcc36;
  transition: all 0.35s;
}
.form-btn[disabled] {
  color: #fff;
  border: 1px solid transparent;
  background: #fff0c3;
  transition: all 0.35s;
}
.content-card:active {
  background: #fff0c3;
  transition: all 0.25s;
}
</style>
