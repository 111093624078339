<template>
  <div class="container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>全周期服务</h1>
    </div>
    <div class="top_main">
      <div class="banner">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner.jpg" />
      </div>

      <div class="nav">
        <div class="tool" @click="gotoLink('health/main')">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/baseFile.png" />
          <span>基础档案</span>
        </div>
        <div class="tool" @click="gotoLink('health/exam/list')">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/physicaExam.png" />
          <span>体检记录</span>
        </div>
        <div class="tool" @click="router('23')">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/followUp.png" />
          <span>年度评估报告</span>
        </div>
        <!-- <div class="tool" @click="gotoLink('health/follow/list')">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/followUp.png" />
          <span>健康随访</span>
        </div> -->
      </div>
    </div>
    <!-- <div class="bottom_main">
      <div class="register">
        <div class="userinfo">
          <div class="sub">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" />
          </div>
        </div>
        <div class="regRight">
          <div>
            <span class="addr">{{name}}您好！欢迎访问健康档案！<br /> </span>
          </div>
        </div>
      </div>
      <div class="eventList">
        <div class="header">
          <div class="title">健康宣教</div>
          <div class="more" @click="gotoLink('')">更多</div>
        </div>
        <div class="list">
          <div v-for="item in eduList" :key="item.title" @click="gotoLink('')" class="list-item">
            <div class="content">{{ item.title }}</div>
            <div class="time">{{ item.pubDateFormat }}</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { openUrl } from "@/utils/index";
export default {
  data() {
    return {
      eduList: [],
      hasRegister: false,
      userId: "",
      name: "",
    };
  },
  components: {},
  mounted() {
    this.loginReq();
    // this.getEduDatas();
    // this.name = this.getSessionData('userInfo') ? this.getSessionData('userInfo').userName || this.getSessionData('userInfo').nickname : '';
  },
  methods: {
    loginReq: function() {
      var _this = this;
      const token = this.getSessionData("token") || "";
      this.axios({
        url: "/authApi/sdk-auth/getThirdUserId",
        method: "get",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: token
            ? `Bearer ${token}`
            : "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg",
        },
        data: {},
      }).then((res) => {
        if (res.data.flag) {
          this.userId = res.data.data;
          sessionStorage.setItem("healthUserId", res.data.data);
          this.axios
            .get("https://yyh.ikeqiao.net/app/login", {
              params: {
                userTmpId: res.data.data,
                limit: 10,
              },
            })
            .then(function(response) {
              let token = response.data.token;
              sessionStorage.setItem("healthtToken", token);
            })
            .catch(function(err) {});
        }
      });
    },
    router(vote_url) {
      openUrl(vote_url);
    },
    getEduDatas: function() {
      var _this = this;
      var userId = this.getSessionData("healthUserId");
      this.axios
        .get("https://yyh.ikeqiao.net/app/edu/list", {
          params: {
            userId: userId,
            limit: 10,
          },
        })
        .then(function(res) {
          console.log(res.data.result);
          _this.eduList = res.data.result.list;
        })
        .catch(function(err) {});
    },

    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/health.scss";
</style>
