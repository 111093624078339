<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="reply-list"
  >
    <div>
      <p>共有<span style="color: #4479ed;">{{total}}</span>个{{belongOwn ? '需求' : '回答'}}</p>
    </div>
    <ul>
      <li v-for="item in dataList" :key="item.abilityId" @click="gotoLink(`nrbDetail?id=${item.abilityId}`)">
        <div class="tit">{{item.title}}</div>
        <div class="reply-info">
          <div v-if="belongOwn"><span>{{`发布时间：${item.issueTime}`}}</span></div>
          <div v-else><span>{{`认领时间：${item.issueTime}`}}</span></div>
          <i class="tag1">{{item.issueStatus}}</i>
        </div>
        <p>{{item.content}}</p>
        <div v-if="belongOwn" class="state"><span>{{item.className}}</span></div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import nrbApi from "api/llyjr/nrb";
import { mapState } from 'vuex';
export default {
  name: 'nrbList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      nrbApi
        .getNrbList({
          page,
          size,
          object: {
            className: category,
            issueStatus: status,
            belongOwn: this.belongOwn,
          },
        })
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
          const { list = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = list;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(list)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
    },
  }
}
</script>
<style lang="scss" scoped>
.reply-list {
  width: 23.4rem;
  margin: 0 auto;
  padding: 0.6rem 0;
  li {
    padding: 0.8rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    .tit {
      font-weight: 600;
      color: #333;
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    .date {
      color: #999;
    }
    p {
      color: #7a7a7a;
    }
    .reply-info {
      margin-bottom: 0.4rem;
      position: relative;
      height: 2rem;
      img {
        width: 2rem;
        height: 2rem;
        float: left;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
      div {
        float: left;
        font: 500 0.8rem/1rem "microsoft yahei";
        color: #333;
        span {
          color: #bbb;
        }
      }
      i {
        position: absolute;
        display: inline-block;
        height: 1.8rem;
        padding: 0 0.7rem 0 0.9rem;
        line-height: 1.8rem;
        border-radius: 0.9rem 0 0 0.9rem;
        font-style: inherit;
        font-size: 0.7rem;
        color: #fff;
        right: -0.8rem;
        top: 0.1rem;
      }
      .tag1 {
        background: #3291f8;
      }
      .tag2 {
        background: #f8b432;
      }
      .tag3 {
        background: #c5cbd2;
      }
    }
    .state {
      padding-top: 0.4rem;
      span {
        display: inline-block;
        height: 1.4rem;
        padding: 0 0.7rem;
        line-height: 1.4rem;
        border-radius: 0.2rem;
        font-style: inherit;
        font-size: 0.8rem;
        background: #e8e8e8;
        color: #101010;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 1rem;
        height: 1rem;
        margin-right: 0.3rem;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images2/icon-zan.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>