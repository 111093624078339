<template>
  <div class="volunteer-team-container">
    <van-nav-bar
      title="团队详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <!-- top bar -->
      <div class="top-bar">
      </div>
      <div class="content">
        <h1 class="title-text">{{volunteerTeam.name}}</h1>
        <!-- <div class="flex">
          <span style="margin-right: 20px;">人数：34</span>
          <span>活动：14</span>
        </div> -->
        <div :class="[!show ? 'van-multi-ellipsis--l2' : '']">
          {{volunteerTeam.introduction}}
        </div>
        <!-- <div class="blue" style="display: flex;align-items: center;justify-content: center;" @click="show = !show">
          <span>{{ show ? "收起" : "展开" }}</span>
          <img
            src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
            alt=""
            :style="{'height': '0.9rem','transform': show ? 'rotate(180deg)' : ''}"
          />
        </div> -->
      </div>
      <!-- <div class="card-con">
        卡片标题
        <div class="card-title-con">
          <div class="card-title-text-con">
            <i class="card-title-icon"></i>
            <h2 class="title-text">志愿者活动</h2>
          </div>
        </div>
        卡片内容
        <div class="card-body">
          <div class="activity-item" v-for="item in activityList" :key="item.title" @click="gotoLink(`volunteerActivity?id=${item.id}`)">
            <div class="top-image-con">
              <van-image :src="item.imgUrl" width="100%" height="120px" fit="cover" />
              <span class="date-tag" v-if="item.permitEnroll">{{`截止日期：${item.time}`}}</span>
              <span class="date-tag" v-else>{{item.time}}</span>
              <span class="status-tag" :style="{background: colors[item.status].background,color: colors[item.status].text}">{{item.activityStatus}}</span>
            </div>
            <div class="activity-item-body">
              <div class="flex between">
                <div class="flex">
                  <span class="type-tag">{{item.activityType}}</span>
                  <div class="title-text" style="line-height: 1.2rem;">{{item.title}}</div>
                </div>
                <div style="width: 4.5rem;word-break: break-all;">
                  <span class="tag-text">已报名</span>
                  <span class="tag-text blue">{{item.applyNum}}</span>
                </div>
              </div>
              <div>
                <span class="label-text">发布人：</span>
                <span>{{item.dep}}</span>
              </div>
              <div>
                <span class="label-text">活动时间：</span>
                <span>{{item.date}}</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="footer-button">
      <van-button round type="info" @click="handleApply" >{{getApplyBtnText()}}</van-button>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import volunteerApi from 'api/volunteer';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      show: true,
      volunteerTeam: {},
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      activityList: [
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/card_bg.png",
          title: "丁香公寓清卫",
          state: "0",
          status: "0",
          activityType: '环境维护',
          time: "剩5日20小时开始",
          applyNum: "10",
          dep: "同心员志愿团队",
          activityStatus: '未开始',
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/card_bg.png",
          title: "丁香公寓为独居老人送饭",
          state: "1",
          status: "1",
          activityType: '扶弱济困',
          time: "剩20分钟结束",
          applyNum: "200",
          activityStatus: '进行中',
          dep: "同心员志愿团队",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/card_bg.png",
          title: "反诈宣传",
          state: "2",
          status: "2",
          activityStatus: '已结束',
          activityType: '宣传教育',
          time: "",
          applyNum: "30",
          dep: "同心员志愿团队",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.getVolunteerTeam();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getVolunteerTeam() {
      volunteerApi.getVolunteerTeam().then(res => {
        if (res.flag) {
          this.volunteerTeam = res.data || {};
        } else {
          this.volunteerTeam = {};
        }
      })
    },
    getApplyBtnText() {
      switch(this.volunteerTeam.enrollStatus) {
        case '申请中': {
          return '申请中';
        }
        case '已加入': {
          return '取消加入';
        }
        case '未通过': {
          return '重新申请';
        }
        default: {
          return '申请加入';
        }
      }
    },
    handleApply() {
      switch(this.volunteerTeam.enrollStatus) {
        case '申请中': {
          this.gotoLink('volunteerApply');
          break;
        }
        case '已加入': {
          this.$dialog.confirm({
            title: '提示',
            message: '确定是否取消加入',
            confirmButtonText: '立即取消',
            confirmButtonColor: '#4379ed'
          }).then(() => {
            volunteerApi.cancelVolunteer({ type: 0 }).then(res => {
              this.getVolunteerTeam();
            })
          }).catch((e) => {
          });
          break;
        }
        case '未通过': {
          this.gotoLink('volunteerApply');
          break;
        }
        default: {
          this.gotoLink('volunteerApply');
          break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "assets/scss/common.scss";
.volunteer-team-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 2.4rem - 70px);
    width: 100vw;
    background: #f6f7fa;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      height: 160px;
      width: 100vw;
      background: url("http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/团队详情.png") no-repeat;
      background-size: 100% 100%;
      color: #FFF;
      text-align: center;
      font-size: 24px;
      line-height: 100px;
      font-weight: 500;
    }
    .content {
      margin: -20px 0 12px;
      background: #FFF;
      padding: 12px;
      border-radius: 20px 20px 0 0;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .card-con {
      background-color: #FFF;
      width: calc(100% - 24px);
      padding: 12px;
      margin: 12px;
      // 卡片标题
      .card-title-con {
        width: 100%;
        height: 1.44rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .card-title-text-con {
          height: 100%;
          display: flex;
          align-items: center;
          .card-title-icon {
            display: block;
            width: 4px;
            height: 1rem;
            background-color: #4479ed;
            margin-right: 7px;
          }
          .card-title-text {
            color: rgba(0,0,0,0.85);
            font-weight: 500;
            font-size: 1rem;
          }
        }
        .card-title-more {
          height: 100%;
          color: rgba(0,0,0,0.45);
          display: flex;
          align-items: center;
          .card-title-more-icon {
            height: 0.94rem;
          }
        }
      }
      // 卡片内容
      .card-body {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        .activity-item {
          width: 100%;
          margin: 12px 0;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
          background: #FFF;
          .top-image-con {
            width: 100%;
            height: 120px;
            overflow: hidden;
            position: relative;
            background: #FFF;
            font-size: 0.8rem;
            line-height: 1rem;
            .date-tag {
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba(0,0,0,0.2);
              color: #FFF;
              padding: 2px 6px;
              border-radius: 4px;
            }
            .status-tag {
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(213,244,226,0.9);
              color: #13BB49;
              padding: 2px 10px;
              border-radius: 2px 2px 8px 2px;
            }
          }
          .activity-item-body {
            width: 100%;
            padding: 12px;
            .type-tag {
              width: 70px;
              height: 24px;
              line-height: 24px;
              color: #FFF;
              padding: 0 10px 0 6px;
              margin-right: 5px;
              font-size: 0.8rem;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/educationSource/status-bg.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
  .footer-button {
    position: fixed;
    bottom: 15px;
    right: 0;
    left: 0.8rem;
    width: calc(100vw - 1.6rem);
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
    .van-button {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
