<!--清廉社区-->
<template>
  <div class="bg">
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="清廉社区"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="info-qlsq">
      <div class="info-item-qlsq">
        <div class="num">1</div>
        <div class="text">
          依托清廉社区系统开展社区清廉文化建设，以社区一站式服务为基础，打造东盛社区清廉七大篇章；
        </div>
      </div>
      <div class="info-item-qlsq">
        <div class="num">2</div>
        <div class="text">
          立足社区本土文化，深挖清廉元素，个性化优化“清廉社区”建设，以群众满意度作为社区成效评估；
        </div>
      </div>
      <div class="info-item-qlsq">
        <div class="num">3</div>
        <div class="text">
          建立“网格在线监督”机制，充分融合网格管理和清廉社区，实现线上、线下同“廉”心，提升社会治理精准度；
        </div>
      </div>
    </div>

    <div class="box">
      <div class="box-header">
        <div class="index">晒党务</div>
        <div
          @click="
            $router.push({ path: '/uncorrupted/list?key=11' })
          "
        >
          <span class="more">更多</span><van-icon name="arrow" />
        </div>
      </div>
      <div class="box-body" v-if="dangwuList.length">
        <div class="body-item" v-for="(item, index) in dangwuList" :key="index" @click="showDetailPage(item)">
          <img :src="item.cover" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="right-info">
              <!-- <div class="num">{{ item.num }}浏览量</div> -->
              <div class="time">{{ item.publishTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        v-else
        class="custom-image"
        :image="nodata"
        description="无数据"
      />
    </div>

    <div class="box">
      <div class="box-header">
        <div class="index">晒决策</div>
        <div
          @click="
            $router.push({ path: '/uncorrupted/list?key=12' })
          "
        >
          <span class="more">更多</span><van-icon name="arrow" />
        </div>
      </div>
      <div v-if="jueceList.length" class="box-body">
        <div class="body-item" v-for="(item, index) in jueceList" :key="index" @click="showDetailPage(item)">
          <img :src="item.cover" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="right-info">
              <!-- <div class="num">{{ item.num }}浏览量</div> -->
              <div class="time">{{ item.publishTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        v-else
        class="custom-image"
        :image="nodata"
        description="无数据"
      />
    </div>

    <div class="box">
      <div class="box-header">
        <div class="index">晒财务</div>
        <div
          @click="
            $router.push({ path: '/uncorrupted/list?key=13' })
          "
        >
          <span class="more">更多</span><van-icon name="arrow" />
        </div>
      </div>
      <div v-if="caiwuList.length" class="box-body">
        <div class="body-item" v-for="(item, index) in caiwuList" :key="index" @click="showDetailPage(item)">
          <img :src="item.cover" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="right-info">
              <!-- <div class="num">{{ item.num }}浏览量</div> -->
              <div class="time">{{ item.publishTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        v-else
        class="custom-image"
        :image="nodata"
        description="无数据"
      />
    </div>

    <!-- <div class="box">
      <div class="box-header">
        <div class="index">晒维修</div>
        <div
          @click="
            $router.push({ path: '/uncorrupted/list', query: { type: 4 } })
          "
        >
          <span class="more">更多</span><van-icon name="arrow" />
        </div>
      </div>
      <div v-if="weixiuList.length" class="box-body">
        <div class="body-item" v-for="(item, index) in weixiuList" :key="index">
          <img :src="item.img" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="right-info">
              <div class="num">{{ item.num }}浏览量</div>
              <div class="time">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        v-else
        class="custom-image"
        :image="nodata"
        description="无数据"
      />
    </div> -->

    <!-- <van-tabbar v-model="active">
      <van-tabbar-item>
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? tabIcon[0].active : tabIcon[0].inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="search"
        ><span>晒社情</span>
        <template #icon="props">
          <img
            :src="props.active ? tabIcon[1].active : tabIcon[1].inactive"
          /> </template
      ></van-tabbar-item>
      <van-tabbar-item icon="friends-o"
        ><span>晒帮扶</span>
        <template #icon="props">
          <img
            :src="props.active ? tabIcon[2].active : tabIcon[2].inactive"
          /> </template
      ></van-tabbar-item>
      <van-tabbar-item icon="setting-o"
        ><span>邀你来监督</span>
        <template #icon="props">
          <img
            :src="props.active ? tabIcon[3].active : tabIcon[3].inactive"
          /> </template
      ></van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      active: 0,
      nodata: require("../../assets/images/uncorrupted/nodata.png"),
      dangwuList: [],
      jueceList: [],
      caiwuList: [],
      weixiuList: [],
      tabIcon: [
        {
          active: require("../../assets/images/uncorrupted/icon-index-select.png"),
          inactive: require("../../assets/images/uncorrupted/icon-index.png"),
        },
        {
          active: require("../../assets/images/uncorrupted/icon-sheqing-select.png"),
          inactive: require("../../assets/images/uncorrupted/icon-sheqing.png"),
        },
        {
          active: require("../../assets/images/uncorrupted/icon-bangfu-select.png"),
          inactive: require("../../assets/images/uncorrupted/icon-bangfu.png"),
        },
        {
          active: require("../../assets/images/uncorrupted/icon-jiandu-select.png"),
          inactive: require("../../assets/images/uncorrupted/icon-jiandu.png"),
        },
      ],
    };
  },
  mounted() {
    homeApi
      .getArticleList({
        page: 1,
        pageSize: 5,
        type: 12,
        columnType: '11',
      })
      .then((res) => {
        console.log(res);
        const { flag, data } = res;
        if (data && flag) {
          this.dangwuList = data.records || [];
          console.log('this.dangwuList', this.dangwuList);
        }
    });
    homeApi
      .getArticleList({
        page: 1,
        pageSize: 5,
        type: 12,
        columnType: '12',
      })
      .then((res) => {
        console.log(res);
        const { flag, data } = res;
        if (data && flag) {
          this.jueceList = data.records || [];
        }
    });
    homeApi
      .getArticleList({
        page: 1,
        pageSize: 5,
        type: 12,
        columnType: '13',
      })
      .then((res) => {
        console.log(res);
        const { flag, data } = res;
        if (data && flag) {
          this.caiwuList = data.records || [];
        }
    });
  },
  methods: {
    ...mapMutations(['setDataInfo']),
    showDetailPage(data) {
      this.setDataInfo(data);
      if (data.contentType === 1) {
        window.location.href = data.content;
      } else {
        this.$router.push(`/zxzxDetail`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: linear-gradient(
    180deg,
    rgba(114, 170, 253, 0.1) 0%,
    rgba(62, 113, 249, 0.1) 100%
  );
  padding-bottom: 30px;
  .info-qlsq {
    padding: 60px 0 10px;
    .info-item-qlsq {
      padding: 0 20px;
      margin-top: 10px;
      display: flex;
      .num {
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        opacity: 0.85;
        background-image: linear-gradient(180deg, #72aafd 0%, #3e71f9 100%);
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.08);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
      }
      .text {
        width: calc(100% - 32px);
        opacity: 0.65;
        margin-left: 8px;
        // flex: 1;
        font-size: 16px;
        letter-spacing: 0.6px;
        line-height: 20px;
      }
    }
  }
}

.box {
  border-radius: 4px;
  background-color: #fff;
  margin: 0.8rem;
  .box-header {
    height: 3rem;
    margin: 0.5rem;
    border-bottom: 1px solid #ededed;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .index {
      margin-left: 0.8rem;
      font-size: 1.1rem;
    }
    .more {
      margin-right: 0.2rem;
      font-size: 0.9rem;
      color: #2a70ed;
    }
  }
  .box-body {
    // display: flex;
    .body-item {
      padding: 1rem 0;
      margin: 0 1rem;
      display: flex;
      border-top: 1px solid #ededed;
      &:nth-child(1) {
        border-top: none;
      }
      img {
        height: 5.6rem;
        width: 5.6rem;
      }
      .right {
        margin-left: 0.8rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        .title {
          font-size: 1.1rem;
          line-height: 1.3rem;
          letter-spacing: 0.1rem;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .right-info {
          opacity: 0.25;
          display: flex;
          .time {
            margin-left: 1.4rem;
          }
        }
      }
    }
  }
}

::v-deep {
  .custom-image .van-empty__image {
    width: 5rem;
    height: 3.5rem;
  }
}
</style>