<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>一起拼多多</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/yqpdd_banner.png" />
        </div>
        <div class="all-nav clearfix">
          <ul>
            <li @click="gotoLink('yqpddPJ')">
              <i class="pj"></i><span>拼“接”</span>
            </li>
            <li @click="gotoLink('yqpddPY')">
              <i class="py"></i><span>拼游</span>
            </li>
            <li @click="gotoLink('yqpddPM')">
              <i class="pm"></i><span>拼买</span>
            </li>
            <li @click="handleLink('yqpddPD')">
              <i class="wdpd"></i><span>我的拼单</span>
            </li>
          </ul>
        </div>
        <div class="daryLine"></div>
        <div class="all-nav" style="marginbottom: 0">
          <div class="index-tab tab">
            <b>热门拼单</b>
          </div>
        </div>
        <ul class="detail-img-box-two">
          <li v-for="item in pageDataList.filter(v => v.orderId !== 17)" :key="item.key">
            <div
              class="img"
              @click="gotoLink('yqpddDetail?key=' + item.orderId)"
            >
              <div class="img-top">
                <div class="img-status" :style="{background: colors[item.status].background,color: colors[item.status].text}"><p class="img-text">{{ item.status }}</p></div>
                <img :src="item.image" />
              </div>
              <div class="img-title left">{{ item.title }}</div>
              <div class="img-des">
                <span
                  >已有<span class="blue">{{ item.number }}</span
                  >人报名</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="tab-flex-bottom" @click="gotoLink('yqpddAdd')">发起拼单</div>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";

export default {
  data() {
    return {
      pageDataList: [],
      colors: {
        '报名中': { text: '#4479ED', background: '#E3EFFD' },
        '进行中': { text: '#E5B20A', background: '#FFF6D9' },
        '已结束': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
    };
  },
  components: {},
  mounted() {
    this.getList({ page: 1, size: 10 });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    handleLink(path) {
      // this.$store.dispatch('login/checkRealName', () => {
        this.$router.push(path);
      // })
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getList(params) {
      yqpddApi.getHotOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => {
            item.img = item.image.split("#")[0];
            item.status = item.status === -1 ? '报名中' : item.status === 0 ? '进行中' : '已结束'
          });
        this.pageDataList = records;
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
