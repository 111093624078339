<template>
  <div class="wtzzcl-container">
    <div class="header-nav" style="display: flex">
      <img @click="goBack" style="width: 20px; height: 20px; margin: 10px" src="http://dssq.ikeqiao.net/data/image/h5/images/myInvite/leftWhite.png" />
      <h1 style="margin-left: 7.5%">操作流程</h1>
    </div>
    <div class="wtzzcl-body-container">
      <div class="title"><img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/wzzzclTitle.png" alt=""></div>
      <div class="subTitle">保存下方二维码后，使用浙里办APP或支付宝APP进行扫码。</div>
      <div class="imageContent">
        <div class="item">
          <div class="image"><img :src="zlbImage" alt=""></div>
          <div class="name">浙里办APP扫二维码</div>
          <!-- <div class="btn">
            <a :href="zlbImage" download="二维码">保存二维码</a>
          </div> -->
        </div>
        <div class="item">
          <div class="image"><img :src="zfbImage" alt=""></div>
          <div class="name">支付宝APP扫二维码</div>
          <!-- <div class="btn">
            <a :href="zfbImage" download="二维码">保存二维码</a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="bottomImage"><img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/wzzzclBottom.png" alt=""></div>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
export default {
  data() {
    return {
      zlbImage: require('../../../assets/images/zzclZLB.png'),
      zfbImage: require('../../../assets/images/zzclZFB.png'),
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    goBack(){
          this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getBase64Image(imgUrl) {
        var img = new Image();
        img.src = imgUrl;
        img.setAttribute('crossOrigin', 'anonymous');
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL;
    },
    convertImgToBase64(url, callback){
    },
    downLoadImage(url) {
      console.log(url);
      // var canvas = document.createElement('CANVAS'),
      // ctx = canvas.getContext('2d'),
      // img = new Image;
      // img.crossOrigin = 'Anonymous';
      // img.onload = function(){
      //   canvas.height = img.height;
      //   canvas.width = img.width;
      //   ctx.drawImage(img,0,0);
      //   var dataURL = canvas.toDataURL('image/png');
      //   FileSaver.saveAs(dataURL, "二维码");
      //   canvas = null;
      // };
      // img.src = url;
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        function download() {
          let canvas = document.createElement('CANVAS');
          const ctx = canvas.getContext('2d');
          canvas.height = this.height;
          canvas.width = this.width;
          ctx.drawImage(this, 0, 0);
          canvas.toBlob((blob) => {
            FileSaver.saveAs(blob, '下载图片.png');
          });
          canvas = null;
        }
        img.onload = download;
        img.src = url;
      // FileSaver.saveAs(url, "二维码");
    },
  }
};
</script>

<style lang="scss" scoped>
.wtzzcl-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  padding-top: 2.7rem;
  background: #fff;
  overflow: scroll;
  background-image: linear-gradient(0deg, #547DF5 12%, #7483FF 97%);
  .header-nav{
    background-image: linear-gradient(0deg, #7182fe 12%, #7182fe 100%);
    >h1{
      color: #fff;
    }
  }
  .wtzzcl-body-container {
    font-size: 14px;
    padding: 16px;
    .title{
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      width: 300px;
      margin: 0 auto;
      >img{
        width: 100%;
        height: auto;
      }
    }
    .subTitle{
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      line-height: 22px;
    }
    .van-divider{
      color: rgba(0,0,0,0.85);
      border-color: rgba(0,0,0,0.2);
    }
    .imageContent{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .item{
        width: 140px;
        align-items: center;
        .image{
          width: 140px;
          height: 140px;
          margin-bottom: 10px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .name{
          font-size: 14px;
          color: #FFFFFF;
          text-align: center;
          font-weight: 500;
        }
        .btn{
          margin-top: 10px;
          text-align: center;
          line-height: 24px;
          border-radius: 12px;
          border: 1px solid #08f;
          color: #08f;
          a{
            color: #08f;
          }
        }
      }
    }
  }
  .bottomImage{
    position: absolute;
    width: 100%;
    margin-top: 10px;
    bottom: 0px;
    left: 0px;
    >img{
      width: 100%;
      height: auto;
    }
  }
}
</style>
