<template>
  <div id="index">
    <div class="hjlbn-add-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>申请加入</h1>
      </div>
      <van-notice-bar
        v-if="info.applyStatus === '申请中'"
        left-icon="volume-o"
        color="#1989fa"
        background="#ecf9ff"
        text="申请审核中"
      />
      <van-notice-bar
        v-if="info.applyStatus === '未通过'"
        left-icon="volume-o"
        color="#FF4535"
        background="#FFEFEE"
        :text="info.checkReason || ''"
      />
      <van-form @submit="onSubmit">
        <!-- <van-field
          v-model="name"
          name="name"
          label="姓名"
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          @click="showType = true"
          :rules="[{ required: true, message: '请选择上报类型' }]"
        /> -->
        <van-field
          v-model="name"
          name="creater"
          label="姓名"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写正确的姓名' }]"
        />
        <van-field
          v-model="phone"
          name="phone"
          label="联系电话"
          type="tel"
          placeholder="请输入"
          input-align="right"
          :rules="[{ pattern: /^1[0-9]{10}$/, message: '请填写正确的联系电话' }]"
        />
        <div class="section-textArea">
          <div class="section-label">个人介绍</div>
          <van-field
            v-model="content"
            name="introduce"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="400"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写个人介绍' }]"
            show-word-limit
          />
        </div>
        <div class="section-textArea">
          <div class="section-label">加入原因</div>
          <van-field
            v-model="desc"
            name="joinReason"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="400"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写加入原因' }]"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="image-title">照片</div>
          <van-uploader v-model="invitationImage" name="invitationImage" :max-count="1" />
        </div>
        <div class="foot-button">
          <van-button v-if="info.applyStatus === '未申请'" block type="info" native-type="submit">立即申请</van-button>
          <van-button v-if="info.applyStatus === '申请中'" block type="info" native-type="button" @click="handleCancel">取消申请</van-button>
          <van-button v-if="info.applyStatus === '未通过'" block type="info" native-type="submit">重新提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import hjlbnApi from 'api/hjlbn';
import commonApi from "api/common";

export default {
  data() {
    return {
      name: '',
      phone: '',
      content: "",
      desc: "",
      invitationImage: [],
      info: {},
    };
  },
  components: {},
  created() {
    
    this.getInfo();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    handleCancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定是否取消申请',
        confirmButtonText: '立即取消',
        confirmButtonColor: '#4379ed'
      }).then(() => {
        hjlbnApi.hjlbnDelete({ type: 1 }).then(res => {
          if (res.flag) {
            this.$router.go(-1);
          }
        })
      }).catch((e) => {
      })
    },
    getInfo() {
      const obj = JSON.parse(sessionStorage.getItem('userInfo'));
      const { userName = '', phone: userPhone = '' } = obj || {};
      hjlbnApi.getList().then(res => {
        if (res.flag) {
          this.info = res.data || {};
          const { creator = '', phone = '', introduce = '', joinReason = '', image } = res.data;
          this.name = creator || userName;
          this.phone = phone || userPhone;
          this.content = introduce;
          this.desc = joinReason;
          this.invitationImage = image ? [{ url: image }] : [];
          
        } else {
          this.info = {};
        }
      })
    },
    onSubmit(values) {
      if (this.invitationImage && this.invitationImage.length < 1) {
        Toast("请选择照片后提交");
        return;
      }
      const formData = new FormData();
      this.invitationImage.map((item) => formData.append("files", item.file));
      commonApi.uploadMultiFile(formData).then((res) => {
        const params = {
          ...values,
          image: res.data,
        };
        hjlbnApi.hjlbnAdd(params).then((res) => {
          const { message,data, flag } = res;
          if (flag) {
            Toast.success('申请提交成功');
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            Toast(message);
          }
        });
      });
      
    }
  }
};
</script>

<style lang="scss" scoped>
.hjlbn-add-page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
