import { render, staticRenderFns } from "./qlxfxt.vue?vue&type=template&id=03e7ca96&scoped=true&"
import script from "./qlxfxt.vue?vue&type=script&lang=js&"
export * from "./qlxfxt.vue?vue&type=script&lang=js&"
import style0 from "./qlxfxt.vue?vue&type=style&index=0&id=03e7ca96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e7ca96",
  null
  
)

export default component.exports