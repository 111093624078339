<template>
  <div class="publish-need-container">
    <van-nav-bar
      title="我要推荐"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="publish-recommand-body-container">
      <div class="image-bar">
        我要推荐
        <p>推荐您身边的能人，成功采纳后您可获得<b>5</b>积分</p>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="recommendName"
          label-class="input-label"
          name="userName"
          label="被推荐人姓名"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写被推荐人姓名' }]"
        />
        <van-field
          size="large"
          v-model="idCard"
          label-class="input-label"
          name="celNum"
          type="digit"
          label="身份证号"
          placeholder="请输入"
          @input="idCard = idCard.replace(/^[^1-9][^0-9]*/, '')" 
          :rules="[{ validator: isIdentityId, message: '请填写正确的身份证号' }]"
        />
        <van-field
          size="large"
          v-model="phone"
          label-class="input-label"
          name="phone"
          type="tel"
          label="联系电话"
          placeholder="请输入"
          :rules="[{ pattern: /^1[0-9]{10}$/, message: '请填写正确的联系电话' }]"
        />
        <div class="section" style="padding: 0.8rem;margin-top: 0.8rem;">
          <div class="title">个人特长</div>
          <van-field
            size="large"
            v-model="remark"
            rows="3"
            autosize
            name="ability"
            type="textarea"
            maxlength="200"
            placeholder="请填写被推荐人的个人介绍和特长"
            :rules="[{ required: true, message: '' }]"
            show-word-limit 
          />
        </div>
        <div class="section" style="padding: 0.8rem;margin-top: 0.8rem;">
          <div class="title">图片</div>
          <van-uploader v-model="imageList" name="image" multiple :max-count="4" />
        </div>
        <div class="footer-button">
          <van-button
            block
            type="info"
            plain
            style="font-size: 16px;margin-right: 10px;border-radius: 4px;"
            @click="gotoLink('myRecommend')"
          >
            我的推荐
          </van-button>
          <van-button
            block
            type="info"
            style="font-size: 16px;border-radius: 4px;"
            native-type="submit"
          >
            提交推荐
          </van-button>
        </div>
      </van-form>
        
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";

import moment from 'moment';
import { isIdentityId } from 'utils';
export default {
  data() {
    return {
      remark: '',
      phone: '',
      recommendName: '',
      idCard: '',
      imageList: [],
    };
  },
  computed: {
    dateList() {
      const result = [];
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week()).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('MM-DD');
        const week = current.format('dddd').replace('星期','周');
        result.push({ day, week });
      }
      return result;
    }
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    publishRecommend(values) {
      const _this = this;
      api.recommend(values).then(res => {
        console.log('success', res);
        
        if (res.data == 1) {
          _this.$toast("提交成功");
          _this.$router.replace("myRecommend");
        }
      })
    },
    onSubmit(values) {
      if (this.imageList.length) {
        const formData = new FormData();
        this.imageList.forEach(file => {
          formData.append("files", file.file);
        });
        api.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.image = res.data;
            this.publishRecommend(values);
          }
        });
      } else {
        this.publishRecommend(values);
      }
    },
    isIdentityId(e) {
      isIdentityId(e);
    }
  }
};
</script>

<style lang="scss" scoped>
.publish-need-container {
  height: 100vh;
  width: 100vw;
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;

  .publish-recommand-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 92px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/form-top-bg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      text-align: center;
      padding: 20px;
      line-height: 28px;
      >p {
        font-size: 14px;
        >b {
          font-size: 16px;
          color: #FFFFFF;
          margin: 0 5px;
        }
      }
    }
    ::v-deep .input-label {
      >span {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
    }
    .section {
      width: 100%;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
      .date-btn-con {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .date-btn {
          width: calc(50% - 5px);
          background: #F4F5F8;
          border-radius: 4px;
          line-height: 32px;
          text-align: center;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          margin-bottom: 10px;
        }
        .selected {
          color:  #4479ED;
          background:  rgba(68,121,237,0.15);
        }
      }
      .divider {
        height: 1px;
        width: 100%;
        background-color:#ebedf0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      font-size: 18px;
      display: flex;
    }
  }
}
</style>
