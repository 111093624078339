<template>
  <div class="digital-member-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="digital-member-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="image-con">
          <img class="image" :src="data.picture" alt="">
          <span class="tag">{{data.tag}}</span>
          <div
            v-if="data.state !== '0'"
            class="time"
          >
            {{ data.time }}
          </div>
          <div v-if="data.state === '0'" class="end">报名已截止</div>
        </div>
        <div class="desc">
          <div class="title">
            <b>{{ data.title }}</b>
            <span><i class="collect"></i>收藏</span>
          </div>
          <div class="info">
            <label>发布单位：</label><span>{{ data.dep }}</span>
          </div>
          <div class="info">
            <label>活动时间：</label><span>{{ data.date }}</span>
          </div>
          <div class="info">
            <label>报名截止：</label><span>{{ data.deadline }}</span>
          </div>
          <div class="info">
            <label>活动地点：</label><span class="location-con">{{ data.location }}<i class="location"></i></span>
          </div>
        </div>
      </div>
      <!-- 报名情况 -->
      <div class="userIconList">
        <div class="userIconList-title"><i></i>成员介绍</div>
        <ul class="iconList">
          <li class="iconItem" v-for="(v, k) in (data.members || [])" :key="k">
            <div class="topIcon"><img :src="v.headImage || 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png'" alt=""></div>
            <div class="bottomIcon">{{v.username || '张三'}}</div>
          </li>
        </ul>
      </div>
      <!-- 详情介绍/评论 -->
      <div class="middle-bar">
        <van-tabs v-model="selectedTab" @click="handleTab" color="#4479ED">
          <van-tab title="详情介绍" name="introduce">
            <p class="comment-desc">{{data.desc}}</p>
          </van-tab>
          <van-tab :title="`全部反馈（${data.commentList.length}）`" name="comment">
            <commentList :zan="false" :dataList="data.commentList"></commentList>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 按钮 -->
      <div class="footer-button">
        <van-button
          v-if="data.status === '未报名'"
          block
          type="info"
          style="font-size: 16px"
          @click="handleApply"
        >
          我要报名
        </van-button>
        <van-button
          v-if="data.status === '已报名'"
          block
          style="font-size: 16px;margin-right: 5px;"
          @click="handleCancelApply"
        >
          取消报名
        </van-button>
        <van-button
          v-if="data.status === '已签到'"
          block
          type="info"
          plain
          style="font-size: 16px;margin-right: 5px;"
        >
          反馈评价
        </van-button>
        <van-button
          v-if="data.status === '已报名' || data.status === '已签到'"
          block
          type="info"
          style="font-size: 16px"
          @click="showSign = true"
        >
          {{`签到${selectedMembers.length}/${digitalMembers.length}`}}
        </van-button>
      </div>
    </div>
    <!-- 报名窗口 -->
    <van-overlay show v-if="show || showSign" @click.stop>
      <div class="wrapper">
        <div class="register-window">
          <div class="register-window-top">
            <b>{{`请选择${showSign ? '签到' : '报名'}`}}</b>
            <div class="close" v-if="show" @click="gotoLink('myFamily')">
              <van-icon size="16" color="#FFF"  name="apps-o" />
              管理
            </div>
          </div>
          <div class="register-window-content">
            <van-cell v-for="(item, index) in digitalMembers" :key="`${item.name}_${index}`" :title="item.name" icon="user-circle-o">
              <template #default>
                  <input @click="handleClick(item)" :checked="isSelected(item)" type="checkbox" />
                </template>
            </van-cell>
          </div>
          <div class="register-window-footer">
            <button class="cancle-btn" v-if="show" @click="show = false;">取消</button>
            <button class="cancle-btn" v-if="showSign" @click="showSign = false;">取消</button>
            <button v-if="show" class="confirm-btn" @click="handleWindow">确认</button>
            <button v-if="showSign" class="confirm-btn" @click="handleSign">提交</button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import commentList from 'components/commentList.vue';
export default {
  data() {
    return {
      data: {
        tag: '进行中',
        status: '未报名',
        title: "开展微党课学习",
        state: "1",
        time: "剩20分钟结束",
        applyNum: "200",
        dep: "诸暨市党建总支部委员会",
        date: "2021-07-08 12:00至2021-07-08 14:00",
        deadline: '2021-07-08 12:00',
        location: '东盛社区邻里中心',
        num: 342,
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity-detail.png',
        desc: '详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍',
        commentList: [
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '公共资源需要大家共同承担。',
            hasThumbsUp: '未点赞',
            thumbsUp: 23,
          },
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '公共资源需要大家共同承担。',
            hasThumbsUp: '已点赞',
            thumbsUp: 23,
          }
        ],
        members: [1,2,3,4,5,6,7,8,9,10],
      },
      inputItem: '', // 感想
      selectedTab: 'introduce',
      show: false,
      selectedMembers: [], // 复选框选中状态
      showSign: false,
    };
  },
  computed: {
    ...mapState({
      digitalMembers: state => state.digitalMember.digitalMembers,
    })
  },
  components: { commentList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    handleWindow() {
      if(!this.selectedMembers || this.selectedMembers.length < 1) {
        this.$toast('请选择报名对象')
      } else {
        
        this.data = {
          ...this.data,
          status: '已报名'
        };
        this.selectedMembers = [];
        this.show = false;
      }
    },
    handleSign() {
      if(!this.selectedMembers || this.selectedMembers.length < 1) {
        this.$toast('请选择签到对象')
      } else {
        
        this.data = {
          ...this.data,
          status: '已签到'
        };
        this.showSign = false;
      }
    },
    handleCancelApply() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定取消报名？'
      })
      .then(() => {
        this.data = {
          ...this.data,
          status: '未报名'
        }
      })
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleApply() {
      this.show = true;
    },
    handleClick(data) {
      if(this.isSelected(data)) {
        this.selectedMembers = this.selectedMembers.filter(item => item !== data.name);
      } else {
        this.selectedMembers.push(data.name);
      }
    },
    isSelected(data) {
      return this.selectedMembers.find(item => item === data.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.digital-member-detail-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFF;
  
  .digital-member-detail-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;

    .top-bar {
      width: 100%;
      background: #FFF;
      margin-bottom: 10px;
      .image-con {
        width: 100%;
        height: 240px;
        position: relative;
        .image {
          height: 100%;
          width: 100%;
        }
        .tag {
          position: absolute;
          top: 12px;
          right: 12px;
          padding: 0 5px;
          background: none;
          font-size: 12px;
          color: #4479ED;
          border: 1px solid #4479ED;
          line-height: 20px;
          text-align: center;
          border-radius: 4px 2px 8px 2px;
        }
        .time {
          background: rgba(0, 0, 0, 0.45);
          padding: 0 0.4rem;
          color: #fff;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.3rem;
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 0.4rem;
        }
        .end {
          background: rgba(0, 0, 0, 0.45);
          padding: 0 0.4rem;
          color: #fff;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.3rem;
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 0.4rem;
        }
      }
      .desc {
        background: #fff;
        border-radius: 0 0 0.3rem 0.3rem;
        padding: 0.5rem 0.7rem;
        .title {
          height: 2rem;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          overflow: hidden;
          line-height: 26px;
          b {
            float: left;
            color: rgba(0, 0, 0, 0.85);
            font-size: 1rem;
            width: 17.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            float: right;
            display: flex;
            align-items: center;
            .collect {
              display: inline-block;
              width: 1rem;
              height: 1rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/collect.png) no-repeat;
              background-size: 100% 100%;
              margin-right: 2px;
            }
          }
        }
        .info {
          font-size: 14px;
          height: 1.6rem;
          line-height: 1.6rem;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          label {
            color: rgba(0, 0, 0, 0.45);
          }
          .location-con {
            display: flex;
            align-items: center;
            .location {
              display: inline-block;
              width: 1rem;
              height: 1rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/location-blue.png) no-repeat;
              background-size: 100% 100%;
            }
          }
          
        }
      }
    }

    .userIconList{
      background-color: #FFF;
      width: 100%;
      margin-bottom: 10px;
      .userIconList-title {
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        padding-left: 16px;
        display: flex;
        align-items: center;
        >i {
          height: 16px;
          background-color: #4479ED;
          width: 3px;
          margin-right: 10px;
        }
      }
      .iconList {
        height: 80px;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 10px 10px 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        &::-webkit-scrollbar {
          /*隐藏滚轮*/
          display: none;
        }
        .iconItem{
          margin-right: 10px;
          margin-top: 10px;
          width: 50px;
          text-align: center;
          .topIcon{
            >img{
              width: 40px;
              height: 40px;
              border-radius: 20px;
            }
          }
          .bottomIcon{
            line-height: 20px;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .desc {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            text-align: left;
            line-height: 24px;
          }
        }
      }
      
    }

    .middle-bar {
      width: 100%;
      flex: 1;
      background: #FFF;
      .comment-desc {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: left;
        line-height: 24px;
        font-weight: 400;
        padding: 10px 15px;
        width: 100%;
        height: 100%;
      }
    }

    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      display: flex;
      align-items:  center;
    }

    

  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .register-window {
      background-color: #FFF;
      border-radius: 8px;
      
      font-size: 14px;
      width: 85vw;
      .register-window-top {
        width: 100%;
        height: 48px;
        background-color:  #4479ED;
        font-size: 16px;
        color: #FFF;
        text-align: center;
        line-height: 48px;
        border-radius: 8px 8px 0 0;
        position: relative;
        .close {
          position: absolute;
          top: 16px;
          right: 10px;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 16px;
        }
      }
      .register-window-content {
        width: 100%;
        // text-align: center;
        line-height: 20px;
        padding: 6px 0;
        
      }
      .register-window-footer {
        width: 100%;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 48px;
        border-top: 0.5px solid rgba(0,0,0,0.1);
        .confirm-btn {
          flex: 1;
          color: #4479ed;
          border: none;
          background: none;
        }
        .cancle-btn {
          flex: 1;
          color: rgba(0, 0, 0, 0.45);
          border-right: 0.5px solid rgba(0,0,0,0.1);
          background: none;
          border-top: none;
          border-left: none;
          border-bottom: none;
        }
      }
    }
  }
}
</style>
