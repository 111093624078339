<template>
  <div class="lucky_container">
    <div class="container">
      <div class="frame_div" :style="`background-image: url('${require('../../assets/background.png')}');`">
        <div class="frame_row">
          <div class="frame_item item0" :style="color[0] === 1 ? isActive : ''">
            <img :src="award[0].awardName === '谢谢参与!' ? xxcyImage : award[0].prizeImage" />
            <span>{{ award[0].prizeName || award[0].awardName }}</span>
          </div>
          <div class="frame_item item0" :style="color[1] === 1 ? isActive : ''">
            <img :src="award[1].awardName === '谢谢参与!' ? xxcyImage : award[1].prizeImage" />
            <span>{{ award[1].prizeName || award[1].awardName }}</span>
          </div>
          <div class="frame_item item0" :style="color[2] === 1 ? isActive : ''">
            <img :src="award[2].awardName === '谢谢参与!' ? xxcyImage : award[2].prizeImage" />
            <span>{{ award[2].prizeName || award[2].awardName }}</span>
          </div>
        </div>
        <div class="frame_row">
          <div class="frame_item item0" :style="color[7] === 1 ? isActive : ''">
            <img :src="award[7].awardName === '谢谢参与!' ? xxcyImage : award[7].prizeImage" />
            <span>{{ award[7].prizeName || award[7].awardName }}</span>
          </div>
          <div v-if="checkStatus !== '已实名'" class="frame_item clickBtn" style="background-image:url(http://dssq.ikeqiao.net/data/image/h5/images/lucky/noLogin.svg)">
            <div class="noLogin" @click="gotoLogin">请先登录</div>
          </div>
          <div v-if="checkStatus === '已实名' && timeStatus === '未开始'" class="frame_item clickBtn" style="background-image:url(http://dssq.ikeqiao.net/data/image/h5/images/lucky/endTurn.svg)">
            <div class="noStart">暂未开始</div>
          </div>
          <div v-if="checkStatus === '已实名' && timeStatus === '进行中'" class="frame_item clickBtn" style="background-image:url(http://dssq.ikeqiao.net/data/image/h5/images/lucky/startTurn.svg)">
            <div class="isStart" @click="beginClickLuck(surplusNumber)">开始抽奖</div>
            <div class="leave">剩余{{surplusNumber}}次</div>
          </div>
          <div v-if="checkStatus === '已实名' && (timeStatus === '已结束')" class="frame_item clickBtn" style="background-image:url(http://dssq.ikeqiao.net/data/image/h5/images/lucky/endTurn.svg)">
            <div class="noStart">抽奖结束</div>
          </div>
          <div class="frame_item item0" :style="color[3] === 1 ? isActive : ''">
            <img :src="award[3].awardName === '谢谢参与!' ? xxcyImage : award[3].prizeImage" />
            <span>{{ award[3].prizeName || award[3].awardName }}</span>
          </div>
        </div>

        <div class="frame_row">
          <div class="frame_item item0" :style="color[6] === 1 ? isActive : ''">
            <img :src="award[6].awardName === '谢谢参与!' ? xxcyImage : award[6].prizeImage" />
            <span>{{ award[6].prizeName || award[6].awardName }}</span>
          </div>
          <div class="frame_item item0" :style="color[5] === 1 ? isActive : ''">
            <img :src="award[5].awardName === '谢谢参与!' ? xxcyImage : award[5].prizeImage" />
            <span>{{ award[5].prizeName || award[5].awardName }}</span>
          </div>
          <div class="frame_item item0" :style="color[4] === 1 ? isActive : ''">
            <img :src="award[4].awardName === '谢谢参与!' ? xxcyImage : award[4].prizeImage" />
            <span>{{ award[4].prizeName || award[4].awardName }}</span>
          </div>
        </div>
      </div>
      <!-- 中奖弹窗 -->
      <div class="mask" v-if="showToast"></div>
      <div class="lottery-alert" v-if="showToast">
          <h1>{{luckObj.awardName === '谢谢参与!' ? '抱歉,未中奖!' : '恭喜您,中奖啦!'}}</h1>
          <p><img :src="luckObj.prizeImage || xxcyImage" alt=""></p>
          <h2>{{luckObj.prizeName || luckObj.awardName || '谢谢参与'}}</h2>
          <div class="btnsave" @click="showToast=false">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
  import luckyApi from 'api/lucky';
  import store from '../../store/index';
  import { mapState } from 'vuex';
  import context from '../../main';
  import { Toast } from 'vant';
  var interval = null;
  //值越大旋转时间越长  即旋转速度
  var intime = 50;
  export default {
    name: 'turnTable',
    data() {
      return {
        checkStatus: '未实名',
        luckObj: {},
        overLuck: false,
        isActive: `background-image: linear-gradient(180deg, #FCF22F 10%, #FCC52F 100%);box-shadow: inset 0px 2px 8px 0px rgba(255,166,0,1)`,
        color: [0, 0, 0, 0, 0, 0, 0, 0],
        // color: [1, 1, 1, 1, 1, 1, 1, 1],
        // award: [
        //   { prizeName: '西王胚芽油', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xwpyy.png', chance: 1 },
        //   { prizeName: '电饭煲', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/dfb.png', chance: 1 },
        //   { prizeName: '谢谢参与', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xxcy.png', chance: 50 },
        //   { prizeName: '空气净化器', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/kqjhj.png', chance: 1 },
        //   { prizeName: '西王胚芽油', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xwpyy.png', chance: 1 },
        //   { prizeName: '电饭煲', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/dfb.png', chance: 1 },
        //   { prizeName: '西王胚芽油', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xwpyy.png', chance: 1 },
        //   { prizeName: '谢谢参与', prizeImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xxcy.png', chance: 49 },
        // ],//接收数组
        xxcyImage: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/xxcy.png',
        img: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/redBox.png',//图片链接
        btnconfirm: 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/choujiang.png',//点击图片链接
        Luck: true,//防置重复点击
        luckPosition: 0, //停止位置
        showToast: false, //显示中奖弹窗     
      };
    },
    mounted() {
      this.checkStatus = context.getSessionData('checkStatus') || '';
      store.dispatch('login/checkRealName', () => {
        this.checkStatus = context.getSessionData('checkStatus') || '';
      })
    },
    props: {
      timeStatus: {
        type: String,
        default: function() {
          return '';
        }
      },
      surplusNumber: {
        type: Number,
        default: function() {
          return '';
        }
      },
      award: {
        type: Array,
        default: function() {
          return [];
        }
      },
      luckyActiveItem: {
        type: Object,
        default: function() {
          return {};
        }
      },
    },
    methods: {
      beginClickLuck(surplusNumber) {
        if (surplusNumber === 0) {
          Toast('没有次数了！');
          return;
        }
        if (this.Luck == false) {//防止过度点击
          return;
        }
        this.Luck = false;
        luckyApi.getPrize({ lid: this.luckyActiveItem.lid }).then((res = {}) => {
          const { data = {} } = res || {};
          const { pid } = data || {};
          const obj = this.award.filter(v => v.pid === pid)[0] || {};
          const xxhg = this.award.filter(v => v.pid === -1)[0] || {};
          this.luckPosition = Number(obj.awardLocation) - 1;
          if (pid === -1 || !pid) {
            this.luckPosition = Number(xxhg.awardLocation) - 1;
            console.log(2,this.luckPosition);
          }
          console.log('data===>', data, obj, this.luckPosition);
          this.$emit('onTurnChange');
          this.clickLuck();
        });
      },
      clickLuck() {
        var arr = [];
        //根据概率循环位置
        for (var i = 0; i < this.award.length; i++) {
          for (var k = 0; k < this.award[i].chance; k++) {
            arr.push(i);
          }
        }
        console.log(arr);
        //随机打乱数组
        // for (let i = 0, len = arr.length; i < len; i++) {
        //   let index = parseInt(Math.random() * (len - 1));
        //   let tempValue = arr[i];
        //   arr[i] = arr[index];
        //   arr[index] = tempValue;
        // }
        // console.log(arr);
        // //从数组中随机抽取一个位置
        // var a = Math.ceil(Math.random() * arr.length - 1);
        // console.log(arr[a]);
        // this.luckPosition = arr[a];
        var e = this;
        //设置按钮不可点击
        e.btnconfirm = 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/choujiang.png';
        //清空计时器
        clearInterval(interval);
        var index = 0;
        //循环设置每一项的背景图
        interval = setInterval(function() {
          var c = [0, 0, 0, 0, 0, 0, 0, 0];
          if (index > 7) {
            index = 0;
          }
          c[index] = 1;
          e.color = c;
          index++;
        }, intime);
        // console.log(intime);
        //模拟网络请求时间  设为两秒
        var stoptime = 1000;
        setTimeout(function() {
          e.stop(e.luckPosition);
        }, stoptime);
      },
      gotoLogin(){
        store.dispatch('login/checkRealName', () => {
          Toast('实名认证已通过！');
          this.checkStatus = context.getSessionData('checkStatus') || '';
        })
      },
      stop(which) {
        var e = this;
        //清空计数器
        clearInterval(interval);
        //初始化当前位置
        var current = -1;
        var color = e.color;
        for (var i = 0; i < color.length; i++) {
          if (color[i] == 1) {
            current = i;
          }
        }
        //下标从1开始
        var index = current + 1;
        e.stopLuck(which, index, intime, 10);
      },
      /**
      * which:中奖位置
      * index:当前位置
      * time：时间标记
      * splittime：每次增加的时间 值越大减速越快
      */
      stopLuck(which, index, time, splittime) {
        var e = this;
        //值越大出现中奖结果后减速时间越长
        var color = e.color;
        setTimeout(function() {
          console.log('e.color', e.color, time, which, index, e.luckPosition);
          //重置前一个位置
          var c = [0, 0, 0, 0, 0, 0, 0, 0];
          if (index > 7) {
            index = 0;
          }
          //当前位置为选中状态
          c[index] = 1;
          e.color = c;
          //如果旋转时间过短或者当前位置不等于中奖位置则递归执行
          //直到旋转至中奖位置
          if (time < 400 || index != which) {
            //越来越慢
            splittime++;
            time += splittime;
            //当前位置+1
            index++;
            e.stopLuck(which, index, time, splittime);
          } else {
            console.log('结束');
            //1秒后显示弹窗
            setTimeout(function() {
              //中奖
              e.overLuck = true;
              e.btnconfirm = 'http://dssq.ikeqiao.net/data/image/h5/images/lucky/choujiang.png';
              e.Luck = true;
              e.showToast = true;
              e.luckObj = e.award[e.luckPosition];
            }, 400);
          }
        }, time);
      }
    },
    computed: {
      ...mapState({
        // userInfo: (state) => (state.login.userInfo.nickname ? state.login.userInfo : null) || context.getSessionData('userInfo') || {},
      })
    },
  };
</script>

<style lang="scss">
  .lucky_container{
    height: 100%;
    .container {
      background: inherit;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .frame_div{
        // background-image: url("../../assets/background.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        width: 100%;
        padding: 28px;
        padding-top: 89px;
      }
      .clickBtn{
        background-size: 110% 110%;
        background-repeat: no-repeat;
        display: block;
        .noLogin{
          padding: 10px 18px;
          padding-top: 15px;
          padding-left: 22px;
          height: 100%;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          font-weight: 500;
        }
        .noStart{
          padding: 10px 18px;
          padding-top: 20px;
          padding-left: 22px;
          height: 100%;
          font-size: 20px;
          color: rgba(0,0,0,0.45);
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          font-weight: 500;
        }
        .isStart{
          padding: 0px 18px;
          padding-top: 12px;
          font-size: 20px;
          color: #E62626;
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          font-weight: 500;
        }
        .leave{
          font-size: 12px;
          color: #E62626;
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
    .frame_view {
      bottom: 80px;
      left: 30px;
      right: 30px;
      width: 314px;
      height: 330px;
      padding: 25px;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .frame_row {
      width: 255px;
      height: 77px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
    }
    .frame_item {
      width: 81px;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 12px;
      border-radius: 10px;
      color: #666;
    }
    .frame_item img {
      width: 50px;
      height: 50px;
      display: block;
    }
    .frame_item span {
      display: block;
      width: 100%;
      text-align: center;
    }
    .item1 {
      color: #ffffff;
    }
    .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        overflow: hidden;
        z-index: 222;
        top: 0;
        left: 0;
    }

    .lottery-alert {
        max-width: 250px;
        width: 80%;
        text-align: center;
        z-index: 10000;
        border-radius: 10px;
        background: #fff;
        padding: 20px;
        position: fixed;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
    }

    .lottery-alert h1 {
        font-size: 18px;
        font-weight: bold;
        color: #D92B2F;
    }

    .lottery-alert img {
        display: block;
        height: 120px;
        margin: 0 auto;
    }

    .lottery-alert h2 {
        font-weight: normal;
        color: #D92B2F;
        font-size: 15px;
        padding-top: 15px;
    }

    .lottery-alert p {
        color: #666;
        font-size: 16px;
        padding-top: 5px;
    }

    .lottery-alert .btnsave {
        border-radius: 3px;
        box-shadow: none;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
        color: #fff;
        margin-top: 12px;
        background: linear-gradient(180deg, rgba(213, 60, 63, 1) 0%, rgba(201, 20, 24, 1) 100%);
        font-size: 16px;
    }
   }
</style>