<template>
  <div class="home-index" style="background: #fff; height: 100vh">
    <div class="page-container paddclose" style="height: calc(100vh - 60px);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>何姐来帮忙</h1>
      </div>
      <div class="hot-main" style="width: 100%;">
        <div class="top_banner" v-if="showData.picture">
          <img :src="showData.picture" />
        </div>
        <div class="desc_content" v-for="(item, i) in (showData.contents || [])" :key="i">
          {{item}}
        </div>
        <div class="desc_title">{{showData.title}}</div>
        <div class="desc_content">
          {{showData.desc}}
        </div>
      </div>
    </div>
    <div class="foot-btn">
      <div v-if="info.applyStatus === '未申请'" @click="gotoLink('hjlbnAdd')">加入协会</div>
      <div v-else-if="info.applyStatus === '申请中'" @click="gotoLink('hjlbnAdd')">申请审核中</div>
      <div v-else-if="info.applyStatus === '已加入'" @click="handleCancel">退出协会</div>
      <div v-else-if="info.applyStatus === '未通过'" @click="gotoLink('hjlbnAdd')">申请未通过</div>
      <div @click="gotoLink('hjlbnHelp')">寻求帮助</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import service from 'utils/service';
import hjlbnApi from 'api/hjlbn';
export default {
  data() {
    return {
      showData: {
        picture: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/person_hj.png',
        contents: [
          '何月红，女，1963年生，诸暨市枫桥镇人。现任诸暨市心理卫生协会会长，诸暨市第十四届政协委员，国家二级心理咨询师，高级催眠师，少儿注意力训练导师，婚姻家庭高级咨询师。',
          '多年来从事心理咨询辅导工作，先后被评为“‘十二五’全省计生协工作先进志愿者”、“绍兴市杰出志愿者”、“诸暨市百名优秀志愿者” 、“诸暨市优秀志愿者”、“最美暨阳人”，并连续9年被市民政局评为“诸暨市优秀婚姻家庭辅导员”。',
        ],
        title: '诸暨市心理卫生协会',
        desc: '诸暨市心理卫生协会成立于2013年12月，是经市民政局审批登记并具有法人资格的社会团体，其业务主管单位是市科学技术协会。 协会的主要任务是团结全市心理卫生学、心理学、医学、教育学、社会学界等科学工作者开展心理卫生宣教、心理健康服务、科学研究、学术交流，为促进心理卫生科学技术的繁荣和发展，宣传普及心理卫生知识，培养儿童、青少年的健全人格，维护和提高人民心理健康水平和社会适应能力，提高道德水平，预防心理疾病预防心身疾病，促进心理卫生科学技术的普及和推广，从而提高全民的心理素质。',
      },
      showDialog: false,
      name: '',
      phone: '',
      habbit: '',
      info: {},
    };
  },
  components: {},
  mounted(opt) {
    this.getInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path)
    },
    addOrderEnroll() {
      Toast.success('报名成功');
      this.showDialog = false;
    },
    getInfo() {
      hjlbnApi.getList().then(res => {
        if (res.flag) {
          this.info = res.data || {};
        } else {
          this.info = {};
        }
      })
    },
    handleCancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定是否退出协会',
        confirmButtonText: '继续退出',
        confirmButtonColor: '#4379ed'
      }).then(() => {
        hjlbnApi.hjlbnDelete({ type: 0 }).then(res => {
          if (res.flag) {
            this.getInfo();
          }
        })
      }).catch((e) => {
      })
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .hot-main{
    height: auto !important;
  }
  .top_banner{
    padding: 10px;
    width: 100%;
    >img{
      width: 100%;
      height: 220px;
    }
  }
  .desc_title{
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    text-align: center;
  }
  .desc_content{
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    text-align: left;
    line-height: 24px;
    padding: 5px 10px;
    text-indent: 24px;
  }
  .desc_image{
    padding: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >img{
      margin-top: 10px;
      width: 48%;
    }
  }

  .foot-btn{
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    background: #fff;
    line-height: 50px;
    height: 50px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    display: flex;
    justify-content:space-between;
    >div{
      width: 48%;
      height: 100%;
      text-align: center;
      background: #08f;
      border-radius: 25px;
      &:first-child{
        color: #000;
        background: #fff;
        border: 1px solid rgba(0,0,0,0.1);
      }
    }
  }
</style>
