<template>
  <div class="">
    <van-nav-bar title="服务团队" left-arrow @click-left="onClickLeft" />
    <div class="serve-group-body-container">
      <div class="ser-banner">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/catalog/ser-banner.png" />
      </div>
      <div class="cata-box">
        <div class="ser-list">
          <ul>
            <li v-for="(item, i) in dataList" :key="i">
              <div class="title">
                <b>{{ item.group }}</b>
                <!-- <span>>详情 &gt;</span> -->
              </div>
              <div class="data-info">
                成员<span>{{ item.membernum }}人</span> 帮扶<span
                  >{{ item.helpnum }}人</span
                >
              </div>
              <div class="itemDesc">
                {{ item.commentContent }}
              </div>
              <div class="image-list-con">
                <img
                  v-for="(item, index) in item.images"
                  :src="item"
                  :key="index"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: "",
      selected: 1,
      dataList: [
        {
          commentContent:
            "大雨倾盆，浇不灭热情；积水没踝，挡不住勇气。一只只雨水井摸过去，徒手寻找堵点，排出积水，优秀党员朱雪芬值得一个大大的赞！",
          images: ["http://dssq.ikeqiao.net/data/image/h5/images/coordination/zxf.jpg"],
          group: "润泽救助团",
          membernum: "91",
          helpnum: "29",
        },
        {
          commentContent:
            "风里来，雨里去，巡逻不停歇；掀井盖，通管道，徒手排积水。“烟花”很猛，党员丁成吉很赞！",
          group: "润泽救助团",
          membernum: "91",
          helpnum: "29",
          images: ["http://dssq.ikeqiao.net/data/image/h5/images/coordination/dcj.png"],
        },
        {
          commentContent:
            "东盛社区张晓鸿书记获得“全国优秀共产党员”称号并在人民大会堂接受表彰。我们为你骄傲！",
          group: "润泽救助团",
          membernum: "91",
          helpnum: "29",
          images: ["http://dssq.ikeqiao.net/data/image/h5/images/coordination/zxh.jpg"],
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    tip() {
      this.$message("页面正在建设中");
    },
    gotoLink(path) {
      this.$router.push(path);
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.serve-group-body-container {
  height: calc(100vh - 3rem);
  width: 100vw;
  background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
  background-size: 100% 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .van-tabs__line {
    background-color: #4e79f2;
  }
  .ser-banner {
    width: 100%;
    height: 6.4rem;
    overflow: hidden;
    img {
      height: 6.4rem;
      width: 100%;
    }
  }
  .cata-box {
    .ser-list {
      li {
        background: #fff;
        border-radius: 0.3rem;
        padding: 0.5rem 0.7rem;
        position: relative;
        margin-top: 0.8rem;
        .title {
          line-height: 24px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        .itemDesc {
          width: 100%;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .image-list-con {
          width: 100%;
          // padding: 10px;
          display: flex;
          flex-wrap: wrap;
          > img {
            width: 56px;
            height: 56px;
            border-radius: 4px;
            margin: 5px;
          }
        }
        .data-info {
          width: 100%;
          margin: 10px 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          span {
            color: #4479ed;
            margin-left: 1rem;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
