<template>
  <div id="index">
    <div class="edu-container">
      <div class="profile">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/faceTmp.jpg" />
        <div>张阿三</div>
      </div>
      <div class="about-nav">
        <ul>
          <li @click="tip">
            <i class="icon1"></i><b>我的家人</b><span></span>
          </li>
          <li @click="tip">
            <i class="icon2"></i><b>我的收藏</b><span></span>
          </li>
          <li @click="tip">
            <i class="icon3"></i><b>我的咨询</b><span></span>
          </li>
          <li @click="tip">
            <i class="icon4"></i><b>我的投诉</b><span></span>
          </li>
        </ul>
      </div>
      <div class="other">
        <div class="bd">
          <b>注册绑定</b>
          <div>
            <el-tag type="blue" @click="tip">我是机构</el-tag>
            <el-tag type="success" @click="tip">我是老师</el-tag>
          </div>
        </div>
        <i></i>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "./footer";
export default {
  data() {
    return {};
  },
  components: { Footer },
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    tip() {
      this.$message("页面正在建设中");
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
