<template>
  <div>
    <div class="reply-list">
      <ul>
        <li v-for="item in list" :key="item.id" @click="onCanTakeClick(item)">
          <div class="tit">{{ item.title }}</div>
          <p>{{ item.content }}</p>
          <div class="data">
            <div><label>接送学校：</label><span class="blue">{{ item.age }}</span></div>

            <div>
              <label>接送方式：</label><span class="blue">{{ item.meetMethod }}</span>
            </div>
            <div><label>接送时间：</label><span class="blue">{{ item.meetTime }}</span></div>
          </div>
          <div class="person-info">
            <img :src="item.image" />
            <div>{{ item.owner }}</div>
            <span class="state">发布时间：{{ item.createTime }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Search } from "vant";
import yqpddApi from "api/yqpdd";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      value: "",
      status: "",
      sortField: "",
      mode: "",
      meetMethod: "", // 接送方式
      list: [],
      option1: [
        { text: "接送方式", value: "" },
        { text: "不限", value: "" },
        { text: "汽车", value: "汽车" },
        { text: "自行车", value: "自行车" },
        { text: "电瓶车", value: "电瓶车" },
        { text: "步行", value: "步行" },
        { text: "其他", value: "其他" },
      ],
      option2: [
        { text: "更多筛选", value: "" },
        { text: "年龄段", value: 0 },
        { text: "接送时间段", value: 1 },
      ],
    };
  },
  components: {},
  mounted() {
    this.getlist({ page: 1, size: 10 });
  },
  methods: {
    ...mapMutations(["setCanTakeDetail"]),
    onCanTakeClick(item) {
      this["setCanTakeDetail"](item);
      this.gotoLink('cantakeDetail')
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleChange(e, type) {
      this[type] = e;
      this.onSearch();
    },
    getlist({ page = this.page, size = this.size} = {}) {
      const params = {
        page,
        size,
        object: {
          meetMethod: this.meetMethod,
        },
        ...params,
      };
      yqpddApi.getChildren(params).then((res) => {
        const { records = [], total = 0 } = res.data || {};
        this.list = this.list.concat(records);
        this.total = total;
        if (this.list.length >= this.total) {
          // 数据全部加载完成
          this.finished = true;
        }
      });
    },
    onSearch(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          meetMethod: this.meetMethod,
        },
      };
      yqpddApi.getChildren(params).then((res) => {
        const { records = [], total = 0 } = res.data || {};
        this.list =records;
        this.total = total;
        if (this.list.length >= this.total) {
          // 数据全部加载完成
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  padding: 0;
  .nrb-box {
    margin-top: 2.8rem;
    .van-tab--active {
      color: #1890ff;
    }
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    -webkit-box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    font-size: 13px;
    color: #fff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
  .reply-list {
    width: 23.4rem;
    margin: 0 auto;
    padding: 0.6rem 0;
    li {
      padding: 0.8rem 0;
      //border-bottom: 1px solid #ddd;
      font: 500 0.9rem/1.4rem "microsoft yahei";
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      .data {
        font-size: 0.9rem;
        label {
          color: #bbb;
        }
        .blue {
          color: #1890ff;
        }
      }
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      p {
        color: #7a7a7a;
      }
      .person-info {
        margin-top: 0.4rem;
        position: relative;
        height: 2rem;
        img {
          width: 1.5rem;
          height: 1.5rem;
          float: left;
          border-radius: 50%;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        div {
          float: left;
          font: 500 0.8rem/1.5rem "microsoft yahei";
          color: #333;
        }
        .state {
          color: #bbb;
          float: right;
          i {
            color: #1890ff;
            font-style: inherit;
          }
        }
      }
      .state {
        padding-top: 0.2rem;
        span {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.8rem;
          background: #e8e8e8;
          color: #101010;
        }
      }
    }
  }
}
</style>
