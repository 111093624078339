<template>
  <div class="container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>健康随访</h1>
    </div>
    <div class="main">
      <div class="form">
        <div class="sub-main">
          <label>随访详情</label>
          <div class="sub-content">
            <div class="cont block" v-if="type == '0'">
              <div class="info" v-if="followDtl.sfrq">
                <span class="label">随访日期</span
                ><span class="val"> {{ followDtl.sfrq }}</span>
              </div>
              <div class="info" v-if="followDtl.sffs">
                <span class="label">随访方式</span
                ><span class="val"> {{ followDtl.sffs }}</span>
              </div>
              <div class="info" v-if="followDtl.hzzgzzxs">
                <span class="label">症状</span
                ><span class="val"> {{ followDtl.hzzgzzxs }}</span>
              </div>
              <div class="info" v-if="followDtl.ssy">
                <span class="label">收缩压</span
                ><span class="val"> {{ followDtl.ssy }}</span>
              </div>
              <div class="info" v-if="followDtl.szy">
                <span class="label">舒张压</span
                ><span class="val"> {{ followDtl.szy }}</span>
              </div>
              <div class="info" v-if="followDtl.sg">
                <span class="label">身高</span
                ><span class="val"> {{ followDtl.sg }}</span>
              </div>
              <div class="info" v-if="followDtl.tz">
                <span class="label">体重</span
                ><span class="val"> {{ followDtl.tz }}</span>
              </div>
              <div class="info" v-if="followDtl.bmi">
                <span class="label">bmi</span
                ><span class="val"> {{ followDtl.bmi }}</span>
              </div>
              <div class="info" v-if="followDtl.tzkzjy">
                <span class="label">体重控制建议</span
                ><span class="val"> {{ followDtl.tzkzjy }}</span>
              </div>
              <div class="info" v-if="followDtl.bmijy">
                <span class="label">体质指数建议</span
                ><span class="val"> {{ followDtl.bmijy }}</span>
              </div>
              <div class="info" v-if="followDtl.yw">
                <span class="label">腰围</span
                ><span class="val"> {{ followDtl.yw }}</span>
              </div>
              <div class="info" v-if="followDtl.xyqk">
                <span class="label">吸烟情况</span
                ><span class="val"> {{ followDtl.xyqk }}</span>
              </div>
              <div class="info" v-if="followDtl.xyl">
                <span class="label">日吸烟量</span
                ><span class="val"> {{ followDtl.xyl }}</span>
              </div>
              <div class="info" v-if="followDtl.yjqk">
                <span class="label">饮酒情况</span
                ><span class="val"> {{ followDtl.yjqk }}</span>
              </div>
              <div class="info" v-if="followDtl.yjl">
                <span class="label">日饮酒量</span
                ><span class="val"> {{ followDtl.yjl }}</span>
              </div>
              <div class="info" v-if="followDtl.yjlx">
                <span class="label">饮酒种类</span
                ><span class="val"> {{ followDtl.yjlx }}</span>
              </div>
              <div class="info" v-if="followDtl.ydqk">
                <span class="label">运动情况</span
                ><span class="val"> {{ followDtl.ydqk }}</span>
              </div>
              <div class="info" v-if="followDtl.zyydzl">
                <span class="label">主要运动方式</span
                ><span class="val"> {{ followDtl.zyydzl }}</span>
              </div>
              <div class="info" v-if="followDtl.zyydfs">
                <span class="label">其他详述</span
                ><span class="val"> {{ followDtl.zyydfs }}</span>
              </div>
              <div class="info" v-if="followDtl.ydzcs">
                <span class="label">每周运动次数</span
                ><span class="val"> {{ followDtl.ydzcs }}</span>
              </div>
              <div class="info" v-if="followDtl.ydcfz">
                <span class="label">每次运动时间</span
                ><span class="val"> {{ followDtl.ydcfz }}</span>
              </div>
              <div class="info" v-if="followDtl.ysrlcd">
                <span class="label">盐摄入量程度</span
                ><span class="val"> {{ followDtl.ysrlcd }}</span>
              </div>
              <div class="info" v-if="followDtl.ysrl">
                <span class="label">盐摄入量</span
                ><span class="val"> {{ followDtl.ysrl }}</span>
              </div>
              <div class="info" v-if="followDtl.gzys">
                <span class="label">高脂饮食</span
                ><span class="val"> {{ followDtl.gzys }}</span>
              </div>
              <div class="info" v-if="followDtl.jyxyl">
                <span class="label">日吸烟量建议</span
                ><span class="val"> {{ followDtl.jyxyl }}</span>
              </div>
              <div class="info" v-if="followDtl.jyyjl">
                <span class="label">日饮酒量建议</span
                ><span class="val"> {{ followDtl.jyyjl }}</span>
              </div>
            </div>

            <div class="cont block" v-if="type == '1'">
              <div class="info" v-if="followDtl.sfrq">
                <span class="label">随访日期</span
                ><span class="val"> {{ followDtl.sfrq }}</span>
              </div>
              <div class="info" v-if="followDtl.sffs">
                <span class="label">随访方式</span
                ><span class="val"> {{ followDtl.sffs }}</span>
              </div>
              <div class="info" v-if="followDtl.xcsfrq">
                <span class="label">下次随访日期</span
                ><span class="val"> {{ followDtl.xcsfrq }}</span>
              </div>
              <div class="info" v-if="followDtl.sfysxm">
                <span class="label">随访医生姓名</span
                ><span class="val"> {{ followDtl.sfysxm }}</span>
              </div>
              <div class="info" v-if="followDtl.ssy">
                <span class="label">收缩压</span
                ><span class="val"> {{ followDtl.ssy }}</span>
              </div>
              <div class="info" v-if="followDtl.szy">
                <span class="label">舒张压</span
                ><span class="val"> {{ followDtl.szy }}</span>
              </div>
              <div class="info" v-if="followDtl.kfxt">
                <span class="label">空腹血糖</span
                ><span class="val"> {{ followDtl.kfxt }}</span>
              </div>
              <div class="info" v-if="followDtl.chxt">
                <span class="label">餐后血糖</span
                ><span class="val"> {{ followDtl.chxt }}</span>
              </div>
              <div class="info" v-if="followDtl.sg">
                <span class="label">身高</span
                ><span class="val"> {{ followDtl.sg }}</span>
              </div>
              <div class="info" v-if="followDtl.tz">
                <span class="label">体重</span
                ><span class="val"> {{ followDtl.tz }}</span>
              </div>
              <div class="info" v-if="followDtl.bmi">
                <span class="label">bmi</span
                ><span class="val"> {{ followDtl.bmi }}</span>
              </div>
              <div class="info" v-if="followDtl.tzkzjy">
                <span class="label">体重控制建议</span
                ><span class="val"> {{ followDtl.tzkzjy }}</span>
              </div>
              <div class="info" v-if="followDtl.bmijy">
                <span class="label">体质指数建议</span
                ><span class="val"> {{ followDtl.bmijy }}</span>
              </div>
              <div class="info" v-if="followDtl.yw">
                <span class="label">腰围</span
                ><span class="val"> {{ followDtl.yw }}</span>
              </div>
              <div class="info" v-if="followDtl.xyqk">
                <span class="label">吸烟情况</span
                ><span class="val"> {{ followDtl.xyqk }}</span>
              </div>
              <div class="info" v-if="followDtl.xyl">
                <span class="label">日吸烟量</span
                ><span class="val"> {{ followDtl.xyl }}</span>
              </div>
              <div class="info" v-if="followDtl.yjqk">
                <span class="label">饮酒情况</span
                ><span class="val"> {{ followDtl.yjqk }}</span>
              </div>
              <div class="info" v-if="followDtl.yjl">
                <span class="label">日饮酒量</span
                ><span class="val"> {{ followDtl.yjl }}</span>
              </div>
              <div class="info" v-if="followDtl.yjlx">
                <span class="label">饮酒种类</span
                ><span class="val"> {{ followDtl.yjlx }}</span>
              </div>
              <div class="info" v-if="followDtl.ydqk">
                <span class="label">运动情况</span
                ><span class="val"> {{ followDtl.ydqk }}</span>
              </div>
              <div class="info" v-if="followDtl.zyydzl">
                <span class="label">主要运动方式</span
                ><span class="val"> {{ followDtl.zyydzl }}</span>
              </div>
              <div class="info" v-if="followDtl.zyydfs">
                <span class="label">其他详述</span
                ><span class="val"> {{ followDtl.zyydfs }}</span>
              </div>
              <div class="info" v-if="followDtl.ydzcs">
                <span class="label">每周运动次数</span
                ><span class="val"> {{ followDtl.ydzcs }}</span>
              </div>
              <div class="info" v-if="followDtl.ydcfz">
                <span class="label">每次运动时间</span
                ><span class="val"> {{ followDtl.ydcfz }}</span>
              </div>
              <div class="info" v-if="followDtl.ysrlcd">
                <span class="label">盐摄入量程度</span
                ><span class="val"> {{ followDtl.ysrlcd }}</span>
              </div>
              <div class="info" v-if="followDtl.ysrl">
                <span class="label">盐摄入量</span
                ><span class="val"> {{ followDtl.ysrl }}</span>
              </div>
              <div class="info" v-if="followDtl.gzys">
                <span class="label">高脂饮食</span
                ><span class="val"> {{ followDtl.gzys }}</span>
              </div>
              <div class="info" v-if="followDtl.jyxyl">
                <span class="label">日吸烟量建议</span
                ><span class="val"> {{ followDtl.jyxyl }}</span>
              </div>
              <div class="info" v-if="followDtl.jyyjl">
                <span class="label">日饮酒量建议</span
                ><span class="val"> {{ followDtl.jyyjl }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-main" v-if="followDtl.mbyyjl">
          <label>用药记录</label>
          <!-- <div class="sub-content" style="height:auto;"> -->
          <div scroll-y class="sub-content">
            <div class="cont">
              <template v-for="item in followDtl.mbyyjl">
                <div class="sub-cont">
                  <div class="info">
                    <span class="label">药品名称</span
                    ><span class="val"> {{ item.ypmc }}</span>
                  </div>
                  <div class="info">
                    <span class="label">每日次数</span
                    ><span class="val"> {{ item.ffcs }}</span>
                  </div>
                  <div class="info" v-if="item.fyff">
                    <span class="label">服用方法</span
                    ><span class="val"> {{ item.fyff }}</span>
                  </div>
                  <div class="info" v-if="item.jl">
                    <span class="label">单次计量</span
                    ><span class="val"> {{ item.jl }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      followDtl: {},
      type: 0,
    };
  },
  components: {},
  mounted() {
   this.getFollowDetail();
  },
  methods: {
    getFollowDetail: function() {
      let _this = this;
      let followId = this.$route.params.id;
      let token = this.getSessionData("healthtToken");
      let type = this.$route.params.type;
      this.axios
        .get(
          "https://yyh.ikeqiao.net/app/health/followDtl//" +
            type +
            "/" +
            followId,
          {
            headers: { token: token },
          }
        )
        .then(function(res) {
          _this.followDtl = res.data.followDtl;
        })
        .catch(function(err) {});
    },
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/health.scss";
</style>
