<template>
  <div class="educationMap-container">
    <van-nav-bar
      title="东盛地图"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="education-map-body-container">
      <!-- 地图 -->
      <div class="map-con" id="mapContain" tabindex="0"></div>
      <!-- 搜索栏 -->
      <!-- <van-cell-group class="search-con">
        <mt-navbar v-model="selectedItem">
          <mt-tab-item :id="item.id" v-for="(item, index) in selectList" :key="index">{{item.name}}({{item.count}})</mt-tab-item>
        </mt-navbar>
      </van-cell-group> -->
      <!-- 详情弹窗 -->
      <div
        v-if="showModal"
        class="popupContent"
      >
        <div class="close" @click="closePopup">✖️</div>
        <div class="point-detail-con">
          <div class="detail-info" @click="showDetailPage(detailRecord)">
            <div class="detail-info-image">
              <img :src="detailRecord.iconPath" />
            </div>
            <div class="detail-info-text">
              <div class="detail-info-title">
                <h1>{{detailRecord.name}}</h1>
              </div>
              <div class="location-text">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/location-icon.png" alt="">
                <span>{{detailRecord.address}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zjyApi from 'api/zjy';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/educationMap';
export default {
  data() {
    return {

      selectedItem: '全部',
      selectList: [
        { id: '全部', name: '全部', count: 7},
        { id: '学校', name: '学校', count: 3},
        { id: '博物馆', name: '博物馆', count: 2},
        { id: '美术馆', name: '美术馆', count: 2},
      ],
      condition: '',
      list: ['浣东街道', '柯岩街道', '华舍街道', '齐贤街道'],
      mapObj: {},
      markerList: [],
      showModal: false,
      detailRecord: {},
      refreshCount: 0,
      mapCenter: [120.262403,29.716905],
      locationMark: null,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const _this = this;
    _this.mapObj = new window.AMap.Map('mapContain', {
      resizeEnable: true,
      zoom: 14,
      center: [120.262403,29.716905],//中心点坐标
      scrollWheel: false,
      
    });
    _this.mapObj.panBy(0, 1);
    this.getMarkerList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getMarkerList() {
      zjyApi.getMarkList().then(res => {
        if(res.flag) {
          this.markerList = res.data;
          this.drawMark(this.markerList);
        }
      })
    },
    drawMark(list) {
      if (this.markerList.length > 0) {
        this.mapObj.remove(this.markerList);
        this.markerList = [];
      }
      list.forEach(v => {
        const { lng, lat, icon_path} = v;
        if(lng && lat) {
          const marker = new window.AMap.Marker({
            icon: new window.AMap.Icon({
              // image: require(`http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/marker_${type}.png`),
              image: require('../../assets/images/zjy/ms_marker.png'),
              imageSize: new window.AMap.Size(22,26),
            }),
            // icon: icons.icon || '../../../yhcz/static/mapIcon/marker_yfp.png',
            position: [Number(lng),Number(lat)],
            offset: new window.AMap.Pixel(0, 0)
          });
          marker.on('click', (e) => {
            this.showDetailInfo(v);
          })
          marker.setMap(this.mapObj);
          this.markerList.push(marker);
        }
      })
    },
    showDetailInfo(data) {
      console.log('data', data)
      this.detailRecord = data;
      this.showModal = true;
    },
    closePopup(e){
      e.stopPropagation();
      this.showModal = false;
    },
    showDetailPage(data){
        this.$router.push(`/zjyDetail?id=${data.id}`)
    },
  }
};
</script>

<style lang="scss" scoped>
.educationMap-container {
  height: 100vh;
  width: 100vw;
  .mint-navbar{
    width: 100%;
  }
  .mint-tab-item-label{
    font-size: 16px;
    color: rgba(0,0,0,0.65);
    letter-spacing: 0;
    font-weight: 400;
  }
  .education-map-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .map-con {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .search-con {
      display: flex;
      background-color: #FFF;
      width: 100%;
      .search-bar {
        background: #FFF;
        width: 80%;
      }
      .dropdown-menu-bar {
        flex: 1;
        ::v-deep .van-dropdown-menu__bar {
          box-shadow: none;
        }
        .radio {
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          .radio-btn {
            width: 81px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #DCDEE0;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            text-align: center;
            line-height: 32px;
            margin-right: 5px;
          }
          .selected-radio-btn {
            background: #4479ed;
            color: #fff;
            border: none;
          }
        }
        .dropdown-menu-footer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 10px 0;
          .dropdown-menu-btn {
            width: 46vw;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #DCDEE0;
            border-radius: 4px;
            color: rgba(0,0,0,0.85);
            text-align: center;
            line-height: 40px;
            margin: 0 auto;
          }
          .blue {
            background: #4479ed;
            color: #FFF;
          }
        }
        
      }
    }
    .map-icons-con {
      position: fixed;
      top: 110px;
      left: 10px;
      background: #FFF;
      border-radius: 4px;
      padding: 10px;
      width :110px;
      .map-icons-item {
        display: flex;
        align-items: center;
        height: 24px;
        >img {
          width: 24px;
          height: 24px;
          // margin: auto 10px;
        }
      }
    }
    .point-detail-con {
      background-color: #F7F8FA;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      .detail-info {
        background: #FFF;
        display: flex;
        padding: 10px;
        .detail-info-image {
          margin-right: 10px;
          >img {
            width: 6rem;
            height: 6rem;
          }
        }
        .detail-info-text {
          flex: 1;
          .detail-info-title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            >h1 {
              width: 220px;
              line-height: 18px;
              font-size: 16px;
              color: rgba(0,0,0,0.85);
              white-space: wrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
            >span {
              background: #E3EFFD;
              border-radius: 2px;
              font-size: 12px;
              color: #4479ED;
              padding: 2px;
            }
          }
          .icon-text {
            display: flex;
            height: 24px;
            color: rgba(0,0,0,0.85);
            font-size: 14px;
            align-items: center;
            >img {
              height: 16px;
              width: 16px;
            }
          }
          .location-text {
             display: flex;
            height: 24px;
            color: rgba(0,0,0,0.45);
            font-size: 12px;
            align-items: center;
            >img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      .map-activity-con {
        width: 100%;
        background: #FFF;
        margin-top: 12px;
        .map-activity-title {
          width: 100%;
          height: 33px;
          padding: 10px;
          background: #fff;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          .text-title {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            font-weight: 500;
          }
          .text-more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            float: right;
            display: flex;
            align-items: center;
          }
        }
        .map-activity-list {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          padding: 10px;
          .map-activity-card {
            width: 252px !important;
            border-radius: 4px;
            margin-right: 10px;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
            .map-activity-image {
              width: 100%;
              height: 87px;
            }
            .map-activity-title-1 {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 10px;
              >h1 {
                font-size: 14px;
                color: rgba(0,0,0,0.85);
                letter-spacing: 0;
                font-weight: 500;
              }
              >span {
                font-size: 12px;
                color: rgba(0,0,0,0.65);
                >b {
                  color: #4479ed;
                }
              }
            }
            .map-activity-text {
              font-size: 14px;
              line-height: 24px;
              color: rgba(0,0,0,0.85);
              padding: 0 10px;
              .text-label {
                color: rgba(0,0,0,0.45);
              }
            }
          }
        }
      }
    }
  }
  .popupContent{
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding: 10px;
    width: 100%;
    .close{
      position: absolute;
      right: 20px;
      top: 30px;
    }
  }
}
</style>
