<template>
  <div class="own-index">
    <div class="page-container" style="padding-top: 2.7rem">
      <div class="header-nav">
        <i @click="goBack"></i>
        <!-- <h1>我的拼单</h1> -->
        <mt-navbar v-model="selectedTitle" class="mint-navbar-own-title">
          <mt-tab-item id="yqpddA">我发起的</mt-tab-item>
          <mt-tab-item id="yqpddB">我拼成的</mt-tab-item>
        </mt-navbar>
      </div>
      <van-tabs v-model="selected" color="#4479ED">
        <van-tab title="审核中" v-if="selectedTitle === 'yqpddA'" name="yqpddShz">
          <ul v-if="pageDataList && pageDataList.length > 0" class="detail-img-box-two">
            <li v-for="item in pageDataList" :key="item.orderId">
              <div
                class="img"
                @click="gotoLink('yqpddDetail?key=' + item.orderId)"
              >
                <img :src="getImage(item.image)" />
                <div class="img-title left">
                  {{ item.title }}
                </div>
                <div class="img-des">
                  <span
                    >已有<span class="blue">{{ item.number }}</span
                    >人报名</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <van-empty
            v-else
            description="无数据"
          />
        </van-tab>
        <van-tab title="未通过" v-if="selectedTitle === 'yqpddA'" name="yqpddWtg">
          <ul v-if="pageDataList && pageDataList.length > 0" class="detail-img-box-two">
            <li v-for="item in pageDataList" :key="item.orderId">
              <div
                class="img"
                @click="gotoLink('yqpddDetail?key=' + item.orderId)"
              >
                <img :src="getImage(item.image)" />
                <div class="img-title left">
                  {{ item.title }}
                </div>
                <div class="img-des">
                  <span
                    >已有<span class="blue">{{ item.number }}</span
                    >人报名</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <van-empty
            v-else
            description="无数据"
          />
        </van-tab>
        <van-tab title="进行中" name="yqpddJxz">
          <ul v-if="pageDataList && pageDataList.length > 0" class="detail-img-box-two">
            <li v-for="item in pageDataList" :key="item.orderId">
              <div
                class="img"
                @click="gotoLink('yqpddDetail?key=' + item.orderId)"
              >
                <img :src="getImage(item.image)" />
                <div class="img-title left">
                  {{ item.title }}
                </div>
                <div class="img-des">
                  <span
                    >已有<span class="blue">{{ item.number }}</span
                    >人报名</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <van-empty
            v-else
            description="无数据"
          />
        </van-tab>
        <van-tab title="已结束" name="yqpddYjs">
          <ul v-if="pageDataList && pageDataList.length > 0" class="detail-img-box-two">
            <li v-for="item in pageDataList" :key="item.orderId">
              <div
                class="img"
                @click="gotoLink('yqpddDetail?key=' + item.orderId)"
              >
                <img :src="getImage(item.image)" />
                <div class="img-title left">
                  {{ item.title }}
                </div>
                <div class="img-des">
                  <span
                    >已有<span class="blue">{{ item.number }}</span
                    >人报名</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <van-empty
            v-else
            description="无数据"
          />
        </van-tab>
        <van-tab title="已取消" name="yqpddYqx">
          <ul v-if="pageDataList && pageDataList.length > 0" class="detail-img-box-two">
            <li v-for="item in pageDataList" :key="item.orderId">
              <div
                class="img"
                @click="gotoLink('yqpddDetail?key=' + item.orderId)"
              >
                <img :src="getImage(item.image)" />
                <div class="img-title left">
                  {{ item.title }}
                </div>
                <div class="img-des">
                  <span
                    >已有<span class="blue">{{ item.number }}</span
                    >人报名</span
                  >
                </div>
              </div>
            </li>
          </ul>
          <van-empty
            v-else
            description="无数据"
          />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Search } from "mint-ui";
import yqpddApi from "api/yqpdd";
export default {
  data() {
    return {
      selectedTitle: "yqpddA",
      selected: "yqpddShz",
      pageDataList: [],
    };
  },
  watch: {
    selected(val, oldVal) {
      if (val === "yqpddShz") {
        this.getList(
          { page: 1, size: 10, object: { access: '审核中' } }
        );
      } else if (val === "yqpddWtg") {
        this.getList(
          { page: 1, size: 10, object: { access: '未通过' } }
        );
      } else if (val === 'yqpddJxz'){
        this.getList(
          { page: 1, size: 10, object: { status: 0 } }
        );
      } else if (val === 'yqpddYqx'){
        this.getList(
          { page: 1, size: 10, object: { status: 1 } }
        );
      } else {
        this.getList(
          { page: 1, size: 10, object: { status: 2 } }
        );
      }
    },
    selectedTitle(val) {
      const params = {
        yqpddShz: { access: '审核中' },
        yqpddWtg: { access: '未通过' },
        yqpddJxz: { status: 0 },
        yqpddYqx: { status: 1 },
        yqpddYjs: { status: 2 }
      };
      this.getList({
        page: 1,
        size: 10,
        object: params[this.selected]
      })
    }
  },
  components: {},
  mounted() {
    this.getList(
      {
        page: 1,
        size: 10,
        object: {
          access: '审核中',
        },
      }
    );
  },
  methods: {
    getImage(images) {
      const list = images.split("#");
      return list[0];
    },
    gotoLink(path) {
      this.$router.push({
        path: path,
        params: { name: "我的拼单", selected: this.selected },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getList(params) {
      console.log(this.selectedTitle)
      if (this.selectedTitle === "yqpddA") {
        // 查询我发起的
        yqpddApi.getOwnLaunchOrders(params).then((res) => {
          const { records = [] } = res.data;
          records &&
            records.length &&
            records.map((item) => (item.img = item.image.split("#")[0]));
          this.pageDataList = records;
        });
      } else {
        // 查询我拼成的
        yqpddApi.getOwnCompleteOrders(params).then((res) => {
          const { records = [] } = res.data;
          records &&
            records.length &&
            records.map((item) => (item.img = item.image.split("#")[0]));
          this.pageDataList = records;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
