<template>
  <div class="home-index" style="background: #fff; height: 100vh">
    <div class="page-container paddclose" style="height: calc(100vh - 5rem);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>团队介绍</h1>
      </div>
      <div class="hot-main" style="width: 100%;">
        <div class="top_banner" v-if="showData.picture">
          <img :src="showData.picture" />
        </div>
        <div class="desc_title">{{showData.name}}</div>
        <div class="desc_content" v-for="(item, i) in (showData.contents || [])" :key="i">
          {{item}}
        </div>
        <div class="desc_title">{{showData.title2}}</div>
        <div class="desc_image">
          <img :src="item" alt="" v-for="(item, i) in (showData.pictures || [])" :key="i" />
        </div>
      </div>
    </div>
    <div class="foot-button1" @click="showDialog = true">互动招募</div>
    <van-dialog
      v-model="showDialog"
      title="互动招募"
      show-cancel-button
      @cancel="showDialog = false"
      @confirm="addOrderEnroll"
    >
      <van-cell-group style="margin: 0.5rem 0;">
        <van-field
          v-model="name"
          label="报名人姓名"
          placeholder="请输入报名人姓名"
          maxlength="10"
        />
        <van-field
          v-model="phone"
          label="联系手机"
          placeholder="请输入联系手机"
          type="tel"
        />
        <van-field
          v-model="habbit"
          label="特长"
          placeholder="请输入您的特长"
          type="text"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant';
import service from 'utils/service';
export default {
  data() {
    return {
      selected: "wed",
      showData: {},
      showDialog: false,
      name: '',
      phone: '',
      habbit: '',
    };
  },
  components: {},
  mounted(opt) {
    const { key } = this.$route.query;
    this.getInfo({id: key});
    const userInfo = this.getSessionData('userInfo') || {};
    this.name = userInfo.userName || userInfo.nickname || '';
    this.phone = userInfo.phone || '';
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    addOrderEnroll() {
      Toast.success('报名成功');
      this.showDialog = false;
    },
    getInfo(params) {
      service.post('/homePageCulture/selectDetail', params).then(res => {
        console.log('aa', res)
        if(res.flag) {
          this.showData = res.data;
        }
      })
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .hot-main{
    height: 100% !important;
  }
  .top_banner{
    padding: 10px;
    width: 100%;
    >img{
      width: 100%;
      height: 220px;
    }
  }
  .desc_title{
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    text-align: center;
  }
  .desc_content{
    font-size: 14px;
    text-align: left;
    color: rgba(0,0,0,0.85);
    line-height: 22px;
    padding: 10px;
    text-indent: 24px;
  }
  .desc_image{
    padding: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >img{
      margin-top: 10px;
      width: 48%;
    }
  }

  .foot-button1 {
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    background: #4479ed;
    border-radius: 4px;
    line-height: 3rem;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
  }
</style>
