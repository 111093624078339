<template>
  <div class="activity-need-list-con">
    <div class="item" v-for="(item,i) in (dataList || [])" :key='i' @click="gotoLink('knowledgeGroupDetail?key='+i)">
      <div class="itemName">
        <div class="image">
          <img :src="item.image" alt="">
        </div>
        <div class="text">
          <div class="title">
            {{item.title}}
          </div>
          <div class="info">
            <span class="info-item">
              成员：
              <span class="info-value">{{item.num}}</span>
            </span>
            <span class="info-item">
              活动：
              <span class="info-value">{{item.activity}}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="itemDesc">
        {{item.commentContent}}
      </div>
      <div class="image-list-con">
        <img v-for="(item, index) in item.images" :src="item" :key="index" alt="">
      </div>
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'knowledgeGroupList',
  data() {
    return {
      
    }
  },
  props: {
    dataList: {
      type: Array,
      default: function() {
        return [
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            title: '润泽智囊团',
            createTime: '2021-05-21 12:00',
            commentContent: '智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介…',
            images: [
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
            ],
            num: 20,
            activity: 123
          },
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            title: '香水湾智囊团',
            createTime: '2021-05-21 12:00',
            commentContent: '智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介…',
            images: [
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
            ],
            num: 20,
            activity: 123
          },
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            title: '香林智囊团',
            createTime: '2021-05-21 12:00',
            commentContent: '智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介绍智囊团介…',
            images: [
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
              'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
            ],
            num: 20,
            activity: 123
          }
        ];
      }
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    
  },
  methods: {
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-need-list-con {
  width: 100%;
  margin: 0 auto;
  border: none;
  .item{
    position: relative;
    padding: 10px;
    background-color: #FFF;
    margin-bottom: 10px;
    border-radius: 4px;
    .itemName{
      display: flex;
      height: 50px;
      padding: 5px;
      .image{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        >img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .text{
        width: calc(100% - 50px);
        height: 100%;
        line-height: 20px;
        color: rgba(0,0,0,0.45);
        font-size: 14px;
        .title {
          color: rgba(0,0,0,0.85);
          font-size: 16px;
          font-weight: 500;
          .tag {
            background: #E3EFFD;
            border-radius: 2px;
            font-size: 12px;
            color: #4479ED;
            padding: 3px 6px;
          }
        }
        .info {
          .info-item {
            margin-right: 10px;
            .info-value {
              color: #4479ED;
              font-weight: 500;
            }
          }
        }
        
      }
    }
    .itemDesc{
      width: 100%;
      color: rgba(0,0,0,0.85);
      line-height: 24px;
      font-size: 16px;
      margin-top: 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .image-list-con {
      width: 100%;
      // padding: 10px;
      display: flex;
      flex-wrap: wrap;
      >img {
        width: 56px;
        height: 56px;
        border-radius: 4px;
        margin: 5px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background-color: rgba(17,31,44,0.10);
      margin: 10px 0;
    }
    .footer {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      line-height: 50px;
    }
  }
}
</style>