<template>
  <div class="evergreen-class-container">
    <van-nav-bar
      title="常青课堂"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="ever-green-class-body-container">
      <evergreenClassList />
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import evergreenClassList from './evergreenClassList';
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: { evergreenClassList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.evergreen-class-container {
  height: 100vh;
  width: 100vw;
  
  .ever-green-class-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
