<template>
  <div class="myfamily-container">
    <van-nav-bar
      title="我的家人"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="my-family-body-container">
      <van-cell v-for="item in digitalMembers" :key="item.name" center :title="item.name" :label="`${item.relation} ${item.age}岁`">
        <template #default>
          <span style="color: #4479ed;" @click="handleEdit(item)">编辑</span>
        </template>
      </van-cell>
      <div class="footer-button">
        <van-button
          block
          type="info"
          style="font-size: 16px"
          @click="gotoLink('addFamily')"
        >
          新增家人
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState({
      digitalMembers: state => state.digitalMember.digitalMembers,
    })
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleEdit(data) {
      this.$store.commit('digitalMember/setCurrentMembers', data);
      this.$router.push('editFamily')
    }
  }
};
</script>

<style lang="scss" scoped>
.myfamily-container {
  height: 100vh;
  width: 100vw;
  
  .my-family-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      display: flex;
      align-items:  center;
    }
  }
}
</style>
