<template>
  <div class="znt-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="znt-detail-body-container">
      <div class="zntItem">
        <div class="zntItemIconName">
          <div class="image">
            <img :src="data.icon" alt="">
          </div>
          <div class="text">
            <div style="color: #000; font-size: 16px;font-weight: bold">{{data.title}}</div>
            <div>
              人数：
              <span style="color: #26a2ff; margin-right: 10px;">{{data.memberNum}}</span>
              回答：
              <span style="color: #26a2ff; margin-right: 10px;">{{data.activityNum}}</span>
            </div>
          </div>
        </div>
        <div v-html="data.content" class="zntItemDesc">
          <!-- {{item.content}} -->
        </div>
      </div>
      
      <div class="userIconList">
        <div class="userIconList-title"><i></i>成员介绍</div>
        <!-- <div class="iconItem" v-for="(v, k) in (data.users || [])" :key="k">
          <div class="zntItemIconName">
            <div class="image">
              <img :src="v.image" alt="">
            </div>
            <div class="text">
              <div style="color: #000; font-size: 16px;font-weight: bold">{{v.nickname}}</div>
              <div>
                加入时间：
                <span style="color: #26a2ff; margin-right: 10px;">{{v.time}}</span>
                发布活动：
                <span style="color: #26a2ff; margin-right: 10px;">{{v.num}}</span>
              </div>
            </div>
          </div>
          <p class="desc">{{v.desc}}</p>
        </div> -->
        <div class="iconList">
          <div class="iconItem" v-for="(v, k) in (data.members || [])" :key="k">
            <div class="topIcon"><img :src="v.headImage" alt=""></div>
            <div class="bottomIcon">{{v.username}}</div>
          </div>
        </div>
        
      </div>
      <div @click="joinZnt(data)" class="editBtn" :style="`background: ${data.status === '申请中' ? 'rgba(0,0,0,0.5)' : data.status === '已参加' ? '#f00' : '#26a2ff'}`">{{data.status === '申请中' ? '申请中' : data.status === '已参加' ? '取消加入' : '我要加入'}}</div>
    </div>
  </div>
</template>

<script>
import llyjrApi from 'api/llyjr';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/zntDetail.json';
export default {
  data() {
    return {
      data: data,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { key } = this.$route.query;
    this.getDtail({ id: key });
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getDtail(obj) {
      llyjrApi
        .getZntDetail(obj)
        .then(res => {
          this.data = res.data || {};
        });
    },
    joinZnt(record){
      if(record.status === '已参加') {
        Dialog.confirm({
          title: "取消提示",
          message: "确定要退出智囊团吗？",
        }).then(() => {
          llyjrApi
          .deleteGroup({ groupId: record.id })
          .then(res => {
            console.log(res);
            const { flag } = res || {};
            if (flag) {
              Toast('取消加入成功！')
              this.getGroupList();
            }
          });
        })
        .catch(() => {
           Toast('取消加入失败')
        });
      } else if(record.status !== '已参加' || record.status !== '申请中') {
        console.log(11111)
        this.$router.push('joinZnt?key=' + record.id);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.znt-detail-container {
  height: 100vh;
  width: 100vw;
  
  .znt-detail-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .zntItem{
      padding: 10px;
      margin-bottom: 10px;
      position: relative;
      background-color: #FFF;
      .zntItemTitle{
        line-height: 30px;
        color: #000;
        font-size: 17px;
        font-weight: bold;
      }
      .zntItemName{
        line-height: 24px;
        color: #000;
        font-size: 12px;
        display: flex;
        img{
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
      .zntItemIconName{
        display: flex;
        height: 50px;
        padding: 5px;
        .image{
          width: 40px;
          height: 40px;
          margin-right: 10px;
          >img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .text{
          width: calc(100% - 50px);
          height: 100%;
          line-height: 20px;
          color: #707070;
        }
      }
      
      .tags{
        margin-left: 5%;
        background: rgba(0,0,0,0.15);
        border-radius: 5px;
        color: #000;
        padding: 0px 5px;
      }
      .resultType{
        position: absolute;
        right: -10px;
        top: 15px;
        height: 24px;
        line-height: 24px;
        padding: 0px 10px;
        padding-right: 15px;
        text-align: left;
        color: #fff;
        background: #26a2ff;
        border-radius: 12px;
      }
      
      .zntItemDesc{
        line-height: 24px;
        font-size: 14px;
        color: rgba(0,0,0,0.85);
      }
      .zntItemImage{
        display: flex;
        img{
          width: 60px;
          height: 60px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
      .zntItemTime{
        line-height: 24px;
        font-size: 12px;
        color: #707070;
        display: flex;
        .edit{
          margin-left: auto;
          margin-right: 10px;
          display: flex;
          >div{
            line-height: 20px;
          }
          img{
            width: 12px;
            height: 12px;
            margin-top: 3.5px;
          }
        }
      }
    }

    .userIconList{
      margin-top: 10px;
      background-color: #FFF;
      width: 100%;
      .userIconList-title {
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        padding-left: 16px;
        display: flex;
        align-items: center;
        >i {
          height: 16px;
          background-color: #4479ED;
          width: 3px;
          margin-right: 10px;
        }
      }
      .iconList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px 10px 10px;
        .iconItem{
          margin-right: 10px;
          margin-top: 10px;
          width: 50px;
          text-align: center;
          .zntItemIconName{
            display: flex;
            height: 50px;
            padding: 5px;
            .image{
              width: 40px;
              height: 40px;
              margin-right: 10px;
              >img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .text{
              width: calc(100% - 50px);
              height: 100%;
              line-height: 20px;
              color: #707070;
              text-align: left;
            }
          }
          .topIcon{
            width: 100%;
            height: 30px;
            padding: 0 10px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .bottomIcon{
            line-height: 20px;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .desc {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            text-align: left;
            line-height: 24px;
          }
        }
      }
      
    }
    .editBtn{
      position: fixed;
      right: 10px;
      bottom: 15px;
      left: 10px;
      height: 48px;
      line-height: 48px;
      padding: 0px 10px;
      text-align: center;
      color: #fff;
      background: #26a2ff;
      border-radius: 12px;
      font-size: 18px;
    }
  }
}
</style>
