<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack()"></i>
        <h1>招募志愿者</h1>
      </div>
      <div class="img-container">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/sdbxt/zyz1.png" alt="" />
      </div>
      <div class="recruit-volunteer">
        <div class="header">
          <span>社区学堂诚招志愿者</span>
        </div>
        <div class="main">
          <div class="amount">
            <span>数量: </span>
            <span>3人</span>
          </div>
          <div class="intro">
            <span>介绍: </span>
            <span
              >三点半公益课堂，旨在通过公益课堂的形式，解决辖区内“学生放学去哪儿”的问题。
              不少家长因为上班，没有时间接孩子放学，也有家长提出，孩子一个人在家写作业效率不高，或是功课有不懂的地方，也没人可以解答。于是社区依托之江学院的志愿优势，开展了“三点半课堂”活动，为社区内学生辅导课后作业。今年，社区在开展常规公益课堂的同时，开设亲子微课，邀请有志愿意向的家长一同参与进来，为孩子们开展英语启蒙、手工制作等活动，同时也增加亲子互动。</span
            >
          </div>
          <div class="amount" style="margin: 10px 0px;">
            <span>时间: </span>
            <span>周一至周五  15:30-17:30</span>
          </div>
        </div>
      </div>
      <van-button
        type="info"
        class="button"
        color="#4479ED"
        @click="gotoLink('volsign')"
      >
        我要报名
      </van-button>
    </div>
    <van-dialog
      v-model="showDialog"
      title="请确认报名信息"
      show-cancel-button
      @cancel="showDialog = false"
      @confirm="addVolunteer"
    >
      <van-cell-group style="margin: 0.5rem 0;">
        <van-field
          v-model="name"
          label="报名人姓名"
          placeholder="请输入报名人姓名"
          maxlength="10"
        />
        <van-field
          v-model="phone"
          label="联系手机"
          placeholder="请输入联系手机"
          type="tel"
        />
      </van-cell-group>
    </van-dialog>
    <van-dialog
      v-model="showCancel"
      title="提示"
      message="是否取消报名？取消后可重新报名"
      show-cancel-button
      @cancel="showCancel = false"
      @confirm="handleCancelConfirm"
    />
  </div>
</template>

<script>
import sdbxtApi from "api/sdbxt/sdbxt";
import { Toast } from "vant";
export default {
  data() {
    return {
      volunteerCount: 0,
      name: "",
      phone: "",
      showDialog: false,
      status: false, // 是否报名
      showCancel: false,
      sdbRecord: {},
    };
  },
  components: {},
  mounted() {
    // 学堂信息 暂时写死
    // sdbxtApi
    //   .getDateByPage({
    //     page: 1,
    //     size: 10,
    //   })
    //   .then((res) => {
    //     const { records = [] } = res.data;
    //     this.sdbRecord = records.length ? records[0] : {};
    //   });
    //查询今日志愿者报名数
    sdbxtApi
      .getVolunteerList({
        page: 1,
        size: 10,
      })
      .then((res) => {
        this.volunteerCount = res.data.count;
      });
    sdbxtApi.checkStatus({}).then((res) => {
      console.log("res", res, res.flag, res.data === "未报名");
      if (res.flag && res.data === "未报名") {
        this.status = false;
      } else {
        this.status = true;
      }
    });
    const userInfo = (this.getSessionData("userInfo")) || {};
    const { userName: name = "", phone = "" } = userInfo;
    this.name = name;
    this.phone = phone;
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    checkForm() {
      let message = "";
      if (!this.name || !/[\u4e00-\u9fa5]$/.test(this.name)) {
        message += "请输入正确的姓名！";
      }
      if (!this.phone || !/^1[0-9]{10}$/.test(this.phone)) {
        message += "请输入正确的联系手机！";
      }
      if (message) {
        Toast.fail(message);
        // this.name = '';
        // this.phone = '';
        return false;
      }
      return true;
    },
    handleEnroll() {
      // this.$store.dispatch("login/checkRealName", () => {
        this.showDialog = true;
      // });
    },
    handleCancelConfirm() {
      sdbxtApi.cancelVolunteer({}).then((res) => {
        Toast("报名取消成功");
        this.status = false;
      });
    },
    addVolunteer() {
      const { key } = this.$route.query;
      const { name, phone } = this;
      if (this.checkForm()) {
        //志愿者报名
        sdbxtApi
          .addVolunteer({
            name,
            phone,
            dsc: "三点半课堂介绍",
          })
          .then((res) => {
            this.volunteerCount = res.data.count;
            this.$toast("报名成功");
            this.status = true;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.button {
  position: fixed;
  left: 10vw;
  bottom: 1.0667rem;

  width: 80vw;
  height: 48px;
  font: 20px;
  border-radius: 6px;
}
.img-container {
  margin-top: 2.7rem;
  img {
    display: inline-block;
    width: 25rem;
    height: 10.667rem;
  }
}
.recruit-volunteer {
  margin-top: 1.133rem;
  margin-left: 0.8rem;
  overflow: hidden;
  .header {
    font: 600 1.0667rem/0.8rem "PingFangSC-Medium";
    margin-top: 3px;
  }
  .main {
    font: 0.933rem/0.8rem "PingFangSC-Regular";
    margin-top: 0.933rem;
    span:nth-child(1) {
      opacity: 0.45;
    }
    span:nth-child(2) {
      opacity: 0.85;
    }
    .intro {
      margin-top: 0.8rem;
      span:nth-child(2) {
        display: inline-block;
        width: 20.4rem;
        // height: 4.4rem;
        line-height: 1.4667rem;
        margin-left: 2.333rem;
        margin-top: -1.0667rem;
      }
    }
  }
}
</style>
