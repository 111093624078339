<template>
  <div class="activity-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="activity-pool-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="image-con">
          <img class="image" :src="data.picture" alt="">
          <span class="tag">{{data.tag}}</span>
          <div
            v-if="data.state !== '0'"
            class="time"
          >
            {{ data.time }}
          </div>
          <div v-if="data.state === '0'" class="end">报名已截止</div>
        </div>
        <div class="desc">
          <div class="title">
            <b>{{ data.title }}</b>
            <span><i class="collect"></i>收藏</span>
          </div>
          <div class="info">
            <label>发布单位：</label><span>{{ data.dep }}</span>
          </div>
          <div class="info">
            <label>活动时间：</label><span>{{ data.date }}</span>
          </div>
          <div class="info">
            <label>报名截止：</label><span>{{ data.deadline }}</span>
          </div>
          <div class="info">
            <label>活动地点：</label><span class="location-con">{{ data.location }}<i class="location"></i></span>
          </div>
        </div>
      </div>
      <!-- 报名情况 -->
      <div class="userIconList">
        <div class="userIconList-title"><i></i>成员介绍</div>
        <ul class="iconList">
          <li class="iconItem" v-for="(v, k) in (data.members || [])" :key="k">
            <div class="topIcon"><img :src="v.headImage || 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png'" alt=""></div>
            <div class="bottomIcon">{{v.username || '张三'}}</div>
          </li>
        </ul>
      </div>
      <!-- 详情介绍/评论 -->
      <div class="middle-bar">
        <van-tabs v-model="selectedTab" @click="handleTab" color="#4479ED">
          <van-tab title="详情介绍" name="introduce">
            <p class="comment-desc">{{data.desc}}</p>
          </van-tab>
          <van-tab :title="`全部反馈（${data.commentList.length}）`" name="comment">
            <commentList :zan="false" :dataList="data.commentList"></commentList>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 按钮 -->
      <div class="footer-button">
        <van-button
          block
          type="info"
          style="font-size: 16px"
        >
          我要报名
        </van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import commentList from 'components/commentList.vue';
export default {
  data() {
    return {
      data: {
        tag: '进行中',
        title: "开展微党课学习",
        state: "1",
        time: "剩20分钟结束",
        applyNum: "200",
        dep: "诸暨市党建总支部委员会",
        date: "2021-07-08 12:00至2021-07-08 14:00",
        deadline: '2021-07-08 12:00',
        location: '东盛社区邻里中心',
        num: 342,
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity-detail.png',
        desc: '详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍',
        commentList: [
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '公共资源需要大家共同承担。',
            hasThumbsUp: '未点赞',
            thumbsUp: 23,
          },
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '公共资源需要大家共同承担。',
            hasThumbsUp: '已点赞',
            thumbsUp: 23,
          }
        ],
        members: [1,2,3,4,5,6,7,8,9,10],
      },
      inputItem: '', // 感想
      selectedTab: 'introduce',
    };
  },
  computed: {
  },
  components: { commentList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.activity-detail-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFF;
  
  .activity-pool-detail-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;

    .top-bar {
      width: 100%;
      background: #FFF;
      margin-bottom: 10px;
      .image-con {
        width: 100%;
        height: 240px;
        position: relative;
        .image {
          height: 100%;
          width: 100%;
        }
        .tag {
          position: absolute;
          top: 12px;
          right: 12px;
          padding: 0 5px;
          background: none;
          font-size: 12px;
          color: #4479ED;
          border: 1px solid #4479ED;
          line-height: 20px;
          text-align: center;
          border-radius: 4px 2px 8px 2px;
        }
        .time {
          background: rgba(0, 0, 0, 0.45);
          padding: 0 0.4rem;
          color: #fff;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.3rem;
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 0.4rem;
        }
        .end {
          background: rgba(0, 0, 0, 0.45);
          padding: 0 0.4rem;
          color: #fff;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.3rem;
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 0.4rem;
        }
      }
      .desc {
        background: #fff;
        border-radius: 0 0 0.3rem 0.3rem;
        padding: 0.5rem 0.7rem;
        .title {
          height: 2rem;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          overflow: hidden;
          line-height: 26px;
          b {
            float: left;
            color: rgba(0, 0, 0, 0.85);
            font-size: 1rem;
            width: 17.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            float: right;
            display: flex;
            align-items: center;
            .collect {
              display: inline-block;
              width: 1rem;
              height: 1rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/collect.png) no-repeat;
              background-size: 100% 100%;
              margin-right: 2px;
            }
          }
        }
        .info {
          font-size: 14px;
          height: 1.6rem;
          line-height: 1.6rem;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          label {
            color: rgba(0, 0, 0, 0.45);
          }
          .location-con {
            display: flex;
            align-items: center;
            .location {
              display: inline-block;
              width: 1rem;
              height: 1rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/location-blue.png) no-repeat;
              background-size: 100% 100%;
            }
          }
          
        }
      }
    }

    .userIconList{
      background-color: #FFF;
      width: 100%;
      margin-bottom: 10px;
      .userIconList-title {
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        padding-left: 16px;
        display: flex;
        align-items: center;
        >i {
          height: 16px;
          background-color: #4479ED;
          width: 3px;
          margin-right: 10px;
        }
      }
      .iconList {
        height: 80px;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 10px 10px 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        &::-webkit-scrollbar {
          /*隐藏滚轮*/
          display: none;
        }
        .iconItem{
          margin-right: 10px;
          margin-top: 10px;
          width: 50px;
          text-align: center;
          .topIcon{
            >img{
              width: 40px;
              height: 40px;
              border-radius: 20px;
            }
          }
          .bottomIcon{
            line-height: 20px;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .desc {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            text-align: left;
            line-height: 24px;
          }
        }
      }
      
    }

    .middle-bar {
      width: 100%;
      flex: 1;
      background: #FFF;
      .comment-desc {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: left;
        line-height: 24px;
        font-weight: 400;
        padding: 10px 15px;
        width: 100%;
        height: 100%;
      }
    }

    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      display: flex;
      align-items:  center;
    }

  }
}
</style>
