<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="class-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index" @click="handleGoto(item)">
        <div class="left">
          <img :src="item.image" alt="">
          <i class="play"></i>
        </div>
        <div class="right">
          <div class="tit">{{item.videoName}}</div>
          <div class="foot-info">
            <!-- <i class="tag1">{{item.tag}}</i> -->
            <!-- <div v-if="type === 'studyVideo'"><span>{{item.num}}</span>次播放</div>
            <div v-if="type === 'studyArticle'"><span>{{item.num}}</span>次浏览</div> -->
          </div>
        </div>
        
      </li>
    </ul>
  </van-list>
</template>
<script>
import api from "api";
import { mapState } from 'vuex';
import data from 'assets/json/everGreenClassList';
export default {
  name: 'evergreenClassList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  props: {
    type: {
      type: String,
      default: function() {
        return 'studyVideo';
      }
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      this.loading = false;
      api
        .getEverGreenList({})
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
            this.dataList = res.data || [];
          
          this.total = res.data.length || 0;
          // 数据全部加载完成
          this.listFinished = true;
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
      // this.dataList = data;
      // this.total = 4;
      // this.listFinished = true;
    },
    handleGoto(data) {
      this.$store.commit('evergreen/setCurrentVideo', data);
      this.$router.push(`everGreenClassDetail`);
    }
  }
}
</script>
<style lang="scss" scoped>
.class-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem;
  background: #FFF;
  border: none;
  li {
    padding: 0.3rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    display: flex;
    .left {
      width: 140px;
      height: 86px;
      margin-right: 10px;
      position: relative;
      img {
        width: 140px;
        height: 86px;
      }
      .play {
        display: inline-block;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/play.png) no-repeat;
        background-size: 100%;
        position: absolute;
        left: 58px;
        top: 31px;
        width: 24px;
        height: 24px;
      }
    }
    .right {
      flex: 1;
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 16px;
        margin-bottom: 0.3rem;
        width: 100%;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .date {
        color: #999;
      }
      p {
        color: #7a7a7a;
      }
      
      .foot-info {
        margin-bottom: 0.4rem;
        position: relative;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 2rem;
          height: 2rem;
          float: left;
          border-radius: 50%;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        div {
          float: left;
          font: 500 0.8rem/1rem "microsoft yahei";
          color: rgba(0,0,0,0.45);
          span {
            color: #4479ED;
          }
        }
        i {
          display: inline-block;
          padding: 2px 4px;
          margin: 4px 0;
          border-radius: 4px;
          font-style: inherit;
          font-size: 0.7rem;
          color: #E13F30;
        }
        .tag1 {
          background: #FCEBEA;
        }
        .tag2 {
          background: #f8b432;
        }
        .tag3 {
          background: #c5cbd2;
        }
      }
      .state {
        padding-top: 0.4rem;
        span {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.8rem;
          background: #e8e8e8;
          color: #101010;
        }
        i {
          display: inline-block;
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          margin-right: 0.3rem;
          background: url(http://dssq.ikeqiao.net/data/image/h5/images2/icon-zan.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    
  }
}
</style>