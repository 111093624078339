<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="place-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index">
        <div class="left">
          <img :src="item.picture" alt="">
        </div>
        <div class="right">
          <div class="tit">{{item.name}}</div>
          <div class="introduce">
            {{item.introduce}}
          </div>
          <div class="icon-text">
            <i class="address"></i>
            {{item.address}}
          </div>
          <div class="icon-text">
            <i class="date"></i>
            {{item.date}}
          </div>
          <div class="foot-btn">
            <div class="call btn">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/building/call.png" alt="">
              <span>联系他</span>
            </div>
            <div class="navigate btn">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/building/navigate.png" alt="">
              <span>到这去</span>
            </div>
          </div>
        </div>
        
      </li>
    </ul>
  </van-list>
</template>
<script>
import nrbApi from "api/llyjr/nrb";
import { mapState } from 'vuex';
export default {
  name: 'placeList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  props: {
    type: {
      type: String,
      default: function() {
        return 'video';
      }
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      // nrbApi
      //   .getNrbList({
      //     page,
      //     size,
      //     object: {
      //       className: category,
      //       issueStatus: status,
      //       belongOwn: this.belongOwn,
      //     },
      //   })
      //   .then((res) => {
      //     console.log('res', res);
          
      //     this.loading = false;
      //     const { list = [], total = 0 } = res.data || {};
      //     if(page === 1) {
      //       this.dataList = list;
      //     } else {
      //       this.dataList = Array.from(new Set(this.dataList.concat(list)))
      //     }
      //     this.total = total;
      //     if (this.dataList.length >= this.total) {
      //       // 数据全部加载完成
      //       this.listFinished = true;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('error====>', err);
      //     this.loading = false;
      //   });
      this.dataList = new Array(4).fill({
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
          name: '东盛邻里中心',
          introduce: '用于社区党建组织召开会议使用',
          address: '诸暨市万商路409号',
          date: '周一至周五 9:00-21:30',
          phone: '88390023',
        });
      this.total = 4;
      this.listFinished = true;
    },
  }
}
</script>
<style lang="scss" scoped>
.place-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  background: #FFF;
  border: none;
  li {
    padding: 0.3rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    display: flex;
    .left {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      .tit {
        font-weight: 500;
        color: #000000;
        font-size: 16px;
        line-height: 24px;
      }
      .introduce {
        color: rgba(0,0,0,0.45);
        font-size: 14px;
        line-height: 24px;
      }
      .icon-text {
        font-size: 14px;
        display: flex;
        align-items: center;
        color: rgba(0,0,0,0.65);
        line-height: 24px;
        .address {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/location.png') no-repeat;
          background-size: 100% 100%;
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
        .date {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/date.png') no-repeat;
          background-size: 100% 100%;
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
      }
      .foot-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
          color: #FFF;
          font-size: 14px;
          padding: 3.5px 12px;
          margin-right: 9px;
          width: 82px;
          height: 28px;
          opacity: 0.9;
          border-radius: 14px;
          >img {
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }
        }
        .call {
          background-color: #81CEF2;
        }
        .navigate {
          background-color: #49d975;
        }
      }
      
    }
    
  }
}
</style>