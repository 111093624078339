<template>
  <div class="hjlbn-detail-container">
    <van-nav-bar
      title="问题详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <div class="record-card">
        <div class="record-card-top">
          <div class="record-card-top-time">
            <!-- <img src="" alt=""> -->
            <div>{{detail.createTime}}</div>
          </div>
          <div :style="{'color': colors[detail.dealStatusText] || '#4479ED'}">{{detail.dealStatusText}}</div>
        </div>
        <div class="record-card-body">
          <div class="record-card-row">
            <span class="record-card-row-label">帮助类型：</span>
            <span>{{detail.helpTypeText || '-'}}</span>
          </div>
          <div class="record-card-row">
            <span class="record-card-row-label">是否上门：</span>
            <span>{{detail.toDoorType || '-'}}</span>
          </div>
          <div class="record-card-row">
            <span class="record-card-row-label">所在位置：</span>
            <span>{{detail.address || '-'}}</span>
          </div>
          <div class="record-card-row">
            <span class="record-card-row-label">问题描述：</span>
            <span>{{detail.problemDes || '-'}}</span>
          </div>
        </div>
      </div>
      <div class="record-card" v-if="detail.dealStatusText === '不接收'">
        <div class="record-card-body">
          <h4>何姐反馈</h4>
          <div class="record-card-row">
            <p class="record-card-row-label">{{detail.dealReason || '-'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hjlbnApi from 'api/hjlbn';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      detail: {},
      id: '',
      colors: {
        '待处理': '#4479ED',
        '已接收': '#E5B20A',
        '不接收': 'rgba(0,0,0,0.45)',
      },
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.id = id;
    this.getDetail({ id });
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getDetail({ id }) {
      hjlbnApi.getHelpList({
        page: 1,
        pageSize: 100,
      }).then(res => {
        if (res.flag) {
          this.detail = res.data.records.find(item => item.id == id) || {};
        } else {
          this.detail = {}
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.hjlbn-detail-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px;
    .record-card {
      background: #FFF;
      margin-bottom: 12px;
      border-radius: 4px;
      .record-card-top {
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #f6f7fa;
        display: flex;
        align-items: center;
        padding: 12px;
        justify-content: space-between;
        .record-card-top-time {
          display: flex;
          color: rgba(0,0,0,0.45);
        }
        
      }
      .record-card-body {
        padding: 12px;
        width: 100%;
        .record-card-row {
          width: 100%;
          line-height: 24px;
          .record-card-row-label {
            color: rgba(0,0,0,0.45);
          }
        }
        
      }
      .record-card-foot {
        width: 100%;
        border-top: 1px solid #f6f7fa;
        .record-card-foot-button {
          width: 100%;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #4379ed;
        }
      }
    }

  }
}
</style>
