<template>
  <div class="hui-study-container">
    <van-nav-bar
      class="hui-study-nav-bar"
      title="全龄幸福学堂"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hui-study-body-container">
      <van-tabs color="#5278e5"   @click="handleTabSelect" v-model="studyItemSelect">
        <van-tab title="学堂详情" name="学堂详情" ></van-tab>
        <van-tab title="活动" name="活动" ></van-tab>
      </van-tabs>
      <div v-if="studyItemSelect === '学堂详情'" class="arcContent">
        <div class="desc">
          东盛社区携手城新小学党支部为社区内的3-6年级的孩子们开展了周末托管服务，每周开设周六班和周日班，每班30名学生，共有60名学生享受到周末托管服务。课程改变了传统的补习班模式，以手工课阅读课等特色课程为主，旨在陶冶情操，培养孩子爱好。为家长减轻压力，让孩子的周末生活更丰富多彩。
        </div>
        <div  class="image">
          <img src="http://dssq.ikeqiao.net/data/image/dongsheng/hxx/1.png" alt="">
        </div>
        <div class="desc">
          合作机构：授课团体——城新小学党员志愿队介绍城新小学党员志愿队队长：何晓峰    副队长：郭顺风 戚伯芳城新小学党支部，现有党员33人，其中正式党员32人，预备党员1人。党支部在上级党、团组织的领导下，从严要求，从实际出发，认真履行党章规定的职责，形成健康、有序、规范的支部发展状态。支部依托固定党日活动、教研动志愿活动、工会活动等，锻造新时代“硬核”共产党员，充分发挥优秀党员示范、引领作用，形成“关键岗位有党员，困难面前有党员，突击攻关有党员”的良好局面，创新做优“争做最亮那颗星”支部品牌。支部成员自觉联系实际，深入研究思考积极撰写读心得、理论调研文章、研究论文。
        </div>
      </div>
      <div v-if="studyItemSelect === '活动'">
        <div class="huodongzhongxin-body-container">
          <wyslList activityType="幸福学堂" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import WyslList from "./wyslList";
export default {
  data() {
    return {
      selected: '活动',
      barList: [],
    };
  },
  computed: {
    ...mapState({
      studyItemSelect: (state) => state.study.studyItemSelect,
    }),
  },
  components: { WyslList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleTabSelect(name, title) {
      this.$store.commit('study/setStudyItemSelect', name);
    },
    showToast(message) {
      this.$toast(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.hui-study-container {
  height: 100vh;
  width: 100vw;
  .arcContent{
    padding: 10px;
    .desc{
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      line-height: 28px;
      font-weight: 400;
    }
    .image{
      width: 100%;
      >img{
        width: 100%;
      }
    }
  }
}
</style>
