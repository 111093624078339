<template>
  <div>
    <div class="borrow-apply-container">
      <van-nav-bar
        title="申请借用"
        left-arrow
        @click-left="onClickLeft"
      />
      <div class="borrow-apply-body-container">
        <van-notice-bar
          left-icon="volume-o"
          text="申请借用后需审核，通过后可拨打出借人联系电话进行借用"
        />
        <div class="form-bar">
          <van-form @submit="onSubmit">
            <van-field
              size="large"
              required
              v-model="name"
              name="rentUser"
              label="姓名"
              placeholder="请填写"
              :rules="[{ required: true, message: '请填写姓名' }]"
              class="input-bar"
            />
            <van-field
              size="large"
              v-model="phone"
              required
              name="rentPhone"
              label="联系电话"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写联系电话' }]"
              class="input-bar"
            />
            <van-field
              size="large"
              v-model="start"
              name="rentTime"
              is-link
              readonly
              required
              label="借用时间"
              placeholder="请选择借用时间"
              @click="showStart = true"
              :rules="[{ required: true, message: '请选择借用时间' }]"
              class="input-bar"
            />
            <van-field
              size="large"
              v-model="end"
              name="returnTime"
              is-link
              readonly
              required
              label="归还时间"
              placeholder="请选择归还时间"
              @click="handleEnd"
              :rules="[{ required: true, message: '请选择归还时间' }]"
              class="input-bar"
            />
            <div class="section-borrow" style="padding: 0.8rem">
              <div class="title-borrow">借用用途</div>
              <van-field
                size="large"
                v-model="description"
                name="rentReason"
                rows="3"
                autosize
                label=""
                type="textarea"
                maxlength="200"
                placeholder="请填写借用该工具的用途"
                show-word-limit
              />
            </div>
            <div class="footer-button">
              <van-button
                round
                block
                type="info"
                style="font-size: 16px"
                native-type="submit"
                >提交</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </div>
    <van-popup :close-on-click-overlay="false" v-model="showStart" position="bottom">
      <van-datetime-picker
        type="datetime"
        title="选择开始时间"
        @cancel="onCancel('showStart', 'end')"
        @confirm="onStartConfirm"
        :min-date="startMinDate"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup :close-on-click-overlay="false" v-model="showFinish" position="bottom" style="z-index: 9999">
      <van-datetime-picker
        type="datetime"
        title="选择结束时间"
        :min-date="minDate"
        @cancel="onCancel('showFinish', 'end')"
        @confirm="onEndConfirm"
        :formatter="formatter"
      />
    </van-popup>
  </div>
</template>

<script>
import api from "api";
import { mapMutations, mapState } from "vuex";
import context from '@/main';
import moment from 'moment';
export default {
  data() {
    return {
      name: "",
      phone: "",
      description: "",
      fieldNames: {
        text: "value",
        value: "value",
        children: "children"
      },
      start: "",
      end: "",
      showStart: false,
      showFinish: false,
      show: false,
      minDate: "",
      startMinDate: new Date(),
      invitationId: '',
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) =>
        state.login.userInfo || context.getSessionData("userInfo"),
    }),
  },
  components: {},
  mounted() {
    this.name = this.userInfo.nickname;
    this.phone = this.userInfo.phone;
    const{ invitationId } = this.$route.query;
    this.invitationId = invitationId;
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      console.log('values', values);
      api.rentTool({
        invitationId: this.invitationId,
        ...values,
        rentTime: values.rentTime.replace(/\//g, '-'),
        returnTime: values.returnTime.replace(/\//g, '-'),
      }).then(res => {
        if(res.flag) {
          this.$toast('借用申请提交成功！');
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer);
          }, 1000)
        }
      })
    },
    onFinish({ selectedOptions }) {
      this.show = false;  
      this.address = selectedOptions.map((option) => option.value).join("");
    },
    onCancel(type, key) {
      this[type] = false;
    },
    onStartConfirm(value, index) {
      console.log(value);
      this.start = this.formatDateTime(value);
      this.minDate = new Date(this.start);
      this.end = "";
      this.showStart = false;
    },
    onEndConfirm(value, index) {
      this.end = this.formatDateTime(value);
      this.showFinish = false;
    },

    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      // console.log("y  ", y + "-" + m + "-" + d + " " + h + ":" + minute + ":00");
      return y + "/" + m + "/" + d + " " + h + ":" + minute + ":00";
    },
    handleEnd() {
      if (!this.start) {
        this.$toast("请先选择开始时间");
      } else {
        this.showFinish = true;
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
  }
};
</script>

<style lang="scss" scoped>
.borrow-apply-container {
  height: 100vh;
  width: 100vw;
  
  .borrow-apply-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
        .section-borrow {
          width: 100%;
          margin-top: 0.8rem;
          background: #ffffff;
          .title-borrow {
            margin: 0.5rem 0;
            font-size: 16px;
            color: #646566;
            letter-spacing: 0;
          }
        }
        .footer-button {
          position: fixed;
          bottom: 0.8rem;
          right: 0.8rem;
          left: 0.8rem;
        }
    .form-bar {
      .van-form {
      }
    }

  }
}
</style>
