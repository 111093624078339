<template>
  <div class="sourceStore-container">
    <van-nav-bar title="智囊团" left-arrow @click-left="onClickLeft" />
    <div class="konwledge-group-body-container">
      <van-tabs v-model="selected">
        <van-tab title="智囊团">
          <div class="tab-container class-source-con">
            <knowledgeGroupList></knowledgeGroupList>
          </div>
        </van-tab>
        <van-tab title="党建活动">
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="status"
              @change="handleChange($event, 'status')"
              :options="option1"
            />
            <van-dropdown-item
              v-model="sortField"
              @change="handleChange($event, 'remark')"
              :options="option2"
            />
          </van-dropdown-menu>
          <div class="class-source-con">
            <div class="class-source-list">
              <van-list
                v-model="loading"
                :finished="listFinished"
                finished-text="没有更多了"
                @load="onLoad"
                class="activity-list"
              >
                <ul>
                  <li v-for="(item, index) in dataList" :key="index">
                    <div class="img">
                      <img :src="item.imgUrl" />
                      <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                      <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                      <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                      <div
                        v-if="item.state == '0' || item.state == '1'"
                        class="time"
                      >
                        {{ item.time }}
                      </div>
                      <div v-if="item.state == '1'" class="end">报名已截止</div>
                    </div>
                    <div class="desc">
                      <div class="title">
                        <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                      </div>
                      <div class="info">
                        <label>发布人：</label><span>{{ item.dep }}</span>
                      </div>
                      <div class="info">
                        <label>活动时间：</label><span>{{ item.date }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </van-list>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div v-if="selected === 1" class="tab-flex-bottom" @click="gotoLink('yqpddAdd')">发布活动</div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations, mapState } from "vuex";
import knowledgeGroupList from './knowledgeGrounpList';
export default {
  data() {
    return {
      status: "",
      sortField: "",
      loading: false,
      listFinished: true,
      selected: 0,
      option1: [
        { text: "全部状态", value: "" },
        { text: "报名中", value: "报名中" },
        { text: "进行中", value: "进行中" },
        { text: "已完成", value: "已完成" },
      ],
      option2: [
        { text: "默认排序", value: "" },
        { text: "最新发布", value: "最新发布" },
      ],
      dataList: [
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展微党课学习",
          state: "0",
          time: "剩5日20小时开始",
          applyNum: "10",
          dep: "张三（润泽智囊团）",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展微党课学习",
          state: "1",
          time: "剩20分钟结束",
          applyNum: "200",
          dep: "张三（润泽智囊团）",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展微党课学习",
          state: "2",
          time: "",
          applyNum: "30",
          dep: "张三（润泽智囊团）",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
      ],
    };
  },
  computed: {},
  components: { knowledgeGroupList },
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    handleChange(e, type) {
      this[type] = e;
    },
    onLoad() {},
  },
};
</script>

<style lang="scss">
.sourceStore-container {
  height: 100vh;
  width: 100vw;

  .konwledge-group-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .tab-flex-bottom {
      position: fixed;
      bottom: 3rem;
      right: 10px;
      background: #4479ed;
      box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      color: #fff;
      padding: 0.8rem;
      font-size: 0.85rem;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.2rem;
    }
    .van-tabs__line {
      background-color: #4479ed;
    }
    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #ededed;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #e3effd;
            color: #4479ed;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
    .activity-list {
      li {
        margin-bottom: 0.8rem;
        .img {
          height: 8rem;
          position: relative;
          img {
            width: 100%;
            height: 8rem;
          }
          .van-tag {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 0.4rem;
          }
          .van-tag--on {
            background: #e3effd;
            color: #4479ed;
          }
          .van-tag--off {
            background: #d3f1e3;
            color: #40c76d;
          }
          .van-tag--finish {
            background: #ededed;
            color: rgba(0, 0, 0, 0.45);
          }
          .time {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.4rem;
          }
          .end {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 0.4rem;
          }
        }
        .desc {
          background: #fff;
          border-radius: 0 0 0.3rem 0.3rem;
          padding: 0.5rem 0.7rem;
          .title {
            height: 1.4rem;
            overflow: hidden;
            b {
              float: left;
              color: rgba(0, 0, 0, 0.85);
              font-size: 1rem;
              width: 17.4rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            span {
              color: rgba(0, 0, 0, 0.65);
              font-size: 0.4rem;
              float: right;
              i {
                color: #4479ed;
                font-family: arial;
                font-style: initial;
              }
            }
          }
          .info {
            font-size: 0.9rem;
            line-height: 1.4rem;
            color: rgba(0, 0, 0, 0.85);
            label {
              color: rgba(0, 0, 0, 0.45);
              width: 4.5rem;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
</style>
