<template>
  <div
    class="zxzxIndex"
    style="background: #fff; height: 100vh; padding-top: 2.7rem"
  >
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>{{titleName}}</h1>
    </div>
    <div class="zxList">
      <div
        class="item"
        v-for="(item, index) in eventList || []"
        :key="index"
        @click="showDetailPage(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.subhead }}</div>
        <div class="image"><img :src="item.cover" alt="" /></div>
        <div class="time">{{ item.publishTime }}</div>
      </div>
    </div>
    <div class="noData" v-if="eventList.length === 0">暂无内容</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapMutations } from 'vuex';
import homeApi from "api/home";
export default {
  data() {
    return {
      selected: '',
      eventList: [],
      titleName: '',
    };
  },
  components: {},
  mounted(opt) {
    const { type, name = '更多内容' } = this.$route.query;
    this.titleName =  name;
    console.log(type);
    homeApi.getAllDictByType({ type: "column_type" }).then((res) => {
      const { data, flag } = res;
      if (flag) {
        this.selected = type || '';
        homeApi
          .getArticleList({
            page: 1,
            pageSize: 1000000,
            type: 12,
            columnType: this.selected,
          })
          .then((res) => {
            console.log(res);
            const { flag, data } = res;
            if (data && flag) {
              this.eventList = data.records ? data.records : [];
            }
          });
        }
    });
  },
  methods: {
    ...mapMutations(['setDataInfo']),
    goLink() {
      this.$router.push('/iwantAsk');
    },
    tabClick(value, name) {
      console.log(value, name);
      this.selected = value;
      homeApi
        .getArticleList({
          page: 1,
          pageSize: 1000000,
          type: 12,
          columnType: value !== '全部' ? Number(value) : '',
        })
        .then((res) => {
          console.log(res);
          const { flag, data } = res;
          if (data && flag) {
            this.eventList = data.records || [];
          }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    showDetailPage(data) {
      this.setDataInfo(data);
      if (data.contentType === 1) {
        window.location.href = data.content;
      } else {
        this.$router.push(`/zxzxDetail`);
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

.zxzxIndex {
  height: 100% !important;
  position: relative;
  .mint-tab-item-label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 0;
    font-weight: 400;
  }
  .zxList {
    padding: 10px;
    .item {
      width: 100%;
      height: 260px;
      margin-top: 10px;
      background: #ffffff;
      border-radius: 8px;
      border-radius: 8px;
      .title {
        font-size: 16px;
        color: #000000;
        line-height: 30px;
      }
      .desc {
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        font-size: 14px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .image {
        width: 100%;
        height: 170px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .time {
        line-height: 20px;
        text-align: right;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
      .tab-flex-bottom {
      position: fixed;
      bottom: 3rem;
      right: 10px;
      background: #4479ed;
      box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      color: #fff;
      padding: 0.8rem;
      font-size: 0.85rem;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.2rem;
    }
    .noData {
      height: 4rem;
      line-height: 4rem;
      text-align: center;
    }
}
</style>
