<template>
  <div class="">
    <van-nav-bar title="困难救助" left-arrow @click-left="onClickLeft" />

    <div class="helplist-body-container">
      <van-tabs v-model="selected">
        <van-tab title="救助列表">
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="status"
              @change="handleChange($event, 'status')"
              :options="option1"
            />
          </van-dropdown-menu>
          <div class="cata-box">
            <div class="list">
              <ul>
                <li v-for="(item, index) in dataList" :key="index">
                  <div class="info">
                    <div class="data title">
                      <b>户主名字</b><span>{{ item.name }}</span>
                    </div>
                    <div class="data">
                      <b>身份证号</b><span>{{ item.cid }}</span>
                    </div>
                    <div class="data">
                      <b>电话号码</b><span>{{ item.tel }}</span>
                    </div>
                    <div class="data">
                      <b>家庭人口</b><span>{{ item.num }}</span>
                    </div>
                    <div class="data">
                      <b>救助类型</b><span>{{ item.type }}</span>
                    </div>
                  </div>
                  <div class="remark">
                    <div class="place">
                      <i class="address"></i> {{ item.address }}
                    </div>
                    <div class=""><i class="date"></i> {{ item.date }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </van-tab>
        <van-tab title="帮扶记录">
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="status"
              @change="handleChange($event, 'status')"
              :options="option1"
            />
          </van-dropdown-menu>
          <div class="cata-box">
            <div class="list">
              <ul>
                <li v-for="(item, index) in dataList" :key="index">
                  <div class="info">
                    <div class="data title">
                      <b>户主名字</b><span>{{ item.name }}</span>
                    </div>
                    <div class="data">
                      <b>身份证号</b><span>{{ item.cid }}</span>
                    </div>
                    <div class="data">
                      <b>电话号码</b><span>{{ item.tel }}</span>
                    </div>
                    <div class="data">
                      <b>家庭人口</b><span>{{ item.num }}</span>
                    </div>
                    <div class="data">
                      <b>救助类型</b><span>{{ item.type }}</span>
                    </div>
                  </div>
                  <div class="remark">
                    <div class="place">
                      <i class="address"></i> {{ item.address }}
                    </div>
                    <div class=""><i class="date"></i> {{ item.date }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: "",
      selected: 0,
      option1: [
        { text: "全部类型", value: "" },
        { text: "待帮扶", value: "待帮扶" },
        { text: "审核中", value: "审核中" },
        { text: "已帮扶", value: "已帮扶" },
      ],
      dataList: [
        {
          stats: "审核中",
          name: "张三",
          cid: "33061……1000",
          num: "1",
          type: "特困户",
          address: "东盛社区润泽大院",
          date: "2021-8-15 12：00",
          tel: "136……1234",
        },
        {
          stats: "待帮扶",
          name: "张三",
          cid: "33061……1000",
          num: "1",
          type: "特困户",
          address: "东盛社区润泽大院",
          date: "2021-8-15 12：00",
          tel: "136……1234",
        },
        {
          stats: "已帮扶",
          name: "张三",
          cid: "33061……1000",
          num: "1",
          type: "特困户",
          address: "东盛社区润泽大院",
          date: "2021-8-15 12：00",
          tel: "136……1234",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    tip() {
      this.$message("页面正在建设中");
    },
    gotoLink(path) {
      this.$router.push(path);
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.helplist-body-container {
  height: calc(100vh - 3rem);
  width: 100vw;
  background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
  background-size: 100% 100%;
  overflow-y: auto;
  padding: 0;
  overflow-x: hidden;
  .van-tabs__line {
    background-color: #4e79f2;
  }
  .cata-menu {
    background: #fff;
    border-radius: 0.5rem;
    height: 7rem;
    width: 23.6rem;
    padding-top: 1.5rem;
    li {
      float: left;
      width: 33%;
      text-align: center;
      i {
        display: inline-block;
        width: 3.2rem;
        height: 3.2rem;
      }
      .icon1 {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/cata-nav1.png) no-repeat;
        background-size: 100% 100%;
      }
      .icon2 {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/cata-nav2.png) no-repeat;
        background-size: 100% 100%;
      }
      .icon3 {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/cata-nav3.png) no-repeat;
        background-size: 100% 100%;
      }
      span {
        display: block;
        color: #656565;
        font: 500 0.8rem/1.6rem "microsoft yahei";
      }
    }
  }
  .cata-box {
    padding: 10px;
    margin: 0;
    .list {
      li {
        background: #fff;
        border-radius: 0.3rem;
        padding: 0.5rem 0.7rem;
        position: relative;
        margin-top: 0.8rem;
        .van-tag {
          border-radius: 0 0.3rem 0 0.8rem;
          padding: 0.2rem 0.7rem;
          position: absolute;
          top: 0;
          right: 0;
        }
        .blue {
          background: #7592f4;
        }
        .org {
          background: #e0a66d;
        }
        .green {
          background: #36c9ac;
        }
        .info {
          .data {
            display: inline-block;
            width: 50%;
            padding: 0 0.4rem;
            font-size: 0.8rem;
            line-height: 1.6rem;
            color: #000;
            height: 1.6rem;
            overflow: hidden;
            b {
              color: #bcbcbc;
              font-weight: 500;
              margin-right: 0.4rem;
            }
          }
          .title {
            width: 100%;
          }
        }
        .remark {
          border-top: 1px solid #bbbbbb;
          padding: 0.5rem 0.4rem;
          display: flex;
          div {
            display: inline-block;
            width: 50%;
            i {
              display: inline-block;
            }
          }
          .address {
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/catalog/address.png") no-repeat;
            background-size: 100% 100%;
            height: 0.8rem;
            width: 0.6rem;
            margin-right: 0.3rem;
          }
          .date {
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/catalog/date.png") no-repeat;
            background-size: 100% 100%;
            height: 0.6rem;
            width: 0.6rem;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
  .cata-tab {
    line-height: 1.4rem;
    font-size: 0.9rem;
    height: 1.4rem;
    b {
      float: left;
      color: #5879e9;
      i {
        display: inline-block;
        color: #fff;
        background: #5879e9;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.4rem;
        font-style: initial;
        line-height: 0.8rem;
        padding: 0 0.1rem;
        margin-left: 0.2rem;
      }
    }
    span {
      float: right;
      color: #6d7279;
    }
  }
}
</style>
