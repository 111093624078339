<template>
  <div class="vanguard-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="vanguard-detail-body-container">
      <!-- detail -->
      <div class="item">
        <div class="itemName">
          <div class="image">
            <img :src="data.submitUserAvatar" alt="" />
          </div>
          <div class="text">
            <div class="title">
              {{ data.submitUserName }}
              <span v-if="data.submitUserPartyType !== 0" class="tag">党员</span>
            </div>
            <div>发布时间：{{ data.createTime }}</div>
            <van-tag v-if="data.my == '0'" @click="gotoLink('myvanguard')">我发布的</van-tag>
          </div>
          <div class="right-tag">
            <van-tag v-if="data.issueStatus === 2" type="on">{{data.issueStatusText}}</van-tag>
            <van-tag v-else-if="data.issueStatus === 4" type="off">{{data.issueStatusText}}</van-tag>
            <van-tag v-else-if="data.issueStatus === 3" type="success">{{data.issueStatusText}}</van-tag>
            <van-tag v-else type="draft">{{data.issueStatusText}}</van-tag>
           
          </div>
        </div>
        <div class="itemDesc">{{data.issueContent}}</div>
        <div class="image-list-con">
          <img
            v-for="(item, index) in data.photoVO"
            :src="item"
            :key="index"
            alt=""
          />
        </div>
        <div class="data-info">
          <div><span>{{ data.visitsUp }}</span>人浏览 <span>{{ data.thumbsUp }}</span>个点赞</div>
          <!-- <span>{{ data.repoNum }}</span>条评论 -->
          <div class="dzIcon" @click="vanguardZan(data)">
            <img :src="data.isThumb === 1 ? 'http://dssq.ikeqiao.net/data/image/h5/images/znt/dzB.png' : 'http://dssq.ikeqiao.net/data/image/h5/images/znt/dz.png'" alt="">
            <div style="margin-left: 10px">{{data.thumbsUp}}</div>
          </div>
        </div>
      </div>
      <!-- 评论列表 -->
      <div v-if="data.issueStatusText === '审核通过'" class="middle-bar">
        <div class="middle-title">{{`全部评论(${this.commentList.length})`}}</div>
        <commentList @commentZan="commentZan" zanClick="commentZan" :dataList="this.commentList"></commentList>
      </div>
      <div v-if="data.issueStatusText === '不通过'" class="middle-bar">
        <div class="middle-title">审核记录</div>
        <div class="middle-content">
          <div class="itemName">
            <div class="text">
              <div class="title">
                {{ data.auditorUser }}
              </div>
              <div>审核时间：{{ data.auditorTime }}</div>
            </div>
          </div>
          <div class="itemDesc">
            {{ data.auditorReason }}
          </div>
        </div>
      </div>
      <!-- 评论 -->
      <div v-if="data.issueStatusText === '审核通过'" class="footer">
        <van-field
          v-model="inputItem"
          placeholder="发布你的感想"
          shape="round"
          class="send-input"
          clearable
        >
          <template #left-icon>
            <img class="send-image" src="http://dssq.ikeqiao.net/data/image/h5/images/coordination/send.png" alt="">
          </template>
        </van-field>
        <van-button class="send-btn" size="small" @click="handleComment">发送</van-button>
      </div>
      <div class="footer" v-else-if="data.issueStatusText === '不通过'">
        <van-button class="cancel-btn" style="margin-right: 10px;" size="small" @click="handleCancel">删除</van-button>
        <!-- <van-button class="edit-btn" size="small" @click="handleEdit">重新编辑</van-button> -->
      </div>
      <!-- <div class="footer" v-else-if="data.issueStatusText === '审核中'">
        <van-button class="reback-btn" size="small" @click="handleCancel">撤回</van-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import commentList from 'components/commentList.vue';
import { Toast, Dialog } from 'vant';
export default {
  data() {
    return {
      data: {},
      inputItem: '',
      commentList: [],
      orderByType: 1, // 1-时间倒序 2-点赞数倒序
      current: 1,
      pageSize: 10,
      id: '',
      isAll: '',
    };
  },
  computed: {
  },
  components: { commentList },
  mounted() {
    const { id , isAll } = this.$route.query;
    this.id = id;
    this.isAll = isAll;
    this.getDetail({isAll: isAll === 'true'});
    this.getCommentList({});
    this.visit({});
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    handleCancel() {
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '确认',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: `请确认是否${this.data.issueStatusText === '审核中' ? '撤回' : '删除'}`,
      })
      .then(() => {
        api.deleteVanguard({ id: this.data.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            // Toast('删除成功');
            this.$messagebox("提示", "删除成功");
            this.$router.go(-1);
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    handleEdit() {
      this.$router.push('vanguardAdd?id='+this.data.id);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    visit({ id = this.id }) {
      api.visitVanguard({
        id,
        isAddThumb: false
      }).then(res => {
        
      })
    },
    getDetail({ id = this.id, isAll }) {
      api.getVanguardDetail({
        id,
        isAll
      }).then(res => {
        this.data = res.data;
      })
    },
    getCommentList({ id = this.id,page = this.current, pageSize = this.pageSize, orderByType = this.orderByType }) {
      api.getVanguardCommentList({
        pioneerId: id,
        orderByType,
        page,
        pageSize,
      }).then(res => {
        this.commentList = res.data.records;
      })
    },
    handleComment({ pioneerId = Number(this.id), commentContent = this.inputItem }) {
      if(!commentContent) {
        Toast('评论不能为空！');
      } else {
        api.commentVanguard({
          commentContent,
          commentPid: 0,
          pioneerId
        }).then(res => {
          if(res.flag) {
            this.inputItem = '';
            this.getCommentList({});
          }
        })
      }
    },
    vanguardZan(data) {
      api.zanVanguard({
        id: data.id,
        isAddThumb: data.isThumb === 0
      }).then(res => {
        if(res.flag) {
          this.getDetail({isAll: this.isAll === 'true'});
        }
      })
    },
    commentZan(data) {
      // console.log('comment', data);
      
      api.zanVanguardComment({
        id: data.id,
        isAddThumb: data.isThumb === 0
      }).then(res => {
        if(res.flag) {
          this.getCommentList({});
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.vanguard-detail-container {
  height: 100vh;
  width: 100vw;
  
  .vanguard-detail-body-container {
    height: calc(100vh - 102px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .item {
        position: relative;
        padding: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        border-radius: 4px;
        .itemName {
          display: flex;
          height: 50px;
          padding: 5px;
          .image {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            > img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text {
            flex: 1;
            height: 100%;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            position: relative;
            .title {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 500;
              .tag {
                background: #e3effd;
                border-radius: 2px;
                font-size: 12px;
                color: #4479ed;
                padding: 3px 6px;
              }
            }
            .van-tag {
              background: #e3effd;
              color: #4479ed;
              font-size: 0.4rem;
              padding: 0 0.5rem;
              height: 20px;
              line-height: 20px;
              border-radius: 2px 6px 2px 6px;
              position: absolute;
              right: 0rem;
              top: 0.2rem;
            }
            
          }
          .right-tag {
            width: 5rem;
            .van-tag {
              background: #d5f4e2;
              color: #13bb49;
              font-size: 0.4rem;
              padding: 0 0.5rem;
              height: 20px;
              line-height: 20px;
              border-radius: 2px 6px 2px 6px;
              margin-top: 10px;
            }
            .van-tag--on {
              background: #e3effd;
              color: #4479ed;
            }
            .van-tag--off {
              background: #ffeeec;
              color: #ff4535;
            }
            .van-tag--draft {
              background: #fff6d9;
              color: #e5b20a;
            }
            
          }
          
        }
        .itemDesc {
          width: 100%;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .image-list-con {
          width: 100%;
          // padding: 10px;
          display: flex;
          flex-wrap: wrap;
          > img {
            width: 56px;
            height: 56px;
            border-radius: 4px;
            margin: 5px;
          }
        }
        .data-info {
          width: 100%;
          margin: 10px 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #4479ed;
            margin-left: 1rem;
            &:first-child {
              margin-left: 0;
            }
          }
          .dzIcon{
            height: 24px;
            line-height: 24px;
            text-align: left;
            display: flex;
            >img{
              height: 20px;
              width: 20px;
            }
            color: rgba(0,0,0,0.65);
          }
        }
      }

    .middle-bar {
      width: 100%;
      flex: 1;
      background: #FFF;
      padding: 12px;
      .middle-title {
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
      }
      .middle-content {
        width: 100%;
        .itemName {
          display: flex;
          height: 50px;
          padding: 5px;
          .text {
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            position: relative;
            .title {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
        .itemDesc {
          width: 100%;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      display: flex;
      background: #FFF;
      align-items: center;
      .send-input {
        background: #F4F5F8;
        font-size: 15px;
        color: rgba(0,0,0,0.35);
        display: flex;
        align-items: center;
        border-radius: 20px;
        width: 82%;
        height: 36px;
        .send-image {
          height: 18px;
          width: 18px;
        }
      }
      .send-btn {
        flex: 1;
        // margin-left: 10px;
        border: none;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: center;
      }
      .cancel-btn, .edit-btn {
        width: 48%;
        height: 36px;
        background: #2379ed;
        border-radius: 18px;
        color: #FFF;
        font-size: 16px;
      }
      .cancel-btn {
        width: 100%;
        background: #ee0a24;
      }
      .reback-btn {
        width: 100%;
        height: 36px;
        background: #EEEEEE;
        border-radius: 18px;
        color: #FFF;
        font-size: 16px;
      }
    }
  }
}
</style>
