import service from "utils/service";

const addVolunteerApi = {
  getList: (params) => {
    return service.get("/dataProvide/getGoldKey", {params});
  },
  addVolunteer: params => {
    return service.post('/volunteerFuwu/enroll', params);
  }, // 新增申请
  cancelVolunteer: params => {
    return service.get('/volunteerFuwu/cancelEnroll', { params });
  }, // 取消加入
  getJystjRecord: params => {
    return service.get('/neighborGlodenKeyCommunity/list', { params });
  }, // 获取列表记录
  getVolunteerTeam: params => {
    return service.get('/volunteerFuwu/getVolunteerTeam', { params });
  }, // 获取志愿者团队
  getStatistic: params => {
    return service.get('/volunteerFuwu/getTimeBankInfo', { params });
  }, // 数据统计
  getApplyInfo: params => {
    return service.post('/volunteerFuwu/getApplyInfo', params);
  }, //  获取申请信息
};
export default addVolunteerApi;
