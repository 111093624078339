var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-index",staticStyle:{"background":"#fff","height":"auto !important","margin-top":"0px !important"}},[_c('div',{staticClass:"page-container paddclose"},[_c('div',{staticClass:"header-nav"},[_c('i',{on:{"click":_vm.goBack}}),_c('h1',[_vm._v("文e家")])]),_c('div',{staticClass:"hot-main",staticStyle:{"width":"100%","padding-top":"2.7rem !important"}},[_c('mt-navbar',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('mt-tab-item',{attrs:{"id":"wed"}},[_vm._v("文e队")]),_c('mt-tab-item',{attrs:{"id":"sed"}},[_vm._v("随e点")]),_c('mt-tab-item',{attrs:{"id":"ekt"}},[_vm._v("e课堂")])],1),_c('mt-tab-container',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('mt-tab-container-item',{attrs:{"id":"wed"}},[_c('div',{staticClass:"wyj_content"},[_c('div',{staticClass:"top_banner"},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/wed_top.png"}})]),_c('div',{staticClass:"sub_banner",on:{"click":function($event){return _vm.openHref(
                  'https://www.zjwhtb.cn/content/index/startUp#!/Organization/Index/indexSearchTab/city_code/110600'
                )}}},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/wed_sub.png"}}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text_title"},[_vm._v("文化秀场")]),_c('div',[_vm._v("群众文艺社团组织")])])]),_c('div',{staticClass:"titleBg"},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/wed_title.png"}})])]),_c('van-tabs',{staticStyle:{"margin-top":"-10px"},attrs:{"color":"#4479ED"},on:{"change":_vm.handleTabChange}},[_c('van-tab',{attrs:{"title":"东盛社区"}},[_c('wenList',{attrs:{"selectStreet":_vm.selectStreet}})],1)],1),_c('div',{staticClass:"tab-flex-bottom",on:{"click":function($event){return _vm.openHref('https://kljc.zjwhhly.com/cultureE/#/mine')}}},[_vm._v(" 我的足迹 ")])],1),_c('mt-tab-container-item',{attrs:{"id":"sed"}},[_c('div',{staticClass:"wyj_content"},[_c('div',{staticClass:"top_banner"},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/sed_top.png"}})]),_c('div',{staticClass:"sub_banner",on:{"click":function($event){return _vm.openHref(
                  'https://www.zjwhtb.cn/content/index/startUp#!/ServiceMenu/Index/indexSearchTab'
                )}}},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/sed_sub.png"}}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text_title"},[_vm._v("服务菜单")]),_c('div',[_vm._v("文化服务共享点单")])])]),_c('div',{staticClass:"titleBg"},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/sed_title.png"}})])]),_c('ul',{staticClass:"sed_content"},_vm._l((_vm.sedlist || []),function(item){return _c('li',{key:item.key,staticClass:"item",style:(("background-image: url(" + (item.image) + ")")),on:{"click":function($event){item.name !== '今日活动'
                  ? _vm.gotoLink('wenEjiaLink?link=' + item.link)
                  : _vm.openHref(item.link)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('mt-tab-container-item',{attrs:{"id":"ekt"}},[_c('div',{staticClass:"wyj_content"},[_c('div',{staticClass:"top_banner"},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/ekt_top.png"}})]),_c('div',{staticClass:"sub_banner",on:{"click":function($event){return _vm.openHref(
                  'https://www.zjwhtb.cn/content/index/startUp#!/WebLesson/Index/indexSearchTab/city_code/110600'
                )}}},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/ekt_sub.png"}}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text_title"},[_vm._v("共享课堂")]),_c('div',[_vm._v("百姓公益艺术培训")])])]),_c('div',{staticClass:"titleBg"},[_c('img',{attrs:{"src":"http://dssq.ikeqiao.net/data/image/h5/images/wej/banner/ekt_title.png"}})])]),_c('ul',{staticClass:"ekt_content"},_vm._l((_vm.ektlist || []),function(item){return _c('li',{key:item.key,staticClass:"item",style:(("background-image: url(" + (item.image) + ")")),on:{"click":function($event){item.name !== '今日活动'
                  ? _vm.gotoLink('wenEjiaLink?link=' + item.link)
                  : _vm.openHref(item.link)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('mt-tab-container-item',{attrs:{"id":"esf"}},[_c('div',{staticClass:"wyj_content",staticStyle:{"height":"100%"}},[_c('iframe',{attrs:{"id":"myiframe","scrolling":"auto","frameborder":"0","width":"100%","height":"100%","src":"https://www.zjwhtb.cn/content/index/startUp#!/WebStudy/Index/index/city_code/110600"}})])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }