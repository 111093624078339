<template>
  <div class="knowledge-group-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="konwledge-group-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="item">
          <div class="itemName">
            <div class="image">
              <img :src="data.image" alt="">
            </div>
            <div class="text">
              <div class="title">
                {{data.title}}
              </div>
              <div class="info">
                <span class="info-item">
                  成员：
                  <span class="info-value">{{data.num}}</span>
                </span>
                <span class="info-item">
                  活动：
                  <span class="info-value">{{data.activity}}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="itemDesc">
            {{data.commentContent}}
          </div>
          <div class="image-list-con">
            <img v-for="(item, index) in data.images" :src="item" :key="index" alt="">
          </div>
        </div>
      </div>

      <!-- 详情介绍/评论 -->
      <div class="middle-bar">
        <van-tabs v-model="selectedTab" @click="handleTab" color="#4479ED">
          <van-tab title="成员介绍" name="introduce">
            <memberList :zan="false" :dataList="data.memberList"></memberList>
          </van-tab>
          <van-tab title="党建活动" name="activity">
            <div class="class-source-list">
              <van-list
                v-model="loading"
                :finished="listFinished"
                finished-text="没有更多了"
                @load="onLoad"
                class="activity-list"
              >
                <ul>
                  <li v-for="(item, index) in dataList" :key="index">
                    <div class="img">
                      <img :src="item.imgUrl" />
                      <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                      <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                      <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                      <div
                        v-if="item.state == '0' || item.state == '1'"
                        class="time"
                      >
                        {{ item.time }}
                      </div>
                      <div v-if="item.state == '1'" class="end">报名已截止</div>
                    </div>
                    <div class="desc">
                      <div class="title1">
                        <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                      </div>
                      <div class="info">
                        <label>发布人：</label><span>{{ item.dep }}</span>
                      </div>
                      <div class="info">
                        <label>活动时间：</label><span>{{ item.date }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </van-list>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      
      <!-- 按钮 -->
      <div class="footer-button">
        <van-button
          block
          type="info"
          style="font-size: 16px"
        >
          我要加入
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import moment from 'moment';
import memberList from './memberList';
export default {
  data() {
    return {
      data: {
        image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
        title: '润泽智囊团',
        createTime: '2021-05-21 12:00',
        commentContent: '智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍智囊团详情介绍',
        hasThumbsUp: '已点赞',
        thumbsUp: 23,
        tag: '党员',
        images: [
          'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
          'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
          'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
        ],
        status: '未抢单',
        depart: '润泽党支部',
        result: '联盟未接单',
        phone: '18822340101',
        acceptPerson: '李四',
        orginazition: '东盛社区党总支',
        num: 20,
        activity: 123,
        memberList: [
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            tag: '党员',
            activity: 14,
            commentContent: '个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍',
          },
          {
            image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
            userName: '张三',
            createTime: '2021-05-21 12:00',
            commentContent: '个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍个人介绍',
            tag: '党员',
            activity: 23
          }
        ],
      },
      selectedTab: 'introduce',
      dataList: [
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展微党课学习",
          state: "0",
          time: "剩5日20小时开始",
          applyNum: "10",
          dep: "张三（润泽智囊团）",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展微党课学习",
          state: "1",
          time: "剩20分钟结束",
          applyNum: "200",
          dep: "张三（润泽智囊团）",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展微党课学习",
          state: "2",
          time: "",
          applyNum: "30",
          dep: "张三（润泽智囊团）",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
      ],
      listFinished: false,
      loading: false,
    };
  },
  computed: {
  },
  components: { memberList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onSubmit(values) {
      console.log('values', values);
    },
    onLoad() {
      this.loading = false;
      this.listFinished = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.knowledge-group-detail-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFF;
  
  .konwledge-group-detail-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .top-bar {
      width: 100%;
      background-color: #FFF;
      padding: 10px;
      .item{
        position: relative;
        padding: 10px;
        background-color: #FFF;
        margin-bottom: 10px;
        border-radius: 4px;
        .itemName{
          display: flex;
          height: 50px;
          padding: 5px;
          .image{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text{
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: rgba(0,0,0,0.45);
            font-size: 12px;
            .title {
              color: rgba(0,0,0,0.85);
              font-size: 16px;
              font-weight: 500;
              .tag {
                background: #E3EFFD;
                border-radius: 2px;
                font-size: 12px;
                color: #4479ED;
                padding: 3px 6px;
              }
            }
            .info {
              .info-item {
                margin-right: 10px;
                .info-value {
                  color: #4479ED;
                  font-weight: 500;
                }
              }
            }
            
          }
        }
        .itemDesc{
          width: 100%;
          color: rgba(0,0,0,0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
        }
        .image-list-con {
          width: 100%;
          // padding: 10px;
          display: flex;
          flex-wrap: wrap;
          >img {
            width: 30%;
            height: 79px;
            border-radius: 4px;
            margin: 5px;
          }
        }
      }

    }

    .middle-bar {
      width: 100%;
      background-color: #FFF;
      margin-top: 10px;
      flex: 1;
      .activity-list {
        li {
          margin: 0.8rem;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          .img {
            height: 8rem;
            position: relative;
            img {
              width: 100%;
              height: 8rem;
            }
            .van-tag {
              position: absolute;
              top: 0;
              left: 0;
              padding: 0 0.4rem;
            }
            .van-tag--on {
              background: #e3effd;
              color: #4479ed;
            }
            .van-tag--off {
              background: #d3f1e3;
              color: #40c76d;
            }
            .van-tag--finish {
              background: #ededed;
              color: rgba(0, 0, 0, 0.45);
            }
            .time {
              background: rgba(0, 0, 0, 0.45);
              padding: 0 0.4rem;
              color: #fff;
              height: 1.6rem;
              line-height: 1.6rem;
              border-radius: 0.3rem;
              position: absolute;
              bottom: 0;
              left: 0;
              font-size: 0.4rem;
            }
            .end {
              background: rgba(0, 0, 0, 0.45);
              padding: 0 0.4rem;
              color: #fff;
              height: 1.6rem;
              line-height: 1.6rem;
              border-radius: 0.3rem;
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: 0.4rem;
            }
          }
          .desc {
            background: #fff;
            border-radius: 0 0 0.3rem 0.3rem;
            padding: 0.5rem 0.7rem;
            .title1 {
              height: 1.4rem;
              overflow: hidden;
              b {
                float: left;
                color: rgba(0, 0, 0, 0.85);
                font-size: 1rem;
                width: 17.4rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              span {
                color: rgba(0, 0, 0, 0.65);
                font-size: 0.4rem;
                float: right;
                i {
                  color: #4479ed;
                  font-family: arial;
                  font-style: initial;
                }
              }
            }
            .info {
              font-size: 0.9rem;
              line-height: 1.4rem;
              color: rgba(0, 0, 0, 0.85);
              label {
                color: rgba(0, 0, 0, 0.45);
                width: 4.5rem;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      display: flex;
      align-items:  center;
    }

  }
}
</style>
