<template>
  <div id="index">
    <div class="edu-container">
      <header class="eduheader-nav">
        <i @click="goBack()"></i>
        <h1>校外培训</h1>
      </header>
      <div class="index-top"></div>
      <nav class="menu">
        <ul>
          <li @click="gotoLink('edApply')">
            <i class="icon1"></i><span>我想报名</span>
          </li>
          <li @click="gotoLink('takClass')">
            <i class="icon2"></i><span>我在上课</span>
          </li>
          <li @click="gotoLink('feedback')">
            <i class="icon3"></i><span>我要反馈</span>
          </li>
        </ul>
      </nav>
      <div class="guide">
        <div @click="gotoLink('map')">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/map-nav.png" />
        </div>
      </div>
      <div class="info-pub clearfix">
        <div class="tab float-l">信息公示</div>
        <div class="list float-l">
          <ul>
            <li><i></i><b>6月1日照常上课，儿童节快乐！</b><span></span></li>
            <li><i></i><b>端午节安康！</b><span></span></li>
          </ul>
        </div>
        <span class="more">更多 &gt;</span>
      </div>
      <div class="note-box">
        <div class="main-tab">
          <b><i>通知</i>公告</b>
          <span @click="tip">更多 &gt;</span>
        </div>
        <div class="list">
          <ul>
            <li>通知公告通知公告通知公告通知公告通知公告通知公告</li>
            <li>通知公告通知公告通知公告通知通知公告</li>
            <li>通知公告通知知公告通知公告</li>
          </ul>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "./footer";
export default {
  data() {
    return {};
  },
  components: { Footer },
  mounted() {},
  methods: {
    tip() {
      this.$message("页面正在建设中");
    },
    gotoLink(path) {
      this.$router.push(path);
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
