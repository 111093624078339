<template>
  <div class="hui-study-container">
    <van-nav-bar
      class="hui-study-nav-bar"
      title="老年常青课堂"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hui-study-body-container">
      <van-tabs color="#5278e5"   @click="handleTabSelect" v-model="studyItemSelect">
        <van-tab title="课堂详情" name="课堂详情" ></van-tab>
        <van-tab title="名师" name="名师" ></van-tab>
        <van-tab title="活动" name="活动" ></van-tab>
      </van-tabs>
      <div v-if="studyItemSelect === '课堂详情'" class="arcContent">
        <div class="title">
          老年大学简介
        </div>
        <div class="desc">
          为了让社区里的老年居民“老有所学、老有所乐、老有所
          为”，东盛社区携手辖区优质资源开办老年大学，推出了
          书法、旗袍秀、葫芦丝、舞蹈等9门课程，对东盛社区年
          龄在50周岁——75周岁的居民开放，动态课程（舞蹈、
          太极、旗袍秀）仅限70周岁以下。社区邀请在职教师等
          专业性老师授课，主要以线上报名+线下确认方式进行，
          在规定时间内完成现场确认报名。
        </div>
        <div class="title">
          老年大学课堂公约
        </div>
        <div class="desc">
          <p>1、保持上课教室干净、整洁，下课时自觉带走座位上的垃圾</p>
          <p>2、尊重教师，遵守课堂纪律，爱护教室公物，如有损坏照价赔偿</p>
          <p>3、按时上课，提前请假，文明用语，礼貌待人，谦虚好学</p>
          <p>4、遵守课堂公约，服从老师及助教合理的统筹管理</p>
          <p>5、与同期学员友好相处，杜绝拉帮结对搞小团体</p>
          <p>6、不随意窜班级上课，不带未报名的非社区居民上课</p>
          <p>7、突发高危性疾病和传染性疾病的学员，须及时请假，病愈后凭医院病情书返校上课</p>
          <p>8、严禁带宠物、小孩、贵重物品等前来上课，上课期间保管好自身携带物品，遗失自负</p>
          <p>9、课程所有相关信息一律由社区助教通过班级群发布为准，其他言论</p>
        </div>
      </div>
      <div v-if="studyItemSelect === '名师'">
        <div class="huodongzhongxin-body-container" style="padding: 10px">
          <ul class="detail-img-box-two-ms">
            <li v-for="(item, i) in teacherList" :key="i">
              <div class="img-ms" @click="onQuestionClick(item)">
                <div class="img-top-ms">
                  <img :src="item.teacherImage" />
                </div>
                <div class="img-title-ms">{{ item.courseName }}</div>
                <div class="img-title-ms">授课教师 | {{ item.teacherName }}</div>
                <div class="img-title-ms" style="color: rgba(0,0,0,0.65)">{{ item.honor[0] }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="studyItemSelect === '活动'">
        <div class="huodongzhongxin-body-container">
          <wyslList activityType="常青课堂" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import huiApi from 'api/education/huiStudy';
import { mapMutations, mapState } from "vuex";
import WyslList from "./wyslList";
export default {
  data() {
    return {
      selected: '活动',
      barList: [],
      teacherList: [],
    };
  },
  computed: {
    studyItemSelect: {
      get() {
        return this.$store.state.study.studyItemSelect;
      },
      set(data) {
        this.$store.commit('study/setStudyItemSelect', data)
      }
    }
  },
  components: { WyslList },
  mounted() {
    this.getTeacherList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleTabSelect(name, title) {
      this.$store.commit('study/setStudyItemSelect', name);
    },
    onQuestionClick(item){
      this.$router.push(`lncqktDetail?key=${item.id}`);
    },
    showToast(message) {
      this.$toast(message);
    },
    getTeacherList() {
      huiApi.getTeacherList().then(res => {
        console.log(res.data);
        
        this.teacherList = res.data;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.hui-study-container {
  height: 100vh;
  width: 100vw;
  .arcContent{
    padding: 10px;
    .title{
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      font-weight: 500;
      margin: 10px;
    }
    .desc{
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      line-height: 28px;
      font-weight: 400;
    }
    .image{
      width: 100%;
      >img{
        width: 100%;
      }
    }
  }
  .detail-img-box-two-ms {
    height: auto !important;
    margin: 1rem 0;
    width: 100%;
    li {
      display: inline-flex;
      align-items: center;
      width: 50% !important;
      height: auto !important;
      // position: relative;
      // overflow: hidden;
      // .tag {
      //     position: absolute;
      //     top: 0;
      //     right: 0.5rem;
      //     padding: 0.2rem 0.5rem;
      //     border-radius: 4px;
      //     font-size: 12px;
      // }
      .img-ms {
        height: auto !important;
        width: 100% !important;
        text-align: center;
        img {
          width: auto !important;
          height: auto !important;
          max-height: 10rem;
          max-width: 100%;
        }
        .img-top-ms {
          margin: 0 0.6rem;
          position: relative;
          overflow: hidden;
        }
        .img-title-ms {
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 1rem;
          font-weight: bold;
          padding: 0.5rem 0.6rem 0 0.6rem;
        }
      }
    }
  }
}
</style>
