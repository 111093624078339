<template>
  <div class="yizuji-container">
    <van-nav-bar
      title="伊·足迹"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yizuji-body-container">
      <!-- 四必访 -->
      <div class="sibifang-card-con">
        <div class="sibifang-card-title">
          <span class="text-title">
            “四必”走访
            <van-icon @click="openWindow('sbzf')" style="margin-left: 5px;" color="#4379ed" name="question-o" />
          </span>
          <!-- <span class="text-more">
            更多
            <van-icon name="arrow" color="rgba(0,0,0,0.45)" size="14px" />
          </span> -->
        </div>
        <div>
          <van-swipe :autoplay="3000">
            <van-swipe-item v-for="(image, index) in list" :key="index">
              <img style="width: 100%; height: 15rem;" v-lazy="image.picture || 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png'" />
            </van-swipe-item>
          </van-swipe>
          <!-- <div class="sibifang-card-card" v-for="item in list" :key="item.id">
            <img :src="item.picture || 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png'" class="sibifang-card-image" alt="">
            <h1>{{item.title}}</h1>
          </div> -->
        </div>
      </div>
      <!-- 寻找最美 -->
      <div class="xunzhaozuimei-bar">
        <h1>
          寻找“最美”
          <van-icon @click="openWindow('xzzm')" color="#4379ed" style="margin-left: 5px;" name="question-o" />
        </h1>
        <div @click="gotoLink('findZuiMei')" class="xunzhaozuimei-map-con" id="xunzhaozuimeiContain" tabindex="0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      list: [
        {
          id: 1,
          title: '走访对象：张三',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/zfzp1.jpg',
        },
        {
          id: 2,
          title: '走访对象：张三',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/zfzp2.jpg',
        },
        {
          id: 3,
          title: '走访对象：张三',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/zfzp3.jpg',
        },
        {
          id: 4,
          title: '走访对象：张三',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/zfzp4.jpg',
        },
        {
          id: 5,
          title: '走访对象：张三',
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/zfzp5.jpg',
        },
      ],
      mapObj: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const _this = this;
    _this.mapObj = new window.AMap.Map('xunzhaozuimeiContain', {
      resizeEnable: true,
      zoom: 14,
      center: [120.51539,30.080082],//中心点坐标
      scrollWheel: false,
    });
    this.getMarkerList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getMarkerList() {
      this.markerList = [
        {
          lon: 120.506979,
          lat: 30.082681,
          icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
        },
        {
          lon: 120.520368,
          lat: 30.07674,
          icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
        },
      ];
      // educationMapApi.getPositionSimple({}).then(res => {
      //   if(res.flag) {
      //     this.markerList = res.data;
      //     this.drawMark(this.markerList);
      //   }
      // })
      this.drawMark(this.markerList);
    },
    openWindow(type) {
      const obj = {
        sbzf: {
          title: '“四必”走访',
          message: `<p>①走访对象：生产经营遇到困难的创业女性必访、困难家庭必访、空巢老人及留守妇女儿童家庭必访、 涉及婚姻家庭矛盾纠纷的家庭必访。</p>
          <p>②联系方式：通过实地走访、电话联系等多种途径，开展政策宣传、倾听妇情、优化服务、组织活动、关心关爱等活动。</p>
          <p>③走访频率。每月不少于8次，并记录到《执委履职日记》中。</p>`
        },
        xzzm: {
          title: '寻找“最美”',
          message: '<p>为贯彻落实习近平总书记关于家庭文明建设的重要指示精神，东盛社区妇联连续多年开展寻找“最美”活动，并涌现出了一大批廉洁守法、绿色节俭、家庭和美、热心公益、科学教子的“最美家庭”。下面请跟着我，来一睹他们的风采吧！！！</p>'
        }
      }
      this.$dialog({
        ...obj[type],
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickOverlay: true,
        messageAlign: 'left'
      })
    },
    drawMark(list) {
      if (this.markerList.length > 0) {
        this.mapObj.remove(this.markerList);
        this.markerList = [];
      }
      list.forEach(v => {
        const { lon, lat, icon_path} = v;
        console.log('data', lon)
        if(lon && lat) {
          const marker = new window.AMap.Marker({
            icon: new window.AMap.Icon({
              // image: require(`http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/marker_${type}.png`),
              image: icon_path,
              imageSize: new window.AMap.Size(22,26),
            }),
            // icon: icons.icon || '../../../yhcz/static/mapIcon/marker_yfp.png',
            position: [Number(lon),Number(lat)],
            offset: new window.AMap.Pixel(0, 0)
          });
          marker.on('click', (e) => {
            
          })
          marker.setMap(this.mapObj);
          this.markerList.push(marker);
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.yizuji-container {
  height: 100vh;
  width: 100vw;
  
  .yizuji-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .sibifang-card-con {
        width: 100%;
        background: #FFF;
        padding: 1rem;
        .sibifang-card-title {
          width: 100%;
          height: 36px;
          .text-title {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            font-weight: 500;
            display: flex;
            align-items: center;
          }
          .text-more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            float: right;
            display: flex;
            align-items: center;
          }
        }
        .sibifang-card-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .sibifang-card-card {
            width: calc(50% - 20px);
            border-radius: 4px;
            margin: 10px;
            text-align: center;
            .sibifang-card-image {
              width: 100%;
              height: 120px;
            }
            >h1 {
              font-size: 16px;
              color: rgba(0,0,0,0.85);
              font-weight: 500;
            }
          }
        }
      }
    .xunzhaozuimei-bar {
      width: 100vw;
      padding: 1rem;
      margin-top: 10px;
      background-color: #FFF;
      >h1 {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
      .xunzhaozuimei-map-con {
        width: 100%;
        height: 200px;
      }
    }
  }
}
</style>
