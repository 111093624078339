<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>事件详情</h1>
      </div>
      <van-steps direction="vertical" :active="step">
        <van-step v-for="(item, index) in eventDetail" :key="index">
          <div class="step-title">
            <p>{{item.reportTime}}</p>
            <p v-if="step === index">{{item.reportStatus}}</p>
          </div>
          <div class="event-list-detial" v-if="item.reportStatus === '待处理'">
            <div class="desc">{{item.content}}</div>
            <div class="image-list"  v-if="item.image">
              <van-image @click="ImagePreview((item.image || '').split('#'),i)" v-for="(img, i) in (item.image || '').split('#')" :key="i" :src="img" width="50" height="50" fit="cover" />
            </div>
            <!-- <div class="time">{{item.time}}</div> -->
            <div class="type"><i class="tag"></i>{{item.incidentType}}</div>
          </div>
          <div class="event-list-detial-answer" v-if="item.reportStatus === '处理中'">
            <div class="event-list-detial-answer-title">环物委回复</div>
            <div class="desc">{{item.replyContent}}</div>
            <!-- <div class="time">{{eventDetail.answerPerson}}</div> -->
          </div>
          <div class="event-list-detial-answer" v-if="item.reportStatus === '已退回'">
            <div class="event-list-detial-answer-title">社区回复</div>
            <div class="desc">{{item.replyContent}}</div>
            <!-- <div class="time">{{eventDetail.answerPerson}}</div> -->
          </div>
          <div class="event-list-detial-answer" v-if="item.reportStatus === '已处理'">
            <div class="event-list-detial-answer-title">处理结果</div>
            <div class="desc">{{item.replyContent}}</div>
            <div class="event-list-detial-answer-title">完成时间</div>
            <div class="desc">{{item.replyTime}}</div>
            <div class="image-list"  v-if="item.finishImage">
              <van-image @click="ImagePreview((item.finishImage || '').split('#'),i)" v-for="(img, i) in (item.finishImage || '').split('#')" :key="i" :src="img" width="50" height="50" fit="cover" />
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { Toast, ImagePreview } from "vant";
import api from 'api';

export default {
  data() {
    return {
      eventDetail: [],
      step: 0,
    };
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.getEventDetail({ id });
    // this.eventDetail = this.eventList.filter(v => v.id == id)[0];
    // console.log(this.eventDetail);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    ImagePreview(arr, i) {
      ImagePreview({
        images: arr,
        closeable: true,
        startPosition: i,
      });
    },
    getEventDetail(params) {
      api.getEventDetail(params).then(res => {
        this.eventDetail = res.data;
        console.log('event', res.data);
        
        this.step = res.data ? res.data.length - 1 : 0;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .step-title {
    display: flex;
    justify-content: space-between;
  }
  .event-list-detial{
    padding: 16px;
    background: #fff;
    .desc{
      line-height: 40px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
    }
    .time{
        line-height: 20px;
        font-size: 12px;
        color: #707070;
    }
    .image-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .type {
      // border-top: 1px solid rgba(0,0,0,0.05);
      padding-top: 16px;
      display: flex;
      align-items: center;
      .tag {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('~assets/images/tag.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .event-list-detial-answer {
    padding: 16px;
    background: #fff;
    margin-top: 16px;
    .event-list-detial-answer-title {
      width: 100%;
      height: 33px;
      // padding: 10px;
      background: #fff;
      font-size: 16px;
      font-weight: 500;
      color: #4379ed;
    }
    .desc{
      line-height: 30px;
      padding-bottom: 16px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
    }
    .time{
        line-height: 20px;
        font-size: 12px;
        color: #707070;
        text-align: right;
    }
  }
}
</style>
