<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我的</h1>
      </div>
      <div class="nrb-box">
        <van-tabs v-model="active" color="#4479ED">
          <van-tab title="我的发布">
            <needtakemine />
          </van-tab>
          <van-tab title="我的接单">
            <needtakeminejd />
          </van-tab>
          <van-tab title="我能接">
            <cantakemine />
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Search } from "vant";
import yqpddApi from "api/yqpdd";
import Needtake from "./needtakeMine";
import Needtakejd from "./needtakeMinejd";
import Cantake from "./cantakeMine";
export default {
  data() {
    return {
      active: 0,
    };
  },
  components: { needtakemine: Needtake, needtakeminejd: Needtakejd, cantakemine: Cantake },
  mounted() {
    const value = this.getLocalData("plSelect") || "";
    if (value) {
      this.active = parseInt(value);
    }
  },
  watch: {
    active(val, oldVal) {
      this.setLocalData("plSelect", `${val}`);
    },
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  padding: 0;
  .nrb-box {
    margin-top: 2.8rem;
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .comm-banner {
    width: 23.4rem;
    margin: 0.7rem auto 0;
    height: 7.8rem;
    img {
      height: 7.8rem;
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    -webkit-box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    font-size: 13px;
    color: #fff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
  .reply-list {
    width: 23.4rem;
    margin: 0 auto;
    padding: 0.6rem 0;
    li {
      padding: 0.8rem 0;
      //border-bottom: 1px solid #ddd;
      font: 500 0.9rem/1.4rem "microsoft yahei";
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      .data {
        font-size: 0.9rem;
        label {
          color: #bbb;
        }
        .blue {
          color: #1890ff;
        }
      }
      .reply-info {
        margin-bottom: 0.4rem;
        position: relative;
        height: 2rem;
        img {
          width: 2rem;
          height: 2rem;
          float: left;
          border-radius: 50%;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        div {
          float: left;
          font: 500 0.8rem/1rem "microsoft yahei";
          color: #333;
          span {
            color: #bbb;
          }
        }
        i {
          position: absolute;
          display: inline-block;
          height: 1.8rem;
          padding: 0 0.7rem 0 0.9rem;
          line-height: 1.8rem;
          border-radius: 0.9rem 0 0 0.9rem;
          font-style: inherit;
          font-size: 0.7rem;
          color: #fff;
          right: -0.8rem;
          top: 0.1rem;
        }
        .tag1 {
          background: #3291f8;
        }
        .tag2 {
          background: #f8b432;
        }
        .tag3 {
          background: #c5cbd2;
        }
      }
      .state {
        padding-top: 0.4rem;
        span {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.8rem;
          background: #e8e8e8;
          color: #101010;
        }
      }
    }
  }
  .card-nav {
    width: 100%;
    // border-top: 0.8rem solid #f6f7fa;
    // background: #FFFFFF;
    // padding: 0.8rem;
    .question {
      margin-bottom: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      .question-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 12px;
        line-height: 2rem;
        font-weight: 600;
      }
      .foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .person {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          text-align: left;
          line-height: 22px;
          height: 2.5rem;
          .avatar {
            height: 2rem;
            width: 2rem;
            border-radius: 1rem;
            margin-right: 5px;
          }
        }
        .question-foot {
          height: 1.5rem;
          margin: 0.4rem 0;
          color: rgba(0, 0, 0, 0.45);
          display: flex;
          align-items: center;

          div {
            margin-right: 0.4rem;
          }
          img {
            height: 1rem;
            margin-right: 0.2rem;
          }
        }
      }
      .paragraph {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        .long {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);

    font-size: 13px;
    color: #ffffff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
}
</style>
