<template>
  <div class="alarm-container">
    <van-nav-bar title="小区警管家" left-arrow @click-left="onClickLeft()" />
    <div class="policy-main">
      <div class="policy-info">
        <div class="img float-l">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/catalog/police-house.jpg" />
        </div>
        <div class="desc float-l">
          <div class="name"><i></i>{{ police.name }}</div>
          <div class="address"><i></i>{{ police.address }}</div>
          <a class="tel" :href="'tel:' + police.phone"><i></i>联系警员</a>
          <a class="message" @click="onmsgshow()"><i></i>在线留言</a>
        </div>
      </div>
    </div>
    <div class="notice-pub clearfix">
      <div class="tab float-l">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/catalog/police-title.png" />
      </div>
      <div class="list float-l">
        <ul>
          <li>
            近日，有不法分子冒充街道领导通过微信、QQ添加企业老总为好友，随后以需要托领导办事，但自己直接转钱不方便，需要帮助代为转账为由，要求代为转账进行行骗！请各位主动开展对个人和朋友圈的宣传工作，谨防个人亲友遭遇类似诈骗后被骗！
          </li>
        </ul>
      </div>
      <!-- <van-icon name="arrow" class="more" /> -->
    </div>
    <div class="policy-nav">
      <div class="float-l" @click="gotoLink('alarmOrder')">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/catalog/police-nav1.png" />
      </div>
      <div class="float-r" @click="gotoLink('policyAdd')">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/catalog/police-nav2.png" />
      </div>
    </div>
    <div class="newlist">
      <div class="tab">
        <b>警务信息</b>
        <span @click="gotoLink('alarmList')">更多 &gt;</span>
      </div>
      <ul>
        <li v-for="item in datas" :key="item.id">
          <div class="img">
            <van-image
              width="100%"
              height="100%"
              :src="item.imgSrc"
              fit="cover"
            />
          </div>
          <div class="desc">
            <div class="title">
              {{ item.content }}
            </div>
            <div class="time">{{ item.name }}</div>
            <div class="time">时间：{{ item.createTime }}</div>
          </div>
        </li>
      </ul>
    </div>
    <van-dialog v-model="msgshow" title="在线留言" show-cancel-button>
      <van-field
        v-model="message"
        rows="4"
        autosize
        label=""
        type="textarea"
        maxlength="50"
        placeholder="请输入留言"
        show-word-limit
      />
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msgshow: false,
      message: "",
      datas: [
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp1.png",
          content: "弘扬“三能”精神丨淬炼忠诚显担当 勇做警营“螺丝钉”",
          name: "诸暨警方",
          createTime: "2021-09-07   12：10：05",
        },
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp2.png",
          content: "我们始终和你在一起!",
          name: "绍兴公安群蓝星",
          createTime: "2021-09-07   11：00：29",
        },
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp3.png",
          content: "一条卷烟只卖几十元！猫腻还不少...",
          name: "绍兴公安群蓝星",
          createTime: "2021-09-06   15：13：14",
        },
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp4.png",
          content:
            "深化教育整顿丨“三能”精神在浙江公安的弘扬与实践之二：牢记嘱托，擦亮永恒的忠诚底色",
          name: "浙江公安",
          createTime: "2021-09-07   09：29：29",
        },
      ],
      police: {
        name: "来思远",
        address: "诸暨市公安分局-浣东派出所",
        phone: "15215969962",
      },
    };
  },
  components: {},
  mounted() {},
  created() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    tip() {},
    onmsgshow() {
      this.msgshow = true;
    },
  },
};
</script>

<style lang="scss">
.time {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.policy-main {
  padding: 0.7rem 0.8rem;
}
.policy-info {
  background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/policy-bg.png) no-repeat;
  background-size: 100% 100%;
  width: 23.4rem;
  height: 8rem;
  margin: 0 auto;
  overflow: hidden;
  .img {
    width: 6rem;
    img {
      width: 6rem;
      height: 8rem;
      border-radius: 1rem;
    }
  }
  .desc {
    margin-left: 2rem;
    color: #fff;
    padding-top: 1.2rem;
    text-align: left;
    .name {
      font: 600 1rem/1.8rem "microsoft yahei";
      i {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        width: 0.7rem;
        height: 0.8rem;
        margin-right: 0.3rem;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/icon-jh.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .address {
      font: 500 0.8rem/1.4rem "microsoft yahei";
      color: #9db1fc;
      margin-bottom: 0.8rem;
      i {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        width: 0.6rem;
        height: 0.7rem;
        margin-right: 0.3rem;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/icon-map.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .tel {
      color: #000;
      background: #fff;
      border-radius: 0.6rem;
      height: 1.2rem;
      padding: 0.2rem 0.8rem 0.2rem;
      font: 500 0.7rem/1.6rem "microsoft yahei";
      box-shadow: 0 0 0.3rem #fff;
      margin-right: 1rem;
      i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/tel-bg.png) no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.7rem;
      }
    }
    .message {
      color: #000;
      background: #fff;
      border-radius: 0.6rem;
      height: 1.2rem;
      padding: 0.2rem 0.8rem 0.2rem;
      font: 500 0.7rem/1.6rem "microsoft yahei";
      box-shadow: 0 0 0.3rem #fff;
      i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/msg-icon.png) no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 0.7rem;
      }
    }
  }
}
.policy-nav {
  padding: 0.8rem 0.8rem;
  background: #fff;
  height: 7.2rem;
  margin-bottom: 0.8rem;
  .float-l {
    width: 11.4rem;
    img {
      width: 11.4rem;
      height: 5.6rem;
    }
  }
  .float-r {
    img {
      width: 11.4rem;
      height: 5.6rem;
    }
  }
}
.notice-pub {
  background: #fff;
  padding: 0.5rem 1rem 0.5rem;
  margin: 0 auto 0.8rem;
  .tab {
    margin-right: 1rem;
    margin-top: 0.3rem;
    img {
      width: 2.4rem;
    }
  }
  .more {
    float: right;
    color: #aaaaaa;
    margin-top: 0.8rem;
    font-size: 1.4rem;
  }
  .list {
    width: 19.4rem;
    li {
      color: #aaaaaa;
      font: 500 0.9rem/1.6rem "microsoft yahei";
      overflow: hidden;
      height: 3.2rem;
      //white-space: nowrap;
      text-overflow: ellipsis;
      b {
        font-weight: 500;
      }
    }
  }
}
.newlist {
  padding: 0.5rem 0.8rem;
  background: #fff;
  .tab {
    height: 1.5rem;
    font: 500 0.8rem/1.5rem "microsoft yahei";
    text-align: left;
    b {
      float: left;
      color: #262134;
      font-size: 1.1rem;
    }
    span {
      float: right;
      color: #a0a0a0;
    }
  }
  li {
    padding: 0.5rem 0.4rem;
    width: 100%;
    height: 6.2rem;
    //border-bottom: 1px solid #e5e5e5;
  }
  .img {
    width: 7rem;
    height: 5.2rem;
    float: left;
  }
  .img img {
    width: 7rem;
    height: 5.2rem;
    border-radius: 0.2rem;
  }
  .desc {
    float: right;
    width: 15rem;
    overflow: hidden;
    font: 500 0.8rem/1.2rem "Microsoft Yahei";
    color: #999;
    text-align: left;
    .title {
      color: #000;
      text-align: left;
      font-size: 1rem;
      height: 2.8rem;
      line-height: 1.4rem;
      overflow: hidden;
      word-wrap: break-word;
      word-break: break-all;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //margin-bottom: 0.2rem;
    }
    .state {
      margin-bottom: 0.2rem;
      span {
        display: inline-block;
        padding: 0 0.7rem;
        color: #fff;
        background: #81a66a;
        border-radius: 0.3rem;
        height: 1.2rem;
        line-height: 1.2rem;
        font-family: tahoma;
        font-size: 0.8rem;
      }
    }
    .time {
      b {
        color: #666;
        margin-right: 1rem;
      }
    }
  }
}
</style>
