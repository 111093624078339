<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要上报</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="invitationType"
          name="incidentType"
          label="上报类型"
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          @click="showType = true"
          :rules="[{ required: true, message: '请选择上报类型' }]"
        />
        <van-field
          v-model="invitationName"
          name="username"
          label="联系人"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
        <van-field
          v-model="invitationPhone"
          name="phone"
          label="联系电话"
          type="tel"
          placeholder="请输入"
          input-align="right"
          :rules="[{ pattern: /^1[0-9]{10}$/, message: '请填写正确的联系电话' }]"
        />
        <van-field
          v-model="reportPlace"
          name="reportPlace"
          label="上报地点"
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          @click="showPlace = true"
          :rules="[{ required: true, message: '请选择上报地点' }]"
        />
        <van-field name="reportIdea" label="处理建议">
          <template #input>
            <van-checkbox-group v-model="reportIdea" direction="horizontal">
              <van-checkbox style="margin-bottom: 5px;" v-for="(item, index) in ideaList" :key="item" :name="index + 1" shape="square">{{item}}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <div class="section-textArea">
          <div class="section-label">上报内容</div>
          <van-field
            v-model="invitationContent"
            name="content"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="400"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写上报内容' }]"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="image-title">图片</div>
          <van-uploader v-model="invitationImage" name="invitationImage" multiple :max-count="4" />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">立即上报</van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="问题类型"
        show-toolbar
        :columns="typeList"
        @confirm="onTypeConfirm"
        @cancel="onCancel('showType')"
      />
    </van-popup>
    <van-popup v-model="showPlace" position="bottom">
      <van-picker
        title="上报地点"
        show-toolbar
        :columns="placeList"
        @confirm="onPlaceConfirm"
        @cancel="onCancel('showPlace')"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import api from 'api';

export default {
  data() {
    return {
      invitationType: '',
      invitationName: '',
      invitationPhone: "",
      invitationContent: "",
      reportPlace: '',
      reportIdea: [],
      invitationImage: [],
      showType: false,
      showPlace: false,
      typeList: [],
      placeList: ['君城一期', '君城二期', '江南华庭', '丰泽苑', '朗臻雅苑'],
      ideaList: ['物业处理', '业委会处理', '社区处理'],
      selected: 'question',
      anonymous: false,
    };
  },
  components: {},
  mounted() {
    const { userName = '', phone = '' } = this.getSessionData("userInfo") || {};
    this.invitationName = userName;
    this.invitationPhone = phone;
    this.getTypeList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    onTypeConfirm(value, index) {
      this.invitationType = value;
      this.showType = false;
    },
    onPlaceConfirm(value, index) {
      this.reportPlace = value;
      this.showPlace = false;
    },
    getTypeList() {
      api.getIncidentReportTypes().then(res => {
        if(res.flag) {
          this.typeList = res.data.map(item => item.dictLabel);
        }
      })
    },
    onCancel(type) {
      this[type] = false;
    },
    pushThinkTank(values) {
    },
    reportEvent(values) {
      api.reportEvent({...values, reportIdea: values.reportIdea.join(',')}).then(res => {
        if (res.data == 1) {
          const timer = setTimeout(() => {
             this.$router.replace("eventList");
            
            clearTimeout(timer);
          }, 1000);
          Toast.success("上报成功");
        }
      })
    },
    onSubmit(values) {
      // console.log(this.invitationImage);
      if (this.invitationImage.length) {
        const formData = new FormData();
        this.invitationImage.forEach(file => {
          formData.append("files", file.file);
        });
        api.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.image = res.data;
            this.reportEvent(values);
          }
        });
      } else {
        this.reportEvent(values);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
