<template>
  <div id="index">
    <div class="luck-xqyg-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>抽奖活动</h1>
      </div>
      <div class="detailContent">
        <van-dropdown-menu>
          <van-dropdown-item v-model="status"  @change="handleChange($event,'status')" :options="option1" />
          <van-dropdown-item v-model="sortField" @change="handleChange($event,'sortField')" :options="option2" />
        </van-dropdown-menu>
        <div class="detailList">
          <div class="item" v-for="(item, i) in records" :key="i" @click="gotoLink('/luckTurnTable?key=' + item.lid)">
            <div class="image"><img :src="item.overStatus === '已结束' ? item.publicityBanner : (item.overStatus === '抽奖中' ? item.luckBanner : item.preheatBanner)" alt=""></div>
            <div class="title">{{item.luckTitle}}</div>
            <div class="time">抽奖时间:{{item.luckStartTime}} 至 {{item.luckEndTime}}</div>
            <div :class="`${item.overStatus === '已结束' ? 'grep' : ''} ${item.overStatus === '抽奖中' ? 'orgin' : ''} tagTop`">{{item.overStatus}}</div>
          </div>
        </div>
        <div v-if="list.length === 0" class="noInfo">暂无数据</div>
        <div v-if="records.length >= pageSize" class="detailFooter" @click="showMore">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import luckyApi from 'api/lucky';
import moment from 'moment';
export default {
  data() {
    return {
      status: '',
      sortField: '1',
      option1: [
        { text: '全部活动', value: '' },
        { text: '未开始', value: '2' },
        { text: '抽奖中', value: '3' },
        { text: '已结束', value: '4' },
      ],
      option2: [
        { text: '默认排序', value: '1' },
        { text: '最多参与', value: '2' },
        { text: '最近开始', value: '3' },
      ],
      list: [],
      total: 0,
      records: [],
      pageSize: 20,
    };
  },
  components: {
  },
  mounted() {
    this.page = 1;
    this.list = [];
    this.getTableList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack(){
      this.$router.go(-1);
    },
    getTableList(page = this.page, pageSize = this.pageSize, status = this.status, sort = this.sortField){
      luckyApi.drawLotteryInformationListPage({
        page,
        pageSize,
        status,
        sort,
      }).then((res) => {
        const data = res.data || {};
        const { records = [], total = 0 } = data;
        const arr = records.map(v => ({ ...v, overStatus: this.getTimeStatus(v) }))
        this.list = [...this.list, ...arr];
        this.records = arr;
        this.total = total;
      });
    },
    getTimeStatus(record){
      let text = '抽奖中';
      if (moment(record.luckEndTime).isBefore(moment())) {
        text = '已结束';
      } else if (moment().isBefore(moment(record.luckStartTime))) {
        text = '未开始';
      }
      return text;
    },
    handleChange(e, type) {
      this[type] = e;
      this.page = 1;
      this.list = [];
      this.getTableList();
    },
    showMore(){
      this.page = this.page + 1;
      this.getTableList();
    }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.luck-xqyg-container{
  padding-top: 2.7rem;
  padding-bottom: 10px;
  height: 100vh;
  overflow: scroll;
  .detailContent{
    .detailList{
      padding: 10px;
      .item{
        border-radius: 5px;
        background: #fff;
        margin-top: 10px;
        position: relative;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        .image{
          width: 100%;
          height: 100px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .tagTop{
          position: absolute;
          left: 0px;
          top: 5px;
          height: 20px;
          width: 50px;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          border-radius: 0 10px 10px 0;
          color: #fff;
          background: #08f;
        }
        .tagBottom{
          position: absolute;
          right: 0px;
          bottom: 0px;
        }
        .orgin{
          background: #f8b431;
        }
        .grep{
          background: #b2b2b2;
        }
        .title{
          padding: 0px 10px;
          line-height: 30px;
          font-size: 18px;
          font-weight: 600;
        }
        .time{
          padding: 0px 10px;
          line-height: 24px;
          font-size: 13px;
          color: rgba(0,0,0,0.85);
        }
      }
    }
    .noInfo{
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .detailFooter{
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
}
</style>
