<template>
  <div class="education-source-container">
    <van-nav-bar title="乐学课堂" left-arrow @click-left="onClickLeft" />
    <div class="study-room-body-container">
      <!-- 职业乐学课堂 -->
      <div class="coin-bar-con">
        <div class="coin-bar">
          <h1>职业乐学课堂</h1>
          <h3>学堂一句话介绍</h3>
        </div>
      </div>
      <!-- 线上学习 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">线上学习</span>
          </div>
        </div>
        <div class="card-content">
          <!-- 按钮栏 -->
          <div class="icon-bar">
            <div
              v-for="(icon, index) in iconList"
              @click="gotoLink(icon.path)"
              :key="`${icon.name}_${index}`"
            >
              <img class="icon" :src="icon.icon" :alt="icon.name" />
              <p>{{ icon.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 线下讲座 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">线下讲座</span>
          </div>
          <div>
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="" />
          </div>
        </div>
        <div class="card-content">
          <van-tabs @click="handleTab" color="#4479ED">
            <van-tab title="社会公德">
              <interactionList></interactionList>
            </van-tab>
            <van-tab title="法制教育">
              <interactionList></interactionList>
            </van-tab>
            <van-tab title="科普文化">
              <interactionList></interactionList>
            </van-tab>
            <van-tab title="公共安全">
              <interactionList></interactionList>
            </van-tab>
            <van-tab title="环境保护">
              <interactionList></interactionList>
            </van-tab>
            <van-tab title="身心健康">
              <interactionList></interactionList>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <!-- 创业技能 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">创业技能</span>
          </div>
        </div>
        <div class="card-content">
          <div class="coin-bar-con">
            <div class="coin-bar online" @click="gotoLink('onlineStudy')">
              <h1>线上考证辅导</h1>
            </div>
            <div class="coin-bar offline" @click="gotoLink('offlineStudy')">
              <h1>线下送教上门</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations, mapState } from "vuex";
import interactionList from "./interactionList.vue";
export default {
  data() {
    return {
      iconList: [
        {
          name: "智能技术",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/intelligent.png",
          path: "technology",
        },
        {
          name: "知识技能",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/technology.png",
          path: "technology",
        },
        {
          name: "文化休闲",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/culture.png",
          path: "technology",
        },
        {
          name: "国学礼仪",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/etiquette.png",
          path: "technology",
        },
      ],
    };
  },
  computed: {},
  components: { interactionList },
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.education-source-container {
  height: 100vh;
  width: 100vw;
  .study-room-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .coin-bar-con {
      // display: flex;
      // background-color: #FFF;
      margin-bottom: 10px;
      .coin-bar {
        width: 100%;
        height: 121px;
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/lexue-top.png") no-repeat;
        background-size: 100% 100%;
        padding: 20px;
        color: #fff;
        > h1 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 32px;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
          font-weight: 600;
        }
        > h3 {
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
      }
    }

    .card-nav {
      background: #fff;
      padding: 10px;
      margin: 0 10px 10px 10px;
      .card-title {
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ed;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ed;
          }
        }
      }
      .card-content {
        .icon-bar {
          width: 100%;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background: #fff;
          > div {
            width: 20%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
            .icon {
              width: 47px;
              height: 47px;
              margin-bottom: 5px;
            }
            > p {
              text-align: center;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
        .coin-bar-con {
          display: flex;
          .coin-bar {
            width: 100%;
            margin: 10px;
            height: 112px;
            background-size: 100% 100%;
            padding: 10px;
            color: #fff;
            position: relative;
            > h1 {
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.4);
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }

          .online {
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/online.png") no-repeat;
            background-size: 100% 100%;
          }
          .offline {
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/offline.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
