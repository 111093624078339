var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{staticClass:"butler-nav-bar",attrs:{"fixed":"","title":_vm.style.title,"left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"body",style:({ 'background-image': ("url(" + (_vm.style.bg) + ")") })},[(_vm.type == 1)?_c('div',[_c('div',{staticClass:"info",style:({
          color: _vm.style.mainColor,
          border: ("2px solid " + (_vm.style.borderColor)),
        })},[_vm._m(0),_c('div',{staticClass:"desc"},[_vm._v(" 适宜回收可以循环利用的生活废弃物。投放可回收物时，应尽量保持清洁干燥，避免污染；立体包装应清空内容物。 ")])]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("物品类型")])]),_c('div',{staticClass:"desc"},[_vm._v(" 主要有"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("玻璃")]),_vm._v("（玻璃瓶罐、平板玻璃及其他玻璃制品）、"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("金属")]),_vm._v("（铁、铜、铝等制品）、"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("塑料")]),_vm._v("（泡沫塑料、塑料瓶、硬塑料、橡胶及橡胶制品等）、"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("纸类")]),_vm._v("（报纸、传单、杂志、旧书、纸板箱等及其他未受污染的纸制品）、"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("纺织类、小家电")]),_vm._v("。 ")])]),_c('div',{staticClass:"img"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("处理流程")])]),_c('img',{attrs:{"src":_vm.style.img}})])]):_vm._e(),(_vm.type == 2)?_c('div',[_c('div',{staticClass:"info",style:({
          color: _vm.style.mainColor,
          border: ("2px solid " + (_vm.style.borderColor)),
        })},[_vm._m(1),_c('div',{staticClass:"desc"},[_vm._v(" 指对人体健康或者自然环境造成直接或者潜在危害的生活垃圾。 ")])]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("物品类型")])]),_c('div',{staticClass:"desc"},[_vm._v(" 主要有"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("剩菜剩饭")]),_vm._v("与"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("西餐糕点")]),_vm._v("等"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("食物残余、菜梗菜叶、食物内脏、茶叶渣、水果残余、果壳瓜皮、家用盆景、花卉")]),_vm._v("等"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("植物残枝落叶、食用油")]),_vm._v("等。 ")])]),_c('div',{staticClass:"img"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("处理流程")])]),_c('img',{attrs:{"src":_vm.style.img}})])]):_vm._e(),(_vm.type == 3)?_c('div',[_c('div',{staticClass:"info",style:({
          color: _vm.style.mainColor,
          border: ("2px solid " + (_vm.style.borderColor)),
        })},[_vm._m(2),_c('div',{staticClass:"desc"},[_vm._v(" 指对人体健康或者自然环境造成直接或者潜在危害的生活垃圾。 ")])]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("物品类型")])]),_c('div',{staticClass:"desc"},[_vm._v(" 主要有"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("充电电池、扣式电池、灯管、弃置药品、杀虫剂（容器）、油漆（容器）、硒鼓、水银产品")]),_vm._v("等。 ")])]),_c('div',{staticClass:"img"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("处理流程")])]),_c('img',{attrs:{"src":_vm.style.img}})])]):_vm._e(),(_vm.type == 4)?_c('div',[_c('div',{staticClass:"info",style:({
          color: _vm.style.mainColor,
          border: ("2px solid " + (_vm.style.borderColor)),
        })},[_vm._m(3),_c('div',{staticClass:"desc"},[_vm._v(" 指除可回收物、有害垃圾和易腐垃圾之外的其他生活垃圾。 ")])]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("物品类型")])]),_c('div',{staticClass:"desc"},[_vm._v(" 主要有"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("受污染与无法再生的纸张")]),_vm._v("（照片、复写纸、压敏纸、收据用纸、明信片、相册、卫生纸、底片等）、"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("塑料与其他受污染的塑料制品、破旧陶瓷器、难以自然降解的食物残余")]),_vm._v("（大棒身、大贝壳）、"),_c('span',{style:({ color: _vm.style.mainColor })},[_vm._v("卫生用品、烟头、少量尘土")]),_vm._v("等。 ")])]),_c('div',{staticClass:"img"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line",style:({ background: _vm.style.mainColor })}),_c('div',{staticClass:"title"},[_vm._v("处理流程")])]),_c('img',{attrs:{"src":_vm.style.img}})])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_vm._v("可回收物")]),_c('div',{staticClass:"name-e"},[_vm._v("RECYCLABLE WASTE")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_vm._v("易腐垃圾")]),_c('div',{staticClass:"name-e"},[_vm._v("PERISHABLE WASTE")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_vm._v("有害垃圾")]),_c('div',{staticClass:"name-e"},[_vm._v("HARMFUL WASTE")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_vm._v("其他垃圾")]),_c('div',{staticClass:"name-e"},[_vm._v("OTHER WASTE")])])}]

export { render, staticRenderFns }