<template>
  <div class="own-index">
    <div class="page-container" style="margin-bottom: 5rem">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>
          <!-- {{ showData.orderBaseInfo.title }} -->
          我的反馈
        </h1>
      </div>
      <div class="park-main">
        <div class="banner-deatil-title">
          <div class="deatil-title-flex">
            <span class="value">{{ showData.content }}</span>
          </div>
          <div v-if="showData.image">
            <img
              v-for="(item, index) in imageList"
              :key="index"
              style="
                margin-top: 10px;
                margin-right: 10px;
                width: 50px;
                height: 50px;
              "
              :src="item"
              @click="ImagePreview(index)"
            />
          </div>
          <div style="width: 100%;overflow:hidden;white-space: nowrap;text-overflow:ellipse;border-bottom:1px solid #f5f5f5;padding: 0.5rem 0;">
            {{title}}
          </div>
          <div style="margin-top: 0.5rem;display:flex;align-items: center;">
            <van-icon name="clock-o" />
            <span style="margin-left:10px;">{{showData.createTime || ''}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      orderId: null,
      title: '',
      butName: null,
      propsType: null,
      propsSel: null,
      pageDataList: [],
      pageUserList: [],
      dataList: [],
      showData: {},
      imageList: [],
    };
  },
  components: {},
  mounted() {
    const { key, name } = this.$route.params;
    this.orderId = key;
    this.title = name;
    this.propsType = this.$route.params.name;
    this.propsSel = this.$route.params.selected;
    this.getDetail({ orderId: key });
  },
  methods: {
    ImagePreview(index) {
      ImagePreview({
        images: this.imageList,
        closeable: true,
        startPosition: index,
      });
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    gotoFkpj(path) {
      this.$router.push({
        name: path,
        params: { key: this.orderId, name: this.showData.orderBaseInfo.title },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    comment() {
      this.$router.push('yqpddFk');
    },
    getDetail(params) {
      console.log('params', params)
      yqpddApi.getOrderEvaluate(params).then((res) => {
        const { data } = res;
        this.showData = data;
        this.imageList = data.image ? data.image.split('#') : [];
      });
    },
    deleteOrder() {
      const { key } = this.$route.query;
      yqpddApi.deleteOrder({ orderId: key }).then((res) => {
        const { data } = res;
        this.$messagebox("提示", data);
      });
    },
    addOrderEnroll() {
      const { key } = this.$route.query;
      yqpddApi.addOrderEnroll({ orderId: key }).then((res) => {
        const { data } = res;
        this.$messagebox("提示", data);
        this.getDetail({ orderId: key });
      });
    },
    deleteOrderEnroll() {
      const { key } = this.$route.query;
      yqpddApi.deleteOrderEnroll({ orderId: key }).then((res) => {
        const { data } = res;
        this.$messagebox("提示", data);
        this.getDetail({ orderId: key });
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.banner-deatil-title {
  padding: 0.8rem;
  .deatil-title-name {
    opacity: 0.85;
    font-size: 1rem;
    color: #000000;
    text-align: left;
    line-height: 2rem;
    font-weight: bold;
  }
  .deatil-title-flex {
    display: flex;
    > div {
      width: 50%;
      padding: 0.5rem 0;
    }
    .name {
      opacity: 0.45;
      font-size: 0.8rem;
      color: #000000;
      text-align: left;
      line-height: 1.5rem;
    }
    .value {
      opacity: 0.85;
      font-size: 0.8rem;
      color: #000000;
      text-align: left;
      line-height: 1.5rem;
    }
  }
}
.banner-detail-people {
  padding: 0.8rem;
  .banner-title {
    border-left: 3px solid #4479ED;
    padding-left: 6px;
    display: flex;
    justify-content: space-between;
    margin: 0.8rem 0;
    b {
      font-size: 16px;
      color: rgba(0,0,0,0.85);
    }
    span {
      color: rgba(0,0,0,0.45);
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 16.6%;
      min-height: 3.5rem;
      padding: 0.5rem;
      // margin-bottom: 1.2rem;
      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
      .name {
        text-align: center;
        margin: 0.3rem 0;
        width: 3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.banner-detail-content {
  // padding: 0.8rem;
  font-size: 0.8rem;
  color: rgba(0,0,0,0.65);
  text-align: left;
  line-height: 1.6rem;
  text-indent: 2rem;
  padding: 0.5rem 0;
}
</style>
