<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>新衣捐赠</h1>
      </div>
      <div class="info">
        感谢您献出的爱心，您可以预约捐赠时间，工作人员会上门服务。
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="捐赠人"
            placeholder="请填写"
            :rules="[{ required: true, message: '请填写捐赠人姓名' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="address"
            name="address"
            is-link
            readonly
            label="所在住址"
            placeholder="请选择所在住址"
            @click="show = true"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="start"
            name="beginTime"
            is-link
            readonly
            label="捐赠时间"
            placeholder="请选择开始时间"
            @click="showStart = true"
            :rules="[{ required: true, message: '请选择开始时间' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="end"
            name="endTime"
            is-link
            readonly
            label=" "
            placeholder="请选择结束时间"
            @click="handleEnd"
            :rules="[{ required: true, message: '请选择结束时间' }]"
            class="input-bar"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">捐赠物品</div>
            <van-field
              size="large"
              v-model="description"
              name="donate"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入捐赠物品名称、数量等信息"
              show-word-limit
            />
          </div>

          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <van-popup v-model="showStart" position="bottom">
      <van-datetime-picker
        v-model="start"
        type="datetime"
        title="选择开始时间"
        @cancel="onCancel('showStart')"
        @confirm="onStartConfirm"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup v-model="showEnd" position="bottom">
      <van-datetime-picker
        v-model="end"
        type="datetime"
        title="选择结束时间"
        :min-date="minDate"
        @cancel="onCancel('showEnd')"
        @confirm="onEndConfirm"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="address"
        title="请选择所在住址"
        :options="options"
        :field-names="fieldNames"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import commonApi from "api/common";
import loveActivityApi from "api/ddya/loveActivity";
import { mapState } from "vuex";
import context from '@/main';
export default {
  data() {
    return {
      name: "",
      phone: "",
      address: "",
      description: "",
      options: [],
      fieldNames: {
        text: "value",
        value: "value",
        children: "children"
      },
      start: "",
      end: "",
      showStart: false,
      showEnd: false,
      show: false,
      minDate: "",
    };
  },
  components: {},
  mounted() {
    commonApi.getAddress().then((res) => {
      this.options = res.data;
    });
    this.name = this.userInfo.nickname;
  },
  computed: {
    ...mapState({
      userInfo: (state) =>
        state.login.userInfo || context.getSessionData("userInfo"),
    }),
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onSubmit(values) {
      loveActivityApi.donateClothes({ ...values }).then((res) => {
        if (res.data) {
          this.$messagebox("提示", "提交成功！等待工作人员上门。");
        }
      });
    },
    onFinish({ selectedOptions }) {
      this.show = false;  
      this.address = selectedOptions.map((option) => option.value).join("");
    },
    onCancel(type) {
      this[type] = false;
    },
    onStartConfirm(value, index) {
      console.log(value);
      this.start = this.formatDateTime(value);
      this.minDate = new Date(this.start);
      this.end = "";
      this.showStart = false;
    },
    onEndConfirm(value, index) {
      this.end = this.formatDateTime(value);
      this.showEnd = false;
    },

    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      console.log("y  ", y + "-" + m + "-" + d + " " + h + ":" + m + ":00");
      return y + "-" + m + "-" + d + " " + h + ":" + m + ":00";
    },
    handleEnd() {
      if (!this.start) {
        Toast("请先选择开始时间");
      } else {
        this.showEnd = true;
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    // formatDateTime(date) {
    //   var y = date.getFullYear();
    //   var m = date.getMonth() + 1;
    //   m = m < 10 ? "0" + m : m;
    //   var d = date.getDate();
    //   d = d < 10 ? "0" + d : d;
    //   var h = date.getHours();
    //   var minute = date.getMinutes();
    //   minute = minute < 10 ? "0" + minute : minute;
    //   return y + "-" + m + "-" + d;
    // },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .info {
    font-size: 14px;
    line-height: 28px;
    padding: 0.2rem 1rem;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0.5rem;
  }
  .form-bar {
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
