import service from "utils/service";

const hjlbnApi = {
  getList: (params) => {
    return service.post("/hejieEnroll/getApplyInfo", params);
  }, // 查询申请信息
  hjlbnAdd: params => {
    return service.post('/hejieEnroll/add', params);
  }, // 新增申请
  hjlbnDelete: params => {
    return service.get('/hejieEnroll/delete', { params });
  }, // 退出协会或取消申请
  hjlbnPublish: params => {
    return service.post('/hejieSearchHelp/add', params);
  }, // 发布问题
  getHelpType: params => {
    return service.get('/hejieSearchHelp/getHelpType', { params });
  }, // 获取帮助类型列表
  getHelpList: params => {
    return service.post('/hejieSearchHelp/list', params);
  }, // 查询提交记录
};
export default hjlbnApi;
