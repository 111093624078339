<template>
  <div class="footer">
    <ul>
      <li class="home selected" @click="gotoLink('edIndex')">
        <i class="el-icon-s-home"></i>
        <p>首页</p>
      </li>
      <li class="mine" @click="gotoLink('mine')">
        <i class="el-icon-s-custom"></i>
        <p>我的</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
