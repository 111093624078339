<template>
  <div id="index">
    <div class="page-container" style="height: auto !important;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>工具箱</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/gjx_banner.png" />
        </div>
        <div class="all-nav clearfix">
          <ul>
            <li @click="gotoLink('fxgj')">
              <i class="fxgj"></i>
              <span>分享工具</span>
            </li>
            <li @click="gotoLink('jygj')">
              <i class="jygj"></i>
              <span>借用工具</span>
            </li>
            <li @click="gotoLink('wydz')">
              <i class="wydz"></i>
              <span>我要点赞</span>
            </li>
            <li v-if="count > 0" @click="gotoLink('handleBorrow')">
              <van-badge :content="count">
                <i class="jycl"></i>
                <span>借用处理</span>
              </van-badge>
            </li>
            <li v-else @click="gotoLink('handleBorrow')">
              <i class="jycl"></i>
              <span>借用处理</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="note-box-gjx">
      <div class="tab">热门工具</div>
      <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list">
          <div
            style="border-bottom: 1px solid rgba(0,0,0,0.1)"
            v-for="(item, index) in list"
            :key="`${item.invitationId}_${index}`"
            class="card-con"
            @click="gotoDetail(item)"
          >
            <img
              style="width: 69px; height: 69px"
              :src="item.invitationContent || 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main_select.png'"
              class="image"
            />
            <div>
              <div class="card-title">
                <span>{{ item.invitationTitle }}</span>
              </div>
              <div class="row">
                <span>数量：{{ item.toolNumber }}</span>
              </div>
              <!-- <div class="row">
                <span>位置：{{item.toolPlace}}</span>
              </div> -->
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import gjxApi from "api/gjx";
import { List, Toast } from "vant";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      finished: false,
      count: 0,
    };
  },
  components: {},
  mounted() {
    gjxApi.getBorrowCount({}).then(res => {
      if(res.flag) {
        this.count = res.data;
      }
    })
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    getList({ page = this.page, size = this.size } = {}) {
      gjxApi
        .getToolCasePage({
          page,
          size,
          object: {
            rentTool: true,
          },
        })
        .then((res) => {
          const { records = [], total = 0 } = res.data || {};
          this.list = this.list.concat(records);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    gotoDetail(item) {
      this["setQuestionInfo"](item);
      this.gotoLink(`gjxxq?invitationId=${item.invitationId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";

.note-box-gjx {
  // border-top: 0.8rem solid #f6f7fa;
  background: #ffffff;
  padding: 0.5rem 0.8rem 1rem;
  //border-radius: 0.5rem;
  .tab {
    height: 1.4rem;
    font: 600 1.2rem/1.4rem "microsoft yahei";
    text-align: left;
    margin: 0.8rem 1rem 0.4rem 0.2rem;
    border-left: 0.4rem solid #4479ed;
    padding-left: 0.8rem;
  }
  .list {
    //padding: 0 0.6rem;
    li {
      padding: 0.7rem 0.5rem;
      font: 500 0.8rem/1.2rem "microsoft yahei";
      color: #878787;
      border-bottom: 1px solid #d1d1d1;
      span {
        margin-right: 1rem;
      }
    }
    .card-con {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.8rem;
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        height: 16px;
        margin-bottom: 0.1rem;

        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 4.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
