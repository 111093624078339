<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>拼游</h1>
      </div>
      <div class="park-main">
        <div class="daryLine"></div>
        <form action="javascript:return true" style="width: 100%">
          <van-search
            v-model="value"
            placeholder="请输入搜索关键词"
            @search="onSearch"
          >
          </van-search>
        </form>
        <div class="daryLine"></div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="status"  @change="handleChange($event,'status')" :options="option1" />
          <van-dropdown-item v-model="content" @change="handleChange($event,'content')" :options="option2" />
        </van-dropdown-menu>
        <ul class="detail-img-box-two">
          <li v-for="item in pageDataList" :key="item.orderId">
            <div
              class="img"
              @click="gotoLink('yqpddDetail?key=' + item.orderId)"
            >
              <div class="img-top">
                <div class="img-status" :style="{background: colors[item.status].background,color: colors[item.status].text}" ><p class="img-text">{{ item.status }}</p></div>
                <img :src="item.img" />
              </div>
              <div class="img-title left">{{ item.title }}</div>
              <div class="img-des">
                <span
                  >已有<span class="blue">{{ item.number }}</span
                  >人报名</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Search } from "vant";
import yqpddApi from "api/yqpdd";
export default {
  data() {
    return {
      value: "",
      pageDataList: [],
      status: '',
      content: '',
      option1: [
        { text: '全部活动', value: '' },
        { text: '报名中', value: -1 },
        { text: '进行中', value: 0 },
        { text: '已结束', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: '' },
        { text: '最近开始', value: '最近开始' },
        { text: '报名人数高', value: '报名人数' },
      ],
      colors: {
        '报名中': { text: '#4479ED', background: '#E3EFFD' },
        '进行中': { text: '#E5B20A', background: '#FFF6D9' },
        '已结束': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
    };
  },
  components: {},
  mounted() {
    this.getlist({ page: 1, size: 10 });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    handleChange(e, type) {
      this[type] = e;
      this.getlist({ page: 1, size: 10 });
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getlist(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          type: "拼游",
          status: this.status,
          title: this.value,
          content: this.content
        },
        ...value,
      };
      yqpddApi.getOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => {
            item.img = item.image.split("#")[0];
            item.status = item.status === -1 ? '报名中' : item.status === 0 ? '进行中' : '已结束'
          });
        this.pageDataList = records.filter((item) => item.type === "拼游");
      });
    },
    handleClear() {
      this.searchValue = '';
      this.page = 1;
      this.getlist({ page: 1 });
    },
    onSearch(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          title: value,
          type: "拼游",
          status: this.status,
          content: this.content,
        },
      };
      yqpddApi.getOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => {
            item.img = item.image.split("#")[0];
            item.status = item.status === -1 ? '报名中' : item.status === 0 ? '进行中' : '已结束'
          });
        this.pageDataList = records;
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
