<template>
  <div class="no-data-con">
    暂无数据
  </div>
</template>
<script>
export default {
  name: 'NoData',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.no-data-con {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #646566;
}
</style>