<template>
  <div class="yishuju-container">
    <van-nav-bar
      title="伊·数据"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yishuju-body-container">
      <van-tabs color="#4479ED">
        <van-tab title="基础大数据">
          <!-- 数据动态情况 -->
          <div class="shujudongtai-bar">
            <div class="chart-con">
              <BasicLineEchart :cdata="basicData1" />
            </div>
            <p>注：下个季度数据于结束后将展示</p>
          </div>
          <!-- 指标统计 -->
          <div class="zhibiaotongji-bar">
            <h1>指标统计</h1>
            <div class="zhibiaotongji-bar-list">
              <div
                class="zhibiaotongji-bar-list-card"
                v-for="item in basicData2"
                :key="item.title"
                :style="{backgroundColor: item.color}"
              >
                <div>
                  <span>{{item.num}}</span>
                  <span>人</span>
                </div>
                <p>{{item.title}}</p>
                <img :src="item.icon" alt="">
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="维权大数据">
          <!-- 数据动态情况 -->
          <div class="shujudongtai-bar">
            <div class="chart-con">
              <BasicLineEchart1 :cdata="basicData4" />
            </div>
            <p>注：下个季度数据于结束后将展示</p>
          </div>
          <!-- 指标统计 -->
          <div class="zhibiaotongji-bar">
            <h1>指标统计</h1>
            <div class="zhibiaotongji-bar-list">
              <div
                class="zhibiaotongji-bar-list-card"
                v-for="item in basicData3"
                :key="item.title"
                :style="{backgroundColor: item.color}"
              >
                <div>
                  <span>{{item.num}}</span>
                  <!-- <span>人</span> -->
                </div>
                <p>{{item.title}}</p>
                <img :src="item.icon" alt="">
              </div>
            </div>
            <div class="zhibiaotongji-bar-list">
              <div
                class="zhibiaotongji-bar-list-card4"
                v-for="item in basicData4"
                :key="item.title"
                :style="{backgroundColor: item.color}"
              >
                <div>
                  <span>{{item.num}}</span>
                  <!-- <span>人</span> -->
                </div>
                <p>{{item.title}}</p>
                <img :src="item.icon" alt="">
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import { basicData1, basicData2, basicData3, basicData4 } from 'assets/json/yishuju';
import BasicLineEchart from './basicLineEchart';
import BasicLineEchart1 from './basicLineEchart1';
export default {
  data() {
    return {
      basicData1: basicData1,
      basicData2: basicData2,
      basicData3: basicData3,
      basicData4: basicData4,
    };
  },
  computed: {
  },
  components: { BasicLineEchart, BasicLineEchart1 },
  mounted() {
    console.log('data', basicData1)
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.yishuju-container {
  height: 100vh;
  width: 100vw;
  
  .yishuju-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .shujudongtai-bar {
      width: 100vw;
      padding: 15px;
      background-color: #FFF;
      margin-top: 10px;
      .chart-con {
        height: 302px;
        width: 100%;
      }
      >p {
        color: rgba(0,0,0,0.45);
        font-size: 12px;
      }
    }
    .zhibiaotongji-bar {
      width: 100vw;
      padding: 15px;
      background-color: #FFF;
      margin-top: 10px;
      >h1 {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        text-align: center;
      }
      .zhibiaotongji-bar-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .zhibiaotongji-bar-list-card, .zhibiaotongji-bar-list-card4 {
          width: 48%;
          border-radius: 4px;
          height: 78px;
          margin: 5px 0;
          background-color: rgba(0,0,0,0.03);
          padding: 1rem;
          position: relative;
          >div {
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            line-height: 20px;
            font-weight: 600;
            >span:nth-child(1) {
              font-size: 20px;
              color: rgba(0,0,0,0.85);
              font-weight: 600;
            }
          }
          >img {
            width: 48px;
            height: 48px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
          >p {
            font-size: 12px;
            color: rgba(0,0,0,0.65);
            letter-spacing: 0;
            line-height: 24px;
          }
        }
        .zhibiaotongji-bar-list-card4 {
          width: 32%;
        }
      }
    }
  }
}
</style>
