<template>
  <div class="container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>体检记录</h1>
    </div>
    <div class="main">
      <div class="form">
        <div
          class="sub-main"
          v-if="examList && examList.length > 0"
          v-for="item in examList"
          @click="gotoExamDetail(item.id)"
        >
          <div class="sub-content">
            <div class="cont">
              <div class="info">
                <span class="label">体检机构</span
                ><span class="val"> {{ item.tjjgmc }}</span>
              </div>
              <div class="info">
                <span class="label">体检日期</span
                ><span class="val"> {{ item.tjrq }}</span>
              </div>
              <div class="info">
                <span class="label">总检查医生</span
                ><span class="val"> {{ item.tjysxm }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="sub-main" v-if="!examList || examList.length == 0">
          <div class="sub-content">
            <div class="cont">
              无相关记录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        examList: []

    };
  },
  components: {},
  mounted() {
   this.getExamList();
  },
  methods: {
    getExamList: function() {
      let _this = this;
      let userId = this.getSessionData("healthUserId");
      let token = this.getSessionData("healthtToken");
      this.axios
        .get("https://yyh.ikeqiao.net/app//health/exam/" + userId, {
          headers: { token: token },
        })
        .then(function(res) {
          _this.examList = res.data.examList;
        })
        .catch(function(err) {});
    },
    gotoExamDetail: function(id) {
      this.$router.push({ name: "health/exam", params: { id: id } });
    },
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/health.scss";
</style>
