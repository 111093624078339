<template>
  <div class="own-index">
    <div class="cobuilding-detail-page-container" style="padding-bottom: 46px">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>活动详情</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img :src="dataInfo.bannerImg" />
        </div>
        <div class="banner-deatil-title">
          <div
            :style="{background: '#E3EFFD',color: '#4479ED'}"
            class="tag"
          >
            {{ dataInfo.activityStatus }}
          </div>
          <div class="deatil-title-name">
            <span>{{dataInfo.title}}</span>
          </div>
          <div class="deatil-title-flex">
            <span class="name">活动时间：</span>
            <span class="value">{{dataInfo.activityTime}}</span>
          </div>
          <div class="deatil-title-flex">
            <span class="name">活动地址：</span>
            <span class="value">{{dataInfo.address}}</span>
          </div>
          <div class="deatil-title-flex">
            <span class="name">报名截止时间：</span>
            <span class="value">{{dataInfo.deadlineTime}}</span>
          </div>
        </div>
        <div class="banner-detail-people">
          <div class="index-tab tab">
            <b>报名情况</b>
            <span>
              已报名人数
              <span style="color:#4479ED; margin-left: 3px">{{dataInfo.number}}</span>人
            </span>
          </div>
          <div class="content">
            <div v-for="item in gameEnroll" :key="item.key">
              <img v-if="item.enrollUrl" :src="item.enrollUrl" />
              <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" />
              <div class="name">{{item.enrollName}}</div>
            </div>

          
          </div>
        </div>
        <div class="daryLine"></div>
        <div class="banner-detail-content">
          <div class="all-nav" style="margin:0;padding:0">
            <div class="index-tab tab">
              <b>具体内容</b>
            </div>
          </div>
          <div ref="long" class="content" v-html="dataInfo.content">
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in getButton(dataInfo)"
        :key="index"
        class="footer-button"
      >
        <van-button round :color="item.color" :disabled="item.disabeld" @click="item.action">{{ item.name }}</van-button>
      </div>
    </div>
    <van-dialog
      v-model="showDialog"
      title="请确认报名信息"
      show-cancel-button
      @cancel="showDialog = false"
      :before-close="addOrderEnroll"
    >
      <van-cell-group style="margin: 0.5rem 0;">
        <div class="radio-bar">
          <span
            :class="['radio', { selected: person === 'relation' }]"
            @click="handleRadio('relation')"
          >
            给亲友报名
          </span>
          <span
            :class="['radio', { selected: person === 'self' }]"
            @click="handleRadio('self')"
          >
            为自己报名
          </span>
        </div>  
        <van-field
          v-model="name"
          required
          label="报名人姓名"
          placeholder="请输入报名人姓名"
          maxlength="10"
        />
        <div style="color: red;font-size: 14px;margin: 10px 16px;" v-if="showNameWarn">请输入报名人姓名</div>
        <van-field
          v-model="phone"
          required
          label="联系手机"
          placeholder="请输入联系手机"
          type="tel"
        />
        <div style="color: red;font-size: 14px;margin: 10px 16px;" v-if="showPhoneWarn">请输入联系手机</div>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import wyslApi from "api/llyjr/wysl";
import { Toast, Dialog } from "vant";

export default {
  data() {
    return {
      isExpand: false,
      gameEnroll: [],
      colors: {
        0: { text: '#4479ED', background: '#E3EFFD' },
        1: { text: '#E5B20A', background: '#FFF6D9' },
        2: { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        4: { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      dataInfo: {},
      showDialog: false,
      name: '',
      phone: '',
      person: 'self', // 为谁报名
      showNameWarn: false,
      showPhoneWarn: false,
    };
  },
  components: {},
  computed: {
    ...mapState({
      questionInfo: state => {
        return state.llyjr.questionInfo;
      }
    })
  },
  mounted() {
    console.log(this.dataInfo);
    const userInfo = (this.getSessionData('userInfo')) || {};
    const { userName: name = '', phone = '' } = userInfo;
    this.name = name;
    this.phone = phone;
    const { key } = this.$route.query;
    this.activityId = key;
    
    this.getActivityInfo();
    // this.$nextTick(() => {
    //   const { offsetHeight } = this.$refs["long"];
    //   if (offsetHeight > 86) {
    //     this.isExpand = true;
    //   }
    // });
  },
  methods: {
    getActivityInfo() {
      wyslApi.getActivityInfo({ activityId: this.activityId }).then(res => {
        console.log('aa', res.data)
        this.dataInfo = res.data;
        this.getGameEnroll();
      })
    },
    getGameEnroll() {
      wyslApi
        .getGameEnroll({ activityId: this.dataInfo.activityId })
        .then(res => {
          this.gameEnroll = res.data;
          this.dataInfo.number = res.data.length;
        });
    },
    handleRadio(value) {
      this.person = value;
      this.showNameWarn = false;
      this.showPhoneWarn = false;
      if(value === 'self') {
        const userInfo = (this.getSessionData('userInfo')) || {};
        const { userName: name = '', phone = '' } = userInfo;
        this.name = name;
        this.phone = phone;
      } else {
        this.name = '';
        this.phone = '';
      }
    },
    ...mapMutations(["setQuestionInfo"]),
    updateQuestionInfo(flag) {
      this.getGameEnroll();
      // this["setQuestionInfo"]({
      //   ...this.questionInfo,
      //   enrollStatus: flag === "cancelEnroll" ? "已取消" : "已报名"
      // });
      this.getActivityInfo();
    },
    handleAddEnroll(item) {
      const { enrollStatus } = item;
      this.$store.dispatch('login/checkRealName', () => {
        if (enrollStatus === "已报名") {
          wyslApi.cancelEnroll({ activityId: item.activityId }).then(res => {
            if (res.data == 1) {
              Toast.success("取消报名成功");
              this.updateQuestionInfo("cancelEnroll");
            }
          });
        }
        if (enrollStatus === "已取消") {
          wyslApi.repeatEnroll({ activityId: item.activityId }).then(res => {
            if (res.data == 1) {
              Toast.success("重新报名成功");
              this.updateQuestionInfo("repeatEnroll");
            }
          });
        }
        if (enrollStatus === "未报名") {
          // 我要报名
          wyslApi.addEnroll({ activityId: item.activityId }).then(res => {
            if (res.data == 1) {
              Toast.success("报名成功");
              this.updateQuestionInfo("addEnroll");
            }
          });
        }
      })
    },
    handleEnroll() {
      this.$store.dispatch('login/checkRealName', () => {
        this.showDialog = true
      })
    },
    addOrderEnroll(action, done) {
      const params = this.dataInfo;
      const { name, phone } = this;
      if(action === 'confirm') {
        if(!name) { 
          this.showNameWarn = true;
          done(false);
        } else if(!phone) {
          this.showPhoneWarn = true;
          done(false);
        } else if (params.enrollStatus === '已取消') {
          // 重新报名
          wyslApi.repeatEnroll({ activityId: params.activityId, createUserName: name, phone: phone }).then(res => {
            if (res.data == 1) {
              done();
              Toast.success("重新报名成功");
              this.updateQuestionInfo("repeatEnroll");
            }
          });
        } else {
          // 我要报名
          wyslApi.addEnroll({ activityId: params.activityId, createUserName: name, phone: phone }).then(res => {
            if (res.data == 1) {
              done();
              Toast.success("报名成功");
              this.updateQuestionInfo("addEnroll");
            }
          });
        }
      } else {
        done();
      }
    },
    deleteOrder() {
      const params = this.dataInfo;
      const { name, phone } = this;
      Dialog.confirm({
        title: '提示',
        message: '确定是否取消报名？',
        'confirm-button-text': '取消报名',
        'confirm-button-color': '#2e70ed',
        'cancel-button-color': '#eeeeee'
      })
      .then(() => {
        wyslApi.cancelEnroll({ activityId: params.activityId, createUserName: name, phone: phone }).then(res => {
            if (res.data == 1) {
              Toast.success("取消报名成功");
              this.updateQuestionInfo("cancelEnroll");
            }
          });
      })
      .catch(() => {

      })
    },
    handleExpand() {
      this.isExpand = false;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getButton(dataInfo) {
      let list = [];
      const obj = {
        ksbm: [{ name: '我要报名', color: '#1989fa', disabled: false, action: this.handleEnroll}],
        qxbm: [{ name: '取消报名', color: '#1989fa', disabled: false, action: this.deleteOrder}],
        cxbm: [{ name: '重新报名', color: '#1989fa', disabled: false, action: this.handleEnroll}],
        bmjz: [{ name: '报名已截止', color: '#ccc', disabled: true}],
        hdjs: [{ name: '活动已结束', color: '#ccc', disabled: true}]
      }
      if(dataInfo.activityStatus === '报名中') {
        if(dataInfo.permitEnroll === '已截止报名') {
          list = obj.bmjz;
        } else {
          switch (dataInfo.enrollStatus) {
            case '未报名': list = obj.ksbm;break;
            case '已报名': list = obj.qxbm;break;
            case '已取消': list = obj.cxbm;break;
          }
        }
      } else if(dataInfo.activityStatus === '进行中') {
        if(dataInfo.permitEnroll === '已截止报名') {
          list = obj.bmjz;
        } else {
          switch (dataInfo.enrollStatus) {
            case '未报名': list = obj.ksbm;break;
            case '已报名': list = obj.qxbm;break;
            case '已取消': list = obj.cxbm;break;
          }
        }
      } else if(dataInfo.activityStatus === '已截止') {
        switch (dataInfo.enrollStatus) {
          case '未报名': list = obj.bmjz;break;
          case '已报名': list = obj.qxbm;break;
          case '已取消': list = obj.bmjz;break;
        }
      } else {
        list = obj.hdjs;
      }
      return list;
    }
  }
};
</script>

<style lang="scss">
  .banner-detail-content {
    img{
      width: 100% !important;
    }
  }
  .banner {
    >img {
      width: 100%;
    }
  }
</style>
<style lang="scss" scoped>
.radio-bar {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  .radio {
    height: 2rem;
    width: 45%;
    text-align: center;
    border-radius: 4px;
    // background: #4479ED;
    color: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(0, 0, 0, 0.35);
    padding: 0.1rem 1.2rem;
  }
  .selected {
    background: #4479ed;
    color: #ffffff;
    border: 1px solid #4479ed;
  }

}
.cobuilding-detail-page-container {
  // padding: 0.8rem 0rem;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  overflow: auto;
  
  .park-main {
    padding-top: 3.2rem;
    .banner {
      img {
        width: 100vw;
      }
    }
    .index-tab {
      height: 1.4rem;
      margin-bottom: 0.4rem;
      padding-right: 0.6rem;
      b {
        float: left;
        //padding-left: 2.4rem;
        //color: #0fade7;
        img {
          height: 1rem;
          vertical-align: middle;
        }
      }
      span {
        float: right;
        color: #a1a1a1;
        font-size: 0.8rem;
        //font-family: simsun;
      }
    }
    .banner-deatil-title {
      padding: 0.8rem;
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0.5rem;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        font-size: 12px;
    }
      .deatil-title-name {
        opacity: 0.85;
        font-size: 1rem;
        color: #000000;
        text-align: left;
        line-height: 2rem;
        font-weight: bold;
      }
      .deatil-title-flex {
        display: flex;
        > div {
          width: 50%;
          padding: 0.5rem 0;
        }
        .name {
          opacity: 0.45;
          font-size: 0.8rem;
          color: #000000;
          text-align: left;
          line-height: 1.5rem;
        }
        .value {
          opacity: 0.85;
          font-size: 0.8rem;
          color: #000000;
          text-align: left;
          line-height: 1.5rem;
        }
      }
    }
    .banner-detail-people {
      padding: 0.8rem;
      .content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 16%;
          height: 3.5rem;
          padding: 0.5rem;
          margin-bottom: 1.2rem;
          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
          .name {
            text-align: center;
            margin: 0.3rem 0;
          }
        }
      }
    }
    .daryLine {
      height: 0.8rem;
      width: 100%;
      background-color: #f5f5f5;
    }

    .banner-detail-content {
      padding: 0.8rem;
      text-indent: 0rem !important;
      width: 100%;
      overflow-x: hidden;
      .tab {
        height: 1.2rem;
        font: 500 0.9rem/1.2rem "microsoft yahei";
        text-align: left;
        margin: 0.8rem 1rem 0.4rem 0.2rem;
        border-left: 0.4rem solid #4479ed;
        padding-left: 0.8rem;
      }
      .content {
        // opacity: 0.65;
        font-size: 0.8rem;
        color: rgba(0,0,0,0.65);
        text-align: left;
        line-height: 1.6rem;
        padding-top: 0.5rem;
        // height: 5.6rem;
        position: relative;
        overflow-x: hidden;
        div {
          width: 100%;
          img {
            width: 80vw;
            height: auto;
          }
        }
        .expand-btn {
          color: #4479ed;
          position: absolute;
          bottom: 5px;
          right: 0;
          width: 40px;
          text-align: right;
          background: #fff;
        }
      }
      .long {
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
        height: 5.6rem;
        overflow: hidden;
      }
    }
  }
  .footer-button {
    position: fixed;
    bottom: 1rem;
    right: 0;
    left: 0.8rem;
    width: calc(100vw - 1.6rem);
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
    .van-button {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
