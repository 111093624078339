<template>
  <div class="own-index">
    <div class="page-container" style="margin-bottom: 5rem">
      <div class="header-nav">
        <i v-if="!isToMain" @click="goBack"></i>
        <div class="isToMain" style="color: #08f" v-if="isToMain" @click="goMain">主页</div>
        <h1>
          <!-- {{ showData.orderBaseInfo.title }} -->
          拼单详情
        </h1>
      </div>
      <div class="park-main">
        <!-- 图片轮播 -->
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in showData.imageList" :key="index">
            <img style="width: 100%; height: 15rem;" v-lazy="image || 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png'" @click="ImagePreview(index)" />
          </van-swipe-item>
        </van-swipe>
        <!-- 标题 -->
        <div class="banner-deatil-title">
          
          <div class="deatil-title-name">
            <span
              :style="{background: colors[this.status] ? colors[this.status].background : '#E3EFFD',color: colors[this.status] ? colors[this.status].text : '#4479ED'}"
              class="tag"
            >
              {{statusText(this.status)}}
            </span>
            <span style="width: 100%;">{{ showData.orderBaseInfo && showData.orderBaseInfo.title }}</span>
          </div>
          <div class="deatil-title-flex">
            <div style='padding:0px !important;'>
              <span class="name111">发起人：</span>
              <span class="value111">{{ showData.orderBaseInfo && showData.orderBaseInfo.own }}</span>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;">
              <img src="~assets/images/detail_phone.png" width="16" height="16" alt="">
              <span style="color: #4379ed;font-size: 14px;margin-left: 5px;" @click="callPhone(showData.orderBaseInfo.phone)">拨打联系</span>
            </div>
          </div>
          
        </div>
        <!-- 报名情况 -->
        <!-- <div class="daryLine"></div> -->
        <!-- <div class="banner-detail-people">
          <div class="banner-title">
            <b>报名情况</b>
            <span>已报名人数<span style="color: #4479ed">{{showData.orderBaseInfo && showData.orderBaseInfo.number}}</span>人</span>
          </div>
          <div class="content">
            <div
              v-for="item in showData.orderEnrolls || []"
              :key="item.username"
            >
              <img :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" />
              <div class="name" v-html="item.realName"></div>
            </div>
          </div>
        </div> -->
        <div class="daryLine"></div>
        <div style="padding: 10px 5px;">
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_time.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px;">活动时间：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ showData.orderBaseInfo && `${showData.orderBaseInfo.startTime}~${showData.orderBaseInfo.endTime}` }}</span>
          </div>
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_deadline.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px;">报名截止：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ showData.orderBaseInfo && showData.orderBaseInfo.deadline }}</span>
          </div>
          <!-- <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_link.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px;">报名人数：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ showData.orderBaseInfo && showData.orderBaseInfo.number }}</span>
          </div> -->
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_location.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">活动地点：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ showData.orderBaseInfo && showData.orderBaseInfo.address }}</span>
          </div>
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_location.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">报名人数：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">
              <span style="color: #08f">{{ showData.orderBaseInfo.number || 0 }}</span>
              /{{ showData.orderBaseInfo.limitPeopleNum || 0 }}
            </span>
          </div>
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_location.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">活动积分：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ showData.orderBaseInfo.integral || 0 }}积分</span>
          </div>
          <!-- <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <span style="margin-left: 29px;font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">活动积分：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ showData.orderBaseInfo && showData.orderBaseInfo.integral }}</span>
          </div> -->
        </div>
        <div class="daryLine"></div>
        <van-tabs  color="#4479ED">
          <van-tab v-if="status === 2" title="活动总结" title-style="font-size: 16px;">
            <div v-if="showData.orderBaseInfo && showData.orderBaseInfo.summary" style="text-indent: 0px !important;" class="banner-detail-content" v-html="showData.orderBaseInfo && showData.orderBaseInfo.summary"></div>
            <van-empty v-else description="暂未发布活动总结，请稍等" />
          </van-tab>
          <van-tab title="活动详情" title-style="font-size: 16px;">
            <div style="text-indent: 0px !important;" class="banner-detail-content" v-html="showData.orderBaseInfo && showData.orderBaseInfo.content"></div>
          </van-tab>
          <van-tab :title="`全部反馈(${showData.orderEvaluate && showData.orderEvaluate.length})`" title-style="font-size: 16px;">
            <ul class="list-img">
              <li v-for="(item, index) in showData.orderEvaluate || []" :key="index">
                <div class="img-radius">
                  <img :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" />
                </div>
                <div class="desc">
                  <div class="title" v-html="item.own"></div>
                  <div class="time">{{ item.createTime }}</div>
                  <div class="info">{{ item.content }}</div>
                  <div class="image">
                      <img style="width: 30px; height: 30px; margin: 5px" :src="v" alt="" v-for="(v, i) in (item.conmentImage || '').split('#').filter(v => v)" :key="i" >
                  </div>
                </div>
              </li>
            </ul>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="btns-flex" v-if="showData && Object.keys(showData).length > 0" style="justify-content: space-around;">
      <div
        v-for="(item, index) in getButton(showData)"
        :key="index"
        class="cancel-btn"
      >
        <button
          
          :class="item.class"
          form-type="submit"
          @click="item.action"
        >
          {{item.name}}
        </button>
      </div>
      
    </div>
    <van-dialog
      v-model="showDialog"
      title="请确认报名信息"
      show-cancel-button
      @cancel="showDialog = false"
      @confirm="addOrderEnroll"
    >
      <van-cell-group style="margin: 0.5rem 0;">
        <van-field
          v-model="name"
          label="报名人姓名"
          placeholder="请输入报名人姓名"
          maxlength="10"
        />
        <van-field
          v-model="phone"
          label="联系手机"
          placeholder="请输入联系手机"
          type="tel"
        />
        <van-field
          v-model="remark"
          label="备注"
          placeholder="请输入备注"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";
import axios from 'axios';
import { sharePage } from 'utils';
import { Toast, ImagePreview, Dialog } from 'vant';
export default {
  data() {
    return {
      orderId: null,
      butName: null,
      propsType: null,
      propsSel: null,
      pageDataList: [],
      pageUserList: [],
      dataList: [],
      showData: {},
      status: '', // 拼单状态
      access: '', // 拼单审核状态
      enrollStatus: '', // 报名状态
      commentStatus: '', //评价状态
      owner: '', // 是否发起人
      showDialog: false,
      name: '',
      phone: '',
      remark: '',
      colors: {
        '-1': { text: '#4479ED', background: '#E3EFFD' },
        '0': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
    };
  },
  components: {},
  mounted() {
    const { key, isToMain } = this.$route.query;
    this.isToMain = isToMain;
    this.orderId = key;
    this.propsType = this.$route.params.name;
    this.propsSel = this.$route.params.selected;
    const userInfo = this.getSessionData('userInfo') || {};
    const { userName: name = '', phone = '' } = userInfo;
    this.name = name;
    this.phone = phone;
    // if (this.propsType == "拼买") {
    //   this.butName = "我要报名";
    // } else if (this.propsType == "拼游") {
    //   this.butName = "反馈评价";
    // } else if (this.propsType == "我的拼单") {
    //   if (this.propsSel == "yqpddC") {
    //     this.butName = "取消活动";
    //   } else if (this.propsSel == "yqpddD") {
    //     this.butName = "重新发起";
    //   } else {
    //     this.butName = "活动已结束";
    //   }
    // } else {
    //   this.butName = "保存";
    // }
    this.getDetail({ orderId: key }, (detail) => {
      sharePage(key, 'yqpddDetail', detail);
    });
  },
  methods: {
    callPhone(number) {
      window.location.href = `tel://${number}`;
    },
    statusText(data) {
      switch (data) {
        case -1:
          return '报名中';
        case 0:
          return '进行中';
        case 2:
          return '已结束';
        default:
          return '';
      }
    },
    getButton(record) {
      let list = [];
      console.log('record', record);
      const {
          orderBaseInfo: { status, access },
          owner,
          enrollStatus,
          commentStatus,
          deadlineStatus,
        } = record;
      // cancel灰黑 disabeld灰白 delete红白 save蓝白
      const obj = {
        wtg: [
          { name: '查看原因', action: () => {
              Dialog({
                title: '审核未通过',
                message: this.showData.orderBaseInfo.reason,
                confirmButtonColor: '#2a70ed'
              });
            },
            class: 'normal'
          },
          { name: '重新提交', action: this.gotoAdd, class: 'save' },
        ],
        shz: [
          { name: '活动审核中', action: () => {}, class: 'disabeld' },
        ],
        // djx: [
        //   { name: '带进行', action: this.fangfa, class: '蓝' },
        // ],
        // jxz: [
        //   { name: '进行中', action: this.fangfa, class: '蓝' },
        // ],
        wbm: [
          { name: '我要报名', action: this.handleEnroll, class: 'save' },
        ],
        ybm: [
          { name: '取消报名', action: this.deleteOrderEnroll, class: 'cancel' }, 
          { name: '反馈评价', action: () => {this.gotoFkpj('yqpddFk')}, class: 'save' },
        ],
        ybmYpj: [
          { name: '取消报名', action: this.deleteOrderEnroll, class: 'cancel' },
          { name: '查看我的反馈', action: () => {this.gotoFkpj('yqpddComment')}, class: 'save' },
        ],
        ybmOwnerYpj: [
          { name: '取消活动', action: this.deleteOrder, class: 'cancel' },
          { name: '查看我的反馈', action: () => {this.gotoFkpj('yqpddComment')}, class: 'save' },
        ],
        ybmOwner: [
          { name: '取消活动', action: this.deleteOrder, class: 'cancel' },
          { name: '反馈评价', action: () => {this.gotoFkpj('yqpddFk')}, class: 'save' },
        ],
        yqx: [
          { name: '重新提交', action: this.gotoAdd, class: 'save' },
        ],
        yjs: [
          { name: '活动已结束', action: () => {}, class: 'disabeld' },
        ],
        wpj: [
          { name: '活动已结束', action: () => {}, class: 'disabeld' },
          { name: '反馈评价', action: () => {this.gotoFkpj('yqpddFk')}, class: 'save' },
        ],
        ypj: [
          { name: '活动已结束', action: () => {}, class: 'disabeld' },
          { name: '查看我的反馈', action: () => {this.gotoFkpj('yqpddComment')}, class: 'save' },
        ],
        bmjz: [{ name: '报名已截止', class: 'disabeld'}],
      };
      if (access === '未通过') {
        list = obj.wtg;
      } else if(access === '审核中') {
        list = obj.shz;
      } else {
        switch (status) {
          case -1: list = obj.djx;break;
          case 0: list = obj.jxz;break;
          case 1: list = obj.yqx;break;
          case 2: list = obj.yjs;break;
        }
        if (status === -1 || status === 0) {
          if (owner) {
            if (commentStatus) {
              list = obj.ybmOwnerYpj;
            } else {
              list = obj.ybmOwner;
            }
          } else {
            
            if (enrollStatus === 0) {
              if(deadlineStatus === 0) {
                list = obj.bmjz;
              } else {
                 list = obj.wbm;
              }
            } else if(enrollStatus === 1 && commentStatus){
              list = obj.ybmYpj;
            } else {
              list = obj.ybm;
            }
          }
        }
        if (status === 2) {
          if (enrollStatus === 0) {
            list = obj.yjs;
          } else if (commentStatus) {
            list = obj.ypj;
          } else {
            list = obj.wpj;
          }
        }
      }
      console.log('btns', list);
      
      return list;
    },
    ImagePreview(index) {
      ImagePreview({
        images: this.showData.imageList,
        closeable: true,
        startPosition: index,
      });
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    gotoFkpj(path) {
      this.$router.push({
        name: path,
        params: { key: this.orderId, name: this.showData.orderBaseInfo.title },
      });
    },
    gotoAdd() {
      const { key } = this.$route.query;
      this.$router.push({
        name: 'yqpddAdd',
        params: { id: key },
      });
    },
    goBack() {
      if(window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.replace('/indexCopy');
      }
    },
    goMain(){
      this.$router.replace('/');
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getDetail(params, callback = () => {}) {
      yqpddApi.getOrderDetail(params).then((res) => {
        const { data } = res;
        data.imageList = data.orderBaseInfo.image.split("#");

        const {
          orderBaseInfo: { status, access },
          owner,
          enrollStatus,
          commentStatus,
        } = data;
        this.owner = owner;
        this.enrollStatus = enrollStatus;
        this.commentStatus = commentStatus;
        this.status = status;
        console.log(status);
        
        this.access = access;
        this.showData = data;
        callback(data || {});
      });
    },
    deleteOrder() {
      Dialog.confirm({
        title: '提示',
        message: '活动取消后，已报名用户将无法参加本次活动，是否确认取消？',
        'confirm-button-text': '取消报名',
        'confirm-button-color': '#2e70ed',
        'cancel-button-color': '#eeeeee'
      })
      .then(() => {
        const { key } = this.$route.query;
        yqpddApi.deleteOrder({ orderId: key }).then((res) => {
          const { data } = res;
          Toast(data);
        });
      })
      .catch(() => {

      })
      
    },
    checkForm() {
      let message = '';
      if(!this.name || !(/[\u4e00-\u9fa5]$/).test(this.name)) {
        message += '请输入正确的姓名！';
      }
      if(!this.phone || !/^1[0-9]{10}$/.test(this.phone)) {
        message += '请输入正确的联系手机！'
      }
      if(message) {
        Toast.fail(message);
        return false;
      }
      return true;
    },
    handleEnroll() {
      // this.$store.dispatch('login/checkRealName', () => {
        this.showDialog = true
      // })
    },
    addOrderEnroll() {
      const { key } = this.$route.query;
      const { name, phone, remark } = this;
      if(this.checkForm()) {
        yqpddApi.addOrderEnroll({ orderId: key, own: name, phone, remark }).then((res) => {
          if(res.flag) {
            const { data } = res;
            Toast(data);
            this.getDetail({ orderId: key });
          }
        });
      }
    },
    deleteOrderEnroll() {
      const { key } = this.$route.query;
      yqpddApi.deleteOrderEnroll({ orderId: key }).then((res) => {
        const { data } = res;
        Toast(data);
        this.getDetail({ orderId: key });
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.banner-deatil-title {
  padding: 0.8rem;
  position: relative;
  
  .deatil-title-name {
    font-size: 18px;
    color: rgba(0,0,0,0.85);
    word-break: break-all;
    text-align: left;
    line-height: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    .tag {
      padding: 0 0.5rem;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 5px;
      height: 24px;
      line-height: 24px;
      display: inline-block;
    }
  }
  .deatil-title-flex {
    display: flex;
    > div {
      width: 50%;
      // padding: 0.5rem 0;
    }
    .name111 {
      opacity: 0.45;
      font-size: 14px;
      color: #000000;
      text-align: left;
      line-height: 1.5rem;
    }
    .value111 {
      opacity: 0.85;
      font-size: 14px;
      color: #000000;
      text-align: left;
      line-height: 1.5rem;
    }
  }
}
.banner-detail-people {
  padding: 0.8rem;
  .banner-title {
    border-left: 3px solid #4479ED;
    padding-left: 6px;
    display: flex;
    justify-content: space-between;
    margin: 0.8rem 0;
    b {
      font-size: 16px;
      color: rgba(0,0,0,0.85);
    }
    span {
      color: rgba(0,0,0,0.45);
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 16.6%;
      min-height: 3.5rem;
      padding: 0.5rem;
      // margin-bottom: 1.2rem;
      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
      .name {
        text-align: center;
        margin: 0.3rem 0;
        width: 3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.banner-detail-content {
  // padding: 0.8rem;
  font-size: 0.8rem;
  color: rgba(0,0,0,0.65);
  text-align: left;
  line-height: 1.6rem;
  // text-indent: 2rem;
  padding: 0.5rem 0;
  img{
    width: 100% !important;
    height: auto !important;
  }
}
    .isToMain{
      position: absolute;
      left: 10px;
      top: 15px;
    }
</style>
