<template>
  <div class="own-index" style="background-color:#f5f5f5">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>房屋认证</h1>
      </div>
      <div class="park-main">
        <div class="main-des">
            为保障社区内的住户享受高品质的服务，请提供您的真实信息以进行验证
        </div>
        <div class='own-form'>
            <div class="row">
                <div class="lable">
                    姓&nbsp;&nbsp;名
                    <span class="required">*</span>
                </div>
                <div class='value'>
                    <input placeholder="请输入姓名" v-model="personName" maxlength="6" @input="handleName" />
                </div>
            </div>
            <div class="row">
                <div class="lable">
                  身份证号
                </div>
                <div class='value'>
                    <input v-model="certificatesCode" maxlength="18" @input="certificatesCode = certificatesCode.replace(/^[^1-9][^0-9]*/, '')"  placeholder="请输入身份证号">
                </div>
            </div>
            <div class="daryLine"></div>
            <div class="row">
                <div class="lable">
                    所在小区
                    <!-- <span class="required">*</span> -->
                </div>
                <div class='value icon' @click="showCommunity = true">
                    <input disabled  placeholder="请选择所在小区" v-model="communityName">
                </div>
            </div>
            <div class="row">
                <div class="lable">
                    所在房屋
                    <!-- <span class="required">*</span> -->
                </div>
                <div class='value icon' @click="handleHouseClick">
                    <input disabled v-model="positionName" placeholder="请选择所在房屋">
                </div>
            </div>
             <div class="row">
                <div class="lable">
                    与户主关系
                    <!-- <span class="required">*</span> -->
                </div>
                <div class='value icon' @click="gotoSel('loginList')">
                    <input disabled v-model="relationship" placeholder="请选择住户类型">
                </div>
            </div>
            <div class="row">
                <div class="lable">
                    邀&nbsp;请&nbsp;码
                </div>
                <div class='value'>
                    <input placeholder="请输入邀请码" v-model="inviterCode" maxlength="6" @input="handleName" />
                </div>
            </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showType" round position="bottom">
      <van-picker
        title="与户主关系"
        show-toolbar
        :columns="livingTypeData"
        @confirm="onConfirm"
        @cancel="onCancle"
        value-key='dictLabel'
      />
    </van-popup>
    <van-popup v-model="showCommunity" round position="bottom">
      <van-picker
        title="小区"
        show-toolbar
        :columns="communityList"
        @confirm="onCfmConfirm"
        @cancel="onCfmCancle"
        value-key='key'
      />
    </van-popup>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        :value="positionName"
        @close="onFwCancle"
        title="选择房屋"
        :options="addressList"
        :field-names="fieldNames"
        @finish="onFinish"
      />
    </van-popup>
    <div class="btns-flex" style="justify-content:center">
        <div class="save-btn"><button class="save" @click="handleSubmit">提交认证</button></div>
    </div>
  </div>
</template>

<script>
import { Actionsheet } from 'mint-ui';
import { mapState } from 'vuex';
import { Toast } from 'vant';
import context from '@/main';
import { isIdentityId } from 'utils';
export default {
  data() {
    return {
      show:false,
      showType: false,
      showCommunity: false,
      positionName:null,
      address:null,
      sheetVisible:false,
      relationship:null,
      fieldNames: {
        text: 'key',
        value: 'value',
        children: 'children',
      },
      personName: '',
      certificatesCode: '',
      householdType:'',
      houseId: '',
      communityList: [],
      communityId: '',
      communityName: '',
      inviterId: '',
      inviterCode: '',
      oldPositionName: '',
    }
  },
  components: {},
  mounted() {
      const { inviterId = '' } = this.$route.query;
      this.inviterId = inviterId;
      this.address=this.$route.params.detail&&this.$route.params.detail.title;
      this.$store.dispatch('login/getlivingTypeData');
      this.getCommunityData();
  },
  methods: {
    handleName() {
      this.personName=this.personName.replace(/^\w*/g, '');
    },
    handleHouseClick() {
      if(this.community && this.community.value) {
        this.getHouseData();
        this.show = true;
      } else {
        Toast('请先选择小区!')
      }
    },
    getCommunityData() {
      this.$store.dispatch('login/getCommunityData', { params:{}, obj: this });
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    onConfirm(e){
        this.relationship = e.dictLabel;
        this.householdType = e.dictValue;
        this.showType = false;
    },
    onCancle() {
      this.showType = false;
    },
    onFwCancle(){
      console.log(111111);
      this.show = false;
      this.positionName = this.oldPositionName;
    },
    onCfmConfirm(e){
        this.communityId = e.value;
        this.communityName = e.key;
        this.showCommunity = false;
        this.positionName = '';
        this.$store.commit('login/selectCommunity', e);
    },
    onCfmCancle() {
      this.showCommunity = false;
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.positionName = selectedOptions.map((option) => option.key).join('-');
      this.houseId = selectedOptions[2].value;
      this.oldPositionName = this.positionName || '';
    },
    gotoSel(){
        this.showType=true;
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getHouseData() {
      this.$store.dispatch('login/getBuildingData', this);
    },
    checkForm() {
      const checkObj = {
        personName: '姓名',
        // certificatesCode: '身份证号',
        // positionName: '所在房屋',
        // relationship: '与户主关系',
        // community: '所住小区'
      };
      let message = Object.keys(checkObj).reduce((result, current) => {
        if(!this[current]) {
          result += `${checkObj[current]}，`;
        }
        return result;
      },'');
      if(message) {
        message += '不能为空。';
        // if(isIdentityId(this.certificatesCode)) {
        //   message += isIdentityId(this.certificatesCode);
        // }
        Toast.fail(message);
        return false;
      }
      return true;
    },
    handleSubmit() {
      if(this.checkForm()) {
        const params = [
          'personName', 
          'certificatesCode', 
          'communityId', 
          'communityName', 
          'houseId', 
          'phone', 
          'positionName', 
          'householdType', 
          'relationship',
          'phone',
          'inviterCode',
        ].reduce((result, current) => {
          result[current] = this[current];
          return result;
        }, {});
        params.inviterId = this.inviterId ? this.inviterId : undefined;
        console.log('params', params);
        this.$store.dispatch('login/handleHouseCheck',{
          params,
          callback: (data = {}) => {
            Toast('房屋认证成功！');
            this.setSessionData('isRealName', data.isRealName || '');
            this.setSessionData('checkStatus', data.isRealName ? '已实名' : '您的实名信息正在审核中!');
            setTimeout(() => {
              // this.$router.push('/');
              this.$router.go(-2);
            }, 500);
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      community: (state) => state.login.community,
      addressList: (state) => state.login.buildingData,
      livingTypeData: (state) => state.login.livingTypeData,
      phone: (state) => state.login.phone || context.getSessionData('phone'),
    }),
    // communityId() {
    //   return this.community.value;
    // },
    // communityName() {
    //   return this.community.key;
    // }
  }
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.main-des{
    background-color: #f5f5f5;
    line-height: 1.8rem;
    color: rgba(187, 187, 187, 100);
    font-size: 0.9rem;
    padding:0.8rem 1.6rem;
    margin-left: -0.8rem;
    width:110%;
}
.daryLine{
    margin-left: -1.6rem !important;
    width:120% !important
}
</style>
