<template>
  <div class="hui-study-container">
    <van-nav-bar
      class="hui-study-nav-bar"
      title="老年常青课堂"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hui-study-body-container">
      <div>
        <div class="huodongzhongxin-body-container" style="padding: 10px">
          <div class="detail-image">
            <img :src="activeItem.teacherImage" alt="">
          </div>
          <div class="detail-title">{{activeItem.courseName}}</div>
          <div class="detail-title">授课教师 | {{activeItem.teacherName}}</div>
          <div class="detail-desc" style="text-align: center;" v-for="item in (activeItem.honors.split('#') || [])" :key="item">
            {{item}}
          </div>
          <div class="detail-desc">
            {{activeItem.introduction}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import huiApi from 'api/education/huiStudy';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      selected: '活动',
      barList: [],
      activeItem: {},
    };
  },
  computed: {
    studyItemSelect: {
      get() {
        return this.$store.state.study.studyItemSelect;
      },
      set(data) {
        this.$store.commit('study/setStudyItemSelect', data)
      }
    }
  },
  mounted() {
    const { key } = this.$route.query;
    this.getDetail(key);
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleTabSelect(name, title) {
      this.$store.commit('study/setStudyItemSelect', name);
    },
    showToast(message) {
      this.$toast(message);
    },
    getDetail(id) {
      huiApi.getTeacherDetail({ id }).then(res => {
        if(res.flag) {
          this.activeItem = res.data;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.hui-study-container {
  height: 100vh;
  width: 100vw;
  .hui-study-body-container{
    padding: 10px;
    .detail-image{
      padding: 10px 60px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .detail-title{
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      line-height: 30px;
    }
    .detail-desc{
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      line-height: 28px;
      font-weight: 400;
    }
  }
}
</style>
