<template>
  <div class="sqxzIndex" style="background: #fff; height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>我的</h1>
    </div>
    <mt-navbar v-model="selected">
      <mt-tab-item :id="item.id" v-for="(item, index) in selectList" :key="index">{{item.name}}</mt-tab-item>
    </mt-navbar>
    <div class="eventYsListDetail">
      <div class="item" v-for="(item, index) in (eventList || []).filter(v => v.type === selected || selected === '全部')" :key="index">
        <div class="image"><img :src="item.image" alt=""></div>
        <div class="text">
          <div>{{item.name}}</div>
          <div>{{item.ff}}</div>
        </div>
        <div class="btn">{{item.xj}}</div>
        <div class="tag">
          原价：
          <span class="num">¥{{item.yj}}</span>
        </div>
      </div>
    </div>
    <div class="btnList">
      <div>我的</div>
      <div>发布闲置</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import service from 'utils/service';
export default {
  data() {
    return {
      selected: '我的发布',
      selectList: [
        { id: '我的发布', name: '我的发布', count: 3},
        { id: '我的置换', name: '我的置换', count: 2},
        // { id: '设备器材', name: '设备器材', count: 2},
        // { id: '休闲娱乐', name: '休闲娱乐', count: 2},
      ],
      eventList: [
        { type: '我的发布', image: require('../../assets/images/sqxz/img1.png'), desc: '急转！新款电动车', name: '以物换物', ff: '我去取', yj: '99', xj: '30元抢购' },
        { type: '我的发布', image: require('../../assets/images/sqxz/img2.png'), desc: '9成新婴儿推车', name: '免费赠送', ff: '我去取', yj: '299', xj: '99元抢购' },
        { type: '我的发布', image: require('../../assets/images/sqxz/img1.png'), desc: '急转！新款电动车', name: '以物换物', ff: '我去取', yj: '99', xj: '30元抢购' },
        { type: '设备器材', image: require('../../assets/images/sqxz/img2.png'), desc: '9成新婴儿推车', name: '免费赠送', ff: '我去取', yj: '299', xj: '99元抢购' },
        { type: '我的置换', image: require('../../assets/images/sqxz/img1.png'), desc: '急转！新款电动车', name: '以物换物', ff: '我去取', yj: '99', xj: '30元抢购' },
        { type: '休闲娱乐', image: require('../../assets/images/sqxz/img2.png'), desc: '9成新婴儿推车', name: '免费赠送', ff: '我去取', yj: '299', xj: '99元抢购' },
        { type: '我的置换', image: require('../../assets/images/sqxz/img1.png'), desc: '急转！新款电动车', name: '以物换物', ff: '我去取', yj: '99', xj: '30元抢购' },
      ],
    };
  },
  components: {},
  mounted(opt) {
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .sqxzIndex{
    height: 100% !important;
    position: relative;
    .mint-tab-item-label{
      font-size: 16px;
      color: rgba(0,0,0,0.65);
      letter-spacing: 0;
      font-weight: 400;
    }
    .addYs{
      position: fixed;
      bottom: 50px;
      right: 10px;
      width: 52px;
      height: 52px;
      line-height: 16px;
      padding: 10px 10px;
      text-align: center;
      background: #3E71F9;
      color: #fff;
      border-radius: 50%;
    }
    .eventYsListDetail{
      padding: 10px;
      padding-bottom: 60px;
      background: #f7f7f7;
      display: flex;
      flex-wrap: wrap;
      .item{
        width: 50%;
        margin-bottom: 10px;
        background: #FFFFFF;
        position: relative;
        .image{
          width: 100%;
          height: 140px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          display: flex;
          line-height: 30px;
          >div{
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            &:last-child{
              margin-left: auto;
              margin-right: 10px;
              text-align: right;
              font-size: 14px;
              color: rgba(0,0,0,0.65);
            }
          }
        }
        .btn{
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 16px;
          color: #FFFFFF;
          background: #3E71F9;
          border-radius: 12px;
          width: 80%;
          margin-left: 10%;
        }
        .tag{
          position: absolute;
          top: 10px;
          left: 0px;
          padding-right: 20px;
          padding-left: 5px;
          line-height: 30px;
          font-size: 14px;
          color: rgba(0,0,0,0.65);
          background: #FFFFFF;
          box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.08);
          border-radius: 0 15px 15px 0;
          .num{
            font-size: 18px;
            color: #FF6C2B;
          }
        }
      }
    }
    .btnList{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px 20px;
      position: fixed;
      left: 0px;
      bottom: 0px;
      background: #fff;
      >div{
        width: 45%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #3E71F9;
        color: #fff;
        font-size: 16px;
        border-radius: 25px;
        &:first-child{
          border: 1px solid rgba(0,0,0,0.08);
          background: #fff;
          color: #000;
        }
      }
    }
  }
</style>
