<template>
  <div class="sourceStore-container">
    <van-nav-bar title="东盛先锋" left-arrow @click-left="onClickLeft" />
    <div class="my-vanguard-body-container">
      <van-tabs v-model="selected">
        <van-tab title="我发布的">
          <div class="card-list-con">
            <div class="activity-need-list-con">
              <div class="item" v-for="(item, i) in dataList" :key="i" @click="gotoLink(`vanguardDetail?id=${item.id}`)">
                <div class="itemDesc">
                  {{ item.issueContent }}
                </div>
                <div class="image-list-con">
                  <img
                    v-for="(item, index) in item.photoVO"
                    :src="item"
                    :key="index"
                    alt=""
                  />
                </div>
                <div class="data-info">
                  <span>{{ item.visitsUp }}</span>人浏览 
                  <span>{{ item.thumbsUp }}</span>个点赞 
                  <span>{{ item.commentUp }}</span>条评论
                </div>
                <div class="state">
                  <div>发布时间：{{ item.createTime }}</div>
                  <van-tag v-if="item.issueStatus === 2" type="on">{{item.issueStatusText}}</van-tag>
                  <van-tag v-else-if="item.issueStatus === 4" type="off">{{item.issueStatusText}}</van-tag>
                  <van-tag v-else-if="item.issueStatus === 3" type="success">{{item.issueStatusText}}</van-tag>
                  <van-tag v-else type="draft">{{item.issueStatusText}}</van-tag>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="我评论的">
          <div class="card-list-con">
            <div class="activity-need-list-con">
              <div class="item" v-for="(item, i) in commentList" :key="i" @click="gotoLink(`vanguardDetail?id=${item.id}`)">
                <div class="itemName">
                  <div class="image">
                    <img :src="item.submitUserAvatar" alt="" />
                  </div>
                  <div class="text">
                    <div class="title">
                      {{ item.submitUserName }}
                      <span v-if="item.submitUserPartyType !== 0" class="tag">党员</span>
                    </div>
                    <div>发布时间：{{ item.createTime }}</div>
                  </div>
                </div>
                <div class="itemDesc">
                  {{ item.issueContent }}
                </div>
                <div class="image-list-con">
                  <img
                    v-for="(item, index) in item.images"
                    :src="item"
                    :key="index"
                    alt=""
                  />
                </div>
                <div class="data-info">
                  <span>{{ item.visitsUp }}</span
                  >人浏览 <span>{{ item.thumbsUp }}</span
                  >个点赞 <span>{{ item.commentUp }}</span
                  >条评论
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <footer>
        <ul>
          <li class="catalog">
            <a @click="gotoReplaceLink('ddvanguard')"
              ><i class="home2"></i>
              <p>首页</p>
            </a>
          </li>
          <li class="selected">
            <a @click="gotoLink('vanguardAdd')"
              ><i></i>
              <p>先锋发布</p>
            </a>
          </li>
          <li class="mine2">
            <a
              ><i></i>
              <p>我的</p>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import api from "api";
import nrbApi from "api/llyjr/nrb";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      status: "",
      option1: [
        { text: "默认排序", value: "" },
        { text: "最新发布", value: "最新发布" },
      ],
      dataList: [],
      commentList: [],
      dataCurrent: 1,
      commentCurrent: 1,
      selected: '',
    };
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === "我的发布" ? "yes" : "no";
    },
  },
  components: {},
  mounted() {
    this.getDataList({});
    this.getCommentList({});
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    gotoReplaceLink(path) {
      this.$router.replace(path);
    },
    getDataList({ page = this.dataCurrent }) {
      api.getMyVanguardList({
        isMyAdd: true,
        orderByType: 0,
        page,
        pageSize: 100,
      }).then(res => {
        this.dataList = res.data.records;
      })
    },
    getCommentList({ page = this.commentCurrent }) {
      api.getMyVanguardList({
        isMyComment: true,
        // isMyAdd: false,
        orderByType: 0,
        page,
        pageSize: 100,
      }).then(res => {
        this.commentList = res.data.records;
      })
    }
  },
};
</script>

<style lang="scss">
.sourceStore-container {
  height: 100vh;
  width: 100vw;

  .my-vanguard-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .van-tabs__line {
      background-color: #4479ed;
    }
    .card-list-con {
      height: calc(100% - 48px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 2rem;
    }
    .activity-need-list-con {
      width: 100%;
      margin: 0 auto;
      border: none;
      padding: 10px;
      .item {
        position: relative;
        padding: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        border-radius: 4px;
        .itemName {
          display: flex;
          height: 50px;
          padding: 5px;
          .image {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            > img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text {
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            .title {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 500;
              .tag {
                background: #e3effd;
                border-radius: 2px;
                font-size: 12px;
                color: #4479ed;
                padding: 3px 6px;
              }
            }
          }
        }
        .itemDesc {
          width: 100%;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          //-webkit-line-clamp: 2;
          //line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .image-list-con {
          width: 100%;
          // padding: 10px;
          display: flex;
          flex-wrap: wrap;
          > img {
            width: 56px;
            height: 56px;
            border-radius: 4px;
            margin: 5px;
          }
        }
        .data-info {
          width: 100%;
          margin: 10px 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          span {
            color: #4479ed;
            margin-left: 1rem;
            &:first-child {
              margin-left: 0;
            }
          }
        }
        .state {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.45);
          border-top: 1px solid rgba(17, 31, 44, 0.1);
          .van-tag {
            background: #d5f4e2;
            color: #13bb49;
            font-size: 0.4rem;
            padding: 0 0.5rem;
            height: 20px;
            line-height: 20px;
            border-radius: 2px 6px 2px 6px;
            margin-top: 10px;
          }
          .van-tag--on {
            background: #e3effd;
            color: #4479ed;
          }
          .van-tag--off {
            background: #ffeeec;
            color: #ff4535;
          }
          .van-tag--draft {
            background: #fff6d9;
            color: #e5b20a;
          }
        }
      }
    }
    footer {
      height: 3.2rem;
      border-top: 2px solid #f1f2f5;
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding-top: 0.4rem;
      ul {
        display: flex;
        justify-content: space-around;
        li {
          flex: 1;
          text-align: center;
          i {
            display: inline-block;
            width: 1.3rem;
            height: 1.3rem;
          }
          p {
            font: 500 0.6rem/1rem "microsoft yahei";
            color: #a3b0d0;
          }
        }
        .home {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/home.png) no-repeat;
          background-size: 100% 100%;
        }
        .home2 {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/home2.png) no-repeat;
          background-size: 100% 100%;
        }
        .mine i {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/mine.png) no-repeat;
          background-size: 100% 100%;
        }
        .mine2 i {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/mine_select.png) no-repeat;
          background-size: 100% 100%;
        }
        .mine2 p {
          color: #2a70ed;
        }
        .selected i {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/vang-add.png) no-repeat;
          background-size: 4.5rem 4.5rem;
          margin-top: -2.5rem;
          margin-bottom: -0.7rem;
          width: 4.5rem;
          height: 4.5rem;
        }
        .selected p {
          color: #2a70ed;
        }
      }
    }
  }
}
</style>
