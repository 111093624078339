<template>
  <div class="own-index">
    <div class="page-container" style="padding-bottom: 46px">
      <div class="header-nav">
        <i v-if="!isToMain" @click="goBack"></i>
        <div class="isToMain" style="color: #08f" v-if="isToMain" @click="goMain">主页</div>
        <h1>活动详情</h1>
      </div>
      <div class="park-main">
        <div>
          <img style="width: 100%;" :src="dataInfo.bannerImg" />
        </div>
        <div class="banner-deatil-title">
          
          <div class="deatil-title-name">
            <span
              :style="{background: colors[dataInfo.status] ? colors[dataInfo.status].background : '#E3EFFD',color: colors[dataInfo.status] ? colors[dataInfo.status].text : '#4479ED'}"
              class="tag"
            >
              {{dataInfo.activityStatus}}
            </span>
            <span style="width: 100%;">{{ dataInfo.title }}</span>
          </div>
          <div class="deatil-title-flex">
            <div style='padding:0px !important;'>
              <span class="name111">发起人：</span>
              <span class="value111">{{ dataInfo.createUserName }}</span>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;">
              <img src="~assets/images/detail_phone.png" width="16" height="16" alt="">
              <span style="color: #4379ed;font-size: 14px;margin-left: 5px;" @click="callPhone(dataInfo.phone)">拨打联系</span>
            </div>
          </div>
          
        </div>
        <!-- 报名情况 -->
        <!-- <div class="banner-detail-people">
          <div class="index-tab tab">
            <b>报名情况</b>
            <span>
              已报名人数
              <span style="color:#4479ED; margin-left: 3px">{{dataInfo.number}}</span>人
            </span>
          </div>
          <div class="content">
            <div v-for="item in gameEnroll" :key="item.key">
              <img v-if="item.enrollUrl" :src="item.enrollUrl" />
              <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" />
              <div class="name">{{item.enrollName}}</div>
            </div>

          
          </div>
        </div> -->
        <div class="daryLine"></div>
        <div style="padding: 10px 5px;">
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_time.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px;">活动时间：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ dataInfo.activityTime }}</span>
          </div>
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_deadline.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px;">报名截止：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ dataInfo.deadlineTime }}</span>
          </div>
          <!-- <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_link.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px;">报名人数：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ dataInfo.number }}</span>
          </div> -->
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_location.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">活动地点：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ dataInfo.address }}</span>
          </div>
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_location.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">报名人数：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">
              <span style="color: #08f">{{ dataInfo.number || 0 }}</span>
              /{{ dataInfo.limitPeopleNum || 0 }}
            </span>
          </div>
          <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_location.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">活动积分：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ dataInfo.integral || 0 }}积分</span>
          </div>
          <!-- <div style="width: 100%;display: flex;align-items: flex-start;flex-wrap: wrap;font-size: 16px;line-height: 32px;" class="deatil-title-flex">
            <img src="~assets/images/detail_jifen.png" width="24" height="24" style="margin-right: 5px;margin-top: 5px;" alt="">
            <span style="font-weight: 500;font-color: rgba(0,0,0,0.85);width: 88px !important;">活动积分：</span>
            <span style="word-break: break-all;width: calc(100% - 117px);">{{ dataInfo.integral || 0 }}</span>
          </div> -->
        </div>
        <div class="daryLine"></div>
        <van-tabs  color="#4479ED">
          <van-tab v-if="dataInfo.activityStatus === '已结束'" title="活动总结" title-style="font-size: 16px;">
            <div v-if="dataInfo.summary" style="text-indent: 0px !important;" class="banner-detail-content" v-html="dataInfo.summary"></div>
            <van-empty v-if="!dataInfo.summary" description="暂未发布活动总结，请稍等" />
          </van-tab>
          <van-tab title="活动详情" title-style="font-size: 16px;">
            <div style="text-indent: 0px !important;" class="banner-detail-content" v-html="dataInfo.content"></div>
          </van-tab>
          <!-- <van-tab :title="`全部反馈(${dataInfo.gameEvaluates ? dataInfo.gameEvaluates.length : 0})`" title-style="font-size: 16px;">
            <ul class="list-img">
              <li v-for="item in dataInfo.gameEvaluates || []" :key="item.key">
                <div class="img-radius">
                  <img :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" />
                </div>
                <div class="desc">
                  <div class="title" v-html="item.own"></div>
                  <div class="time">{{ item.createTime }}</div>
                  <div class="info">{{ item.content }}</div>
                </div>
              </li>
            </ul>
          </van-tab> -->
        </van-tabs>
        <!-- <div class="banner-detail-content">
          <div class="all-nav" style="margin:0;padding:0">
            <div class="index-tab tab">
              <b style="font-size: 16px;color: rgba(0,0,0,0.85);">具体内容</b>
            </div>
          </div>
          <div ref="long" class="content" v-html="dataInfo.content">
          </div>
        </div> -->
      </div>
      <div
        v-for="(item, index) in getButton(dataInfo)"
        :key="index"
        class="footer-button"
      >
        <van-button round :color="item.color" :disabled="item.disabeld" @click="item.action">{{ item.name }}</van-button>
      </div>
    </div>
    <van-dialog
      v-model="showDialog"
      title="请确认报名信息"
      show-cancel-button
      @cancel="showDialog = false"
      :before-close="addOrderEnroll"
    >
      <van-cell-group style="margin: 0.5rem 0;">
        <div class="radio-bar">
          <span
            :class="['radio', { selected: person === 'relation' }]"
            @click="handleRadio('relation')"
          >
            给亲友报名
          </span>
          <span
            :class="['radio', { selected: person === 'self' }]"
            @click="handleRadio('self')"
          >
            为自己报名
          </span>
        </div>  
        <van-field
          v-model="name"
          required
          label="报名人姓名"
          placeholder="请输入报名人姓名"
          maxlength="10"
        />
        <div style="color: red;font-size: 14px;margin: 10px 16px;" v-if="showNameWarn">请输入报名人姓名</div>
        <van-field
          v-model="phone"
          required
          label="联系手机"
          placeholder="请输入联系手机"
          type="tel"
        />
        <div style="color: red;font-size: 14px;margin: 10px 16px;" v-if="showPhoneWarn">请输入联系手机</div>
        <van-field
          v-model="remark"
          label="备注"
          placeholder="备注"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import wyslApi from "api/llyjr/wysl";
import { sharePage } from 'utils';
import { Toast, Dialog } from "vant";

export default {
  data() {
    return {
      remark: '',
      isExpand: false,
      gameEnroll: [],
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      dataInfo: {},
      showDialog: false,
      name: '',
      phone: '',
      person: 'self', // 为谁报名
      showNameWarn: false,
      showPhoneWarn: false,
      activityId: '',
    };
  },
  components: {},
  computed: {
  },
  mounted() {
    console.log(this.dataInfo);
    const { key, isToMain } = this.$route.query;
    this.isToMain = isToMain;
    this.activityId = key;
    const userInfo = (this.getSessionData('userInfo')) || {};
    const { userName: name = '', phone = '' } = userInfo;
    this.name = name;
    this.phone = phone;
    
    // this.getGameEnroll();
    this.getActivityInfo({}, (detail) => {
      sharePage(key, 'huodongzhongxinDetail', detail);
    });
    this.$nextTick(() => {
      const { offsetHeight } = this.$refs["long"];
      if (offsetHeight > 86) {
        this.isExpand = true;
      }
    });
  },
  methods: {
    addOrderEnroll(action, done) {
      const params = this.dataInfo;
      const { name, phone, remark } = this;
      console.log('phone', phone, name)
      if(action === 'confirm') {
        if(!name) { 
          this.showNameWarn = true;
          done(false);
        } else if(!phone) {
          this.showPhoneWarn = true;
          done(false);
        } else if (params.enrollStatus === '已取消') {
          // 重新报名
          wyslApi.repeatEnroll({ activityId: this.activityId, createUserName: name, phone: phone, remark }).then(res => {
            if (res.data == 1) {
              Toast.success("重新报名成功");
              done();
              this.updateQuestionInfo("repeatEnroll");
            }
          });
        } else {
          // 我要报名
          wyslApi.addEnroll({ activityId: this.activityId, createUserName: name, phone: phone, remark }).then(res => {
            if (res.data == 1) {
              Toast.success("报名成功");
              done();
              this.updateQuestionInfo("addEnroll");
            }
          });
        }
      } else {
        done();
      }
    },
    callPhone(number) {
      window.location.href = `tel://${number}`;
    },
    getActivityInfo(params, callback = () => {}) {
      wyslApi.getActivityInfo({ activityId: this.activityId }).then(res => {
        console.log('aa', res.data)
        this.dataInfo = res.data;
        callback(res.data || {});
      })
    },
    getGameEnroll() {
      wyslApi
        .getGameEnroll({ activityId: this.activityId })
        .then(res => {
          this.gameEnroll = res.data;
          this.dataInfo.number = res.data.length;
        });
    },
    handleRadio(value) {
      this.person = value;
      this.showNameWarn = false;
      this.showPhoneWarn = false;
      if(value === 'self') {
        const userInfo = (this.getSessionData('userInfo')) || {};
        const { userName: name = '', phone = '' } = userInfo;
        this.name = name;
        this.phone = phone;
      } else {
        this.name = '';
        this.phone = '';
      }
    },
    // ...mapMutations(["setQuestionInfo"]),
    updateQuestionInfo(flag) {
      this.getGameEnroll();
      // this["setQuestionInfo"]({
      //   ...this.questionInfo,
      //   enrollStatus: flag === "cancelEnroll" ? "已取消" : "已报名"
      // });
      this.getActivityInfo();
    },
    handleAddEnroll(item) {
      const { enrollStatus } = item;
      // this.$store.dispatch('login/checkRealName', () => {
        if (enrollStatus === "已报名") {
          wyslApi.cancelEnroll({ activityId: item.activityId }).then(res => {
            if (res.data == 1) {
              Toast.success("取消报名成功");
              this.updateQuestionInfo("cancelEnroll");
            }
          });
        }
        if (enrollStatus === "已取消") {
          wyslApi.repeatEnroll({ activityId: item.activityId }).then(res => {
            if (res.data == 1) {
              Toast.success("重新报名成功");
              this.updateQuestionInfo("repeatEnroll");
            }
          });
        }
        if (enrollStatus === "未报名") {
          // 我要报名
          wyslApi.addEnroll({ activityId: item.activityId }).then(res => {
            if (res.data == 1) {
              Toast.success("报名成功");
              this.updateQuestionInfo("addEnroll");
            }
          });
        }
      // })
    },
    handleEnroll() {
      // this.$store.dispatch('login/checkRealName', () => {
        this.showDialog = true
      // })
    },
    // addOrderEnroll() {
    //   const params = this.dataInfo;
    //   const { name, phone } = this;
    //   if(!name) {
    //     Toast('请输入报名人姓名');
    //     return;
    //   } else if(!phone) {
    //     Toast('请输入联系手机');
    //     return;
    //   } else {
    //     if (params.enrollStatus === '已取消') {
    //       // 重新报名
    //       wyslApi.repeatEnroll({ activityId: this.activityId, createUserName: name, phone: phone }).then(res => {
    //         if (res.data == 1) {
    //           Toast.success("重新报名成功");
    //           this.updateQuestionInfo("repeatEnroll");
    //         }
    //       });
    //     } else {
    //       // 我要报名
    //       wyslApi.addEnroll({ activityId: this.activityId, createUserName: name, phone: phone }).then(res => {
    //         if (res.data == 1) {
    //           Toast.success("报名成功");
    //           this.updateQuestionInfo("addEnroll");
    //         }
    //       });
    //     }
    //   }
    // },
    deleteOrder() {
      const params = this.dataInfo;
      const { name, phone } = this;
      Dialog.confirm({
        title: '提示',
        message: '确定是否取消报名？',
        'confirm-button-text': '取消报名',
        'confirm-button-color': '#2e70ed',
        'cancel-button-color': '#eeeeee'
      })
      .then(() => {
        wyslApi.cancelEnroll({ activityId: this.activityId, createUserName: name, phone: phone }).then(res => {
            if (res.data == 1) {
              Toast.success("取消报名成功");
              this.updateQuestionInfo("cancelEnroll");
            }
          });
      })
      .catch(() => {

      })
    },
    handleExpand() {
      this.isExpand = false;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      if (this.isToMain) {
        this.$router.replace('/');
      } else {
        this.$router.go(-1);
      }
    },
    goMain(){
      this.$router.replace('/');
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getButton(questionInfo) {
      let list = [];
      const obj = {
        ksbm: [{ name: '我要报名', color: '#1989fa', disabled: false, action: this.handleEnroll}],
        qxbm: [{ name: '取消报名', color: '#1989fa', disabled: false, action: this.deleteOrder}],
        cxbm: [{ name: '重新报名', color: '#1989fa', disabled: false, action: this.handleEnroll}],
        bmjz: [{ name: '报名已截止', color: '#ccc', disabled: true}],
        hdjs: [{ name: '活动已结束', color: '#ccc', disabled: true}]
      }
      if(questionInfo.activityStatus === '报名中' && questionInfo.permitEnroll !== '已截止报名') {
        switch (questionInfo.enrollStatus) {
          case '未报名': list = obj.ksbm;break;
          case '已报名': list = obj.qxbm;break;
          case '已取消': list = obj.cxbm;break;
        }
      } else if(questionInfo.activityStatus === '进行中' && questionInfo.permitEnroll !== '已截止报名') {
        switch (questionInfo.enrollStatus) {
          case '未报名': list = obj.ksbm;break;
          case '已报名': list = obj.qxbm;break;
          case '已取消': list = obj.cxbm;break;
        }
      } else if(questionInfo.activityStatus === '已截止' || questionInfo.permitEnroll === '已截止报名') {
        switch (questionInfo.enrollStatus) {
          case '未报名': list = obj.bmjz;break;
          case '已报名': list = obj.qxbm;break;
          case '已取消': list = obj.bmjz;break;
        }
      } else {
        list = obj.hdjs;
      }
      return list;
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-bar {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  .radio {
    height: 2rem;
    width: 45%;
    text-align: center;
    border-radius: 4px;
    // background: #4479ED;
    color: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(0, 0, 0, 0.35);
    padding: 0.1rem 1.2rem;
  }
  .selected {
    background: #4479ed;
    color: #ffffff;
    border: 1px solid #4479ed;
  }

}
.page-container {
  // padding: 0.8rem 0rem;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  
  .park-main {
    padding-top: 3.2rem;
    .banner {
      img {
        width: 100vw;
      }
    }
    .index-tab {
      height: 1.4rem;
      margin-bottom: 0.4rem;
      padding-right: 0.6rem;
      b {
        float: left;
        //padding-left: 2.4rem;
        //color: #0fade7;
        img {
          height: 1rem;
          vertical-align: middle;
        }
      }
      span {
        float: right;
        color: #a1a1a1;
        font-size: 0.8rem;
        //font-family: simsun;
      }
    }
    .banner-deatil-title {
      padding: 0.8rem;
      position: relative;
      .tag {
        padding: 0 0.5rem;
        border-radius: 4px;
        font-size: 12px;
        margin-right: 5px;
        height: 24px;
        line-height: 24px;
        display: inline-block;
      }
      .deatil-title-name {
        font-size: 18px;
        color: rgba(0,0,0,0.85);
        word-break: break-all;
        text-align: left;
        line-height: 2rem;
        font-weight: bold;
        margin-bottom: 10px;
        .tag {
          padding: 0 0.5rem;
          border-radius: 4px;
          font-size: 12px;
          margin-right: 5px;
          height: 24px;
          line-height: 24px;
          display: inline-block;
        }
      }
      .deatil-title-flex {
        display: flex;
        > div {
          width: 50%;
          // padding: 0.5rem 0;
        }
        .name111 {
          opacity: 0.45;
          font-size: 14px;
          color: #000000;
          text-align: left;
          line-height: 1.5rem;
        }
        .value111 {
          opacity: 0.85;
          font-size: 14px;
          color: #000000;
          text-align: left;
          line-height: 1.5rem;
        }
      }
    }
    .banner-detail-people {
      padding: 0.8rem;
      .content {
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 16.6%;
          height: 3.5rem;
          padding: 0.5rem;
          margin-bottom: 1.2rem;
          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
          .name {
            text-align: center;
            margin: 0.3rem 0;
          }
        }
      }
    }
    .daryLine {
      height: 0.8rem;
      width: 100%;
      background-color: #f5f5f5;
      margin-left: -0.8rem;
    }

    .banner-detail-content {
      padding: 0.8rem;
      text-indent: 0rem !important;
      .tab {
        height: 1.2rem;
        font: 500 0.9rem/1.2rem "microsoft yahei";
        text-align: left;
        margin: 0.8rem 1rem 0.4rem 0.2rem;
        border-left: 0.4rem solid #4479ed;
        padding-left: 0.8rem;
      }
      .content {
        // opacity: 0.65;
        font-size: 0.8rem;
        color: rgba(0,0,0,0.65);
        text-align: left;
        line-height: 1.6rem;
        padding-top: 0.5rem;
        // height: 5.6rem;
        position: relative;
        .expand-btn {
          color: #4479ed;
          position: absolute;
          bottom: 5px;
          right: 0;
          width: 40px;
          text-align: right;
          background: #fff;
        }
      }
      .long {
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
        height: 5.6rem;
        overflow: hidden;
      }
    }
  }
  .footer-button {
    position: fixed;
    bottom: 1rem;
    right: 0;
    left: 0.8rem;
    width: calc(100vw - 1.6rem);
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
    .van-button {
      height: 100%;
      width: 100%;
    }
  }
}
    .isToMain{
      position: absolute;
      left: 10px;
      top: 15px;
    }
</style>
