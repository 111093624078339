<template>
  <div class="payMoneyPage" style="height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>取消订单</h1>
    </div>
    <div class="cancelOrder">
      <img src="../../assets/images/axst/qxdd.svg" alt="">
      取消订单成功
    </div>
    <div class="payList">
      <div v-for="(item, i) in (payList ? payList.filter((v, i) => showCount || i <4) : []).filter(v => v.count)" class="item" :key="i">
        <div class="image">
          <img :src="item.image" alt="">
        </div>
        <div class="text">
          <div class="name">{{item.name}}</div>
          <div class="btnGroup">
            <div class="count">✖️{{item.count}}</div>
            <div class="momeny">¥{{item.momeny || 0}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payList.length > 4" class="showMore" @click="showCount = !showCount">{{ showCount ? '收起' : `展开(共${payList.length || 0}条)`}}</div>
    <div class="countMoneny">共计：¥{{countMoney || 0}}</div>
    <div class="order-detail">
      <div class="item">
        <div class="name">时间：</div>
        <div class="value">2021-12-20 10:12:32</div>
      </div>
      <div class="item">
        <div class="name">餐别：</div>
        <div class="value">{{type}}</div>
      </div>
      <div class="item">
        <div class="name">应付金额：</div>
        <div class="value">{{countMoney}}</div>
      </div>
      <div class="item">
        <div class="name">优惠金额：</div>
        <div class="value">0</div>
      </div>
      <div class="item">
        <div class="name">实付金额：</div>
        <div class="value">{{countMoney}}</div>
      </div>
      <div class="item">
        <div class="name">地址：</div>
        <div class="value">幸福家园20幢1505</div>
      </div>
      <div class="item">
        <div class="name">电话：</div>
        <div class="value">姚先生   18888888888</div>
      </div>
      <div class="item">
        <div class="name">送餐时间段：</div>
        <div class="value">10:40—12:20</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import lodash from 'lodash';
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      type: '',
      payList: [],
      count: 0,
      countMoney: 0,
      showCount: false,
    };
  },
  components: {},
  mounted(opt) {
    this.type = this.$route.query;
    this.payList = this.$store.state.allOther.buyList;
      this.count = 0;
      this.countMoney = 0;
      this.payList.forEach(element => {
        this.count += element.count;
        if (element.count) {
          this.countMoney += element.momeny * element.count;
        }
      });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    ydCancel(){
      this.$router.push(`/cancelOrder`)
      // Toast.success("预定成功！");
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .payMoneyPage{
    height: 100% !important;
    position: relative;
    .cancelOrder{
      display: flex;
      line-height: 50px;
      height: 50px;
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      padding-left: 30%;
      img{
        width: 36px;
        height: 36px;
        margin: 7px;
      }
    }
    .payList{
      margin-top: 10px;
      padding: 10px;
      background: #fff;
      .item{
        margin-bottom: 10px;
        display: flex;
        height: 50px;
        line-height: 50px;
        .image{
          width: 40px;
          height: 40px;
          margin: 5px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          display: flex;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // border-bottom: 1px solid rgba(0,0,0,0.1);
          .name{
            font-size: 16px;
            color: #000000;
            font-weight: 500;
          }
          .btnGroup{
            display: flex;
            margin-left: auto;
            margin-right: 10px;
            .count{
              font-size: 16px;
              margin-right: 10px;
            }
            .momeny{
              font-size: 16px;
              width: 50px;
              text-align: right;
            }
            >img{
              width: 16px;
              height: 16px;
              margin: 17px 5px;
            }
          }
        }
      }
    }
    .showMore{
      background: #fff;
      border-top: 1px solid rgba(0,0,0,0.1);
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
    .countMoneny{
      background: #fff;
      border-top: 1px solid rgba(0,0,0,0.1);
      height: 40px;
      line-height: 40px;
      padding-right: 10px;
      text-align: right;
    }
    .order-detail{
      background: #fff;
      padding: 10px;
      margin-top: 10px;
      .item{
        display: flex;
        line-height: 30px;
        .name{
          font-size: 15px;
          color: #000000;
          font-weight: 400;
        }
        .value{
          font-size: 15px;
          color: rgba(0,0,0,0.45);
          font-weight: 400;
        }
      }
    }
    .btnList2{
      margin-top: 10px;
      display: flex;
      >div{
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        line-height: 50px;
        text-align: center;
        background: #fff;
        color: #3E71F9;
        font-size: 16px;
        &:last-child{
          background: #ecf1ff;
        }
      }
    }
  }
</style>
