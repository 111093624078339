<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>托管预约</h1>
        <h5 @click="gotoLink('tgyy')">我的托管</h5>
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="发起人"
            placeholder="请填写"
            :rules="[{ required: true, message: '请填写发起人姓名' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            class="input-bar"
          />
           <van-field
            size="large"
            v-model="deposit"
            name="deposit"
            is-link
            readonly
            label="托管对象"
            placeholder="请选择托管对象"
            @click="showDeposit = true"
            :rules="[{ required: true, message: '请选择托管对象' }]"
            class="input-bar"
          />
          
          <van-field
            size="large"
            v-model="child"
            name="despointName"
            label="托管对象姓名"
            placeholder="请输入"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="depositType"
            name="depositType"
            is-link
            readonly
            @click="showType = true"
            label="托管类型"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择托管类型' }]"
            class="input-bar"
          />
          <van-field
            v-model="despointAge"
            type="digit"
            size="large"
            name="despointAge"
            label="托管对象年龄"
            placeholder="请输入"
            class="input-bar"
          />
          <van-field
            v-if="depositType === '学期托管'"
            size="large"
            v-model="term"
            name="term"
            is-link
            readonly
            label="托管学期"
            placeholder="请选择托管学期"
            @click="showTerm = true"
            :rules="[{ required: true, message: '请选择托管学期' }]"
            class="input-bar"
          />
          <div class="section" style="padding: 0.8rem;">
            <div class="title">托管备注</div>
            <van-field
              size="large"
              v-model="description"
              rows="3"
              autosize
              name="comments"
              type="textarea"
              maxlength="200"
              placeholder="请输入孩子注意事项"
              show-word-limit 
            />
          </div>
          <div class="footer-button">
            <van-button 
              round
              block
              type="info"
              style="font-size: 16px;"
              native-type="submit"
            >
              提交
            </van-button>
          </div>
        </van-form>
      </div>
      <van-popup v-model="showStart" position="bottom">
        <van-datetime-picker 
          v-model="start"
          type="time"
          title="选择开始时间"
          @cancel="onCancel('showStart')"
          @confirm="onStartConfirm"
          :formatter="formatter" 
        />
      </van-popup>
      <van-popup v-model="showEnd" position="bottom">
        <van-datetime-picker 
          v-model="end"
          type="time"
          :min-hour="minEndHour"
          :min-minute="minEndMin"
          title="选择结束时间"
          @cancel="onCancel('showEnd')"
          @confirm="onEndConfirm"
          :formatter="formatter"
        />
      </van-popup>
      <van-popup v-model="showDeposit" position="bottom">
        <van-picker
          title="托管对象"
          value-key="termName"
          show-toolbar
          :columns="depositList"
          @confirm="onDepositConfirm"
          @cancel="onCancel('showDeposit')"
        />
      </van-popup>
      <van-popup v-model="showType" position="bottom">
        <van-picker
          title="托管类型"
          show-toolbar
          :columns="typeList"
          @confirm="onTypeConfirm"
          @cancel="onCancel('showType')"
        />
      </van-popup>
      <van-popup v-model="showTerm" position="bottom">
        <van-picker
          title="托管学期"
          value-key="termName"
          show-toolbar
          :columns="termList"
          @confirm="onTermConfirm"
          @cancel="onCancel('showTerm')"
        />
      </van-popup>
    </div>

  </div>
</template>

<script>
import sdbxtApi from 'api/sdbxt/sdbxt';
var moment = require("moment")
import { Toast } from 'vant';
export default {
  data () {
    return {
      name: '',
      phone: '',
      child: '',
      description: '',
      start: '',
      end: '',
      deposit: '',
      depositType: '',
      depositAge: '',
      term: '',
      termId: '',
      showStart: false,
      showEnd: false,
      minEndHour: '',
      minEndMin: '',
      showDeposit: false,
      showTerm: false,
      showType: false,
      typeList: ['学期托管', '临时托管'],
      depositList: [],
      termList: [],
      despointAge: 0,
    };
  },
  components: {},
  mounted () {
    sdbxtApi.getTerm({}).then(res => {
      if (res.flag) {
        this.termList = res.data.term || [];
        this.depositList = res.data.person || [];
      }
    })
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onSubmit (values) {
      // let dayTime = moment(new Date()).format("YYYY-MM-DD")
      // values.beginTime = dayTime + ' '+ values.beginTime + ':00'
      // values.endTime = dayTime + ' ' + values.endTime + ':00'
      values.termId = this.termId;
      console.log('submit', values);
      let stringVal = JSON.stringify(values)
      console.log(typeof stringVal)
      sdbxtApi.addPreview(stringVal).then((res) => {
        if (res.flag) {
          this.$messagebox("提示", "预约成功");
          this.$router.push('/sdbxt/tgyy');
          // this.$router.go(-1);
        }
      })

      
    },
    onDepositConfirm(value, index) {
      this.deposit = value.termName;
      this.showDeposit = false;
    },
    onTermConfirm(value, index) {
      this.term = value.termName;
      this.termId = value.id;
      this.showTerm = false;
    },
    onTypeConfirm(value, index) {
      this.depositType = value;
      this.showType = false;
    },
    onCancel (type) {
      this[type] = false;
    },
    onStartConfirm (value, index) {
      this.start = value;
      this.minEndHour = Number(this.start.substring(0, 2));
      this.minEndMin = Number(this.start.substring(3));
      this.end = '';
      this.showStart = false;
    },
    onEndConfirm (value, index) {
      this.end = value;
      this.showEnd = false;
    },
    handleEnd () {
      if (!this.start) {
        Toast('请先选择开始时间')
      } else {
        this.showEnd = true;
      }
    },
    formatter (type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      return val;
    },
    formatDateTime (date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? ('0' + minute) : minute;
      return y + '-' + m + '-' + d;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .form-bar {
    height: calc(100% - 60px);
    overflow-y: auto;
    .left-align {
      text-align: left;
    }
    .cell-title {
      min-width: 100px !important;
      color: #646566;
    }
    .van-cell__value {
      min-width: 6.2em !important;
    }
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
