<template>
  <div class="both-location-report-container">
    <van-nav-bar
      title="两地报道"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="blr-body-container">
      <van-tabs color="#4479ED">
        <van-tab title="报道地活动" name="activity">
          <locationReportList />
        </van-tab>
        <van-tab title="其他地活动" name="detail">
          <locationReportList />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import locationReportList from './locationReportList';
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: { locationReportList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.both-location-report-container {
  height: 100vh;
  width: 100vw;
  
  .blr-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
