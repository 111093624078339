<template>
  <div class="own-index" style="background-color:#f5f5f5">
    <div class="info-content">
      <div class="user-baseInfo">
        <div class="image">
          <img :src="baseInfo.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" alt="">
        </div>
        <div class="text">
          <div class="title">{{baseInfo.realName || userInfo.username || userInfo.nickname || '暂无姓名'}}</div>
          <div class="address">{{baseInfo.address || ''}}</div>
        </div>
      </div>
      <div class="share-content">
        <div class="my-share">
          <div class="share-text">
            <div class="share-title">{{inviterCount}}</div>
            <div class="share-desc">我的邀请(人)</div>
          </div>
          <div class="share-image">
            <div class="share-btn" @click="gotoLink(`/myInvite`)">去邀请</div>
          </div>
        </div>
      </div>
      <div class="list-content">
        <div class="list-item" style="margin-bottom: 10px; border-width: 0px" @click="gotoLink('/editUserInfo')">
          <svg-icon icon-class="grxx" />
          <span>个人信息</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div>
        <div class="list-item" @click="gotoLink('yqpddPD')">
          <svg-icon icon-class="wdpd" />
          <span>我的拼单</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div>
        <div class="list-item" @click="gotoLink('fxgj')">
          <svg-icon icon-class="wdgj" />
          <span>我的工具</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div>
        <div class="list-item" @click="gotoLink('repair')">
          <svg-icon icon-class="yjfk" />
          <span>物业报修</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div>
        <!-- <div class="list-item" style="margin-bottom: 10px; border-width: 0px">
          <svg-icon icon-class="wdhd" />
          <span>我的活动</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div>
        <div class="list-item">
          <svg-icon icon-class="yjfk" />
          <span>意见反馈</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div> -->
        <!-- <div class="list-item" style="margin-bottom: 10px; border-width: 0px">
          <svg-icon icon-class="set" />
          <span>设置</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div> -->
      </div>
    </div>
    <footer>
      <ul>
        <li class="catalog">
          <a @click="gotoLink('catalog')"><i></i>
            <p>场景</p>
          </a>
        </li>
        <li class="home">
          <a @click="gotoLink('indexCopy')"><i></i>
            <p>长乐东盛</p>
          </a>
        </li>
        <li class="mine2">
          <a ><i></i>
            <p>我的</p>
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapState } from 'vuex';
import context from '../main';
export default {
  data() {
    return {
      inviterCount: 0,
      baseInfo: {},
    };
  },
  components: {},
  mounted() {
    console.log(this.userInfo)
    console.log('this.userInfo.userId',this.userInfo.userId)
    this.getList(this.userInfo.userId);
    homeApi.getUserInfo({}).then((res) => {
      const { data } = res;
      this.baseInfo = data || {};
    });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getList(inviterId) {
      homeApi.listInviter({ inviterId }).then((res) => {
        const { data = {} } = res;
        this.inviterCount = data.inviterCount || 0;
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => (state.login.userInfo.nickname ? state.login.userInfo : null) || context.getSessionData('userInfo')
    })
  }
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.own-index{
  height: 100vh;
}
.info-content{
  height: calc(100% - 3.2rem);
  background: url("http://dssq.ikeqiao.net/data/image/h5/images/myInvite/background.png") no-repeat;
  background-size: 100% 100%;
  overflow: scroll;
  // padding: 10px;
  padding-bottom: 30px;
  .user-baseInfo{
    display: flex;
    margin: 20px;
    margin-top: 30px;
    .image{
      width: 60px;
      height: 60px;
      margin-right: 10px;
      border-radius: 50%;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .text{
      .title{
        line-height: 36px;
        font-size: 20px;
        color: rgba(0,0,0,0.85);
        font-weight: 600;
      }
      .address{
        line-height: 24px;
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
  .share-content{
    padding: 0 20px;
    .my-share{
      margin-top: 10px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/myInvite/shareBg.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 8px;
      padding: 12px 20px;
      width: 100%;
      display: flex;
      .share-title{
        line-height: 24px;
        font-weight: 600;
        font-size: 20px;
        color: #fff;
      }
      .share-desc{
        line-height: 20px;
        color: #fff;
        font-size: 12px;
      }
      .share-image{
        margin-left: auto;
      }
      .share-btn{
        margin-top: 5px;
        width: 80px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        border: 1px solid #fff;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 400;
        text-align: center;
      }
    }
  }
  .list-content{
    margin-top: 20px;
    .list-item{
      background: #fff;
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 0 10px;
      display: flex;
      font-size: 16px;
      color: #383B40;
      letter-spacing: 0;
      font-weight: 400;
      >img{
         width: 20px;
         height:20px;
         margin-top: 20px;
         margin-right: 10px;
      }
      >svg{
        width: 30px;
        height:30px;
        margin-top: 15px;
        margin-right: 10px;
      }
    }
  }
}
footer {
    height: 3.2rem;
    border-top: 2px solid #f1f2f5;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0.4rem;
    ul {
      display: flex;
      justify-content: space-around;
      li {
        flex: 1;
        text-align: center;
        i {
          display: inline-block;
          width: 1.3rem;
          height: 1.3rem;
        }
        p {
          font: 500 0.6rem/1rem "microsoft yahei";
          color: #a3b0d0;
        }
      }
      .home i {
        background: url(http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main.png) no-repeat;
        background-size: 3.5rem 3.5rem;
        margin-top: -2.2rem;
        margin-bottom: 0rem;
        width: 3.5rem;
        height: 3.5rem;
      }
      .mine i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/mine.png) no-repeat;
        background-size: 100% 100%;
      }
      .mine2 i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/mine_select.png) no-repeat;
        background-size: 100% 100%;
      }
      mine2 p {
        color: #e2e5e9;
      }
      .selected i {
        background: url(http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main_select.png) no-repeat;
        background-size: 3.5rem 3.5rem;
        margin-top: -2.2rem;
        margin-bottom: 0rem;
        width: 3.5rem;
        height: 3.5rem;
      }
      .selected p {
        color: #2a70ed;
      }
      .catalog i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/catalog.png) no-repeat;
        background-size: 100% 100%;
      }
      .catalogS i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/home/catalog_select.png) no-repeat;
        background-size: 100% 100%;
      }
      .catalogS p {
        color: #2a70ed;
      }
    }
    
  }
</style>
