<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="我要加入"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wxxd-body-container">
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="name"
            name="userName"
            label="姓名"
            placeholder="请填写"
            required
            :rules="[{ required: true, message: '请填写姓名' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            required
            :rules="[{ required: true, message: '请填写联系电话' }]"
            class="input-bar"
            input-align="right"
          />
          <van-cell title-class="cell" title="是否党员" size="large">
            <template #default>
              <span
                :class="['radio', { selected: hasParty === '是' }]"
                @click="changeSex('是')"
                >是</span
              >
              <span
                :class="['radio', { selected: hasParty === '否' }]"
                @click="changeSex('否')"
                >否</span
              >
            </template>
          </van-cell>
          <div class="section" style="padding: 0.8rem;">
            <div class="image-title">图片</div>
            <van-uploader v-model="invitationImage" name="invitationImage" multiple :max-count="4" />
          </div>
          <div class="section-textArea">
            <div class="section-label"><span class="required">*</span>个人特长</div>
            <van-field
              v-model="grtc"
              name="specialty"
              rows="4"
              autosize
              label
              type="textarea"
              maxlength="400"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写个人特长' }]"
              show-word-limit
            />
          </div>
          <div class="section-textArea">
            <div class="section-label"><span class="required">*</span>个人介绍</div>
            <van-field
              v-model="grjs"
              name="userIntro"
              rows="4"
              autosize
              label
              type="textarea"
              maxlength="400"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写个人介绍' }]"
              show-word-limit
            />
          </div>
          <div class="section-textArea">
            <div class="section-label"><span class="required">*</span>义工事迹</div>
            <van-field
              v-model="ygsj"
              name="deeds"
              rows="4"
              autosize
              label
              type="textarea"
              maxlength="400"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写义工事迹' }]"
              show-word-limit
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              提交申请
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
import commonApi from "api/common";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      hasParty: "",
      name: "",
      phone: "",
      grtc: '',
      grjs: '',
      ygsj: '',
      invitationImage: [],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    // this.$dialog.alert({
    //   title: '入党须知',
    //   message: '弹窗内容',
    //   theme: 'round-button',
    // })
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    changeSex(val) {
      this.hasParty = val;
    },
    onSubmit(values) {
      // console.log('values', this.invitationImage);
      const formData = new FormData();
      this.invitationImage.forEach((item) => formData.append("files", item.file));
      commonApi.uploadMultiFile(formData).then((res) => {
        const params = {
          ...values,
          image: res.data,
          hasParty: this.hasParty,
        };
        Api.addHongXiaoEr(params).then(res => {
          if(res.flag) {
            this.$toast({
              message: '申请提交成功！',
              position: 'top',
            });
            const timer = setTimeout(() => {
              this.$router.go(-1);
              clearTimeout(timer);
            }, 1000);
          }
        })
      });
    },
    onOk() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }
  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
      .required {
        color: #ee0a24;
      }
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .wxxd-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 50px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/wxxd-top-bar.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #FFF;
      }
      .report-arrow {
        color: #FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }
    .form-bar {
      .cell {
        color: rgba(0, 0, 0, 0.65);
      }
      .radio {
        height: 2rem;
        border-radius: 1rem;
        // background: #4479ED;
        color: rgba(0, 0, 0, 0.35);
        border: 1px solid rgba(0, 0, 0, 0.35);
        padding: 0.1rem 1.2rem;
        margin-left: 8px;
      }
      .selected {
        background: #4479ed;
        color: #ffffff;
        border: 1px solid #4479ed;
      }
      .divide {
        height: 0.8rem;
        background: #f6f7fa;
      }
      .footer-button {
        position: fixed;
        bottom: 0.8rem;
        right: 0.8rem;
        left: 0.8rem;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10vh;
      
      .pay-window {
        background-color: #FFF;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75vw;
        .pay-window-top {
          width: 100%;
          height: 48px;
          background-color:  #4479ED;
          font-size: 16px;
          color: #FFF;
          line-height: 48px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .close {
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }
        .pay-window-content {
          width: 100%;
          text-align: center;
          line-height: 20px;
          padding: 16px;
          // .image-con {
          //   width: 60vw;
          //   height: 60vw;
          //   padding: 16px;
            >img {
              width: 100%;
              height: 100%;
            }
          // }
          .text {
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            text-align: left;
          }
          .button-wxxd {
            width: 100%;
            background-color: #4479ED;
            color: #FFF;
            font-size: 18px;
            line-height: 44px;
            border: none;
            border-radius: 4px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
