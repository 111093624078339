<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="垃圾分类指南"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="body">
      <van-search
        class="search"
        v-model="value"
        shape="round"
        background="transparent"
        placeholder="请输入垃圾名称"
      />
      <div class="imgs">
        <img
          @click="toDetail(1)"
          src="../../assets/images/refuse/recyclable.png"
          alt="可回收"
        />
        <img
          @click="toDetail(2)"
          src="../../assets/images/refuse/putrescible.png"
          alt="易腐"
        />
        <img
          @click="toDetail(3)"
          src="../../assets/images/refuse/hazardous.png"
          alt="有害"
        />
        <img
          @click="toDetail(4)"
          src="../../assets/images/refuse/other.png"
          alt="其他"
        />
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import refuseApi from 'api/refuse';
export default {
  data() {
    return {
      value: "",
      dataInfo: {},
    };
  },
  methods: {
    toDetail(type) {
      this.$router.push({ path: "/refuse/detail", query: { type } });
    },
    getInfo() {
      refuseApi.getRefuseInfo({}).then(res => {
        if(res.flag) {
          this.dataInfo = res.data[0];
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.body {
  background: url("../../assets/images/refuse/index-bg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 46px;
  display: flex;
  flex-flow: column nowrap;

  .search {
    visibility: hidden;
    margin-top: 1.5rem;
  }
  .imgs {
    // flex: 1;
    margin-top: 4rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    > img {
      margin: 2rem;
      width: 7rem;
      height: 11rem;
    }
  }
}

::v-deep .van-search__content {
  background-color: rgba(255, 255, 255, 0.45);
}

::v-deep .van-field__control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
}
</style>