<template>
  <div class="volunteer-apply-container">
    <van-nav-bar
      title="申请加入"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <van-notice-bar
        v-if="info.applyStatus === '申请中'"
        left-icon="volume-o"
        color="#1989fa"
        background="#ecf9ff"
        text="申请审核中"
      />
      <van-notice-bar
        v-if="info.applyStatus === '未通过'"
        left-icon="volume-o"
        color="#FF4535"
        background="#FFEFEE"
        :text="info.checkReason || ''"
      />
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="name"
          name="creater"
          label-class="input-label"
          required
          label="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
          placeholder="请填写"
        />
        <van-field
          size="large"
          v-model="phone"
          name="phone"
          label-class="input-label"
          required
          label="联系电话"
          :rules="[{ required: true, message: '请填写联系电话' }]"
          placeholder="请填写"
        />

        <div class="section" style="padding: 0.8rem">
          <div class="title">照片</div>
          <van-uploader
            v-model="imageList"
            name="imageList"
            multiple
            :max-count="5"
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="title"><span style="color: red;">*</span>个人介绍</div>
          <van-field
            size="large"
            v-model="introduce"
            rows="3"
            autosize
            name="introduce"
            type="textarea"
            maxlength="200"
            placeholder="请填写"
            :rules="[{ required: true, message: '请填写个人介绍' }]"
            show-word-limit 
          />
        </div>
        <div class="section" style="padding: 0.8rem;margin-bottom: 0.8rem;">
          <div class="title">加入原因</div>
          <van-field
            size="large"
            v-model="reason"
            rows="3"
            autosize
            name="joinReason"
            type="textarea"
            maxlength="200"
            placeholder="请填写"
            show-word-limit 
          />
        </div>

        <div class="footer-button">
          <van-button v-if="info.applyStatus === '申请中'" block type="info" round native-type="button" @click="handleCancel">取消申请</van-button>
          <van-button v-else-if="info.applyStatus === '未通过'" :loading="loading" loading-text="提交中..." round type="info" native-type="submit">重新提交</van-button>
          <van-button v-else :loading="loading" loading-text="提交中..." round type="info" native-type="submit" >提交申请</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import volunteerApi from 'api/volunteer';
import commonApi from "api/common";
import { mapMutations, mapState } from "vuex";
import { Toast } from 'vant';
export default {
  data() {
    return {
      name: '',
      phone: '',
      introduce: '',
      reason: '',
      imageList: [],
      loading: false,
      info: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.getInfo();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getInfo() {
      const obj = JSON.parse(sessionStorage.getItem('userInfo'));
      const { userName = '', phone: userPhone = '' } = obj || {};
      volunteerApi.getApplyInfo().then(res => {
        if (res.flag) {
          this.info = res.data || {};
          const { creator = '', phone = '', introduce = '', joinReason = '', image } = res.data;
          this.name = creator || userName;
          this.phone = phone || userPhone;
          this.introduce = introduce;
          this.reason = joinReason;
          this.imageList = image ? [{ url: image }] : [];
        } else {
          this.info = {};
        }
      })
    },
    onSubmit(values) {
      this.loading = true;
      if (this.imageList && this.imageList.length > 0) {
        const formData = new FormData();
        this.imageList.map((item) => formData.append("files", item.file));
        commonApi.uploadMultiFile(formData).then((res) => {
          const params = {
            ...values,
            image: res.data,
          };
          volunteerApi.addVolunteer(params).then((res) => {
            const { message,data, flag } = res;
            if (flag) {
              Toast.success('申请提交成功');
              setTimeout(() => {
                this.loading = false;
                this.$router.go(-1);
              }, 500);
            } else {
              Toast(message);
            }
          });
        });
      } else {
        volunteerApi.addVolunteer({ ...values, image: '' }).then((res) => {
            const { message,data, flag } = res;
            if (flag) {
              Toast.success('申请提交成功');
              setTimeout(() => {
                this.loading = false;
                this.$router.go(-1);
              }, 500);
            } else {
              Toast(message);
            }
          });
      }
    },
    handleCancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定是否取消加入',
        confirmButtonText: '立即取消',
        confirmButtonColor: '#4379ed'
      }).then(() => {
        volunteerApi.cancelVolunteer({ type: 1 }).then(res => {
          if (res.flag) {
            this.$router.go(-1);
          }
          
        })
      }).catch((e) => {
      });
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onConfirm(value) {
      this.type = value;
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.volunteer-apply-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 2.4rem - 70px);
    width: 100vw;
    background: #f5f5f5;
    overflow-y: auto;
    overflow-x: hidden;
    .section {
      background: #ffffff;
      width: 100%;
      border-top: 0.8rem solid #f5f5f5;
      .title {
        line-height: 2rem;

        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }

  }
  .footer-button {
    position: fixed;
    bottom: 15px;
    right: 0;
    left: 0.8rem;
    width: calc(100vw - 1.6rem);
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: transparent;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
    .van-button {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
