<template>
  <div class="zxzxDetail" style="background: #fff; height: 100vh">
    <div class="page-container paddclose" style="height: calc(100vh - 60px);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>资讯详情</h1>
      </div>
      <div class="hot-main" style="width: 100%;">
        <div class="desc_title">{{dataInfo.title}}</div>
        <div class="desc_time">
          <div class="desc_tag"></div>
          <div class="time">{{dataInfo.publishTime}}</div>
        </div>
        <div class="desc_content" v-html="dataInfo.content">
        </div>
        <!-- <div class="top_banner" v-if="dataInfo.cover">
          <img :src="dataInfo.cover" />
        </div> -->
        <!-- <div class="plCotent">
          <div class="plTitle">
            <div>
              评论:
              <span class="num">3</span>
            </div>
            <div>
              浏览:
              <span class="num">68</span>
            </div>
          </div>

          <div class="item" v-for="(item, index) in plList" :key="index">
            <div class="itemName">
              <img src="../../assets/images/zjy/tx.png" alt="">
              <span>{{item.name}}</span>
              <div class="time">{{item.time}}</div>
            </div>
            <div class="itemDesc">{{item.desc}}</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {mapState} from 'vuex';
import service from 'utils/service';
export default {
  data() {
    return {
      showData: {
        picture: require('../../assets/images/zxzx/img1.png'),
        contents: [
          '何月红，女，1963年生，诸暨市枫桥镇人。现任诸暨市心理卫生协会会长，诸暨市第十四届政协委员，国家二级心理咨询师，高级催眠师，少儿注意力训练导师，婚姻家庭高级咨询师。',
          '多年来从事心理咨询辅导工作，先后被评为“‘十二五’全省计生协工作先进志愿者”、“绍兴市杰出志愿者”、“诸暨市百名优秀志愿者” 、“诸暨市优秀志愿者”、“最美暨阳人”，并连续9年被市民政局评为“诸暨市优秀婚姻家庭辅导员”。',
        ],
        title: '诸暨市心理卫生协会',
        desc: '诸暨市心理卫生协会成立于2013年12月，是经市民政局审批登记并具有法人资格的社会团体，其业务主管单位是市科学技术协会。 协会的主要任务是团结全市心理卫生学、心理学、医学、教育学、社会学界等科学工作者开展心理卫生宣教、',
      },
      showDialog: false,
      name: '',
      phone: '',
      habbit: '',
      plList: [
        { name: '小王', time: '2021-12-22 10:21:41', desc: '很实用的信息，多谢分享！'},
        { name: '小王', time: '2021-12-22 10:21:41', desc: '很实用的信息，多谢分享！'},
        { name: '小王', time: '2021-12-22 10:21:41', desc: '很实用的信息，多谢分享！'},
      ],
    };
  },
  computed: {
    ...mapState({
      dataInfo: state => state.zxzx.dataInfo || {},
    }),
  },
  components: {},
  mounted(opt) {
    console.log(this.dataInfo);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    addOrderEnroll() {
      Toast.success('报名成功');
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.zxzxDetail{
  .hot-main{
    height: 100% !important;
  }
  .top_banner{
    padding: 10px;
    width: 100%;
    >img{
      width: 100%;
      height: 220px;
    }
  }
  .desc_title{
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
    line-height: 30px;
  }
  .desc_time{
    display: flex;
    padding: 0 10px;
    line-height: 20px;
    .desc_tag{
      background: #3BDCBC;
      border-radius: 10px;
      line-height: 20px;
      text-align: center;
      font-size: 14px;
      color: #FFFFFF;
      width: 0px;
    }
    .time{
      margin-left: atuo;
      margin-right: 0px;
      text-align: right;
      font-size: 14px;
      color: rgba(0,0,0,0.65);
      // width: calc(100% - 70px);
      width: 100%;
    }
  }
  .desc_content{
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    text-align: left;
    line-height: 24px;
    padding: 5px 10px;
    text-indent: 24px;
    img{
      width: 80% !important;
    }
  }
  .desc_image{
    padding: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >img{
      margin-top: 10px;
      width: 48%;
    }
  }
  .plCotent{
    width: 100%;
    .plTitle{
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #ecf1ff;
      padding: 0 10px;
      font-size: 16px;
      display: flex;
      >div{
        width: 50%;
        .num{
          color: #FF4535;
        }
        &:last-child{
          text-align: right;
        }
      }
    }
    .item{
      padding: 10px;
      border: 1px solid rgba(0,0,0,0.1);
      .itemName{
        line-height: 24px;
        color: #000;
        font-size: 12px;
        display: flex;
        img{
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .time{
          margin-left: auto;
          width: 120px;
          text-align: right;
        }
      }
      .itemDesc{
        line-height: 30px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
    }
  }
}
</style>
