<template>
  <div id="index">
    <div class="kgh-container">
      <van-nav-bar
        title="柯工慧"
        left-arrow
        @click-left="onClickLeft"
      />
      <div class="kgh-body-container">
        <img style="width: 100vw;" src="http://dssq.ikeqiao.net/data/image/h5/images/banner-kgh.png" />
        <!-- 柯工荣誉 -->
        <div class="rongyuyijia-con">
          <div class="rongyu-title">柯工<br />荣誉</div>
          <div class="scroll-con" ref="honor">
            <div class="honor-list">
              <div class="honor-list-item" v-for="item in honorList" :key="item">
                <i class="honor-tag"></i>
                <h3>{{item}}</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="scroll-con" ref="honor">
          <div class="honor-list">
            <div class="honor-list-item" v-for="item in honorList" :key="item">
              <i class="honor-tag"></i>
              <h3>{{item}}</h3>
            </div>
          </div>
        </div> -->
        <!-- 柯工·智服 -->
        <div class="card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">柯工·智服</span>
          </div>
          <div class="card-bar-content">
            <div class="icon-bar">
              <div
                v-for="(icon, index) in list"
                @click="gotoLink(icon.path)"
                :key="`${icon.name}_${index}`"
              >
                <img class="icon" :src="icon.icon" :alt="icon.name" />
                <p>{{ icon.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 柯工·阵地 -->
        <div class="card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">柯工·阵地</span>
          </div>
          <div class="card-bar-content" @click="gotoLink('kegongzhendi')">
            <div class="kgh-img">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/zhendi.png" />
            </div>
          </div>
        </div>
        <!-- 柯工·快讯 -->
        <div class="card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">柯工·快讯</span>
            <span class="text-more" @click="gotoLink(`kghList?type=6`)">
              更多
              <van-icon name="arrow" color="#bfbfbf" size="11px" />
            </span>
          </div>
          <div class="card-bar-content">
            <ul>
              <li class="fl-item" v-for="(item, index) in newsList" :key="index" @click="openArticleUrl(item)">
                <div class="fl-title">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="fl-desc">
                      <i class="time"></i>
                      <span style="margin-right: 10px;">时间：{{item.updateTime}}</span>
                      <i class="view"></i>
                      <span>{{item.visitsUp || 0}}</span>
                    </div>
                  </div>
                  <img v-if="item.cover" :src="item.cover" alt="">
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="content-box">
          <div class="title">柯工·智服</div>
          <ul>
            <li @click="gotoLink('lpjh')">
              <i class="icon1"></i><span>零跑建会</span>
            </li>
            <li @click="gotoLink('yspf')">
              <i class="icon2"></i><span>云上普法</span>
            </li>
            <li @click="gotoLink('hgxt')">
              <i class="icon3"></i><span>惠工学堂</span>
            </li>
            <li @click="gotoLink('zbzx')">
              <i class="icon4"></i><span>职保在线</span>
            </li>
            <li @click="gotoLink('swys')">
              <i class="icon5"></i><span>书屋有声</span>
            </li>
            <li @click="gotoLink('zyya')">
              <i class="icon6"></i><span>志愿有爱</span>
            </li>
            <li @click="gotoLink('wjwh')">
              <i class="icon7"></i><span>微匠文化</span>
            </li>
            <li @click="gotoLink('gqyz')">
              <i class="icon8"></i><span>工晴驿站</span>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {articleList} from 'assets/json/kgh';
import Api from 'api';
export default {
  data() {
    return {
      newsList: [],
      list: [
        {
          name: "云上普法",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/hong/yspf.png",
          path: 'yspf'
        },
        {
          name: "惠工学堂",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/hong/hgxt.png",
          path: "hgxt",
        },
        {
          name: "书屋有声",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/hong/swys.png",
          path: "swys",
        },
        {
          name: "志愿有爱",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/hong/zyya.png",
          path: "zyya",
        },
      ],
      honorList: ['绍兴市区域性党建带工建示范联合工会', '诸暨市十佳工人先锋号', '诸暨市工人文化宫'],
      honorScroll: 0,
      timer: null,
    };
  },
  components: {},
  mounted() {
    this.createInterval();
    this.getDataList(6)
  },
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    openUrl(url) {
      if(url) {
        window.location.href = url;
      }
    },
    openArticleUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    createInterval() {
      // 滚动荣誉计时器
      this.timer = setInterval(() => {
        this.honorScroll = (this.honorScroll + 0.08) % (24 * (this.honorList.length-1.9));
        this.$refs.honor.scrollTop = this.honorScroll;
      }, 10);
    },
    getDataList(type) {
      Api.getHongArticleList({
        page: 1,
        pageSize: 4,
        type,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        this.newsList = records;
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.kgh-container {
  height: 100vh;
  width: 100vw;
  .kgh-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .rongyuyijia-con {
      width: calc(100vw - 20px);
      margin: 10px;
      height: 92px;
      border-radius: 4px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/kegongrongyu.png') no-repeat;
      background-size: 100% 100%;
      padding: 15px;
      display: flex;
      .rongyu-title {
        width: 5rem;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 20px;
        line-height: 22px;
        color: #4479ED;
      }
      .scroll-con {
        width: 100%;
        height: 100%;
        // padding: 5px 1rem;
        overflow-x: hidden;
        overflow-y: scroll;
        transition: all 1s;
        .honor-list {
          width: 100%;
          .honor-list-item {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 24px;
            color: rgba(0,0,0,0.85);
            .honor-tag {
              width: 24px;
              height: 24px;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/rongyu.png') no-repeat;
              background-size: 100% 100%;
              display: inline-block;
              margin-right: 6px;
            }
            >h3 {
              word-break: break-all;
              flex: 1;
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
            }
            >span {
              width: 60px;
              text-align: right;
              font-size: 14px;
            }
          }
        }
      }
      
    }
    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 4px;
      padding: 6px 0;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        background: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          width: 50%;
        }
        .text-more {
          color: #bfbfbf;
          font-size: 11px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        .icon-bar {
          margin: 0 10px;
          width: calc(100% - 20px);
          height: 108px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background: #fff;
          border-radius: 4px;
          > div {
            width: 20%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 15px;
            .icon {
              width: 47px;
              height: 47px;
              margin-bottom: 5px;
            }
            > p {
              text-align: center;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }

        .kgh-img {
          // height: 8rem;
          width: calc(100% - 20px);
          margin: 10px;
          img {
            // height: 8rem;
            width: 100%;
          }
        }

        .fl-item {
          margin: 10px 10px 0 10px;
          padding: 10px 0;
          .fl-title {
            display: flex;
            align-items: flex-start;
            >img {
              width: 7rem;
              margin-left: 10px;
            }
            >div {
              flex: 1;
              >h1 {
                font-size: 16px;
                line-height: 32px;
                color: rgba(0,0,0,0.85);
                font-weight: 500;
              }
              .fl-desc {
                display: flex;
                height: 24px;
                align-items: center;
                font-size: 12px;
                color: rgba(0,0,0,0.45);
                .time, .view {
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                  background-size: 100% 100%;
                }
                .view {
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
            
          }
          
        }
        
      }
    }
  }
}
</style>
