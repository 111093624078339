<!--积分页面-->
<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="积分说明"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="body">
      <van-collapse
        v-model="activeName"
        accordion
        v-for="(item, index) in data"
        :key="index"
      >
        <van-collapse-item :title="item.time" :name="index">
          <div
            class="item"
            v-for="(item1, index1) in item.children"
            :key="index1"
          >
            <div class="left">
              <div class="event">{{ item1.event }}</div>
              <div class="time">{{ item1.time }}</div>
            </div>
            <div class="right">
              <span>{{ item1.point > 0 ? "+" : "-" }}</span>
              <span :class="[item1.point > 0 ? 'yellow' : 'green']">{{
                Math.abs(item1.point)
              }}</span>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 0,
      data: [],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.data = [
        {
          time: "本月",
          children: [
            { time: "2021.12.16", event: "签到", point: 1 },
            { time: "2021.12.15", event: "签到", point: 1 },
            { time: "2021.12.15", event: "签到", point: 1 },
          ],
        },
        {
          time: "2021年11月",
          children: [
            { time: "2021.11.16", event: "签到", point: 1 },
            { time: "2021.11.15", event: "签到", point: 1 },
            { time: "2021.11.15", event: "签到", point: 1 },
          ],
        },
        {
          time: "2021年10月",
          children: [
            { time: "2021.10.7", event: "国庆活动消耗", point: -10 },
            { time: "2021.10.3", event: "签到", point: 1 },
            { time: "2021.10.1", event: "签到", point: 1 },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  margin-top: 44px;

  .item {
    margin-bottom: 0.8rem;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0.8rem;

    .left {
      display: flex;
      flex-flow: column nowrap;
      color: #000;

      .event {
        font-size: 1rem;
      }

      .time {
        font-size: 0.8rem;
      }
    }

    .right {
      .yellow {
        color: #ffaa36;
        font-size: 1rem;
      }

      .green {
        color: #07c160;
        font-size: 1rem;
      }
    }
  }
}

::v-deep .van-collapse-item__content {
  background: transparent;
}

::v-deep .van-collapse-item {
  margin-bottom: 1rem;
}
</style>