export const basicData1 = [
  {
    month: '4月',
    jinguo: 60,
    nvxing: 18,
    ertong: 642,
    danqin: 91
  },
  {
    month: '5月',
    jinguo: 60,
    nvxing: 18,
    ertong: 642,
    danqin: 91
  },
  {
    month: '6月',
    jinguo: 60,
    nvxing: 18,
    ertong: 642,
    danqin: 91
  },
];

export const basicData2 = [
  {
    title: '巾帼志愿者数量',
    num: 60,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/jinguozhiyuanzhe.png',
    color: "#fff7f5"
  },
  {
    title: '女性社会组织数量',
    num: 18,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/nvxingshehui.png',
    color: "#fff7f0"
  },
  {
    title: '流动儿童数量',
    num: 642,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/liudongertong.png',
    color: "#f3f8fd"
  },
  {
    title: '单亲家庭数量',
    num: 91,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/danqinjiating.png',
    color: "#f0faf7"
  },
];

export const basicData3 = [
  {
    title: '本月收益人数',
    num: 400,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/benyueshouyi.png',
    color: '#f3f8fd'
  },
  {
    title: '本月维权金额',
    num: 13000,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/benyueweiquan.png',
    color: '#fff7f0'
  },
];

export const basicData4 = [
  {
    title: '普法活动次数',
    num: 6,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhiyuanzherenshu.png',
    color: '#fff7f5'
  },
  {
    title: '调解数量',
    num: 8,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/benyuehuodongpinci.png',
    color: '#f0faf7'
  },
  {
    title: '志愿者人数',
    num: 20,
    icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/benyueyuyue.png',
    color: '#f5f0fc'
  },
];

export default {
  basicData1,
  basicData2
};