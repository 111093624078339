<template>
  <div class="hui-study-container">
    <van-nav-bar
      class="hui-study-nav-bar"
      title="数字家长课堂"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hui-study-body-container">
      <van-tabs color="#5278e5"   @click="handleTabSelect" v-model="studyItemSelect">
        <van-tab title="学堂详情" name="学堂详情" ></van-tab>
        <van-tab title="活动" name="活动" ></van-tab>
      </van-tabs>
      <div v-if="studyItemSelect === '学堂详情'" class="arcContent">
        <div class="itemText" v-for="(item, i) in barList" :key="i" @click="goDetail(i)">
          <span>{{item}}</span>
          <van-icon style="margin-left: auto; margin-right: 0px; margin-top: 22px; color: #9BA0AA" name="arrow" />
        </div>
      </div>
      <div v-if="studyItemSelect === '活动'">
        <div class="huodongzhongxin-body-container">
          <wyslList activityType="家长课堂" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import WyslList from "./wyslList";
export default {
  data() {
    return {
      selected: '活动',
      barList: [
        '东盛社区“与法同行”家长学校管理制度',
        '东盛社区 家长学校教学计划',
        '东盛社区 家长学校工作岗位职责',
        '东盛社区 家长学校教学计划',
        '东盛社区 家长学校办学章程',
        '东盛社区 家长学校学员守则',
        '东盛社区 家长东盛社区 家长学校考勤制度',
        '东盛社区“家长教子”具体实施办法',
        '东盛社区 家长学校教师一览表',
        '东盛社区 家长学校志愿服务队伍',
        '东盛社区 家长学校活动场地照片',
      ],
    };
  },
  computed: {
    ...mapState({
      studyItemSelect: (state) => state.study.studyItemSelect,
    }),
  },
  components: { WyslList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    goDetail(index) {
      this.$router.push(`/szjzktDetail?id=${index}`);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleTabSelect(name, title) {
      this.$store.commit('study/setStudyItemSelect', name);
    },
    showToast(message) {
      this.$toast(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.hui-study-container {
  height: 100vh;
  width: 100vw;
  .arcContent{
    .itemText{
      padding: 0 10px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      font-weight: 400;height: 56px;
      background: #FFFFFF;
      border-bottom: 1px solid rgba(17,31,44,0.10);
      line-height: 56px;
      display: flex;
    }
  }
}
</style>
