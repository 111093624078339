<template>
  <div class="need-help-container">
    <van-nav-bar
      title="我要求助"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="need-help-body-container">
      <div class="help-list">
        <div class="help-list-item" v-for="item in list" :key="item.id">
          <div class="help-list-item-top">
            <h1>{{item.title}}</h1>
            <span>{{`${item.number}人已预约`}}</span>
          </div>
          <p>{{item.content}}</p>
          <button @click="handlePreview(item)" class="help-list-item-button">预约</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      list: []
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.getList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getList() {
      Api.woyaoqiuzhuList({
        page: 1,
        size: 100
      }).then(res => {
        if(res.flag && res.data && res.data.records) {
          this.list = res.data.records;
        }
      })
    },
    handlePreview(value) {
      this.$router.push(`helpPreview?id=${value.id}&title=${value.title}`)
    }
  }
};
</script>

<style lang="scss" scoped>
.need-help-container {
  height: 100vh;
  width: 100vw;
  
  .need-help-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .help-list {
      width: 100%;
      .help-list-item {
        width: calc(100% - 30px);
        margin: 15px;
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/hunyinmaoduntiaojie.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 4px;
        padding: 10px;
        color: rgba(0,0,0,0.85);
        font-size: 14px;
        line-height: 18px;
        .help-list-item-top {
          width: 100%;
          height: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >h1 {
            font-size: 16px;
            font-weight: 500;
          }
          >span {
            font-size: 14px;
          }
        }
        .help-list-item-button {
          background: #FFF;
          width: 50px;
          height: 20px;
          border: 0.5px solid #4479ED;
          border-radius: 14px;
          color: #4479ED;
          font-size: 12px;
          text-align: center;
          margin: 10px 0;
        }
      }
    }

  }
}
</style>
