<template>
  <div class="loveActivity-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发起活动</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/loveActivity.png" />
        </div>
        <div class="all-nav" style="marginbottom: 0">
          <div @click="handleLink('addloveActivity')" class="index-tab tab">
            <b>志愿活动</b>
            <span style="color: #4479ed">+ 发起志愿活动</span>
          </div>
        </div>
        <van-list
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <ul class="loveActivity—box">
            <li v-for="item in list" :key="item.key">
              <div class="img" @click="gotoLink('loveActivityDetail?key='+item.vId)">
                <img :src="item.image" />
                <div class="img-title">{{ item.title }}</div>
                <div class="img-des">
                  <span>{{ item.beginTime }}-{{ item.endTime }}</span>
                </div>
              </div>
            </li>
          </ul>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import loveActivityApi from "api/ddya/loveActivity";
import { List } from "vant";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      list: [],
      finished: false,
      pageDataList: [],
    };
  },
  components: {},
  mounted() {},
  methods: { 
    getList({ page = this.page, size = this.size } = {}) {
      loveActivityApi
        .getList({
          page,
          size,
        })
        .then((res) => {
          const { records = [], total = 0 } = res.data || {};
          this.list = this.list.concat(records);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleClick(path) {
      this.$store.dispatch('login/checkRealName', () => {
        this.$router.push(path);
      })
    }
  },
};
</script>

<style lang="scss">
// @import "~assets/scss/style.scss";
.loveActivity-index {
  .page-container {
    padding: 0 0.8rem 0rem;
    background: #fff;
}
  .loveActivity—box {
    height: auto !important;
    li {
      display: inline-flex;
      align-items: center;
      width: 100% !important;
      height: auto !important;
      margin-bottom: 0.8rem;
      .img {
        height: auto !important;
        width: 100% !important;
        text-align: center;
        img {
          width: auto !important;
          height: auto !important;
          max-height: 10rem;
          max-width: 100%;
          padding: 0 0.6rem;
        }
        .img-title {
          text-align: left;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 1rem;
          padding: 0.5rem 0 0 0.6rem;
          font-weight: bold;
        }
        .img-des {
          text-align: center;
          color: rgba(0, 0, 0, 0.65);
          line-height: 1.2rem;
          font-size: 0.8rem;
          padding: 0.5rem 0 0 0.6rem;
          display: flex;
          align-items: center;
          img:nth-child(3) {
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
}
</style>
