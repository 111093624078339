<template>
  <div class="alarm-container">
    <van-nav-bar title="警务信息" left-arrow @click-left="onClickLeft()" />
    <div class="newlist">
      <div class="tab">
        <b>警务信息</b>
      </div>
      <ul>
        <li v-for="item in datas" :key="item.id">
          <div class="img">
            <van-image
              width="100%"
              height="100%"
              :src="item.imgSrc"
              fit="cover"
            />
          </div>
          <div class="desc">
            <div class="title">
              {{ item.content }}
            </div>
            <div class="time">{{ item.name }}</div>
            <div class="time">时间：{{ item.createTime }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datas: [
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp1.png",
          content: "弘扬“三能”精神丨淬炼忠诚显担当 勇做警营“螺丝钉”",
          name: "诸暨警方",
          createTime: "2021-09-07   12：10：05",
        },
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp2.png",
          content: "我们始终和你在一起!",
          name: "绍兴公安群蓝星",
          createTime: "2021-09-07   11：00：29",
        },
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp3.png",
          content: "一条卷烟只卖几十元！猫腻还不少...",
          name: "绍兴公安群蓝星",
          createTime: "2021-09-06   15：13：14",
        },
        {
          imgSrc: "http://dssq.ikeqiao.net/data/image/h5/images/catalog/p-temp4.png",
          content:
            "深化教育整顿丨“三能”精神在浙江公安的弘扬与实践之二：牢记嘱托，擦亮永恒的忠诚底色",
          name: "浙江公安",
          createTime: "2021-09-07   09：29：29",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  created() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.newlist {
  padding: 0.5rem 0.8rem;
  background: #fff;
  .tab {
    height: 1.5rem;
    font: 500 0.8rem/1.5rem "microsoft yahei";
    text-align: left;
    b {
      float: left;
      color: #262134;
      font-size: 1.1rem;
    }
    span {
      float: right;
      color: #a0a0a0;
    }
  }
  li {
    padding: 0.5rem 0.4rem;
    width: 100%;
    height: 6.2rem;
    //border-bottom: 1px solid #e5e5e5;
  }
  .img {
    width: 7rem;
    height: 5.2rem;
    float: left;
  }
  .img img {
    width: 7rem;
    height: 5.2rem;
    border-radius: 0.2rem;
  }
  .desc {
    float: right;
    width: 15rem;
    overflow: hidden;
    font: 500 0.8rem/1.2rem "Microsoft Yahei";
    color: #999;
    text-align: left;
    .title {
      color: #000;
      text-align: left;
      font-size: 1rem;
      height: 2.8rem;
      line-height: 1.4rem;
      overflow: hidden;
      word-wrap: break-word;
      word-break: break-all;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //margin-bottom: 0.2rem;
    }
    .state {
      margin-bottom: 0.2rem;
      span {
        display: inline-block;
        padding: 0 0.7rem;
        color: #fff;
        background: #81a66a;
        border-radius: 0.3rem;
        height: 1.2rem;
        line-height: 1.2rem;
        font-family: tahoma;
        font-size: 0.8rem;
      }
    }
    .time {
      b {
        color: #666;
        margin-right: 1rem;
      }
    }
  }
}
</style>
