<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="邻里党员"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wxxd-body-container">
      <van-notice-bar
        left-icon="volume-o"
        text="流动党员可以在此提交认证"
      />
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="姓名"
            placeholder="请填写姓名"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-cell title-class="cell" title="性别" size="large">
            <template #default>
              <span
                :class="['radio', { selected: sex === '男' }]"
                @click="changeSex('男')"
                >男</span
              >
              <span
                :class="['radio', { selected: sex === '女' }]"
                @click="changeSex('女')"
                >女</span
              >
            </template>
          </van-cell>
          <van-field
            size="large"
            v-model="idCard"
            name="idCard"
            label="身份证号"
            placeholder="请填写身份证号"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请填写联系电话"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            readonly
            is-link
            clickable
            name="birth"
            v-model="date"
            size="large"
            label="出生年月"
            placeholder="点击选择日期"
            @formatter="formatter"
            @click="showDate = true"
            :rules="[{ required: true, message: '' }]"
            input-align="right"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="zb"
            name="organizationAddress"
            label="党组织所在地"
            placeholder="请填写党组织所在地"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="juzhudi"
            name="liveAddress"
            label="现居住地"
            placeholder="请填写现居住地"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              提交申请
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="showDate = false"
        @confirm="onDateConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      type: "全血",
      sex: "男",
      name: "",
      phone: "",
      idCard: "",
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(),
      date: '',
      showDate: false,
      showDepart: false,
      depart: "",
      departList: [],
      zb: '',
      juzhudi: '',
      qita: '',
      show: false,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    // this.$dialog.alert({
    //   title: '入党须知',
    //   message: '弹窗内容',
    //   theme: 'round-button',
    // })
  },
  watch: {
   
  },
  methods: {
    testTouch(e) {
      e.stopPropagation();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    onDateConfirm(value, index) {
      this.date = this.formatDateTime(value);
      this.showDate = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m;
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      return val;
    },
    changeSex(val) {
      this.sex = val;
    },
    onSubmit(values) {
      console.log('values', values);
      Api.addNeighborMember({...values, sex: this.sex }).then(res => {
        if(res.flag) {
          this.$toast({
            message: '申请提交成功！',
            position: 'top',
          });
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer);
          }, 1000);
        }
      })
    },
    onOk() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .wxxd-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    // padding-bottom: 10px;
    // background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    // background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 50px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/wxxd-top-bar.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #FFF;
      }
      .report-arrow {
        color: #FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }
    .form-bar {
      .cell {
        color: rgba(0, 0, 0, 0.65);
      }
      .radio {
        height: 2rem;
        border-radius: 1rem;
        // background: #4479ED;
        color: rgba(0, 0, 0, 0.35);
        border: 1px solid rgba(0, 0, 0, 0.35);
        padding: 0.1rem 1.2rem;
        margin-left: 8px;
      }
      .selected {
        background: #4479ed;
        color: #ffffff;
        border: 1px solid #4479ed;
      }
      .divide {
        height: 0.8rem;
        background: #f6f7fa;
      }
      .footer-button {
        position: fixed;
        bottom: 0.8rem;
        right: 0.8rem;
        left: 0.8rem;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10vh;
      
      .pay-window {
        background-color: #FFF;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75vw;
        .pay-window-top {
          width: 100%;
          height: 48px;
          background-color:  #4479ED;
          font-size: 16px;
          color: #FFF;
          line-height: 48px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .close {
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }
        .pay-window-content {
          width: 100%;
          text-align: center;
          line-height: 20px;
          padding: 16px;
          // .image-con {
          //   width: 60vw;
          //   height: 60vw;
          //   padding: 16px;
            >img {
              width: 100%;
              height: 100%;
            }
          // }
          .text {
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            text-align: left;
          }
          .button-wxxd {
            width: 100%;
            background-color: #4479ED;
            color: #FFF;
            font-size: 18px;
            line-height: 44px;
            border: none;
            border-radius: 4px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
