<template>
  <div class="loveDonate-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>爱心捐献</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <div class="banner-title">
            凝聚社区爱心 <span class="red">{{getSum()}}</span
            >次
          </div>
          <div class="banner-des">
            <ul class="banner-des-content">
              <li v-for="item in stateList" :key="item.key">
                <div class="content">
                  <div :class="'state ' + item.class">
                    {{ item.num }}<span style="fontsize: 1rem">人</span>
                  </div>
                  <div class="link" @click="handleLink(item.link)">
                    <span>{{ item.state }}</span>
                    <img
                      src="http://dssq.ikeqiao.net/data/image/h5/images/icons/rightLink.png"
                      alt=">"
                      class="link-icon"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="daryLine"></div>
        <div class="all-nav" style="marginbottom: 0; paddingleft: 0">
          <div class="index-tab tab">
            <b>动态消息</b>
          </div>
        </div>
        <ul class="loveDonate—box">
          <li v-for="item in pageDataList" :key="item.key">
            <div class="content">
              <div :class="'state ' + item.class">{{ item.state }}</div>
              <div>{{ item.des }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import loveActivityApi from "api/ddya/loveActivity";
export default {
  data() {
    return {
      count: {},
      stateList: [
        {
          key: 1,
          state: "新衣捐赠",
          class: "blue",
          num: null,
          link: "clothDonate",
        },
        {
          key: 2,
          state: "献血报名",
          class: "red",
          num: null,
          link: "xxbm",
        },
        // {
        //   key: 3,
        //   state: "爱心筹",
        //   class: "yellow",
        //   num: "67",
        // },
      ],
      pageDataList: [],
    };
  },
  components: {},
  mounted() {
    this.getBanner();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(url) {
      this.$router.push(url);
    },
    handleLink(path) {
      // this.$store.dispatch('login/checkRealName', () => {
        this.$router.push(path);
      // })
    },
    getSum() {
      if(this.count) {
        return this.count.clothesCount + this.count.bloodCount;
      }
      return 0;
    },
    getBanner() {
      loveActivityApi.getBanner().then((res) => {
        if (res.flag && res.data) {
          const { bloodDsc, clothDsc, count, loveDsc } = res.data;
          this.count = count && count.length > 0 && count[0];
          this.stateList[0].num = count[0].clothesCount;
          this.stateList[1].num = count[0].bloodCount;
          // let list1 =
          bloodDsc &&
            bloodDsc.length > 0 &&
            bloodDsc.map((item) => {
              item.class = "red";
              item.state = "献血报名";
              item.des = item.dsc;
              return item;
            });
          clothDsc &&
            clothDsc.length > 0 &&
            clothDsc.map((item) => {
              item.class = "blue";
              item.state = "新衣捐赠";
              item.des = item.dsc;
              return item;
            });
          loveDsc &&
            loveDsc.length > 0 &&
            loveDsc.map((item) => {
              item.class = "yellow";
              item.state = "爱心筹";
              item.des = item.titleDsc;
              return item;
            });
          this.pageDataList = bloodDsc.concat(clothDsc.concat(loveDsc));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.loveDonate-index {
  overflow-x: hidden;
  .blue {
    color: #4479ed;
  }
  .red {
    color: #e13f30;
  }
  .yellow {
    color: #e5b20a;
  }
  .banner {
    height: auto !important;
    margin-bottom: 1.2rem;
    .banner-title {
      opacity: 0.85;
      font-size: 1rem;
      color: #000000;
      text-align: left;
      line-height: 1.6remx;
      margin: 2rem 0;
      font-weight: bold;
      .red {
        font-size: 1.6rem;
        padding: 0 0.5rem;
      }
    }
    .banner-des {
      background: #f6f7fa;
      border-radius: 0.3rempx;
      padding: 1rem 2rem;

      .banner-des-content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .content {
          text-align: center;
          font-size: 0.9rem;
          .state {
            font-size: 1.6rem;
            margin-bottom: 1rem;
          }
          .link {
            display: flex;
            align-items: center;
            .link-icon {
              height: 1rem !important;
              width: 1rem;
            }
          }
        }
      }
    }
  }

  .loveDonate—box {
    .content {
      line-height: 1.8rem;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 0.5rem 0;
      font-size: 1rem;
      .state {
        display: inline-block;
        min-width: 4.4rem;
        border: 1px solid #4479ed;
        border-radius: 0.3rem;
        text-align: center;
        margin-right: 0.8rem;
        font-size: 0.5rem;
        line-height: 1.4rem;
      }
      .blue {
        border: 1px solid #4479ed;
      }
      .red {
        border: 1px solid #e13f30;
      }
      .yellow {
        border: 1px solid #e5b20a;
      }
    }
  }
}
</style>
