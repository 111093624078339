<template>
  <div class="comment-list-con">
    <div class="item" v-for="(item,i) in (dataList || [])" :key='i'>
      <div class="itemName">
        <div class="image">
          <img :src="item.image || item.submitUserAvatar" alt="">
        </div>
        <div class="text">
          <div style="color: rgba(0,0,0,0.85)">
            {{item.userName || item.submitUserName}}
            <!-- <span class="tags" v-for='v in (item.manage ? item.manage.split(",").filter(v1 => v1) : [])' :key="v">{{v}}</span> -->
          </div>
          <div>{{item.createTime}}</div>
        </div>
      </div>
      <div class="itemDesc">
        {{item.commentContent}}
      </div>
      <div v-if="zan" class="dzIcon" @click="handleZan(item)">
        <img :src="item.isThumb === 1 ? 'http://dssq.ikeqiao.net/data/image/h5/images/znt/dzB.png' : 'http://dssq.ikeqiao.net/data/image/h5/images/znt/dz.png'" alt="">
        <div style="margin-left: 10px">{{item.thumbsUp}}</div>
      </div>
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'commentList',
  data() {
    return {
      
    }
  },
  props: {
    dataList: {
      type: Array,
      default: function() {
        return [
          // {
          //   image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
          //   userName: '张三',
          //   createTime: '2021-05-21 12:00',
          //   commentContent: '公共资源需要大家共同承担。',
          //   hasThumbsUp: '未点赞',
          //   thumbsUp: 23,
          // },
          // {
          //   image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
          //   userName: '张三',
          //   createTime: '2021-05-21 12:00',
          //   commentContent: '公共资源需要大家共同承担。',
          //   hasThumbsUp: '已点赞',
          //   thumbsUp: 23,
          // }
        ];
      }
    },
    zan: {
      type: Boolean,
      default: function() {
        return true;
      }
    },
    zanClick: {
      type: String,
      default: function() {
        return 'handleZan';
      }
    }
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    
  },
  methods: {
    handleZan(data) {
      console.log('zan', this.zanClick);
      
      this.$emit(this.zanClick, data);
    }
  }
}
</script>
<style lang="scss" scoped>
.comment-list-con {
  width: 100%;
  margin: 0 auto;
  background: #FFF;
  border: none;
  .item{
    position: relative;
    padding: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    .itemName{
      display: flex;
      height: 50px;
      padding: 5px;
      .image{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        >img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .text{
        width: calc(100% - 50px);
        height: 100%;
        line-height: 20px;
        color: #707070;
      }
    }
    .itemDesc{
      color: rgba(0,0,0,0.85);
      line-height: 24px;
      font-size: 14px;
    }
    .dzIcon{
      position: absolute;
      right: 10px;
      top: 15px;
      height: 24px;
      line-height: 24px;
      text-align: left;
      display: flex;
      >img{
        height: 20px;
        width: 20px;
      }
      color: rgba(0,0,0,0.65);
    }
  }
}
</style>