<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要了解</h1>
      </div>
      <div class="event-list">
        <div class="item" v-for="(item, i) in eventList" :key="i">
          <div class="desc">{{item.desc}}</div>
          <div class="time">{{item.time}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Api from 'api';

export default {
  data() {
    return {
      selectedItem: '安全隐患类',
      typeList: ['安全隐患类', '经费资金类', '业务类', '其他'],
      eventList: [
        // { id: 1, desc: '1《民法典》第五编 婚姻家庭（2021.1.1起实施）', time: '2021-09-09 18:25:42' },
        // { id: 2, desc: '2《反家庭暴力法》（2016.3.1起实施）', time: '2021-09-09 17:24:11' },
        // { id: 3, desc: '3《未成年人保护法》（2007.6.1起实施）', time: '2021-09-09 15:46:21' },
      ],
    };
  },
  components: {},
  mounted() {
    this.getDataList();
  },
  methods: {
    handleTabSelect(name, title) {
      console.log(name, title);
      this.selectedItem = title;
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList() {
      Api.getHongArticleList({
        page: 1,
        pageSize: 20,
        type: 4,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        this.eventList = records;
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  padding: 10px;
  .event-list{
    .item{
      background: #fff;
      margin-top: 10px;
      border-radius: 5px;
      // height: 100px;
      padding: 10px;
      position: relative;
      .desc{
        line-height: 30px;
        font-size: 16px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: rgba(0,0,0,0.85);
      }
      .time{
          line-height: 20px;
          font-size: 12px;
          color: #707070;
      }
      .tag{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        background: #08f;
        color: #fff;
      }
    }
  }
}
</style>
