<template>
  <div class="lyjdContent" style="background: #fff; height: 100vh">
    <div class="page-container paddclose" style="height: calc(100vh - 60px);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>景点详情</h1>
      </div>
      <div class="hot-main" style="width: 100%;">
        <div class="top_banner" v-if="showData && showData.iconPath">
          <img :src="showData.iconPath" />
        </div>
        <div class="top_banner" v-else>
          <img src="../../assets/images/zjy/jdBanner.png" />
        </div>
        <div class="textCotent">
          <div class="name">{{showData.name}}</div>
          <div class="address">{{showData.address}}</div>
          <div class="desc">{{showData.desc}}</div>
        </div>
        <div class="plCotent">
          <div class="plTitle">
            <div>评论列表</div>
          </div>
          <div class="zntItem" v-for="(item, i) in list" :key="i">
            <div class="zntItemName">
              <img src="../../assets/images/zjy/tx.png" alt="">
              <span>{{item.name}}</span>
            </div>
            <div class="zntItemDesc">
              {{item.desc}}
            </div>
            <div class="zntItemTime">
              <div>{{item.time}}</div>
              <div class="edit">
                <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/dz.png" alt="">
                <div style="margin-right: 10px; margin-left: 5px">{{item.thumbsUp || 0}}</div> -->
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/fwl.png" alt="">
                <div style="margin-right: 10px; margin-left: 5px">{{item.look || 0}}</div>
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/pl.png" alt="">
                <div style="margin-right: 10px; margin-left: 5px">{{item.count || 0}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import zjyApi from 'api/zjy';
import service from 'utils/service';
export default {
  data() {
    return {
      showData: {
        // picture: require('../../assets/images/zjy/jdBanner.png'),
        // name: 'xxxx景点',
        // address: '浙江省绍兴市诸暨市XXXXX路128号',
        // desc: '目的地简介目的地简介目的地简介目的地简介目的地简介目的地简介目的地简介',
      },
      showDialog: false,
      name: '',
      phone: '',
      habbit: '',
      list: [
        // { name: '小梁', desc: '评论内容asldjf 奥斯卡的积分阿斯利康地方阿斯利康地方阿萨德飞机爱上发大水空间…', time: '24分钟前',look: 10, count: 9 },
        // { name: '小梁', desc: '评论内容asldjf 奥斯卡的积分阿斯利康地方阿斯利康地方阿萨德飞机爱上发大水空间…', time: '24分钟前',look: 22, count: 18 },
        // { name: '小梁', desc: '评论内容asldjf 奥斯卡的积分阿斯利康地方阿斯利康地方阿萨德飞机爱上发大水空间…', time: '24分钟前',look: 31, count: 14 },
        // { name: '小梁', desc: '评论内容asldjf 奥斯卡的积分阿斯利康地方阿斯利康地方阿萨德飞机爱上发大水空间…', time: '24分钟前',look: 35, count: 22 },
        // { name: '小梁', desc: '评论内容asldjf 奥斯卡的积分阿斯利康地方阿斯利康地方阿萨德飞机爱上发大水空间…', time: '24分钟前',look: 20, count: 6 },
      ],
    };
  },
  components: {},
  mounted(opt) {
    const { id } = this.$route.query;
    if(id) {
      this.getMarker(id)
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    addOrderEnroll() {
      Toast.success('报名成功');
      this.showDialog = false;
    },
    getMarker(id) {
      zjyApi.getMarkList().then(res => {
        if(res.flag) {
          this.showData = res.data.find(item => item.id == id);
          console.log(this.showData);
          
        }
      })
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
  .lyjdContent{
    .hot-main{
      height: 100% !important;
    }
    .top_banner{
      padding: 10px;
      width: 100%;
      >img{
        width: 100%;
        height: 220px;
      }
    }
    .textCotent{
      padding: 10px;;
      .name{
        font-size: 16px;
        color: rgba(0,0,0,1);
        line-height: 30px;
      }
      .address{
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        line-height: 20px;
      }
      .desc{
        font-size: 15px;
        color: rgba(0,0,0,0.85);
        line-height: 24px;
      }
    }
    .plCotent{
      width: 100%;
      .plTitle{
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #ecf1ff;
        padding: 0 10px;
        font-size: 16px;
      }

      .zntItem{
        padding: 10px;
        margin-bottom: 10px;
        background: #fff;
        position: relative;
        .zntItemTitle{
          line-height: 30px;
          color: #000;
          font-size: 17px;
          font-weight: bold;
        }
        .zntItemName{
          line-height: 24px;
          color: #000;
          font-size: 12px;
          display: flex;
          img{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
        .zntItemIconName{
          display: flex;
          height: 50px;
          padding: 5px;
          .image{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text{
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: #707070;
          }
        }
        .zntItemDesc{
          line-height: 24px;
          font-size: 14px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          color: rgba(0,0,0,0.85);
        }
        .zntItemImage{
          display: flex;
          img{
            width: 60px;
            height: 60px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .zntItemTime{
          line-height: 24px;
          font-size: 12px;
          color: #707070;
          display: flex;
          .edit{
            margin-left: auto;
            margin-right: 10px;
            display: flex;
            >div{
              line-height: 20px;
            }
            img{
              width: 12px;
              height: 12px;
              margin-top: 3.5px;
            }
          }
        }
        .userIconList{
          display: flex;
          flex-wrap: wrap;
          height: 70px;
          overflow-y: hidden;
          .iconItem{
            margin-right: 10px;
            margin-top: 10px;
            width: 50px;
            text-align: center;
            .topIcon{
              width: 50px;
              height: 30px;
              padding: 0 10px;
              >img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .bottomIcon{
              line-height: 20px;
              font-size: 12px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .tags{
          margin-left: 5%;
          background: rgba(0,0,0,0.15);
          border-radius: 5px;
          color: #000;
          padding: 0px 5px;
        }
        .resultType{
          position: absolute;
          right: -10px;
          top: 15px;
          height: 24px;
          line-height: 24px;
          padding: 0px 10px;
          padding-right: 15px;
          text-align: left;
          color: #fff;
          background: #26a2ff;
          border-radius: 12px;
        }
        .editBtn{
          position: absolute;
          right: 10px;
          top: 15px;
          height: 24px;
          line-height: 24px;
          padding: 0px 10px;
          text-align: left;
          color: #fff;
          background: #26a2ff;
          border-radius: 12px;
        }
      }
    }
  }
</style>
