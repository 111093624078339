import { render, staticRenderFns } from "./vanguard.vue?vue&type=template&id=2fa26952&scoped=true&"
import script from "./vanguard.vue?vue&type=script&lang=js&"
export * from "./vanguard.vue?vue&type=script&lang=js&"
import style0 from "./vanguard.vue?vue&type=style&index=0&id=2fa26952&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa26952",
  null
  
)

export default component.exports