<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      :title="style.title"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="body" :style="{ 'background-image': `url(${style.bg})` }">
      <div v-if="type == 1">
        <div
          class="info"
          :style="{
            color: style.mainColor,
            border: `2px solid ${style.borderColor}`,
          }"
        >
          <div class="title">
            <div class="name">可回收物</div>
            <div class="name-e">RECYCLABLE WASTE</div>
          </div>
          <div class="desc">
            适宜回收可以循环利用的生活废弃物。投放可回收物时，应尽量保持清洁干燥，避免污染；立体包装应清空内容物。
          </div>
        </div>
        <div class="type">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">物品类型</div>
          </div>
          <div class="desc">
            主要有<span :style="{ color: style.mainColor }">玻璃</span
            >（玻璃瓶罐、平板玻璃及其他玻璃制品）、<span
              :style="{ color: style.mainColor }"
              >金属</span
            >（铁、铜、铝等制品）、<span :style="{ color: style.mainColor }"
              >塑料</span
            >（泡沫塑料、塑料瓶、硬塑料、橡胶及橡胶制品等）、<span
              :style="{ color: style.mainColor }"
              >纸类</span
            >（报纸、传单、杂志、旧书、纸板箱等及其他未受污染的纸制品）、<span
              :style="{ color: style.mainColor }"
              >纺织类、小家电</span
            >。
          </div>
        </div>

        <div class="img">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">处理流程</div>
          </div>
          <img :src="style.img" />
        </div>
      </div>
      <div v-if="type == 2">
        <div
          class="info"
          :style="{
            color: style.mainColor,
            border: `2px solid ${style.borderColor}`,
          }"
        >
          <div class="title">
            <div class="name">易腐垃圾</div>
            <div class="name-e">PERISHABLE WASTE</div>
          </div>
          <div class="desc">
            指对人体健康或者自然环境造成直接或者潜在危害的生活垃圾。
          </div>
        </div>
        <div class="type">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">物品类型</div>
          </div>
          <div class="desc">
            主要有<span :style="{ color: style.mainColor }">剩菜剩饭</span
            >与<span :style="{ color: style.mainColor }">西餐糕点</span>等<span
              :style="{ color: style.mainColor }"
              >食物残余、菜梗菜叶、食物内脏、茶叶渣、水果残余、果壳瓜皮、家用盆景、花卉</span
            >等<span :style="{ color: style.mainColor }"
              >植物残枝落叶、食用油</span
            >等。
          </div>
        </div>

        <div class="img">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">处理流程</div>
          </div>
          <img :src="style.img" />
        </div>
      </div>
      <div v-if="type == 3">
        <div
          class="info"
          :style="{
            color: style.mainColor,
            border: `2px solid ${style.borderColor}`,
          }"
        >
          <div class="title">
            <div class="name">有害垃圾</div>
            <div class="name-e">HARMFUL WASTE</div>
          </div>
          <div class="desc">
            指对人体健康或者自然环境造成直接或者潜在危害的生活垃圾。
          </div>
        </div>
        <div class="type">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">物品类型</div>
          </div>
          <div class="desc">
            主要有<span :style="{ color: style.mainColor }"
              >充电电池、扣式电池、灯管、弃置药品、杀虫剂（容器）、油漆（容器）、硒鼓、水银产品</span
            >等。
          </div>
        </div>

        <div class="img">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">处理流程</div>
          </div>
          <img :src="style.img" />
        </div>
      </div>
      <div v-if="type == 4">
        <div
          class="info"
          :style="{
            color: style.mainColor,
            border: `2px solid ${style.borderColor}`,
          }"
        >
          <div class="title">
            <div class="name">其他垃圾</div>
            <div class="name-e">OTHER WASTE</div>
          </div>
          <div class="desc">
            指除可回收物、有害垃圾和易腐垃圾之外的其他生活垃圾。
          </div>
        </div>
        <div class="type">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">物品类型</div>
          </div>
          <div class="desc">
            主要有<span :style="{ color: style.mainColor }"
              >受污染与无法再生的纸张</span
            >（照片、复写纸、压敏纸、收据用纸、明信片、相册、卫生纸、底片等）、<span
              :style="{ color: style.mainColor }"
              >塑料与其他受污染的塑料制品、破旧陶瓷器、难以自然降解的食物残余</span
            >（大棒身、大贝壳）、<span :style="{ color: style.mainColor }"
              >卫生用品、烟头、少量尘土</span
            >等。
          </div>
        </div>

        <div class="img">
          <div class="top">
            <div class="line" :style="{ background: style.mainColor }"></div>
            <div class="title">处理流程</div>
          </div>
          <img :src="style.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      style: {
        title: "",
        bg: "",
        img: "",
        mainColor: "",
        assistantColor: "",
      },
    };
  },
  created() {
    this.type = this.$route.query.type || "4";
  },
  watch: {
    type(val) {
      console.log("==", val);
      switch (val) {
        case "1":
          this.style = {
            title: "可回收物详情",
            bg: require("../../assets/images/refuse/detail-bg1.png"),
            img: require("../../assets/images/refuse/detail-img1.png"),
            borderColor: "#3E71F9",
            mainColor: "#4384B5",
            assistantColor: "rgba(0,0,0,0.65)",
          };
          break;
        case "2":
          this.style = {
            title: "易腐垃圾详情",
            bg: require("../../assets/images/refuse/detail-bg2.png"),
            img: require("../../assets/images/refuse/detail-img2.png"),
            borderColor: "#2A5A11",
            mainColor: "#2A5A11",
            assistantColor: "#000",
          };
          break;
        case "3":
          this.style = {
            title: "有害垃圾详情",
            bg: require("../../assets/images/refuse/detail-bg3.png"),
            img: require("../../assets/images/refuse/detail-img3.png"),
            borderColor: "#D2260D",
            mainColor: "#D2260D ",
            assistantColor: "rgba(0,0,0,0.65)",
          };
          break;
        case "4":
          this.style = {
            title: "其他垃圾详情",
            bg: require("../../assets/images/refuse/detail-bg4.png"),
            img: require("../../assets/images/refuse/detail-img4.png"),
            borderColor: "#998F9D",
            mainColor: "#998F9D",
            assistantColor: "rgba(0,0,0,0.65)",
          };
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 0.75rem;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  display: flex;
  flex-flow: column nowrap;

  .info {
    margin-top: 0.6rem;
    padding: 1.2rem 1rem;
    border-radius: 4px;
    .title {
      display: flex;
      .name {
        font-size: 1.2rem;
      }
      .name-e {
        margin-left: 0.8rem;
        font-size: 1rem;
      }
    }
    .desc {
      line-height: 1.1rem;
      letter-spacing: 0.05rem;
      font-size: 0.9rem;
      margin-top: 0.8rem;
    }
  }

  .type {
    margin-top: 1.6rem;
    .top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .line {
        height: 1.1rem;
        width: 0.2rem;
      }
      .title {
        margin-left: 0.6rem;
        opacity: 0.85;
        font-size: 1.11rem;
        color: #000000;
      }
    }
    .desc {
      margin: 0.8rem;
      font-size: 0.9rem;
      letter-spacing: 0.05rem;
      line-height: 1.1rem;
    }
  }

  .img {
    margin-top: 1.6rem;
    .top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .line {
        height: 1.1rem;
        width: 0.2rem;
      }
      .title {
        margin-left: 0.6rem;
        opacity: 0.85;
        font-size: 1.11rem;
        color: #000000;
      }
    }
    img {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
</style>